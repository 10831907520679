import { AnnualYield, AnnualYieldPaginatedResult, restfulClient } from '@/restful';

export type ApyInfo = AnnualYield;

export interface ApyInfoPeriodMap {
  week: AnnualYield[] | null;
  month: AnnualYield[] | null;
  year: AnnualYield[] | null;
  today: AnnualYield | null;
}

interface GetApyInfoPeriodMapOptions {
  totalApyAmendValue?: number;
}

/**
 * 获取以时间段为 key，ApyInfo 为 value 的 map
 * totalApyAmendValue 为 totalAPY 修正值，传入一个数值，则所有的 totalAPY 都会加上这个数值，可以传负数
 */
export const getApyInfoPeriodMap = async (
  tokenAddress: string,
  vaultAddress: string,
  options: GetApyInfoPeriodMapOptions = {},
) => {
  const { totalApyAmendValue } = options;

  const getAnnualYieldHistory = (interval: string) =>
    restfulClient.annualYieldHistory({
      token: tokenAddress,
      vault: vaultAddress,
      interval: interval,
    });

  const [{ data: apyInDay }, { data: apyInMonth }, { data: apyToday }] = await Promise.all([
    getAnnualYieldHistory('inDay'),
    getAnnualYieldHistory('inMonth'),
    restfulClient.annualYieldLatest({
      token: tokenAddress,
      vault: vaultAddress,
    }),
  ]);

  const amendApy = (apyInfo: AnnualYield) => {
    if (!totalApyAmendValue) return apyInfo;
    const newApyInfo: AnnualYield = {
      ...apyInfo,
      totalApy: (apyInfo.totalApy += totalApyAmendValue),
    };
    return newApyInfo;
  };

  const setDuetCakeFarmApyToZero = (apyInfo: AnnualYield) => {
    if (apyInfo.depositTokenSymbol === 'DUET-CAKE') {
      return {
        ...apyInfo,
        swapFarmApr: 0,
        totalApy: apyInfo.totalApy - apyInfo.swapFarmApr,
      };
    }
    return apyInfo;
  };

  const apyInfos: ApyInfoPeriodMap = {
    week: apyInDay.result.slice(0, 7).map(amendApy).map(setDuetCakeFarmApyToZero),
    month: apyInDay.result.map(amendApy).map(setDuetCakeFarmApyToZero),
    year: apyInMonth.result.map(amendApy).map(setDuetCakeFarmApyToZero),
    today: setDuetCakeFarmApyToZero(amendApy(apyToday)),
  };

  return apyInfos;
};
