import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { message, Slider } from 'antd';
import cs from 'classnames';
import commifyBigNumber from '@/utils/commify';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import useVaultBalance from '@/hooks/useVaultBalance';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { useStrategy } from '@/pages/DepositDetail/context';
import useIsInnovationDeposit from '@/hooks/useIsInnovationDeposit';
import { useWeb3 } from '@/web3';
import { formatEther } from '@ethersproject/units';
import styles from './style.module.scss';
import { injectContractLpFarmingVault } from '../../../../../contracts';

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
}

const Withdraw: FC<Props> = props => {
  const { refreshDataCount, refreshData } = props;
  const { account, contractContainer } = useWeb3();
  const [inputAmount, setInputAmount] = useState(0);
  const [dyTokenBalance, setDyTokenBalance] = useState<BigNumber>();
  const [inputStr, setInputStr] = useState('');
  const strategy = useStrategy();
  const pageTokenVaultAddress = strategy?.vaultAddress;

  const { vaultBalance, refreshVaultBalance } = useVaultBalance({
    vaultAddress: pageTokenVaultAddress,
  });

  useEffect(() => {
    refreshVaultBalance();
  }, [refreshDataCount]);

  // 获取 token 的 vault dyToken 的 balance, 生息资产
  useEffect(() => {
    (async () => {
      if (!strategy || !contractContainer || !account) return;
      const { vaultAddress } = strategy;
      const contract = injectContractLpFarmingVault(contractContainer, vaultAddress);
      let theBalance: BigNumber;
      try {
        theBalance = await contract.deposits(account);
        setDyTokenBalance(theBalance);
      } catch (e) {
        console.error('deposits error: ', e);
      }
    })();
  }, [account, strategy, refreshDataCount, contractContainer]);

  const withdraw = async () => {
    if (!strategy || !dyTokenBalance || !contractContainer || !account) return;
    const { vaultAddress } = strategy;
    const contract = injectContractLpFarmingVault(contractContainer, vaultAddress).connect(
      contractContainer.getSigner(account),
    );
    let amount: BigNumber;
    if (inputAmount === 100) {
      amount = dyTokenBalance;
    } else {
      amount = dyTokenBalance.mul(+inputAmount * 100).div(10000);
    }
    try {
      await contract.estimateGas.withdraw(amount, true);

      const promise = contract.withdraw(amount, true, { gasLimit: 1500000 });
      await executeAndShowTx(promise, {
        throwError: true,
      });
      refreshData();
      setInputAmount(0);
    } catch (e: any) {
      console.error(e);
      if (e?.data?.message === 'execution reverted: LOW_COLLATERAL') {
        const errorMessage =
          'The withdrawal amount is too large to maintain the credit limit, please adjust the withdrawal ratio.';
        message.error(errorMessage);
        console.error(`${errorMessage}. Error: `, e);
      }
    }
  };

  const handleAmountChange = (value: number) => {
    setInputAmount(value);
  };

  useEffect(() => {
    setInputStr(`${inputAmount}`);
  }, [inputAmount]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const str = event.currentTarget.value;
    if (str === '' || /^([1-9]?\d|100)$/.test(str)) {
      setInputStr(str);
      if (str !== '' && !Number.isNaN(+str)) {
        setInputAmount(+str);
      }
    }
  };
  const handleInputBlur: ChangeEventHandler<HTMLInputElement> = event => {
    setInputStr(`${inputAmount}`);
  };

  const buttonDisabled = dyTokenBalance?.isZero() || !inputAmount || +inputAmount === 0;

  // const isMobile = useIsMobile();

  const handleMinus = () => {
    if (inputAmount && inputAmount >= 1) {
      setInputAmount(inputAmount - 1);
    }
  };

  const handleAdd = () => {
    if (inputAmount !== undefined && inputAmount <= 99) {
      setInputAmount(inputAmount + 1);
    }
  };

  const isInnovation = useIsInnovationDeposit();

  return (
    <section className={styles.WithdrawWrapper}>
      {!isInnovation && (
        <>
          <section className={styles.TokenDisplay}>{strategy?.symbol}</section>
          <Margin top={45} />
        </>
      )}

      {!isInnovation && (
        <FlexRow justifyContent="space-between">
          <Slider
            style={{ width: 360 }}
            min={0}
            max={100}
            onChange={handleAmountChange}
            value={typeof inputAmount === 'number' ? inputAmount : 0}
          />

          <FlexRow justifyContent="space-between">
            <div className={styles.minusButton} onClick={handleMinus}>
              <i className={cs('iconfont icon-No', styles.iconNo)} />
            </div>
            <Margin left={10} />
            <div className={styles.percentInputOuter}>
              <input
                className={styles.PercentInput}
                value={inputStr}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
            </div>
            <Margin left={10} />
            <div className={styles.addButton} onClick={handleAdd}>
              <i className={cs('iconfont icon-jia', styles.iconJia)} />
            </div>
          </FlexRow>
        </FlexRow>
      )}

      {isInnovation && (
        <FlexRow direction="column">
          <FlexRow justifyContent="space-between">
            <div className={cs(styles.minusButton, styles.innovationAddButton)} onClick={handleMinus}>
              <i className={cs('iconfont icon-No', styles.iconNo, styles.innovationIcon)} />
            </div>
            <Margin left={10} />
            <div className={styles.percentInputOuter}>
              <input
                className={cs(styles.PercentInput, styles.InnovationPercentInput)}
                value={inputStr}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
            </div>
            <Margin left={10} />
            <div className={cs(styles.addButton, styles.innovationAddButton)} onClick={handleAdd}>
              <i className={cs('iconfont icon-jia', styles.iconJia, styles.innovationIcon)} />
            </div>
          </FlexRow>

          <Margin top={20} />

          <Slider
            className={styles.Slider}
            min={0}
            max={100}
            onChange={handleAmountChange}
            value={typeof inputAmount === 'number' ? inputAmount : 0}
          />
        </FlexRow>
      )}

      <Margin top={25} />
      <div className={styles.balance}>
        Vault Balance: <div className={styles.balanceValue}>{commifyBigNumber(vaultBalance)}</div>
      </div>
      <Margin top={30} />
      <FlexRow justifyContent="flex-start">
        <DButton width={590} onClick={withdraw} disabled={buttonDisabled}>
          Withdraw
        </DButton>
      </FlexRow>
    </section>
  );
};

export default Withdraw;
