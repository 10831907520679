const config = {
  mainChainId: 97,
  CHAIN_ID: 97,
  NETWORK_NAME: 'BNB Chain Testnet',
  WALLET_NETWORK_NAME_IN_CHAIN_REGISTRY: 'Binance Smart Chain Testnet',
  APYInfo: '',
  LinkUSDOracle: '0x7e050b7d933ca188ffee7f26c854ab93e12d84b8',

  THE_GRAPH_API_URL: 'https://api.thegraph.com/subgraphs/name/iuscript/duet-protocol-chapel',
  API_BASE_URL: 'https://testapi.duet.finance/api',
  CDN_BASE_URL: 'https://duet.s3.amazonaws.com',

  APP_CONTROLLER: '0x4Fbd235fB57eB883EEC84e358d96316285409c83',
  READER: '0xADFC0Ca730f8cD12B333669A0651D61E744cD712',

  FEE_CONF: '0x117eD755Cd56Cb05D7f0E1124cf9896B310e9127',
  DUET_ZAP: '0xEE65acF09fd74D81dE6eB238223dd7d09A820d9a',

  USDT: '',
  DAI: '',
  USDC: '0x34F8e601579a9377ee69C91f25FC28A423893411',
  USDC_VAULT: '0x8C7b9161C73b38747535eE7893bC2085F9a2CD84',
  USDC_DYTOKEN: '0x3910B37b1cE9edccD61Edbfd0AE5b6bE4cA2625D',

  BUSD: '0x0b7b53a3c0E8620a170f7228685ad4686F440406',
  BUSD_VAULT: '0x55D6943e74e681B4fDB2B82491B4C6B377617908',
  BUSD_DYTOKEN: '0x255Eb0BF29e49712f584Ab2373e7D4da8a2f72E6',
  BUSD_ENHANCE_VAULT: '0x476399983494a1988d013Cf9A041becFd0F4f060',
  BUSD_ENHANCE_DYTOKEN: '0xD71B4C282a2a5CBaa575B0e49A261181c5A5727C',

  BNB_USDC: '0x4A5Eab5013A5A22C4DE028EDc1d2fC359F6ef68D',
  BNB_USDC_VAULT: '0x002cFc3F60e1e9696EB9A29886601BF8a83a4A9A',
  BNB_USDC_DYTOKEN: '0xF4ed65ccf9422edBf21D763d5Cc96F8F45533B87',

  USDC_BUSD: '0x5B12F9971eD9B0B55d30bF74c17118332e472cAf',
  USDC_BUSD_VAULT: '0x9E8773aa9e3AB144EF19773AcBdf588E571c7508',
  USDC_BUSD_DYTOKEN: '0xefD292dc2897E7850D50168011E5e6cddf7Fec38',

  DUSD: '0x12391c27c090797bd82f25aada705e53ba873161',
  DUSD_VAULT: '0x8e6f56857CCE0Bfb0801eA98BA2f51Adb130349e',

  BUSD_DUSD: '0xf96b013A6270fc50a187AE05B2BccF4A4b962c05',
  USDC_DUSD: '0xa8A0A63FdD7d4f9c89a252c55cBb5C50A115B945',
  DUET: '0x749e007884eA4340Ae22Ee9d62Ca5C1352705555',
  WBNB: '0xA314A75563cCE9AeF91d132C72737aCf301E0735',
  ABC: '0x96502b12ffdc2126d24301093ceb9a06bdf99555',
  DUET_VAULT: '',
  // // Bond
  // DUET_BOND: '0x3957fCdF79bE8E77bBdb6f5f31721c761125Bfb4',
  // BOND_READER: '0x2CCf3448BeAfA37B2FA38448175806Fce59AA47a',
  // // FARM
  // VAULT_FARM: '0x2DB9829876A4ae9b24C9D8D485c303695018541e',

  // 测试短周期 Bond
  DUET_BOND: '0x92CD5dC4E9E9d1f85e66894fc69Ab6b139aaF101',
  BOND_READER: '0xe9a28655dD25696242D0De01A50165b6A335EE74',
  // FARM
  VAULT_FARM: '0x446fD920309fCD1ec9a4CE3E3b5817B92fF10dC4',

  // 合成资产
  DWTI: '0xadE399b27cF8d4c352F01e4089835c8D0277e2E4',
  DWTI_VAULT: '0x78adb1bF1690773e4E67946061E14437d66D37A1',
  DBTC: '0xCBF036362d56F4ECb9b79490D0B9EB01F1d147c3',
  DBTC_VAULT: '0x965f74D9f5543fEab15A6410672D6CDF7a68e3E1',
  DJPY: '0x13bf94d7a383B2B7570175daD2886C887065E735',
  DJPY_VAULT: '0xEAe820D294beac1804dBbCE04c08fBC74c6E4791',

  // EbCake
  CAKE: '0xfa60d973f7642b748046464e165a65b7323b0dee',
  DUET_BUSD: '0xb50776a4e01a1b2704d35cee69cfbd6d37910f12',
  CAKE_BUSD: '0x78df8ca5bb516efe4dade3726b0c052ba8bdcf57',
  CAKE_VAULT: '',

  MULTI_REWARDS_MASTER_CHEF: '0xF24f701BF8682C2f2b29819f9E5f00e669ABD11A',

  // 715 赔偿合约
  RETRIEVE_TOKENS: '0x1a3FE61c830dfD51633A7ec8C8A1Ff39E40561A0',

  DWTI_BUSD: '0xc104ab269303bf5606f118914aff8a25f7498313',
  DXAU_BUSD: '',
  DTMC_BUSD: '',
  DUET_CAKE: '',
};

export default config;
