import { useEffect, useState } from 'react';
import fetchPublicVaultData, { fetchVaultFees } from '../state/pools/fetchVaultPublic';

export const useCakeVault = () => {
  const [cakeVault, setCakeVault] = useState<any>({});

  useEffect(() => {
    Promise.all([fetchPublicVaultData(), fetchVaultFees()]).then(([res1, res2]) => {
      setCakeVault({
        totalShares: res1.totalShares || undefined,
        pricePerFullShare: res1.pricePerFullShare || undefined,
        fees: {
          performanceFeeAsDecimal: res2.performanceFee,
        },
      });
    });
  }, []);

  return cakeVault;
};
