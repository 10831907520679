import { Address } from '../config/constants/types';
import addresses from '../config/constants/contracts';

export const getAddress = (address: Address): string => {
  const chainId = +import.meta.env.VITE_CHAIN_ID as 97 | 56;
  return address[chainId]!;
};

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef);
};

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall);
};

export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault);
};
