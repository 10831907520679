import Card from '@/components/Card';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const DusdAnnouncement: FC = () => (
  <DusdAnnouncementWrapper>
    We encountered some issues at Block 19575513. The contract codes and user funds in the protocol are safe & only dUSD
    related assets were affected. A snapshot of Block 19575512 was taken and solutions for the affected assets have been
    rolled out.{' '}
    <Link to="/compensation-20220715" target="_blank">
      click here for details.
    </Link>
  </DusdAnnouncementWrapper>
);

export default DusdAnnouncement;

const DusdAnnouncementWrapper = styled.div`
  font-size: 20px;
  color: var(--common-white);
  padding: 30px;
`;
