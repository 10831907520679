import { ZapRoutes } from '../types';

const zapRoutes: ZapRoutes = {
  ABC: {
    BUSD: ['WBNB'],
    USDC: [],
  },
};

export default zapRoutes;
