import Tabs, { PerTab } from '@/components/Tab';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useWeb3 } from '@/web3';
import { BigNumber } from 'ethers';
import { predicate } from '@/utils/lang';
import EbCakeSingle from './components/EbCakeSingle';
import EbCakeLp from './components/EbCakeLp';
import { useEbCakeInfo } from '../../context';
import { EbCake } from '../../server';

const Claim: FC = () => {
  const { ebCakes } = useEbCakeInfo();

  if (ebCakes.length === 0) return null;

  return (
    <ClaimWrapper>
      <Tabs
        tabs={ebCakes
          ?.map((each: EbCake) => {
            return {
              key: each?.name,
              label: each?.name,
              content: (
                <FlexWrapper>
                  <EbCakeSingle ebCake={each} />
                  <EbCakeLp ebCake={each} />
                </FlexWrapper>
              ),
            };
          })
          .filter(predicate)}
      />
    </ClaimWrapper>
  );
};

export default Claim;

const ClaimWrapper = styled.div`
  margin-top: 40px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
