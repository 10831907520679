import { IContractContainer } from '@/fixtures/web3/contract/base';
import type { Network, Web3Provider } from '@ethersproject/providers';
// import type { IContractContainer } from './define';

export class ContractContainer implements IContractContainer {
  static readonly map = new WeakMap<Web3Provider, ContractContainer>();

  static async get(library: Web3Provider) {
    const { map } = ContractContainer;
    const network = await library.getNetwork();
    if (!map.has(library)) map.set(library, new ContractContainer(library, network));
    return map.get(library)!;
  }

  constructor(private readonly library: Web3Provider, private readonly network: Network) {}

  get context() {
    return {
      library: this.library,
      network: this.network,
    };
  }

  getSigner(address: string) {
    return this.library.getSigner(address);
  }
}

export * from './define';

export * from './standalone';
export * from './fungible';
