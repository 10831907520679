import { FC } from 'react';
import styled from 'styled-components';

const Footer: FC = () => <FooterWrapper>Copyright © 2022 Duet . All rights reserved.</FooterWrapper>;

export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  bottom: 0px;
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #707482;
  line-height: 19px;
  padding-bottom: 20px;
`;
