import Card from '@/components/Card';
import DusdAnnouncement from '@/components/DusdAnnouncement';
import FlexRow from '@/components/FlexRow';
import MyVault from '@/components/MyVault/MyVault';
import { Strategy } from '@/config/types';
import useIsMobile from '@/hooks/useIsMobile';
import { FC } from 'react';
import Chart from './components/Chart';
import Farm from './components/Farm';
import StrategyDescription from './components/StrategyDescription';
import SupplyWithdraw from './components/SupplyWithdraw';
import InnovationSupplyWithdraw from './components/SupplyWithdraw/InnovationSupplyWithdraw';
import styles from './style.module.scss';

interface Props {
  strategy: Strategy;
}

const InnovationDepositDetail: FC<Props> = props => {
  const { strategy } = props;

  const isMobile = useIsMobile();

  return (
    <section className={styles.DepositDetailWrapper}>
      <MyVault />
      <section className={styles.CardContainer}>
        <FlexRow direction="column">
          <Card width={430}>
            <DusdAnnouncement />
          </Card>
          <Farm cardWidth={430} />
        </FlexRow>
        <FlexRow direction="column">
          {!isMobile && (
            <Card width={690} height={439}>
              <Chart />
            </Card>
          )}
          <StrategyDescription />
        </FlexRow>
      </section>
    </section>
  );
};

export default InnovationDepositDetail;
