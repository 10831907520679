import { networkConfigMap } from '@/config/network';
import sample from 'lodash/sample';

// Array of available nodes to connect to
export const nodes = networkConfigMap[import.meta.env.VITE_CHAIN_ID].rpcUrls;

const getNodeUrl = () => {
  return sample(nodes);
};

export default getNodeUrl;
