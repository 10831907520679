import cs from 'classnames';
import styles from './style.module.scss';

interface radioItem {
  label: string;
  value: string | number;
}

interface BaseRadioGroupProps {
  optionList: radioItem[]; // 配置列表
  activeRadio: string | number; // 选中项
  onValueChange?: (val: string | number) => void; // 切换函数
}

const BaseRadioGroup = ({ optionList, activeRadio, onValueChange }: BaseRadioGroupProps) => {
  return (
    <section className={styles.BaseRadioGroup}>
      {optionList.map((item, index) => {
        return (
          <div
            key={index}
            className={cs(styles.radioItem, { [styles.activeRadio]: activeRadio === item?.value })}
            onClick={() => {
              if (item?.value !== activeRadio) {
                onValueChange?.(item?.value);
              }
            }}>
            {item?.label}
          </div>
        );
      })}
    </section>
  );
};

export default BaseRadioGroup;
