import DButton from '@/components/DButton';
import PercentDisplay from '@/components/PercentDisplay';
import config from '@/config/config';
import { injectContractEbCakeFarmingPool } from '@/contracts';
import useERC20Balance from '@/hooks/useERC20Balance';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { useCatchError, useEbCakeInfo } from '@/pages/EbCake/context';
import { EbCake } from '@/pages/EbCake/server';
import commifyBigNumber from '@/utils/commify';
import { useWeb3 } from '@/web3';
import { parseEther } from 'ethers/lib/utils';
import { FC, useState } from 'react';
import BaseStakeView, { KeyValue } from './BaseStakeView';

interface Props {
  ebCake: EbCake;
}

const SingleStake: FC<Props> = props => {
  const { ebCake: ebCakeInfo } = props;

  const { contractContainer, account } = useWeb3();
  const { refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const [inputAmount, setInputAmount] = useState('');
  const executeAndShowTx = useExecuteAndShowTx();

  const stake = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCakeFarmingPool(
      contractContainer,
      ebCakeInfo.addresses.ebCakeFarmingPoolAddress,
    ).connect(contractContainer.getSigner(account));

    await contract.estimateGas.stake(parseEther(inputAmount)).then(async gasEstimated => {
      await executeAndShowTx(
        contract.stake(parseEther(inputAmount), {
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      setInputAmount('');
      refresh();
    }, onError);
  };

  if (!ebCakeInfo) return null;

  const infos: KeyValue[] = [
    {
      key: 'Total Staked',
      value: commifyBigNumber(ebCakeInfo.singleTokenBaseInfo?.totalStaked),
    },
    {
      key: 'ebCake APY',
      value: <PercentDisplay>{ebCakeInfo.ebCakeAPR}</PercentDisplay>,
    },
    {
      key: 'bDuet APY',
      value: <PercentDisplay>{ebCakeInfo.yield?.bDuetAPY}</PercentDisplay>,
    },
  ];

  if (!ebCakeInfo) return null;

  return (
    <BaseStakeView
      title={{
        text: 'Single Stake',
        description: 'Staking with ebCAKE single coin',
      }}
      infos={infos}
      token={{
        balance: ebCakeInfo.balance,
      }}
      inputAmount={inputAmount}
      setInputAmount={setInputAmount}
      operationButton={
        <DButton
          disabled={inputAmount === ''}
          height={40}
          width={120}
          onClick={stake}
          approveFrom={ebCakeInfo.addresses.tokenAddress}
          approveTo={ebCakeInfo.addresses.ebCakeFarmingPoolAddress}>
          Stake
        </DButton>
      }
    />
  );
};

export default SingleStake;
