import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';

export const ItemList: FC<{ items: ListItem[]; style?: CSSProperties }> = ({ items, style }) => {
  return (
    <ListBox style={style}>
      {items.map(({ icon, label, key, onClick }) => (
        <li key={key}>
          <a
            onClick={e => {
              if (!onClick) return;
              if (onClick() !== true) e.stopPropagation();
            }}>
            <figure>{icon}</figure>
            {label}
          </a>
        </li>
      ))}
    </ListBox>
  );
};

const textSize = 14;
const iconSide = 32;

const ListBox = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: var(--bg-black4);
  border-radius: 8px;
  border: 1px solid var(--bg-purple3);
  z-index: 50;
  pointer-events: none;

  & > li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: ${Math.max(textSize, iconSide)}px;
    margin: 8px;
    &:hover {
      background: var(--bg-black3);
      border-radius: 4px;
    }

    & > a {
      display: flex;
      width: 100%;
      text-align: left;
      pointer-events: auto;
      font-size: ${textSize}px;
      padding: 0 4px;
      align-items: center;
      color: var(--common-white);

      & > figure {
        margin: 0;
        padding: 4px;
        min-width: ${iconSide}px;
        width: ${iconSide}px;
        min-height: ${iconSide}px;
        height: ${iconSide}px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        & > * {
          max-height: ${iconSide - 4}px;
          max-width: ${iconSide - 4}px;
        }
      }
    }
  }
`;

type ListItem = {
  icon: ReactNode;
  label: ReactNode;
  key: string;
  onClick?: () => boolean;
};
