import { ContractInterface } from 'ethers';

export const DusdZapAbi: ContractInterface = [
  'event OwnershipTransferred(address indexed,address indexed)',
  'event ZapToLP(address,uint256,address,uint256)',
  'function coinToLp(address,bool) payable returns (uint256)',
  'function coinToLpbyPath(address,bool,address[],address[]) payable returns (uint256)',
  'function coinToToken(address,bool) payable returns (uint256)',
  'function coinToTokenbyPath(bool,address[]) payable returns (uint256)',
  'function controller() view returns (address)',
  'function initialize(address,address,address,address)',
  'function minter() view returns (address)',
  'function owner() view returns (address)',
  'function renounceOwnership()',
  'function routePair(address) view returns (address)',
  'function setMinter(address)',
  'function setRoutePairAddress(address,address)',
  'function sweep(address[],bool)',
  'function tokenToLp(address,uint256,address,bool)',
  'function tokenToLpbyPath(address,uint256,address,bool,address[],address[])',
  'function tokenToToken(address,uint256,address,bool) returns (uint256)',
  'function tokenToTokenbyPath(uint256,bool,address[]) returns (uint256)',
  'function transferOwnership(address)',
  'function withdraw(address)',
  'function zapOut(address,uint256)',
];
