import styled from '@emotion/styled';
import { FC } from 'react';
import ebcake from '@/assets/ebcake.png';
import { Link, useLocation } from 'react-router-dom';

const ebCakeRoute = `/ebcake/${import.meta.env.VITE_EBCAKE_GROUP || 'beta'}`;

const EbCakeBanner: FC = () => {
  const location = useLocation();

  if (location.pathname === ebCakeRoute) return null;

  return (
    <Link to={ebCakeRoute}>
      <EbCakeBannerWrapper>
        <BannerLeft>
          <img src={ebcake} alt="EbCake" />
          <span>Enjoy highest $CAKE staking rewards</span>
        </BannerLeft>
        <BannerRight>
          <i className="iconfont icon-jiantou1" />
        </BannerRight>
      </EbCakeBannerWrapper>
    </Link>
  );
};

export default EbCakeBanner;

const EbCakeBannerWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }

  width: calc(100% + 40px);
  height: 50px;
  margin: 30px -20px 0;
  background: var(--bg-purple3);
  padding: 0 20px;

  justify-content: space-between;
  align-items: center;
`;

const BannerLeft = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 12px;
    color: rgb(255, 147, 77);
    font-family: Poppins;
    font-weight: 500;
  }
`;

const BannerRight = styled.div`
  .iconfont {
    font-size: 18px;
    color: var(--white);
    cursor: pointer;
  }
`;
