import { Strategy } from '@/config/types';
import { BigNumber, constants } from 'ethers/lib/ethers';
import { createContext, useContext } from 'react';

const { Zero } = constants;

export interface TokenData {
  walletBalance?: BigNumber;
  liability?: BigNumber;
  marketCap: BigNumber | null;
  discountRatio?: number; // example: 9500 (95%)
  premiumRatio?: number; // example: 10000 (100%)
}

interface DataContextProps {
  tokenData: TokenData;
  refreshData: () => void;
  strategy?: Strategy;
}

export const initialTokenData: TokenData = {
  walletBalance: Zero,
  liability: Zero,
  marketCap: Zero,
  // price: Zero,
};

const initialContext = {
  tokenData: initialTokenData,
  refreshData: () => {
    //
  },
  strategy: undefined,
  apyInfoMap: null,
};

const DataContext = createContext<DataContextProps>(initialContext);

export const useTokenData = () => {
  const { tokenData, refreshData } = useContext(DataContext);
  return { tokenData, refreshData };
};

export const useStrategy = () => {
  const { strategy } = useContext(DataContext);
  return strategy;
};

export default DataContext;
