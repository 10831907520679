import { LockedValue, restfulClient } from '@/restful';

export interface TvlInfoPeriodMap {
  week: LockedValue[] | null;
  month: LockedValue[] | null;
  year: LockedValue[] | null;
  today: LockedValue | null;
}

export const getTvlInfoPeriodMap = async (vault: string) => {
  const getLockedValueHistory = (interval: string) =>
    restfulClient.lockedValueHistory({
      vault: vault,
      interval: interval,
    });

  const [{ data: tvlInDay }, { data: tvlInMonth }, { data: tvlToday }] = await Promise.all([
    getLockedValueHistory('inDay'),
    getLockedValueHistory('inMonth'),
    restfulClient.lockedValueLatest({
      vault: vault,
    }),
  ]);

  const tvlInfoPeriodMap: TvlInfoPeriodMap = {
    week: tvlInDay.result.slice(0, 7),
    month: tvlInDay.result,
    year: tvlInMonth.result,
    today: tvlToday,
  };

  return tvlInfoPeriodMap;
};
