import styled from '@emotion/styled';
import { FC } from 'react';
import bunnyPic from '@/assets/bunny.png';

const QA: FC = () => (
  <QAWrapper>
    <Title>Why Use Duet $CAKE Liquid Staking?</Title>
    <QACardWrapper>
      <QACard>
        <i className="iconfont icon-a-01" />
        <div>Staked $ebCAKE earns the highest reward in the $CAKE syrup pool as well have the $bDuet rewards</div>
      </QACard>
      <QACard>
        <i className="iconfont icon-a-02" />
        <div>
          Sell a portion of your proceeds or exit all of your staked $CAKEs early with the liquidity available in the
          secondary market
        </div>
      </QACard>
      <QACard>
        <i className="iconfont icon-a-03" />
        <div>Provide $ebCAKE LP to earn trading fees or use as collateral in the DeFi Protocols</div>
      </QACard>
    </QACardWrapper>
  </QAWrapper>
);

export default QA;

const QACardWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const QAWrapper = styled.div`
  & > div:last-child {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: var(--text-white3);
  line-height: 45px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const QACard = styled.div`
  padding: 80px 30px 0;
  width: 360px;
  height: 360px;
  border-radius: 10px;
  border: 1px solid #493f87;

  @media (max-width: 768px) {
    width: 100%;

    margin-top: 20px;
  }

  .iconfont {
    display: block;
    font-size: 80px;
    line-height: 80px;
    color: #613bf4;
  }

  div {
    font-size: 20px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: var(--text-white3);
    line-height: 30px;
    margin-top: 30px;
  }
`;
