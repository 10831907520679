import { BigNumber, constants, ethers } from 'ethers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useERC20Balance from '@/hooks/useERC20Balance';
import { useBorrowsAmount } from '@/hooks/useLiability';
import { useVaultInfo } from '@/components/MyVault/context';
import { ZERO_ADDRESS } from '@/constants';
import {
  allDAssetsStrategyList,
  dAssetsArchivedStrategyList,
  dAssetsInnovationStrategyList,
  dAssetsStrategyList,
} from '@/config/allLpTokens';
import MyVault from '@/components/MyVault/MyVault';
import { useWeb3 } from '@/web3';
import { DisplayPosition } from '@/config/types';
import { getDusdMarketCap } from '@/utils/getDusdMarketCap';
import DusdAnnouncement from '@/components/DusdAnnouncement';
import Card from '@/components/Card';
import MortgageMint from './components/MortgageMint';
import Information from './components/Information';
import DataContext, { TokenData } from './context';
import styles from './style.module.scss';
import { injectContractAppController, injectContractReader } from '../../contracts';
import SytheticPause from './components/SytheticPause';

const DAssetsDetail: FC = () => {
  const { address } = useParams();
  const { account, contractContainer } = useWeb3();

  const currentStrategy = allDAssetsStrategyList.find(each => each.address === address);
  const vaultAddress = currentStrategy?.vaultAddress;
  const isArchived = currentStrategy?.displayPosition === DisplayPosition.DASSETS_ARCHIVED;
  const dusdRelated = currentStrategy?.symbol.includes('dUSD');

  const { accountBalance, refreshAccountBalance } = useERC20Balance(address);
  const { borrowsAmount, refreshBorrowsAmount } = useBorrowsAmount(vaultAddress);
  const { refreshVaultInfo } = useVaultInfo();
  const [marketCap, setMarketCap] = useState<BigNumber | null>(null);
  const [refreshMarketCapCount, setRefreshMarketCapCount] = useState(0);
  const [valueConf, setValueConf] = useState<{ discountRatio: number; premiumRatio: number } | null>(null);

  useEffect(() => {
    (async () => {
      if (!vaultAddress || !address || !contractContainer) {
        setMarketCap(null);
        setValueConf(null);
        return;
      }

      const vaults = [vaultAddress];

      const readerContract = injectContractReader(contractContainer);
      const appCtrlContract = injectContractAppController(contractContainer);

      const [readerResult, valueConfResult] = await Promise.all([
        readerContract.getDTokenVaultPrice(vaults, ZERO_ADDRESS, false),
        address && appCtrlContract.getValueConf(address),
      ]);

      if (currentStrategy.symbol === 'dUSD') {
        const dusdMarketCap = await getDusdMarketCap(contractContainer);
        setMarketCap(dusdMarketCap);
      } else {
        setMarketCap(readerResult[3][0]);
      }

      if (valueConfResult) {
        const [_, discountRatio, premiumRatio] = valueConfResult;
        setValueConf({ discountRatio, premiumRatio });
      } else {
        setValueConf(null);
      }
    })();
  }, [vaultAddress, refreshMarketCapCount, address, contractContainer]);

  const tokenData: TokenData = useMemo(
    () => ({
      // liability 包含了手续费
      liability: borrowsAmount?.mul(100).div(99),
      walletBalance: accountBalance,
      marketCap,
      ...valueConf,
    }),
    [borrowsAmount, accountBalance, marketCap, valueConf],
  );
  const refreshData = useCallback(() => {
    refreshBorrowsAmount();
    refreshAccountBalance();
    refreshVaultInfo();
    setRefreshMarketCapCount(refreshMarketCapCount + 1);
  }, [refreshBorrowsAmount, refreshAccountBalance, refreshVaultInfo]);

  const context = useMemo(
    () => ({
      tokenData,
      refreshData,
      strategy: currentStrategy,
    }),
    [tokenData, refreshData],
  );

  // 当 account 变化时，刷新数据
  useEffect(() => {
    refreshData();
  }, [account]);

  if (!currentStrategy) return null;

  if (!address || !ethers.utils.isAddress(address)) return null;

  return (
    <section className={styles.DAssetsDetailWrapper}>
      <DataContext.Provider value={context}>
        <MyVault />
        <section className={styles.CardContainer}>
          <section className={styles.LeftArea}>
            <Information />
          </section>
          <section className={styles.RightArea}>
            {/* {!isMobile && <Chart />} */}
            {/* {isArchived && <RepayPause />} */}
            {dusdRelated && (
              <Card>
                <DusdAnnouncement />
              </Card>
            )}
            <MortgageMint />
            {/* <SytheticPause /> */}
          </section>
        </section>
      </DataContext.Provider>
    </section>
  );
};

export default DAssetsDetail;
