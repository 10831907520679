import { ContractInterface } from 'ethers';

export const LpFarmingVault: ContractInterface = [
  'event ControllerChanged(address)',
  'event Deposit(address indexed,uint256)',
  'event FarmChanged(address)',
  'event FeeConfChanged(address)',
  'event Liquidated(address indexed,address indexed,uint256)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event Withdraw(address indexed,uint256)',
  'function PercentBase() view returns (uint256)',
  'function controller() view returns (address)',
  'function deposit(address,uint256)',
  'function depositTo(address,address,uint256)',
  'function deposits(address) view returns (uint256)',
  'function farm() view returns (address)',
  'function feeConf() view returns (address)',
  'function initialize(address,address,address)',
  'function isDuetVault() view returns (bool)',
  'function liquidate(address,address,bytes)',
  'function owner() view returns (address)',
  'function pair() view returns (address)',
  'function pendingValue(address,uint256) view returns (uint256)',
  'function renounceOwnership()',
  'function setAppController(address)',
  'function setFeeConf(address)',
  'function setVaultFarm(address)',
  'function syncDeposit(address,uint256,address)',
  'function token0() view returns (address)',
  'function token1() view returns (address)',
  'function transferOwnership(address)',
  'function underlying() view returns (address)',
  'function underlyingAmountValue(uint256,bool) view returns (uint256)',
  'function userValue(address,bool) view returns (uint256)',
  'function withdraw(uint256,bool)',
  'function withdrawTo(address,uint256,bool)',
];
