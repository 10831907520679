import React from 'react';

import Notification from 'rc-notification';
import { NotificationInstance } from 'rc-notification/lib/Notification';
import { v4 as uuidv4 } from 'uuid';
import { NOTIFICATION_DURATION } from '@/config/time';
import TxNotification from '../components/TxNotification';

let notification: NotificationInstance | null = null;
Notification.newInstance(
  {
    maxCount: 5,
  },
  n => {
    notification = n;
  },
);

const txSuccess = (chainId: number, address: string): void => {
  notification?.notice({
    duration: NOTIFICATION_DURATION,
    content: (
      <TxNotification
        chainId={chainId}
        key={uuidv4()}
        address={address}
        type="success"
        text="Your transaction is completed!"
      />
    ),
  });
};

const txFail = (chainId: number, address: string): void => {
  notification?.notice({
    duration: NOTIFICATION_DURATION,
    content: (
      <TxNotification
        chainId={chainId}
        key={uuidv4()}
        address={address}
        type="error"
        text="Your transaction is failed!"
      />
    ),
  });
};

const txPending = (chainId: number, address: string): void => {
  notification?.notice({
    duration: NOTIFICATION_DURATION,
    content: (
      <TxNotification
        chainId={chainId}
        key={uuidv4()}
        address={address}
        type="info"
        text="Your transaction is waiting for block confirmation."
      />
    ),
  });
};

export { txSuccess, txFail, txPending };
