import DButton from '@/components/DButton';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import config from '@/config/config';
import { injectContractEbCake } from '@/contracts/fungible';
import useERC20Balance from '@/hooks/useERC20Balance';
import commifyBigNumber from '@/utils/commify';
import toFixed from '@/utils/toFixed';
import { useWeb3 } from '@/web3';
import styled from 'styled-components';
import { message } from 'antd';
import { parseEther } from 'ethers/lib/utils';
import { FC, useMemo, useState } from 'react';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import moment from 'moment';
import { useCatchError, useEbCakeInfo } from '../context';
import { EbCake } from '../server';

interface Props {
  refreshEbCakeInfo: () => void;
}

const Convert: FC<Props> = props => {
  const { refreshEbCakeInfo: refresh } = props;
  const { contractContainer, account } = useWeb3();
  const { ebCakes } = useEbCakeInfo();
  const ebCakeInfo = useMemo(() => {
    const nowMoment = moment();
    for (const ebCake of ebCakes) {
      if (
        ebCake.timeWindow?.convertWindow?.[0]?.isBefore(nowMoment) &&
        ebCake.timeWindow?.convertWindow?.[1]?.isAfter(nowMoment)
      ) {
        return ebCake;
      }
    }
    return null;
  }, [ebCakes]);

  const [inputAmount, setInputAmount] = useState<string>('');
  const { accountBalance: cakeBalance, refreshAccountBalance } = useERC20Balance(config.CAKE);
  // const { ebCakeInfo, refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const executeAndShowTx = useExecuteAndShowTx();

  const convert = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCake(contractContainer, ebCakeInfo.addresses.interactionContractAddress).connect(
      contractContainer.getSigner(account),
    );

    await contract.estimateGas.convert(parseEther(inputAmount)).then(async gasEstimated => {
      await executeAndShowTx(contract.convert(parseEther(inputAmount), { gasLimit: gasEstimated.mul(12).div(10) }));
      setInputAmount('');
      refresh();
      refreshAccountBalance();
    }, onError);
  };

  const maxString = toFixed(cakeBalance);

  if (!ebCakeInfo) return null;
  const inConvertTimeWindow =
    ebCakeInfo.timeWindow?.convertWindow &&
    moment().isBetween(ebCakeInfo.timeWindow.convertWindow[0], ebCakeInfo.timeWindow.convertWindow[1]);

  return (
    <ConvertWrapper>
      <div>
        <DInput value={inputAmount} onChange={setInputAmount} width={600} maxAmount={maxString} />
        <BalanceWrapper>CAKE Balance: {commifyBigNumber(cakeBalance)}</BalanceWrapper>
      </div>

      <Margin left={40} />
      <DButton
        onClick={convert}
        disabled={inputAmount === '' || !inConvertTimeWindow}
        width={260}
        approveFrom={config.CAKE}
        approveTo={ebCakeInfo.addresses.interactionContractAddress}>
        Convert
      </DButton>
    </ConvertWrapper>
  );
};

export default Convert;

const ConvertWrapper = styled.div`
  display: flex;
  padding: 40px 0 0;

  @media (max-width: 768px) {
    flex-direction: column;

    > section {
      margin-top: 20px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

const BalanceWrapper = styled.span`
  margin-top: 10px;
  font-size: 16px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(--text-gray2);
`;
