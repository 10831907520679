/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react';
import { ethers, Contract, utils } from 'ethers';
import { message } from 'antd';
import getTokens from '@/constants/tokenList';
import SelectToken from '@/components/SelectToken';
import useTokenBalance from '@/components/TokenInput/useTokenBalance';
import DButton from '@/components/DButton';
import { Token } from '@/types/token';
import executeAndShowTx from '@/utils/executeAndShowTx';
import TokenInput from '@/components/TokenInput';
import { useWeb3 } from '@/web3';
import { TradeLayout } from './Layout';
import { useTokenData } from '../../../context';
import { getPairAddressBySymbols } from './tokenPairAddress';
import useAmount from './useAmount';
import FeeContent from './FeeContent';
import { injectContractDuetUsdMinerPair, injectContractDUsd } from '../../../../../contracts';

const { parseEther } = utils;

const LEFT_TOKEN_SYMBOLS = ['dUSD'];
const RIGHT_TOKEN_SYMBOLS = ['BUSD', 'USDC'];

const Redeem: React.FC = () => {
  const { account, chainId, contractContainer } = useWeb3();

  const tokenList = useMemo(() => getTokens(chainId) || [], [chainId]);

  const [leftToken, setLeftToken] = useState<Token>();
  const [rightToken, setRightToken] = useState<Token>();
  const [pairContract, setPairContract] = useState<Contract>();
  const [pairContractAddress, setPairContractAddress] = useState<string>();
  const { refreshData } = useTokenData();

  const {
    amountIn,
    amountOut,
    amountOutFee,
    setAmountIn,
    setAmountOut,
    setAmountOutFee,
    onAmountInChange,
    onAmountOutChange,
  } = useAmount({
    pairContract,
    account,
  });

  useEffect(() => {
    const BUSDToken = tokenList.find(token => token.symbol === 'dUSD');
    setLeftToken(BUSDToken);
    const DUSDToken = tokenList.find(token => token.symbol === 'BUSD');
    setRightToken(DUSDToken);
  }, [tokenList]);

  // 每当 leftToken 或 rightToken 变化，则代币对合约随之变化
  useEffect(() => {
    if (!leftToken || !rightToken || !chainId || !contractContainer) return;
    const pairAddress = getPairAddressBySymbols(chainId, leftToken.symbol, rightToken?.symbol);
    if (!pairAddress) {
      console.error('pairAddress not found');
      return;
    }
    const contract = injectContractDuetUsdMinerPair(contractContainer, pairAddress);
    setPairContract(contract);
    setPairContractAddress(pairAddress);
  }, [leftToken, rightToken, chainId, contractContainer]);

  const { balance: leftTokenBalance, refreshBalance: refreshLeftTokenBalance } = useTokenBalance({
    tokenAddress: leftToken?.address,
    account,
  });

  const { balance: rightTokenBalance, refreshBalance: refreshRightTokenBalance } = useTokenBalance({
    tokenAddress: rightToken?.address,
    account,
  });

  const redeem = async () => {
    if (!pairContract || !contractContainer) return;
    const isValid = await pairContract.checkUnderRedeemLimit(parseEther(amountIn));

    if (!isValid) {
      message.error(`The quantity of redeem in a single time shall not be greater than 50% of the quantity in vamm`);
      return;
    }

    if (!leftToken?.address || !account) return;

    const contract = injectContractDUsd(contractContainer, leftToken.address).connect(
      contractContainer.getSigner(account),
    );

    parseEther(amountOut).toHexString();
    const extraData = parseEther(amountOut).toHexString();

    const promise = contract.send(pairContractAddress, parseEther(amountIn), extraData);
    await executeAndShowTx(promise);

    refreshLeftTokenBalance();
    refreshRightTokenBalance();
    refreshData();
    clearInput();
  };

  const clearInput = () => {
    setAmountIn('');
    setAmountOut('');
    setAmountOutFee('');
  };

  const onRightTokenChange = (token: Token) => {
    setRightToken(token);
    clearInput();
  };

  const leftTokenList = tokenList.filter(token => LEFT_TOKEN_SYMBOLS.includes(token.symbol));
  const rightTokenList = tokenList.filter(token => RIGHT_TOKEN_SYMBOLS.includes(token.symbol));

  const rate = useMemo(() => {
    if (!amountOut || !amountOutFee) {
      return '';
    }
    return `1${leftToken?.symbol || ''} = ${(+amountOut / +amountIn).toFixed(4)}${rightToken?.symbol || ''}`;
  }, [leftToken, rightToken, amountIn, amountOut, amountOutFee]);

  const fee = useMemo(() => {
    if (!amountOutFee || !rightToken) {
      return '';
    }
    return `${amountOutFee}${rightToken?.symbol}`;
  }, [amountOutFee, rightToken]);

  const canRedeem = useMemo(
    () => leftTokenBalance && amountIn && ethers.utils.parseEther(amountIn).lte(leftTokenBalance),
    [leftTokenBalance, amountIn],
  );

  return (
    <TradeLayout
      leftHand={
        <TokenInput showClear showMax value={amountIn} balance={leftTokenBalance} onChange={onAmountInChange}>
          <SelectToken selectList={leftTokenList} onChange={setLeftToken} tokenSymbol={leftToken?.symbol} />
        </TokenInput>
      }
      rightHand={
        <TokenInput
          showInfo
          infoContent={<FeeContent rate={rate} fee={fee} />}
          value={amountOut}
          balance={rightTokenBalance}
          onChange={onAmountOutChange}>
          <SelectToken selectList={rightTokenList} onChange={onRightTokenChange} tokenSymbol={rightToken?.symbol} />
        </TokenInput>
      }
      approve={
        <DButton onClick={redeem} disabled={!canRedeem}>
          Redeem
        </DButton>
      }
    />
  );
};

export default Redeem;
