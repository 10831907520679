import { MultiRewardsMasterChefAbi } from '@/abis/MultiRewardsMasterChefAbi';
import { RetrieveTokensAbi } from '@/abis/RetrieveTokensAbi';
import { createContractInjector } from './define';
import config from '../config/config';
import { ReaderAbi } from '../abis/ReaderAbi';
import { AppControllerAbi } from '../abis/AppControllerAbi';
import { BondReaderAbi } from '../abis/BondReader';
import { SingleBondAbi } from '../abis/SingleBond';
import { LinkUSDOracle } from '../abis/LinkUSDOracle';
import { FeeConfAbi } from '../abis/FeeConfAbi';
import { VaultFarmAbi } from '../abis/VaultFarmAbi';
import { DusdZapAbi } from '../abis/DuetZapAbi';

/**
 * https://github.com/duet-protocol/Duet-Over-Collateralization-us/blob/f28ce6bce38e67dd3e83cf911fc83b7a7c66da73/contracts/mock/Reader.sol
 */
export const injectContractReader = createContractInjector(config.READER, ReaderAbi);

/**
 * https://github.com/duet-protocol/Duet-Over-Collateralization-us/blob/a1fc615ae3bbf89d4f168e28554489e7d1c48075/contracts/AppController.sol
 */
export const injectContractAppController = createContractInjector(config.APP_CONTROLLER, AppControllerAbi);

/**
 * https://github.com/duet-protocol/bond-farming/blob/69770ed001d1701493f8f6d8cfef0956bc434e14/contracts/mock/BondReader.sol
 */
export const injectContractBondReader = createContractInjector(config.BOND_READER, BondReaderAbi);

/**
 * https://github.com/duet-protocol/bond-farming/blob/afa1c0c1b6d07cfd785e5681f763d0f769aa5d98/contracts/SingleBond.sol
 */
export const injectContractSingleBond = createContractInjector(config.DUET_BOND, SingleBondAbi);

/**
 * https://github.com/duet-protocol/Duet-Stable-Coin-Minter/blob/823826b8f391a23f64b19ff6977c65d3c1a006f9/contracts/LinkUSDOracle.sol
 */
export const injectContractLinkUsdOracle = createContractInjector(config.LinkUSDOracle, LinkUSDOracle);

/**
 * https://github.com/duet-protocol/Duet-Over-Collateralization-us/blob/cb6e563e81bd07c0b71e9d78d0107b758836d218/contracts/FeeConf.sol
 */
export const injectContractFeeConf = createContractInjector(config.FEE_CONF, FeeConfAbi);

/**
 * https://github.com/duet-protocol/bond-farming/blob/4cc81de0ddcaeb1895d20aaf451fecc0dd749e19/contracts/VaultFarm.sol
 */
export const injectContractVaultFarm = createContractInjector(config.VAULT_FARM, VaultFarmAbi);

/**
 * https://github.com/duet-protocol/Duet-Over-Collateralization-us/blob/cb6e563e81bd07c0b71e9d78d0107b758836d218/contracts/DuetZap.sol
 */
export const injectContractDuetZap = createContractInjector(config.DUET_ZAP, DusdZapAbi);

export const injectContractRetrieveTokens = createContractInjector(config.RETRIEVE_TOKENS, RetrieveTokensAbi);

export const injectContractMultiRewardsMasterChef = createContractInjector(
  config.MULTI_REWARDS_MASTER_CHEF,
  MultiRewardsMasterChefAbi,
);
