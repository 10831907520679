/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
import { ZapRoutes } from '../types';
import allLpTokens from './allLpTokens';

const beZapTokens = allLpTokens.filter(each => !each.symbol.includes('-')).map(each => each.symbol);

const zapTokens = allLpTokens.filter(each => !each.symbol.includes('-')).map(each => each.symbol);

const purgeRedundant = (route: string[]) => {
  for (let i = 0; i < route.length; i += 1) {
    for (let j = route.length - 1; j > i; j -= 1) {
      if (route[i] === route[j]) {
        return [...route.slice(0, i), ...route.slice(j)];
      }
    }
  }
  return route;
};

const getZapRoutes = () => {
  const map: ZapRoutes = {};
  for (const originToken of zapTokens) {
    map[originToken] = {};
    for (const targetToken of beZapTokens) {
      if (originToken === targetToken) {
        map[originToken][targetToken] = [];
        continue;
      }

      let route: string[] = [];
      // 设置 target token 兑换路径
      if (targetToken === 'dUSD') {
        route.push('WBNB', 'BUSD', targetToken);
      } else if (targetToken.startsWith('d')) {
        route.push('WBNB', 'BUSD', 'dUSD', targetToken);
      } else if (targetToken === 'DUET') {
        route.push('WBNB', 'CAKE', targetToken);
      } else if (targetToken === 'WBNB') {
        route.push(targetToken);
      } else {
        route.push('WBNB', targetToken);
      }

      // 设置 origin token 兑换路径
      if (originToken === 'dUSD') {
        route.unshift(originToken, 'BUSD', 'WBNB');
      } else if (originToken.startsWith('d')) {
        route.unshift(originToken, 'dUSD', 'BUSD', 'WBNB');
      } else if (originToken === 'DUET') {
        route.unshift(originToken, 'CAKE', 'WBNB');
      } else {
        route.unshift(originToken, 'WBNB');
      }

      route = purgeRedundant(route);
      route.pop();
      route.shift();

      map[originToken][targetToken] = route;
    }
  }
  return map;
};

const zapRoutes = getZapRoutes();

export default zapRoutes;
