import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;
type Handler = (e: Event) => void;

export function useOnClickOutside(elRef: RefObject<HTMLElement>, handler: Handler) {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!elRef.current || !event.target) return;
      if (elRef.current.contains(event.target as any)) return;
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [elRef, handler]);
}
