import { ReactNode } from 'react';

export const descriptionMap: Record<string, ReactNode> = {
  dWTI: (
    <div>
      dWTI is a synthetic asset whose price is pegged to WTI crude oil.
      <br />
      <br />
      <span>
        West Texas Intermediate (WTI) crude oil is a specific grade of crude oil and one of the three main benchmarks in
        oil pricing. WTI is the underlying commodity of the New York Mercantile Exchange's (NYMEX) oil futures contract.
      </span>
    </div>
  ),
  dUSD: 'dUSD is a stable coin based on algorithm adjustment and over-collateralization',
  dXAU: (
    <div>
      dXAU is a synthetic gold token whose price is pegged to XAU/USD.
      <br />
      <br />
      Gold is traded continuously throughout the world based on the intra-day spot price, derived from over-the-counter
      gold-trading markets around the world (code: XAU). AU is the code for Gold on the Periodic table of elements, and
      the price above is Gold quoted in US Dollars. Gold was the basis of economic capitalism for hundreds of years
      until the repeal of the Gold standard, which led to the expansion of a fiat currency system in which paper money
      doesn't have an implied backing with any physical form of monetization.
    </div>
  ),
  dTMC: 'Synthetic Total Crypto Currency Market Cap (dTMC) tracks the total market capitalization of crypto markets. The price for dTMC equals to the actual crypto market cap in USD divded by 1 billion.',
};
