import { usePrevious } from 'ahooks';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop: FC = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location.pathname !== prevLocation?.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
