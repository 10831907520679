import { Checkbox } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Announcement: FC = () => {
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const agree = localStorage.getItem('agree');
    if (!agree) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'static';
    }
  }, [visible]);

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const clickStart = () => {
    if (!checked) return;
    localStorage.setItem('agree', 'true');
    setVisible(false);
  };

  return (
    <div>
      {visible && (
        <AnnouncementWrapper>
          <div className="modal">
            <div className="title">Welcome to Duet Protocol</div>
            <div className="content-area">
              <p>
                - Duet Protocol is a synthetic asset minter built on a yield aggregator that automates the process of
                yield farming, maximizes its returns, and releases additional liquidity to the users.
              </p>
              <p>- If you encounter any bugs, please report in the "suggestions" channel on our Discord.</p>
              <p>- Please do your own research before investment and always invest at your own risk.</p>
              <p>
                - Please confirm that you are not from the "Prohibited Regions", which includes Cuba, the Democratic
                People’s Republic of Korea (North Korea), Iran, Syria, Crimea (a region of Ukraine annexed by the
                Russian Federation), China (including the Chinese mainland, Hong Kong, and the Macao Special
                Administrative Region), Malaysia, the United Kingdom, the United States (including all American
                territories, such as Puerto Rico, American Samoa, Guam, Northern Mariana Islands, and the Virgin
                Islands), Bangladesh, Bolivia, Ecuador, and Kyrgyzstan.
              </p>
            </div>
            <div className="terms">
              <Checkbox checked={checked} onChange={handleChange}>
                I have read and agreed to{' '}
                <a
                  target="_blank"
                  href="https://duet-protocol.gitbook.io/duet-protocol/library/terms-of-service"
                  rel="noreferrer">
                  Terms of Service
                </a>
              </Checkbox>
            </div>
            <div className="button-row">
              <div onClick={clickStart} className={classNames('button', { disabled: !checked })}>
                Start
              </div>
            </div>
          </div>
        </AnnouncementWrapper>
      )}
    </div>
  );
};

export default Announcement;

const AnnouncementWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;

  .modal {
    margin: 112px auto 0;
    max-width: 530px;
    height: 598px;
    background: #232228;
    border-radius: 15px;
    text-align: center;
    padding: 40px;
    z-index: 101;
    overflow: scroll;

    @media (max-width: 768px) {
      margin: 20px auto 0;
    }
  }

  .title {
    font-size: 24px;
    font-family: Inter-Bold, Inter;
    font-weight: bold;
    color: #7872ff;
    line-height: 29px;
  }

  .content-area {
    /* width: 450px; */
    /* height: 340px; */
    max-height: 360px;
    overflow: scroll;
    background: #0c0b10;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;

    p {
      font-size: 12px;
      font-family: inter;
      font-weight: 400;
      color: #a1a7bb;
      line-height: 18px;
      text-align: left;
    }
  }

  .terms {
    font-size: 14px;
    margin-top: 20px;
    color: #a1a7bb;
    line-height: 19px;

    span {
      color: #a1a7bb;
    }

    a {
    }
  }

  .button-row {
    margin-top: 40px;

    .button {
      /* width: 450px; */
      height: 40px;
      line-height: 40px;
      background: #7872ff;
      border-radius: 20px;
      text-align: center;
      color: white;
      cursor: pointer;
      user-select: none;

      &.disabled {
        background: #484657;
        color: #9195a5;
        cursor: auto;
      }
    }
  }
`;
