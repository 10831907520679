import React from 'react';
import _ from 'lodash';
import DAssetsDetail from './pages/DAssetsDetail';
import DepositList from './pages/Deposit';
import DAssetsList from './pages/DAssets';
import Bond from './pages/Bond';
import Invitation from './pages/Invitation';
import DepositDetail from './pages/DepositDetail';
import EbCakePage from './pages/EbCake';
import Compensation from './pages/Compensation';

interface MenuItem {
  icon: string;
  text: string;
  route: string;
  link: string;
  description: string;
  component: React.ComponentType;
  outerLink?: boolean;
  children?: {
    route: string;
    component: React.ComponentType;
  }[];
  isNew?: boolean;
  hideInMenu?: boolean;
}

const menus: MenuItem[] = [
  {
    icon: 'icon-EARN_Menu',
    text: 'Earn',
    route: '/deposit-list',
    link: '/deposit-list',
    component: DepositList,
    description:
      'Yield Optimizer that enhances your returns through auto-compounding strategy and bDuet incentives to specific assets.',
    children: [
      {
        route: '/deposit-detail/:address',
        component: DepositDetail,
      },
      {
        route: '/innovation-deposit-detail/:address',
        component: DepositDetail,
      },
      {
        route: '/archived-deposit-detail/:address',
        component: DepositDetail,
      },
    ],
  },
  {
    icon: 'icon-dAsset_Menu',
    text: 'dAssets',
    route: '/dAssets-list',
    link: '/dAssets-list',
    component: DAssetsList,
    description: 'Borrow synthetic assets against your EARN balances with Over-Collateralization Minter.',
    children: [
      {
        route: '/dAssets-detail/:address',
        component: DAssetsDetail,
      },
      {
        route: '/archived-dassets-detail/:address',
        component: DAssetsDetail,
      },
    ],
  },
  {
    icon: 'icon-bond',
    text: 'bDuet',
    route: '/bond',
    link: '/bond',
    component: Bond,
    description: 'Reinvest $DUET in Bond market for yields or close $Bonded Duet positions prematurely at a discount',
  },
  // {
  //   icon: 'icon-Referral',
  //   text: 'Referral',
  //   route: '/referral',
  //   component: Invitation,
  //   description: 'Join the Duet Protocol Referral Program and Earn Rewards.',
  // },
  {
    icon: 'icon-ebcake',
    text: 'ebCAKE',
    route: '/ebcake/:group',
    link: `/ebcake/${import.meta.env.VITE_EBCAKE_GROUP || 'beta'}`,
    component: EbCakePage,
    description: '',
    isNew: true,
  },
  {
    icon: 'icon-zhanwei',
    text: 'Compensation',
    route: '/compensation-20220715',
    link: `/compensation-20220715`,
    component: Compensation,
    description: '',
    hideInMenu: true,
  },
  // {
  //   icon: 'icon-zhanwei',
  //   text: 'Vault',
  //   route: '/vault'
  // },
  // {
  //   icon: 'icon-zhanwei',
  //   text: 'Farm',
  //   route: '/farm'
  // },
  // {
  //   icon: 'icon-zhanwei',
  //   text: 'Governance',
  //   route: '/governance'
  // },
];

interface FlattenedRoute {
  route: string;
  component: React.ComponentType;
}

const flattenRoute = (theMenus: any[]): FlattenedRoute[] => {
  return theMenus.reduce((a, c) => [...a, _.omit(c, 'children'), ...(c.children ? flattenRoute(c.children) : [])], []);
};

export const flattenedRoutes = flattenRoute(menus);

export default menus;
