import { FC } from 'react';
import FlexRow from '../FlexRow';
import styles from './style.module.scss';

interface Props {
  token?: {
    symbol: string;
    image: string;
  };
}

const TokenDisplay: FC<Props> = props => {
  const { token } = props;

  return (
    <section className={styles.TokenWrapper}>
      <FlexRow>
        <img className={styles.tokenImage} src={token?.image} alt={token?.symbol} />
        <span className={styles.tokenSymbol}>{token?.symbol}</span>
      </FlexRow>
    </section>
  );
};

export default TokenDisplay;
