/* eslint-disable no-console */
import { Token } from '@/types/token';
import { message } from 'antd';
import getCdnResource from './getCdnResource';

const addTokenToWallet = async (token: Token, tokenImage?: string) => {
  const { address, symbol, decimals = 18 } = token;

  if (!window.ethereum?.isMetaMask) {
    message.error('Importing token is not supported within current wallet.');
    return;
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.

    // @TODO: provide by typed module
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wasAdded = await window.ethereum?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address, // The address that the token is at.
          symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals, // The number of decimals in the token
          image: tokenImage || getCdnResource(`/token/${symbol}.png`), // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.info('Thanks for your interest!');
    } else {
      console.info('Your loss!');
    }
  } catch (error) {
    console.error(error);
  }
};

export default addTokenToWallet;
