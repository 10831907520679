/* Auto generated by "scripts/gen/web3.ts" */

import { createContractInjector, createFungibleContractInjectorFactory } from './base';

import { AccessControl__factory } from '@duet-protocol/contracts/lib/aligned';
import { AccidentHandler20220715V3__factory } from '@duet-protocol/contracts/lib/aligned';
import { Adminable__factory } from '@duet-protocol/contracts/lib/aligned';
import { AggregatorV3Interface__factory } from '@duet-protocol/contracts/lib/aligned';
import { AppController__factory } from '@duet-protocol/contracts/lib/aligned';
import { ApyHelper__factory } from '@duet-protocol/contracts/lib/aligned';
import { BEP20__factory } from '@duet-protocol/contracts/lib/aligned';
import { BTCMintVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { BaseStrategy__factory } from '@duet-protocol/contracts/lib/aligned';
import { BondFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { BondLPFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { BondLPPancakeFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { BondReader__factory } from '@duet-protocol/contracts/lib/aligned';
import { BondToken__factory } from '@duet-protocol/contracts/lib/aligned';
import { CloneFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { ContextUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { DBTC__factory } from '@duet-protocol/contracts/lib/aligned';
import { DPPOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { DPPOracleAdmin__factory } from '@duet-protocol/contracts/lib/aligned';
import { DPPStorage__factory } from '@duet-protocol/contracts/lib/aligned';
import { DPPTrader__factory } from '@duet-protocol/contracts/lib/aligned';
import { DPPVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { DTMC__factory } from '@duet-protocol/contracts/lib/aligned';
import { DUSD__factory } from '@duet-protocol/contracts/lib/aligned';
import { DWTI__factory } from '@duet-protocol/contracts/lib/aligned';
import { DXAU__factory } from '@duet-protocol/contracts/lib/aligned';
import { DYSBUSD__factory } from '@duet-protocol/contracts/lib/aligned';
import { DYTokenBase__factory } from '@duet-protocol/contracts/lib/aligned';
import { DYTokenBaseUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { DYTokenERC20__factory } from '@duet-protocol/contracts/lib/aligned';
import { DYTokenNative__factory } from '@duet-protocol/contracts/lib/aligned';
import { DepositVaultBase__factory } from '@duet-protocol/contracts/lib/aligned';
import { DexUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { DodoOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { DoubleBond__factory } from '@duet-protocol/contracts/lib/aligned';
import { DoubleBondsFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { DppRouter__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetDPPFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetDppController__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetDppLp__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetDppLpFunding__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetDppStorage__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetMath__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetUSDMinerPair__factory } from '@duet-protocol/contracts/lib/aligned';
import { DuetZap__factory } from '@duet-protocol/contracts/lib/aligned';
import { EIP712Upgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { ERC165__factory } from '@duet-protocol/contracts/lib/aligned';
import { ERC20Clonable__factory } from '@duet-protocol/contracts/lib/aligned';
import { ERC20PermitUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { Epoch__factory } from '@duet-protocol/contracts/lib/aligned';
import { ExtendableBond__factory } from '@duet-protocol/contracts/lib/aligned';
import { ExtendableBondReader__factory } from '@duet-protocol/contracts/lib/aligned';
import { ExtendableBondRegistry__factory } from '@duet-protocol/contracts/lib/aligned';
import { ExtendableBondedCake__factory } from '@duet-protocol/contracts/lib/aligned';
import { ExtendableBondedReaderCake__factory } from '@duet-protocol/contracts/lib/aligned';
import { FeeConf__factory } from '@duet-protocol/contracts/lib/aligned';
import { FeeRateModel__factory } from '@duet-protocol/contracts/lib/aligned';
import { IAccessControl__factory } from '@duet-protocol/contracts/lib/aligned';
import { IBEP20__factory } from '@duet-protocol/contracts/lib/aligned';
import { IBondLPFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { IBondLPPancakeFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { IBondToken__factory } from '@duet-protocol/contracts/lib/aligned';
import { IBondTokenUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { ICloneFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDODOApproveProxy__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDODOCallee__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDODOV2__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDPP__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDPPAdmin__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDPPController__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDPPOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDPPOracleAdmin__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDYSToken__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDYToken__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDepositVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDexUsdOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDuetOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IDusdMinter__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC165__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC20MetadataUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC20Permit__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC20PermitUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC20Upgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { IERC2612__factory } from '@duet-protocol/contracts/lib/aligned';
import { IEpoch__factory } from '@duet-protocol/contracts/lib/aligned';
import { IExtendableBondedCake__factory } from '@duet-protocol/contracts/lib/aligned';
import { IFarming__factory } from '@duet-protocol/contracts/lib/aligned';
import { IFeeConf__factory } from '@duet-protocol/contracts/lib/aligned';
import { IFeeRateImpl__factory } from '@duet-protocol/contracts/lib/aligned';
import { IFeeRateModel__factory } from '@duet-protocol/contracts/lib/aligned';
import { ILiquidateCallee__factory } from '@duet-protocol/contracts/lib/aligned';
import { IMasterChefV2__factory } from '@duet-protocol/contracts/lib/aligned';
import { IMigratorChef__factory } from '@duet-protocol/contracts/lib/aligned';
import { IMintVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { IOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IPair__factory } from '@duet-protocol/contracts/lib/aligned';
import { IPancakeFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { IPancakeMasterChefV2__factory } from '@duet-protocol/contracts/lib/aligned';
import { IPancakePair__factory } from '@duet-protocol/contracts/lib/aligned';
import { IPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { IRouter__factory } from '@duet-protocol/contracts/lib/aligned';
import { IRouter02__factory } from '@duet-protocol/contracts/lib/aligned';
import { ISingleBond__factory } from '@duet-protocol/contracts/lib/aligned';
import { IStrategy__factory } from '@duet-protocol/contracts/lib/aligned';
import { IUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IUniswapV2Factory__factory } from '@duet-protocol/contracts/lib/aligned';
import { IUniswapV2Pair__factory } from '@duet-protocol/contracts/lib/aligned';
import { IVaultFarm__factory } from '@duet-protocol/contracts/lib/aligned';
import { IWithdrawCallee__factory } from '@duet-protocol/contracts/lib/aligned';
import { IWooracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { IZap__factory } from '@duet-protocol/contracts/lib/aligned';
import { Initializable__factory } from '@duet-protocol/contracts/lib/aligned';
import { InitializableOwnable__factory } from '@duet-protocol/contracts/lib/aligned';
import { Keepable__factory } from '@duet-protocol/contracts/lib/aligned';
import { KeeperBase__factory } from '@duet-protocol/contracts/lib/aligned';
import { KeeperCompatible__factory } from '@duet-protocol/contracts/lib/aligned';
import { KeeperCompatibleInterface__factory } from '@duet-protocol/contracts/lib/aligned';
import { LinkUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { Liquidate__factory } from '@duet-protocol/contracts/lib/aligned';
import { LpFarmingVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { MasterChefV2__factory } from '@duet-protocol/contracts/lib/aligned';
import { MintVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { MintVaultBase__factory } from '@duet-protocol/contracts/lib/aligned';
import { MockERC20__factory } from '@duet-protocol/contracts/lib/aligned';
import { MockExtendableBond__factory } from '@duet-protocol/contracts/lib/aligned';
import { MockIBondFarmingPool__factory } from '@duet-protocol/contracts/lib/aligned';
import { MockUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { MultiRewardsMasterChef__factory } from '@duet-protocol/contracts/lib/aligned';
import { Multicall__factory } from '@duet-protocol/contracts/lib/aligned';
import { OracleKeeper__factory } from '@duet-protocol/contracts/lib/aligned';
import { Ownable__factory } from '@duet-protocol/contracts/lib/aligned';
import { PausableUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { Pool__factory } from '@duet-protocol/contracts/lib/aligned';
import { Reader__factory } from '@duet-protocol/contracts/lib/aligned';
import { ReentrancyGuardUpgradeable__factory } from '@duet-protocol/contracts/lib/aligned';
import { SBUSDFarmingVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { SingleBond__factory } from '@duet-protocol/contracts/lib/aligned';
import { SingleBondsFactory__factory } from '@duet-protocol/contracts/lib/aligned';
import { SingleFarmingVault__factory } from '@duet-protocol/contracts/lib/aligned';
import { Strategy2ForCake__factory } from '@duet-protocol/contracts/lib/aligned';
import { Strategy2ForPancakeLP__factory } from '@duet-protocol/contracts/lib/aligned';
import { StrategyForCake__factory } from '@duet-protocol/contracts/lib/aligned';
import { StrategyForPancakeLP__factory } from '@duet-protocol/contracts/lib/aligned';
import { StrategyKeeper__factory } from '@duet-protocol/contracts/lib/aligned';
import { TMCUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { TokenRecipient__factory } from '@duet-protocol/contracts/lib/aligned';
import { VaultFarm__factory } from '@duet-protocol/contracts/lib/aligned';
import { WETH9__factory } from '@duet-protocol/contracts/lib/aligned';
import { WooOracleAdapter__factory } from '@duet-protocol/contracts/lib/aligned';
import { ZeroUSDOracle__factory } from '@duet-protocol/contracts/lib/aligned';
import { createContract__AccidentHandler20220715V3 } from '@duet-protocol/contracts/lib/aligned';
import { createContract__AppController } from '@duet-protocol/contracts/lib/aligned';
import { createContract__ApyHelper } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Weekly_BondFarmingPool } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Yearly_BondFarmingPool } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Weekly_BondLPFarmingPool } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Yearly_BondLPFarmingPool } from '@duet-protocol/contracts/lib/aligned';
import { createContract__BondReader } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Weekly_ExtendableBondToken } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Yearly_ExtendableBondToken } from '@duet-protocol/contracts/lib/aligned';
import { createContract__CloneFactory } from '@duet-protocol/contracts/lib/aligned';
import { createContract__DodoOracle } from '@duet-protocol/contracts/lib/aligned';
import { createContract__DuetDPPFactory } from '@duet-protocol/contracts/lib/aligned';
import { createContract__DuetBUSDMinerPair } from '@duet-protocol/contracts/lib/aligned';
import { createContract__ExtendableBondRegistry } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Weekly_ExtendableBondedCake } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Yearly_ExtendableBondedCake } from '@duet-protocol/contracts/lib/aligned';
import { createContract__ExtendableBondedReaderCake } from '@duet-protocol/contracts/lib/aligned';
import { createContract__FeeConf } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Liquidate } from '@duet-protocol/contracts/lib/aligned';
import { createContract__MultiRewardsMasterChef } from '@duet-protocol/contracts/lib/aligned';
import { createContract__Reader } from '@duet-protocol/contracts/lib/aligned';
import { createContract__DuetBond } from '@duet-protocol/contracts/lib/aligned';
import { createContract__SingleBondsFactory } from '@duet-protocol/contracts/lib/aligned';
import { createContract__VaultFarm } from '@duet-protocol/contracts/lib/aligned';

export const createFungibleContract__AccessControl = createFungibleContractInjectorFactory((address, library) =>
  AccessControl__factory.connect(address, library),
);
export const createFungibleContract__AccidentHandler20220715V3 = createFungibleContractInjectorFactory(
  (address, library) => AccidentHandler20220715V3__factory.connect(address, library),
);
export const createFungibleContract__Adminable = createFungibleContractInjectorFactory((address, library) =>
  Adminable__factory.connect(address, library),
);
export const createFungibleContract__AggregatorV3Interface = createFungibleContractInjectorFactory((address, library) =>
  AggregatorV3Interface__factory.connect(address, library),
);
export const createFungibleContract__AppController = createFungibleContractInjectorFactory((address, library) =>
  AppController__factory.connect(address, library),
);
export const createFungibleContract__ApyHelper = createFungibleContractInjectorFactory((address, library) =>
  ApyHelper__factory.connect(address, library),
);
export const createFungibleContract__BEP20 = createFungibleContractInjectorFactory((address, library) =>
  BEP20__factory.connect(address, library),
);
export const createFungibleContract__BTCMintVault = createFungibleContractInjectorFactory((address, library) =>
  BTCMintVault__factory.connect(address, library),
);
export const createFungibleContract__BaseStrategy = createFungibleContractInjectorFactory((address, library) =>
  BaseStrategy__factory.connect(address, library),
);
export const createFungibleContract__BondFarmingPool = createFungibleContractInjectorFactory((address, library) =>
  BondFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__BondLPFarmingPool = createFungibleContractInjectorFactory((address, library) =>
  BondLPFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__BondLPPancakeFarmingPool = createFungibleContractInjectorFactory(
  (address, library) => BondLPPancakeFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__BondReader = createFungibleContractInjectorFactory((address, library) =>
  BondReader__factory.connect(address, library),
);
export const createFungibleContract__BondToken = createFungibleContractInjectorFactory((address, library) =>
  BondToken__factory.connect(address, library),
);
export const createFungibleContract__CloneFactory = createFungibleContractInjectorFactory((address, library) =>
  CloneFactory__factory.connect(address, library),
);
export const createFungibleContract__ContextUpgradeable = createFungibleContractInjectorFactory((address, library) =>
  ContextUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__DBTC = createFungibleContractInjectorFactory((address, library) =>
  DBTC__factory.connect(address, library),
);
export const createFungibleContract__DPPOracle = createFungibleContractInjectorFactory((address, library) =>
  DPPOracle__factory.connect(address, library),
);
export const createFungibleContract__DPPOracleAdmin = createFungibleContractInjectorFactory((address, library) =>
  DPPOracleAdmin__factory.connect(address, library),
);
export const createFungibleContract__DPPStorage = createFungibleContractInjectorFactory((address, library) =>
  DPPStorage__factory.connect(address, library),
);
export const createFungibleContract__DPPTrader = createFungibleContractInjectorFactory((address, library) =>
  DPPTrader__factory.connect(address, library),
);
export const createFungibleContract__DPPVault = createFungibleContractInjectorFactory((address, library) =>
  DPPVault__factory.connect(address, library),
);
export const createFungibleContract__DTMC = createFungibleContractInjectorFactory((address, library) =>
  DTMC__factory.connect(address, library),
);
export const createFungibleContract__DUSD = createFungibleContractInjectorFactory((address, library) =>
  DUSD__factory.connect(address, library),
);
export const createFungibleContract__DWTI = createFungibleContractInjectorFactory((address, library) =>
  DWTI__factory.connect(address, library),
);
export const createFungibleContract__DXAU = createFungibleContractInjectorFactory((address, library) =>
  DXAU__factory.connect(address, library),
);
export const createFungibleContract__DYSBUSD = createFungibleContractInjectorFactory((address, library) =>
  DYSBUSD__factory.connect(address, library),
);
export const createFungibleContract__DYTokenBase = createFungibleContractInjectorFactory((address, library) =>
  DYTokenBase__factory.connect(address, library),
);
export const createFungibleContract__DYTokenBaseUpgradeable = createFungibleContractInjectorFactory(
  (address, library) => DYTokenBaseUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__DYTokenERC20 = createFungibleContractInjectorFactory((address, library) =>
  DYTokenERC20__factory.connect(address, library),
);
export const createFungibleContract__DYTokenNative = createFungibleContractInjectorFactory((address, library) =>
  DYTokenNative__factory.connect(address, library),
);
export const createFungibleContract__DepositVaultBase = createFungibleContractInjectorFactory((address, library) =>
  DepositVaultBase__factory.connect(address, library),
);
export const createFungibleContract__DexUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  DexUSDOracle__factory.connect(address, library),
);
export const createFungibleContract__DodoOracle = createFungibleContractInjectorFactory((address, library) =>
  DodoOracle__factory.connect(address, library),
);
export const createFungibleContract__DoubleBond = createFungibleContractInjectorFactory((address, library) =>
  DoubleBond__factory.connect(address, library),
);
export const createFungibleContract__DoubleBondsFactory = createFungibleContractInjectorFactory((address, library) =>
  DoubleBondsFactory__factory.connect(address, library),
);
export const createFungibleContract__DppRouter = createFungibleContractInjectorFactory((address, library) =>
  DppRouter__factory.connect(address, library),
);
export const createFungibleContract__DuetDPPFactory = createFungibleContractInjectorFactory((address, library) =>
  DuetDPPFactory__factory.connect(address, library),
);
export const createFungibleContract__DuetDppController = createFungibleContractInjectorFactory((address, library) =>
  DuetDppController__factory.connect(address, library),
);
export const createFungibleContract__DuetDppLp = createFungibleContractInjectorFactory((address, library) =>
  DuetDppLp__factory.connect(address, library),
);
export const createFungibleContract__DuetDppLpFunding = createFungibleContractInjectorFactory((address, library) =>
  DuetDppLpFunding__factory.connect(address, library),
);
export const createFungibleContract__DuetDppStorage = createFungibleContractInjectorFactory((address, library) =>
  DuetDppStorage__factory.connect(address, library),
);
export const createFungibleContract__DuetMath = createFungibleContractInjectorFactory((address, library) =>
  DuetMath__factory.connect(address, library),
);
export const createFungibleContract__DuetUSDMinerPair = createFungibleContractInjectorFactory((address, library) =>
  DuetUSDMinerPair__factory.connect(address, library),
);
export const createFungibleContract__DuetZap = createFungibleContractInjectorFactory((address, library) =>
  DuetZap__factory.connect(address, library),
);
export const createFungibleContract__EIP712Upgradeable = createFungibleContractInjectorFactory((address, library) =>
  EIP712Upgradeable__factory.connect(address, library),
);
export const createFungibleContract__ERC165 = createFungibleContractInjectorFactory((address, library) =>
  ERC165__factory.connect(address, library),
);
export const createFungibleContract__ERC20Clonable = createFungibleContractInjectorFactory((address, library) =>
  ERC20Clonable__factory.connect(address, library),
);
export const createFungibleContract__ERC20PermitUpgradeable = createFungibleContractInjectorFactory(
  (address, library) => ERC20PermitUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__Epoch = createFungibleContractInjectorFactory((address, library) =>
  Epoch__factory.connect(address, library),
);
export const createFungibleContract__ExtendableBond = createFungibleContractInjectorFactory((address, library) =>
  ExtendableBond__factory.connect(address, library),
);
export const createFungibleContract__ExtendableBondReader = createFungibleContractInjectorFactory((address, library) =>
  ExtendableBondReader__factory.connect(address, library),
);
export const createFungibleContract__ExtendableBondRegistry = createFungibleContractInjectorFactory(
  (address, library) => ExtendableBondRegistry__factory.connect(address, library),
);
export const createFungibleContract__ExtendableBondedCake = createFungibleContractInjectorFactory((address, library) =>
  ExtendableBondedCake__factory.connect(address, library),
);
export const createFungibleContract__ExtendableBondedReaderCake = createFungibleContractInjectorFactory(
  (address, library) => ExtendableBondedReaderCake__factory.connect(address, library),
);
export const createFungibleContract__FeeConf = createFungibleContractInjectorFactory((address, library) =>
  FeeConf__factory.connect(address, library),
);
export const createFungibleContract__FeeRateModel = createFungibleContractInjectorFactory((address, library) =>
  FeeRateModel__factory.connect(address, library),
);
export const createFungibleContract__IAccessControl = createFungibleContractInjectorFactory((address, library) =>
  IAccessControl__factory.connect(address, library),
);
export const createFungibleContract__IBEP20 = createFungibleContractInjectorFactory((address, library) =>
  IBEP20__factory.connect(address, library),
);
export const createFungibleContract__IBondLPFarmingPool = createFungibleContractInjectorFactory((address, library) =>
  IBondLPFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__IBondLPPancakeFarmingPool = createFungibleContractInjectorFactory(
  (address, library) => IBondLPPancakeFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__IBondToken = createFungibleContractInjectorFactory((address, library) =>
  IBondToken__factory.connect(address, library),
);
export const createFungibleContract__IBondTokenUpgradeable = createFungibleContractInjectorFactory((address, library) =>
  IBondTokenUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__ICloneFactory = createFungibleContractInjectorFactory((address, library) =>
  ICloneFactory__factory.connect(address, library),
);
export const createFungibleContract__IDODOApproveProxy = createFungibleContractInjectorFactory((address, library) =>
  IDODOApproveProxy__factory.connect(address, library),
);
export const createFungibleContract__IDODOCallee = createFungibleContractInjectorFactory((address, library) =>
  IDODOCallee__factory.connect(address, library),
);
export const createFungibleContract__IDODOV2 = createFungibleContractInjectorFactory((address, library) =>
  IDODOV2__factory.connect(address, library),
);
export const createFungibleContract__IDPP = createFungibleContractInjectorFactory((address, library) =>
  IDPP__factory.connect(address, library),
);
export const createFungibleContract__IDPPAdmin = createFungibleContractInjectorFactory((address, library) =>
  IDPPAdmin__factory.connect(address, library),
);
export const createFungibleContract__IDPPController = createFungibleContractInjectorFactory((address, library) =>
  IDPPController__factory.connect(address, library),
);
export const createFungibleContract__IDPPOracle = createFungibleContractInjectorFactory((address, library) =>
  IDPPOracle__factory.connect(address, library),
);
export const createFungibleContract__IDPPOracleAdmin = createFungibleContractInjectorFactory((address, library) =>
  IDPPOracleAdmin__factory.connect(address, library),
);
export const createFungibleContract__IDYSToken = createFungibleContractInjectorFactory((address, library) =>
  IDYSToken__factory.connect(address, library),
);
export const createFungibleContract__IDYToken = createFungibleContractInjectorFactory((address, library) =>
  IDYToken__factory.connect(address, library),
);
export const createFungibleContract__IDepositVault = createFungibleContractInjectorFactory((address, library) =>
  IDepositVault__factory.connect(address, library),
);
export const createFungibleContract__IDexUsdOracle = createFungibleContractInjectorFactory((address, library) =>
  IDexUsdOracle__factory.connect(address, library),
);
export const createFungibleContract__IDuetOracle = createFungibleContractInjectorFactory((address, library) =>
  IDuetOracle__factory.connect(address, library),
);
export const createFungibleContract__IDusdMinter = createFungibleContractInjectorFactory((address, library) =>
  IDusdMinter__factory.connect(address, library),
);
export const createFungibleContract__IERC165 = createFungibleContractInjectorFactory((address, library) =>
  IERC165__factory.connect(address, library),
);
export const createFungibleContract__IERC20MetadataUpgradeable = createFungibleContractInjectorFactory(
  (address, library) => IERC20MetadataUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__IERC20Permit = createFungibleContractInjectorFactory((address, library) =>
  IERC20Permit__factory.connect(address, library),
);
export const createFungibleContract__IERC20PermitUpgradeable = createFungibleContractInjectorFactory(
  (address, library) => IERC20PermitUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__IERC20Upgradeable = createFungibleContractInjectorFactory((address, library) =>
  IERC20Upgradeable__factory.connect(address, library),
);
export const createFungibleContract__IERC2612 = createFungibleContractInjectorFactory((address, library) =>
  IERC2612__factory.connect(address, library),
);
export const createFungibleContract__IEpoch = createFungibleContractInjectorFactory((address, library) =>
  IEpoch__factory.connect(address, library),
);
export const createFungibleContract__IExtendableBondedCake = createFungibleContractInjectorFactory((address, library) =>
  IExtendableBondedCake__factory.connect(address, library),
);
export const createFungibleContract__IFarming = createFungibleContractInjectorFactory((address, library) =>
  IFarming__factory.connect(address, library),
);
export const createFungibleContract__IFeeConf = createFungibleContractInjectorFactory((address, library) =>
  IFeeConf__factory.connect(address, library),
);
export const createFungibleContract__IFeeRateImpl = createFungibleContractInjectorFactory((address, library) =>
  IFeeRateImpl__factory.connect(address, library),
);
export const createFungibleContract__IFeeRateModel = createFungibleContractInjectorFactory((address, library) =>
  IFeeRateModel__factory.connect(address, library),
);
export const createFungibleContract__ILiquidateCallee = createFungibleContractInjectorFactory((address, library) =>
  ILiquidateCallee__factory.connect(address, library),
);
export const createFungibleContract__IMasterChefV2 = createFungibleContractInjectorFactory((address, library) =>
  IMasterChefV2__factory.connect(address, library),
);
export const createFungibleContract__IMigratorChef = createFungibleContractInjectorFactory((address, library) =>
  IMigratorChef__factory.connect(address, library),
);
export const createFungibleContract__IMintVault = createFungibleContractInjectorFactory((address, library) =>
  IMintVault__factory.connect(address, library),
);
export const createFungibleContract__IOracle = createFungibleContractInjectorFactory((address, library) =>
  IOracle__factory.connect(address, library),
);
export const createFungibleContract__IPair = createFungibleContractInjectorFactory((address, library) =>
  IPair__factory.connect(address, library),
);
export const createFungibleContract__IPancakeFactory = createFungibleContractInjectorFactory((address, library) =>
  IPancakeFactory__factory.connect(address, library),
);
export const createFungibleContract__IPancakeMasterChefV2 = createFungibleContractInjectorFactory((address, library) =>
  IPancakeMasterChefV2__factory.connect(address, library),
);
export const createFungibleContract__IPancakePair = createFungibleContractInjectorFactory((address, library) =>
  IPancakePair__factory.connect(address, library),
);
export const createFungibleContract__IPool = createFungibleContractInjectorFactory((address, library) =>
  IPool__factory.connect(address, library),
);
export const createFungibleContract__IRouter = createFungibleContractInjectorFactory((address, library) =>
  IRouter__factory.connect(address, library),
);
export const createFungibleContract__IRouter02 = createFungibleContractInjectorFactory((address, library) =>
  IRouter02__factory.connect(address, library),
);
export const createFungibleContract__ISingleBond = createFungibleContractInjectorFactory((address, library) =>
  ISingleBond__factory.connect(address, library),
);
export const createFungibleContract__IStrategy = createFungibleContractInjectorFactory((address, library) =>
  IStrategy__factory.connect(address, library),
);
export const createFungibleContract__IUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  IUSDOracle__factory.connect(address, library),
);
export const createFungibleContract__IUniswapV2Factory = createFungibleContractInjectorFactory((address, library) =>
  IUniswapV2Factory__factory.connect(address, library),
);
export const createFungibleContract__IUniswapV2Pair = createFungibleContractInjectorFactory((address, library) =>
  IUniswapV2Pair__factory.connect(address, library),
);
export const createFungibleContract__IVaultFarm = createFungibleContractInjectorFactory((address, library) =>
  IVaultFarm__factory.connect(address, library),
);
export const createFungibleContract__IWithdrawCallee = createFungibleContractInjectorFactory((address, library) =>
  IWithdrawCallee__factory.connect(address, library),
);
export const createFungibleContract__IWooracle = createFungibleContractInjectorFactory((address, library) =>
  IWooracle__factory.connect(address, library),
);
export const createFungibleContract__IZap = createFungibleContractInjectorFactory((address, library) =>
  IZap__factory.connect(address, library),
);
export const createFungibleContract__Initializable = createFungibleContractInjectorFactory((address, library) =>
  Initializable__factory.connect(address, library),
);
export const createFungibleContract__InitializableOwnable = createFungibleContractInjectorFactory((address, library) =>
  InitializableOwnable__factory.connect(address, library),
);
export const createFungibleContract__Keepable = createFungibleContractInjectorFactory((address, library) =>
  Keepable__factory.connect(address, library),
);
export const createFungibleContract__KeeperBase = createFungibleContractInjectorFactory((address, library) =>
  KeeperBase__factory.connect(address, library),
);
export const createFungibleContract__KeeperCompatible = createFungibleContractInjectorFactory((address, library) =>
  KeeperCompatible__factory.connect(address, library),
);
export const createFungibleContract__KeeperCompatibleInterface = createFungibleContractInjectorFactory(
  (address, library) => KeeperCompatibleInterface__factory.connect(address, library),
);
export const createFungibleContract__LinkUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  LinkUSDOracle__factory.connect(address, library),
);
export const createFungibleContract__Liquidate = createFungibleContractInjectorFactory((address, library) =>
  Liquidate__factory.connect(address, library),
);
export const createFungibleContract__LpFarmingVault = createFungibleContractInjectorFactory((address, library) =>
  LpFarmingVault__factory.connect(address, library),
);
export const createFungibleContract__MasterChefV2 = createFungibleContractInjectorFactory((address, library) =>
  MasterChefV2__factory.connect(address, library),
);
export const createFungibleContract__MintVault = createFungibleContractInjectorFactory((address, library) =>
  MintVault__factory.connect(address, library),
);
export const createFungibleContract__MintVaultBase = createFungibleContractInjectorFactory((address, library) =>
  MintVaultBase__factory.connect(address, library),
);
export const createFungibleContract__MockERC20 = createFungibleContractInjectorFactory((address, library) =>
  MockERC20__factory.connect(address, library),
);
export const createFungibleContract__MockExtendableBond = createFungibleContractInjectorFactory((address, library) =>
  MockExtendableBond__factory.connect(address, library),
);
export const createFungibleContract__MockIBondFarmingPool = createFungibleContractInjectorFactory((address, library) =>
  MockIBondFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__MockUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  MockUSDOracle__factory.connect(address, library),
);
export const createFungibleContract__MultiRewardsMasterChef = createFungibleContractInjectorFactory(
  (address, library) => MultiRewardsMasterChef__factory.connect(address, library),
);
export const createFungibleContract__Multicall = createFungibleContractInjectorFactory((address, library) =>
  Multicall__factory.connect(address, library),
);
export const createFungibleContract__OracleKeeper = createFungibleContractInjectorFactory((address, library) =>
  OracleKeeper__factory.connect(address, library),
);
export const createFungibleContract__Ownable = createFungibleContractInjectorFactory((address, library) =>
  Ownable__factory.connect(address, library),
);
export const createFungibleContract__PausableUpgradeable = createFungibleContractInjectorFactory((address, library) =>
  PausableUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__Pool = createFungibleContractInjectorFactory((address, library) =>
  Pool__factory.connect(address, library),
);
export const createFungibleContract__Reader = createFungibleContractInjectorFactory((address, library) =>
  Reader__factory.connect(address, library),
);
export const createFungibleContract__ReentrancyGuardUpgradeable = createFungibleContractInjectorFactory(
  (address, library) => ReentrancyGuardUpgradeable__factory.connect(address, library),
);
export const createFungibleContract__SBUSDFarmingVault = createFungibleContractInjectorFactory((address, library) =>
  SBUSDFarmingVault__factory.connect(address, library),
);
export const createFungibleContract__SingleBond = createFungibleContractInjectorFactory((address, library) =>
  SingleBond__factory.connect(address, library),
);
export const createFungibleContract__SingleBondsFactory = createFungibleContractInjectorFactory((address, library) =>
  SingleBondsFactory__factory.connect(address, library),
);
export const createFungibleContract__SingleFarmingVault = createFungibleContractInjectorFactory((address, library) =>
  SingleFarmingVault__factory.connect(address, library),
);
export const createFungibleContract__Strategy2ForCake = createFungibleContractInjectorFactory((address, library) =>
  Strategy2ForCake__factory.connect(address, library),
);
export const createFungibleContract__Strategy2ForPancakeLP = createFungibleContractInjectorFactory((address, library) =>
  Strategy2ForPancakeLP__factory.connect(address, library),
);
export const createFungibleContract__StrategyForCake = createFungibleContractInjectorFactory((address, library) =>
  StrategyForCake__factory.connect(address, library),
);
export const createFungibleContract__StrategyForPancakeLP = createFungibleContractInjectorFactory((address, library) =>
  StrategyForPancakeLP__factory.connect(address, library),
);
export const createFungibleContract__StrategyKeeper = createFungibleContractInjectorFactory((address, library) =>
  StrategyKeeper__factory.connect(address, library),
);
export const createFungibleContract__TMCUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  TMCUSDOracle__factory.connect(address, library),
);
export const createFungibleContract__TokenRecipient = createFungibleContractInjectorFactory((address, library) =>
  TokenRecipient__factory.connect(address, library),
);
export const createFungibleContract__VaultFarm = createFungibleContractInjectorFactory((address, library) =>
  VaultFarm__factory.connect(address, library),
);
export const createFungibleContract__WETH9 = createFungibleContractInjectorFactory((address, library) =>
  WETH9__factory.connect(address, library),
);
export const createFungibleContract__WooOracleAdapter = createFungibleContractInjectorFactory((address, library) =>
  WooOracleAdapter__factory.connect(address, library),
);
export const createFungibleContract__ZeroUSDOracle = createFungibleContractInjectorFactory((address, library) =>
  ZeroUSDOracle__factory.connect(address, library),
);
export const injectContract__AccidentHandler20220715V3 = createContractInjector((chainId, library) => {
  const contract = createContract__AccidentHandler20220715V3(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=AccidentHandler20220715V3`);
});
export const injectContract__AppController = createContractInjector((chainId, library) => {
  const contract = createContract__AppController(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=AppController`);
});
export const injectContract__ApyHelper = createContractInjector((chainId, library) => {
  const contract = createContract__ApyHelper(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=ApyHelper`);
});
export const injectContract__Weekly_BondFarmingPool = createContractInjector((chainId, library) => {
  const contract = createContract__Weekly_BondFarmingPool(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Weekly_BondFarmingPool`);
});
export const injectContract__Yearly_BondFarmingPool = createContractInjector((chainId, library) => {
  const contract = createContract__Yearly_BondFarmingPool(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Yearly_BondFarmingPool`);
});
export const injectContract__Weekly_BondLPFarmingPool = createContractInjector((chainId, library) => {
  const contract = createContract__Weekly_BondLPFarmingPool(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Weekly_BondLPFarmingPool`);
});
export const injectContract__Yearly_BondLPFarmingPool = createContractInjector((chainId, library) => {
  const contract = createContract__Yearly_BondLPFarmingPool(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Yearly_BondLPFarmingPool`);
});
export const injectContract__BondReader = createContractInjector((chainId, library) => {
  const contract = createContract__BondReader(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=BondReader`);
});
export const injectContract__Weekly_ExtendableBondToken = createContractInjector((chainId, library) => {
  const contract = createContract__Weekly_ExtendableBondToken(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Weekly_ExtendableBondToken`);
});
export const injectContract__Yearly_ExtendableBondToken = createContractInjector((chainId, library) => {
  const contract = createContract__Yearly_ExtendableBondToken(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Yearly_ExtendableBondToken`);
});
export const injectContract__CloneFactory = createContractInjector((chainId, library) => {
  const contract = createContract__CloneFactory(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=CloneFactory`);
});
export const injectContract__DodoOracle = createContractInjector((chainId, library) => {
  const contract = createContract__DodoOracle(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=DodoOracle`);
});
export const injectContract__DuetDPPFactory = createContractInjector((chainId, library) => {
  const contract = createContract__DuetDPPFactory(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=DuetDPPFactory`);
});
export const injectContract__DuetBUSDMinerPair = createContractInjector((chainId, library) => {
  const contract = createContract__DuetBUSDMinerPair(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=DuetBUSDMinerPair`);
});
export const injectContract__ExtendableBondRegistry = createContractInjector((chainId, library) => {
  const contract = createContract__ExtendableBondRegistry(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=ExtendableBondRegistry`);
});
export const injectContract__Weekly_ExtendableBondedCake = createContractInjector((chainId, library) => {
  const contract = createContract__Weekly_ExtendableBondedCake(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Weekly_ExtendableBondedCake`);
});
export const injectContract__Yearly_ExtendableBondedCake = createContractInjector((chainId, library) => {
  const contract = createContract__Yearly_ExtendableBondedCake(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Yearly_ExtendableBondedCake`);
});
export const injectContract__ExtendableBondedReaderCake = createContractInjector((chainId, library) => {
  const contract = createContract__ExtendableBondedReaderCake(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=ExtendableBondedReaderCake`);
});
export const injectContract__FeeConf = createContractInjector((chainId, library) => {
  const contract = createContract__FeeConf(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=FeeConf`);
});
export const injectContract__Liquidate = createContractInjector((chainId, library) => {
  const contract = createContract__Liquidate(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Liquidate`);
});
export const injectContract__MultiRewardsMasterChef = createContractInjector((chainId, library) => {
  const contract = createContract__MultiRewardsMasterChef(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=MultiRewardsMasterChef`);
});
export const injectContract__Reader = createContractInjector((chainId, library) => {
  const contract = createContract__Reader(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=Reader`);
});
export const injectContract__DuetBond = createContractInjector((chainId, library) => {
  const contract = createContract__DuetBond(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=DuetBond`);
});
export const injectContract__SingleBondsFactory = createContractInjector((chainId, library) => {
  const contract = createContract__SingleBondsFactory(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=SingleBondsFactory`);
});
export const injectContract__VaultFarm = createContractInjector((chainId, library) => {
  const contract = createContract__VaultFarm(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=VaultFarm`);
});
