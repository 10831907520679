import cs from 'classnames';
import commifyBigNumber from '@/utils/commify';
import executeAndShowTx from '@/utils/executeAndShowTx';
import DButton from '@/components/DButton';
import { useWeb3 } from '@/web3';
import styles from './style.module.scss';
import { BondObject } from '../..';
import { injectContractSingleBond } from '../../../../contracts';

interface IProps {
  doneList: BondObject[];
  onClose: () => void;
}

const MaturedBondsDetails = ({ doneList, onClose }: IProps) => {
  const { account, contractContainer } = useWeb3();
  const redeemClick = async () => {
    if (!contractContainer || !account) return;
    try {
      const contract = injectContractSingleBond(contractContainer).connect(contractContainer.getSigner(account));
      const res = await contract.redeemAll(account);
      executeAndShowTx(res);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <section className={styles.mbdModal}>
      {/* 弹窗内容 */}
      <section className={styles.MaturedBondsDetails}>
        <div className={styles.title}>
          <span className={styles.text}>Matured Bonds Details</span>
          <i className={cs('iconfont icon-guanbi2', styles.icon)} onClick={onClose} />
        </div>
        <section className={styles.table}>
          {/* 头部 */}
          <div className={styles.tableTitle}>
            <div className={styles.tableTitleItem}>Name</div>
            <div className={styles.tableTitleItem}>Amount($DUET)</div>
          </div>
          {/* 表格 */}
          <section className={styles.tableContent}>
            {doneList.map((item: BondObject, index: number) => {
              return (
                <div className={styles.listItem} key={index}>
                  <div className={styles.listItemLabel}>{item?.name}</div>
                  <div className={styles.listItemValue}>{commifyBigNumber(item?.balance)}</div>
                </div>
              );
            })}
          </section>
          <div className={styles.buttonSec}>
            <DButton width={590} onClick={redeemClick}>
              Redeem All
            </DButton>
          </div>
        </section>
      </section>
      {/* 移动端关闭按钮 */}
      <div className={styles.mobileClose}>
        <i className={cs('iconfont icon-guanbi2', styles.icon)} onClick={onClose} />
      </div>
    </section>
  );
};

export default MaturedBondsDetails;
