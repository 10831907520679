import { ContractInterface } from 'ethers';

export const ReaderAbi: ContractInterface = [
  'constructor(address,address)',
  'function depositVaultValues(address[],bool) view returns (uint256[], uint256[])',
  'function getAssetDiscount(address,bool) view returns (uint16)',
  'function getDTokenVaultPrice(address[],address,bool) view returns (uint256[], uint256[], uint256[], uint256[])',
  'function getValidVault(address,address) view returns (uint8)',
  'function getVaultPrice(address,uint256,bool) view returns (uint256)',
  'function pendingBorrow(uint256) view returns (uint256, uint256)',
  'function pendingRepay(address,address,uint256) view returns (uint256, uint256)',
  'function userVaultBorrowAmounts(address,address[]) view returns (uint256[])',
  'function userVaultDepositAmounts(address,address[]) view returns (uint256[], uint256[])',
  'function userVaultValues(address,address[],bool) view returns (uint256[])',
  'function usersTotalVaules(address[],bool) view returns (uint256[], uint256[])',
  'function usersVaules(address[],bool) view returns (uint256[], uint256[])',
];
