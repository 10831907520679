import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { useParams } from 'react-router';
import cs from 'classnames';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from '@ethersproject/units';
import allLpTokens, { Token, TokenType } from '@/config/allLpTokens';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import commifyBigNumber from '@/utils/commify';
import DInput from '@/components/DInput';
import toFixed from '@/utils/toFixed';
import useERC20Balance from '@/hooks/useERC20Balance';
import executeAndShowTx from '@/utils/executeAndShowTx';
import useIsMobile from '@/hooks/useIsMobile';
import { getLpZapRoutes, getTokenZapRoutes } from '@/utils/zapRoutesHelper';
import config from '@/config/config';
import { useWeb3 } from '@/web3';
import styles from './style.module.scss';
import { injectContractDuetZap } from '../../../../../contracts';

const { Option } = Select;

const canZapTokenList = allLpTokens.filter(each => each.inZap);

export interface VaultInfo {
  assets: BigNumber;
  syntheticAssets: BigNumber;
  debtToAssetRatio: string;
}

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
}

const gasOption = { gasLimit: 1500000 };

const ZapSupply: FC<Props> = props => {
  const { refreshDataCount, refreshData } = props;
  const { account, contractContainer, provider } = useWeb3();
  const { address } = useParams();
  const [currentSelectedToken, setCurrentSelectedToken] = useState<Token>();
  const [inputAmount, setInputAmount] = useState('');

  const isBNB = currentSelectedToken?.symbol === 'BNB';

  const { accountBalance, setAccountBalance, refreshAccountBalance } = useERC20Balance(currentSelectedToken?.address);

  const pageToken = useMemo(() => {
    const token = allLpTokens.find(each => each.address === address);
    return token;
  }, [address]);

  useEffect(() => {
    (async () => {
      if (isBNB) {
        if (!account || !provider) return;
        const balance = await provider.getBalance(account);
        setAccountBalance(balance);
      } else {
        refreshAccountBalance();
      }
    })();
  }, [refreshDataCount, isBNB, provider]);

  // 切换 token
  const handleChange = (tokenAddress: string) => {
    setAccountBalance(ethers.constants.Zero);
    const token = allLpTokens.find(each => each.address === tokenAddress);
    if (!token) return;
    setCurrentSelectedToken(token);
  };

  // 点击 supply 按钮
  const handleSupplyClick = async () => {
    if (!currentSelectedToken) return;
    const { address: tokenAddress } = currentSelectedToken;

    // zap & supply
    if (!address) return;
    if (!pageToken?.singleOrLp) return;
    if (!contractContainer) return;

    // 进行 zap & supply
    const contract = injectContractDuetZap(contractContainer).connect(contractContainer.getSigner(address));
    let promise;
    if (pageToken.singleOrLp === TokenType.SINGLE) {
      if (isBNB) {
        const tokenZapRoutes = getTokenZapRoutes(config.WBNB, address);
        promise = contract.coinToTokenbyPath(true, tokenZapRoutes, {
          ...gasOption,
          value: parseEther(inputAmount),
        });
      } else {
        const tokenZapRoutes = getTokenZapRoutes(tokenAddress, address);
        promise = contract.tokenToTokenbyPath(parseEther(inputAmount), true, tokenZapRoutes, gasOption);
      }
    } else if (isBNB) {
      const lpZapRoutes = getLpZapRoutes(config.WBNB, address);
      promise = contract.coinToLpbyPath(address, true, ...lpZapRoutes, {
        ...gasOption,
        value: parseEther(inputAmount),
      });
    } else {
      const lpZapRoutes = getLpZapRoutes(tokenAddress, address);
      promise = contract.tokenToLpbyPath(
        tokenAddress,
        parseEther(inputAmount),
        address,
        true,
        ...lpZapRoutes,
        gasOption,
      );
    }
    await executeAndShowTx(promise);

    refreshData();
    setInputAmount('');
  };

  const handleAmountChange = (amount: string) => {
    setInputAmount(amount);
  };

  const selectable = useMemo(() => {
    const canZapList = canZapTokenList.filter(token => {
      // 不可选自己
      if (token.address === address) return false;
      if (token.singleOrLp === TokenType.SINGLE) {
        return true;
      }
      return false;
    });

    return canZapList;
  }, [address]);

  // 设置 selectable 中的第一项作为默认 token
  useEffect(() => {
    if (selectable && selectable[0]) {
      setCurrentSelectedToken(selectable[0]);
    }
  }, [selectable]);

  const buttonDisabled = !inputAmount || +inputAmount === 0;

  const isMobile = useIsMobile();

  return (
    <section className={styles.SupplyWithDrawWrapperForZap}>
      <div className={styles.flexRow}>
        <div className={styles.selectDiv}>
          <Select
            value={currentSelectedToken?.address}
            style={{ width: isMobile ? '100%' : '250px' }}
            onChange={handleChange}>
            {selectable.map(token => (
              <Option value={token.address} key={token.symbol}>
                {token.symbol}
              </Option>
            ))}
          </Select>
          <i className={cs('iconfont icon-sanjiaojiantou-down', styles.iconfontIcon)} />
        </div>
        <span className={styles.arrowSpan}>
          <i className={cs('iconfont icon-jiantou1', styles.iconJiantou)} />
        </span>
        <section className={styles.TokenDisplay}>{pageToken?.symbol}</section>
      </div>
      <Margin top={30} />
      <DInput width={590} value={inputAmount} onChange={handleAmountChange} maxAmount={toFixed(accountBalance)} />
      <Margin top={10} />
      <div className={styles.tips}>Wallet Balance: {commifyBigNumber(accountBalance)}</div>
      <Margin top={30} />
      <FlexRow justifyContent="flex-start">
        <DButton
          width={590}
          onClick={handleSupplyClick}
          disabled={buttonDisabled}
          approveFrom={currentSelectedToken?.address}
          approveTo={config.DUET_ZAP}>
          Zap & Supply
        </DButton>
      </FlexRow>
    </section>
  );
};

export default ZapSupply;
