import { FC } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);

interface Props {
  options: Highcharts.Options;
}

const ReactHighcharts: FC<Props> = props => {
  const { options } = props;
  const combinedOptions: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    ...options,
  };

  return <HighchartsReact highcharts={Highcharts} options={combinedOptions} />;
};

export default ReactHighcharts;
