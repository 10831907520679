// TODO: 需要根据当前是哪个策略来判断，一个 token 地址可能对应多个 vault
import { strategyListToMap, allStrategies } from '@/config/allLpTokens';

const useTokenAddress = (token?: string) => {
  const strategyMap = {
    ...strategyListToMap(allStrategies),
  };

  if (!token) {
    return {
      vaultAddress: '',
      dyTokenAddress: '',
    };
  }
  const vaultAddress = strategyMap[token]?.vaultAddress;
  const dyTokenAddress = strategyMap[token]?.dyTokenAddress;
  return { vaultAddress, dyTokenAddress };
};

export default useTokenAddress;
