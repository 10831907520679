import { FORKED_CHAIN_IDS } from '@/config/forked';
import zapRoutesTest from './bsctest/zapRoutes';
import zapRoutesBsc from './bsc/zapRoutes';
import { ZapRoutes } from './types';

// zap 路由配置
const zapRoutesMap: Record<string, ZapRoutes> = {
  56: zapRoutesBsc,
  97: zapRoutesTest,
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (zapRoutesBsc[chainId] = zapRoutesBsc[56]));
const zapRoutes = zapRoutesMap[import.meta.env.VITE_CHAIN_ID];

export default zapRoutes;
