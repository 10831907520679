import { Tooltip } from 'antd';
import React, { FC, ReactNode } from 'react';
import cs from 'classnames';
import styles from './style.module.scss';

interface Props {
  text: ReactNode;
  iconStyle?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
}

const InfoCircle: FC<Props> = props => {
  const { text, iconStyle, overlayStyle } = props;

  const handleTooltipClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Tooltip title={text} placement="bottom" overlayStyle={overlayStyle}>
      <i
        onClick={handleTooltipClick}
        className={cs('iconfont icon-tishi_xianxing', styles.icon)}
        style={{ marginLeft: 5, cursor: 'pointer', ...iconStyle }}
      />
    </Tooltip>
  );
};

export default InfoCircle;
