import { FC, ReactNode } from 'react';
import InfoCircle from '../InfoCircle';
import styles from './style.module.scss';

export interface ListRow {
  label: string;
  content: ReactNode;
  infoText?: string;
}

interface Props {
  title?: string;
  rows: ListRow[];
}

const ListView: FC<Props> = props => {
  const { title, rows } = props;

  return (
    <section className={styles.ListViewWrapper}>
      {title && <div className={styles.listViewTitle}>{title}</div>}
      {rows.map((row, index) => (
        <div key={index} className={styles.perRow}>
          <div className={styles.rowLabel}>
            {row.label}
            {row.infoText && <InfoCircle text={row.infoText} />}
          </div>
          <div className={styles.rowContent}>{row.content}</div>
        </div>
      ))}
    </section>
  );
};

export default ListView;
