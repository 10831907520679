import type { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { Contract, ContractInterface } from 'ethers';

export interface IContractContainer {
  context: ContractContext;
  getSigner(address: string): JsonRpcSigner;
}

export function createContractInjector<T extends Contract = Contract>(
  address: string,
  abi: ContractInterface,
): ContractInjector<T> {
  const map = new WeakMap<IContractContainer, T>();
  return container => {
    if (!map.has(container)) map.set(container, new Contract(address, abi, container.context.library) as T);
    return map.get(container)!;
  };
}

type ContractContext = {
  library: Web3Provider;
};

export type ContractInjector<T extends Contract = Contract> = (container: IContractContainer) => T;
