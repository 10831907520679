import { FORKED_CHAIN_IDS } from '@/config/forked';
import config from './config';

export const mainChainId = '0x38';

type Network = {
  chainId: number;
  chainName: string;
  rpcUrls: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  blockExplorerUrls: string[];
};

export const networkConfigMap: Record<string, Network> = {
  56: {
    chainId: 56,
    chainName: config.WALLET_NETWORK_NAME_IN_CHAIN_REGISTRY,
    rpcUrls: import.meta.env.VITE_RPC_OVERWRITE
      ? [import.meta.env.VITE_RPC_OVERWRITE]
      : ['https://bsc-dataseed1.binance.org'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  97: {
    chainId: 97,
    chainName: config.WALLET_NETWORK_NAME_IN_CHAIN_REGISTRY,
    rpcUrls: import.meta.env.VITE_RPC_OVERWRITE
      ? [import.meta.env.VITE_RPC_OVERWRITE]
      : [
          'https://data-seed-prebsc-1-s1.binance.org:8545',
          'https://data-seed-prebsc-1-s2.binance.org:8545',
          'https://data-seed-prebsc-1-s3.binance.org:8545',
          'https://data-seed-prebsc-2-s1.binance.org:8545',
          'https://data-seed-prebsc-2-s2.binance.org:8545',
          'https://data-seed-prebsc-2-s3.binance.org:8545',
        ],
    nativeCurrency: {
      name: 'TBNB',
      symbol: 'tBNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (networkConfigMap[chainId] = networkConfigMap[56]));
export const scanAddressMap: Record<string, string> = {
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (scanAddressMap[chainId] = scanAddressMap[56]));

export const contractAddressMap: Record<string, Record<string, string>> = {
  56: {
    BUSD: config.BUSD,
    USDC: config.USDC,
    dUSD: config.DUSD,
    'BUSD-dUSD': config.BUSD_DUSD,
    'USDC-dUSD': config.USDC_DUSD,
  },
  97: {
    BUSD: config.BUSD,
    USDC: config.USDC,
    dUSD: config.DUSD,
    'BUSD-dUSD': config.BUSD_DUSD,
    'USDC-dUSD': config.USDC_DUSD,
  },
};

// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (contractAddressMap[chainId] = contractAddressMap[56]));
