import styled from '@emotion/styled';
import { FC, useState } from 'react';
import successPng from '@/assets/pro-cloud/success.png';

interface Props {
  visible: boolean;
  closeModal: () => void;
}

const EmailConfirm: FC<Props> = props => {
  const { visible, closeModal } = props;

  return (
    <>
      {!visible && null}
      {visible && (
        <ModalBackground>
          <Modal>
            <TitleRow>
              <Title>Subscribe Successfully!</Title>
              <i className="iconfont icon-guanbi2" onClick={closeModal} />
            </TitleRow>
            <SuccessImg src={successPng} />
            <Text>We will contact you by email within 24 hours for the next step.</Text>
          </Modal>
        </ModalBackground>
      )}
    </>
  );
};

export default EmailConfirm;

const Text = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a5cf2;
  margin: 30px auto;
  width: 70%;
`;

const SuccessImg = styled.img`
  width: 120px;
  height: 120px;
  margin-top: 30px;
`;

const Title = styled.div`
  width: 95%;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  color: white;
`;

const NoText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #c1c1c1;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 10px;
`;

const Button = styled.div`
  background: rgba(157, 33, 214, 1);
  height: 32px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 32px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  padding: 0 10px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const Modal = styled.div`
  margin: 112px auto 0;
  max-width: 700px;
  height: auto;
  background: #1e2450;
  border-radius: 4px;
  text-align: center;
  padding: 30px 40px;
  z-index: 101;
  // overflow: scroll;

  @media (max-width: 768px) {
    margin: 20px auto 0;
  }

  .duet-price {
    text-align: left;
    margin-top: 10px;
    span {
      color: rgba(163, 100, 192, 1);
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0em;
    }
  }

  .my-input {
    width: 50%;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;
    background: var(--bg-purple5);
    outline: none;
    border: 0;
    color: var(--common-white);
    padding-left: 10px;

    @include respond-to(sm) {
      width: 100% !important;
    }
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;

  span {
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }

  i {
    font-size: 20px;
    cursor: pointer;
    color: var(--text-white3);
  }
`;
