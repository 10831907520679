import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ListView, { ListRow } from '@/components/ListView';
import Margin from '@/components/Margin';
import Card from '@/components/Card';
import commifyBigNumber from '@/utils/commify';
import SelectToken from '@/components/SelectToken';
import useBorrowPrice from '@/hooks/useBorrowPrice';
import {
  dAssetsArchivedStrategyList,
  dAssetsInnovationStrategyList,
  dAssetsStrategyList,
  Token,
} from '@/config/allLpTokens';
import { descriptionMap } from '@/config/descriptionMap';
import { DisplayPosition, Strategy } from '@/config/types';
import UsdDisplay from '@/components/UsdDisplay';
import { BigNumber } from 'ethers';
import { parseEther, parseUnits } from '@ethersproject/units';
import styles from './style.module.scss';
import { useStrategy, useTokenData } from '../context';

const Information: FC = props => {
  const strategy = useStrategy();
  const vaultAddress = strategy?.vaultAddress;

  const { tokenData } = useTokenData();

  const price = useBorrowPrice(vaultAddress);

  // const lpToken = useState<Token>();
  const navigate = useNavigate();

  const onLpTokenListChange = (token: Token) => {
    navigate(`/dAssets-detail/${token.address}`);
  };

  const renderMarketCap = () => {
    if (tokenData == null || !tokenData.marketCap) return '-';
    return <UsdDisplay>{tokenData.marketCap}</UsdDisplay>;
  };

  const firstRowList: ListRow[] = [
    {
      label: 'Mint Tax',
      content: '0.5%',
    },
    // {
    //   label: 'Interest Rate',
    //   content: '0.00%',
    // },
    {
      label: 'Price',
      content: `$${commifyBigNumber(price, {
        unit: 8,
        decimal: 4,
      })}`,
    },
    {
      label: 'Credit Burn',
      content: tokenData.premiumRatio == null ? '-' : (tokenData.premiumRatio / 1e4).toFixed(2),
    },
    {
      label: 'Market Cap',
      content: renderMarketCap(),
    },
  ];

  const renderLiability = (value: BigNumber | undefined) => {
    if (!value) return '-';
    if (value.gt(parseEther('0')) && value.lt(parseEther('0.00000001'))) return '0.00000001';
    return `${commifyBigNumber(value, { decimal: 8 })} ${strategy?.symbol}`;
  };

  const secondRowList = [
    {
      label: 'Liability',
      content: renderLiability(tokenData.liability),
      infoText:
        'Liability is the total amount of outstanding synthetic assets that need to be repaid in order to unlock your collateral, which equals the sum of borrowed dAssets and 1% repayment fee.',
    },
    {
      label: 'Wallet Balance',
      content: `${commifyBigNumber(tokenData.walletBalance)} ${strategy?.symbol}`,
      infoText: 'The amount of selected assets left in your crypto wallet. ',
      tokenType: strategy?.symbol,
    },
  ];

  const isInnovation = strategy?.displayPosition === DisplayPosition.DASSETS_INNOVATION;
  const isArchived = strategy?.displayPosition === DisplayPosition.DASSETS_ARCHIVED;
  const selectList = useMemo(() => {
    if (isArchived) {
      return dAssetsArchivedStrategyList;
    }
    if (isInnovation) {
      return dAssetsInnovationStrategyList;
    }
    return dAssetsStrategyList;
  }, [isInnovation, isArchived]);

  return (
    <Card width={430}>
      <SelectToken tokenSymbol={strategy?.symbol} selectList={selectList} onChange={onLpTokenListChange} />
      <ListView rows={firstRowList} />
      <Margin top={30} />
      <section className={styles.DUSDExplain}>
        <div className={styles.label}>What is {strategy?.symbol}?</div>
        <Margin top={10} />
        <div className={styles.content}>{strategy?.symbol && descriptionMap[strategy?.symbol]}</div>
      </section>
      <Margin top={30} />
      <ListView title="Information" rows={secondRowList} />
    </Card>
  );
};

export default Information;
