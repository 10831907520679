import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { scanAddressMap } from '../config/network';
import TxNotificationProcessBar from './TxNotificationProcessBar';

type NotificationType = 'success' | 'info' | 'error';

interface Props {
  type: NotificationType;
  text: string;
  address: string;
  chainId: number;
}

type IconMap = {
  [type in NotificationType]: string;
};

type ColorMap = {
  [type in NotificationType]: string;
};

const iconMap: IconMap = {
  success: '#icon-success',
  info: '#icon-processing',
  error: '#icon-failed',
};

const colorMap: ColorMap = {
  success: '#4DC600',
  info: '#49A8F4',
  error: '#EB4F4E',
};

const TxNotification: React.FC<Props> = props => {
  const { chainId, type, text, address } = props;

  const viewTx = () => {
    if (!chainId) return;
    const prefix = scanAddressMap[chainId];

    window.open(`${prefix}/tx/${address}`, '_blank');
  };

  return (
    <NotificationWrapper>
      <TxNotificationProcessBar />
      <FlexRow>
        <svg className="icon" aria-hidden="true">
          <use xlinkHref={iconMap[type]} />
        </svg>
        <span className="text" style={{ color: colorMap[type] }}>
          {text}
        </span>
      </FlexRow>
      <ButtonRow>
        <ViewInBrowser onClick={viewTx}>view in browser</ViewInBrowser>
      </ButtonRow>
    </NotificationWrapper>
  );
};

export default TxNotification;

const NotificationWrapper = styled.div`
  width: 320px;
  background: #4025a7;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  position: relative;
`;

const FlexRow = styled.div`
  display: flex;
  padding: 20px 10px 0 10px;

  .icon {
    font-size: 21px;
    line-height: 20px;
  }

  .text {
    margin-left: 10px;
  }
`;

const ViewInBrowser = styled.div`
  display: inline-block;
  padding: 0 5px;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #1bd19a;
  margin: 6px 0 12px 0;
  border-radius: 8px;
  border: 1px solid #1bd19a;
  cursor: pointer;
`;

const ButtonRow = styled.div`
  padding-left: 38px;
`;
