import commifyBigNumber from '@/utils/commify';
import styled from '@emotion/styled';
import { BigNumber } from 'ethers';
import { FC } from 'react';

interface Props {
  children: BigNumber | null;
  decimal?: number;
}

const AmountDisplay: FC<Props> = props => {
  const { children, decimal = 4 } = props;

  if (!children) return <>-</>;

  return (
    <UsdDisplayWrapper>
      {commifyBigNumber(children, {
        unit: 18,
        decimal,
      })}
    </UsdDisplayWrapper>
  );
};

export default AmountDisplay;

const UsdDisplayWrapper = styled.div`
  font-family: Roboto;
  font-weight: 500;
`;
