import config from './config';
import { DAssetsType, DisplayPosition, Token, SingleOrLp } from '../types';

const allLpTokens: Token[] = [
  {
    symbol: 'USDC-ABC',
    address: '0xe9b4460b045fd3161e20d41c22c47a3425d7f213',
    image: '',
    singleOrLp: SingleOrLp.LP,
    vaults: [
      {
        vaultAddress: '0x7e841410D8466Fa4c31db7b14C6E0533EeF6C57E',
        dyTokenAddress: '0x066Be0e3Ae91BC728BD3bd950c13B5C0017aB583',
        displayPosition: DisplayPosition.DEPOSIT,
      },
    ],
  },
  {
    symbol: 'dWTI-BUSD',
    address: '0xC104ab269303BF5606F118914aFf8A25F7498313',
    image: '',
    singleOrLp: SingleOrLp.LP,
    platform: 'Dodo',
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: '0x078e22cd3FbC2E98CA9c564fdAE6BCAeA57870E2',
        dyTokenAddress: '0x4125702f9292d9fe42f6d45b78932a6e0af750b4',
        displayPosition: DisplayPosition.DEPOSIT,
      },
    ],
  },
  {
    symbol: 'BUSD',
    address: config.BUSD,
    image: '',
    singleOrLp: SingleOrLp.SINGLE,
    inZap: true,
    vaults: [
      {
        vaultAddress: config.BUSD_VAULT,
        dyTokenAddress: config.BUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
      {
        vaultAddress: config.BUSD_ENHANCE_VAULT,
        dyTokenAddress: config.BUSD_ENHANCE_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_INNOVATION,
        targetTokenSingleOrLp: SingleOrLp.LP,
        targetTokenAddress: config.BUSD_DUSD,
        incentive: false,
      },
    ],
  },
  {
    symbol: 'USDC',
    address: config.USDC,
    image: '',
    singleOrLp: SingleOrLp.SINGLE,
    inZap: true,
    vaults: [
      {
        vaultAddress: config.USDC_VAULT,
        dyTokenAddress: config.USDC_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
      },
    ],
  },
  {
    symbol: 'BNB-USDC',
    address: config.BNB_USDC,
    image: '',
    singleOrLp: SingleOrLp.LP,
    vaults: [
      {
        vaultAddress: config.BNB_USDC_VAULT,
        dyTokenAddress: config.BNB_USDC_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        pancakePid: 2,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'USDC-BUSD',
    address: config.USDC_BUSD,
    image: '',
    singleOrLp: SingleOrLp.LP,
    vaults: [
      {
        vaultAddress: config.USDC_BUSD_VAULT,
        dyTokenAddress: config.USDC_BUSD_DYTOKEN,
        pancakePid: 3,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'ABC',
    address: config.ABC,
    image: '',
    singleOrLp: SingleOrLp.SINGLE,
    inZap: true,
  },
  {
    symbol: 'BNB',
    address: '',
    image: '',
    singleOrLp: SingleOrLp.SINGLE,
    inZap: true,
  },
  {
    symbol: 'WBNB',
    address: config.WBNB,
    image: '',
    singleOrLp: SingleOrLp.SINGLE,
    inZap: true,
  },
  {
    symbol: 'dUSD',
    image: '',
    address: config.DUSD,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.STABLE_COIN,
    vaults: [
      {
        vaultAddress: config.DUSD_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS,
      },
    ],
  },
  {
    symbol: 'dWTI',
    image: '',
    address: config.DWTI,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    vaults: [
      {
        vaultAddress: config.DWTI_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS,
      },
    ],
  },
  {
    symbol: 'dBTC',
    image: '',
    address: config.DBTC,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    vaults: [
      {
        vaultAddress: config.DBTC_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS,
      },
    ],
  },
  {
    symbol: 'dJPY',
    image: '',
    address: config.DJPY,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    vaults: [
      {
        vaultAddress: config.DJPY_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS,
      },
    ],
  },
];

export default allLpTokens;
