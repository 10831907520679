import config from './config';
import { Token, SingleOrLp, DisplayPosition, DAssetsType } from '../types';

const allLpTokens: Token[] = [
  {
    symbol: 'dTMC-BUSD',
    address: '0xa63c7491176b271cb62fa3f7932dd86ffeba0ff4',
    image: '',
    singleOrLp: SingleOrLp.LP,
    platform: 'Dodo',
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: '0x1690523a8cdd85b0f6063e2743895843b0ea46f4',
        dyTokenAddress: '0x3bd051fb76d0563f3e5a1f858a288b89ac6782d8',
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        onFire: false,
        compound: false,
      },
    ],
  },
  {
    symbol: 'dWTI-BUSD',
    address: '0x9f3dcc4e96de4bb63c9f0b5e7b8038522e6ec2ef',
    image: '',
    singleOrLp: SingleOrLp.LP,
    platform: 'Dodo',
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: '0x4178e4cb73d2370b3cceb7a1bab456917c159205',
        dyTokenAddress: '0xcb6d44cc68e797a1230d69be6f9a424ffaeb2d55',
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        onFire: false,
        compound: false,
      },
    ],
  },
  {
    symbol: 'dXAU-BUSD',
    address: '0x8367acf2934feaf9610b45c80b904f4e992f5b70',
    image: '',
    singleOrLp: SingleOrLp.LP,
    platform: 'Dodo',
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: '0xabe7d9df063e25250010b0e3cdc3e446d953ca6e',
        dyTokenAddress: '0xdc25b7ccd40a330c1c5e844046f6d45df25402d3',
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        onFire: false,
        compound: false,
      },
    ],
  },
  {
    symbol: 'dWTI-dUSD',
    address: config.DWTI_DUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        vaultAddress: config.DWTI_DUSD_VAULT,
        dyTokenAddress: config.DWTI_DUSD_DYTOKEN,
        incentive: true,
        onFire: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'dXAU-dUSD',
    address: config.DXAU_DUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DXAU_DUSD_VAULT,
        dyTokenAddress: config.DXAU_DUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
        onFire: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'dTMC-dUSD',
    address: config.DTMC_DUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DTMC_DUSD_VAULT,
        dyTokenAddress: config.DTMC_DUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
        onFire: true,
        compound: false,
      },
    ],
  },
  {
    symbol: 'USDT-BUSD',
    address: config.USDT_BUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.USDT_BUSD_VAULT,
        dyTokenAddress: config.USDT_BUSD_DYTOKEN,
        pancakePid: 7,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'USDC-BUSD',
    address: config.BUSD_USDC,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.BUSD_USDC_VAULT,
        dyTokenAddress: config.BUSD_USDC_DYTOKEN,
        pancakePid: 20,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'USDC-USDT',
    address: config.USDC_USDT,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.USDC_USDT_VAULT,
        dyTokenAddress: config.USDC_USDT_DYTOKEN,
        pancakePid: 48,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'UST-BUSD',
    address: config.UST_BUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: config.UST_BUSD_VAULT,
        dyTokenAddress: config.UST_BUSD_DYTOKEN,
        pancakePid: 23,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'BNB-CAKE',
    address: config.BNB_CAKE,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.BNB_CAKE_VAULT,
        dyTokenAddress: config.BNB_CAKE_DYTOKEN,
        pancakePid: 2,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'DUET-CAKE',
    address: config.DUET_CAKE,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DUET_CAKE_VAULT,
        dyTokenAddress: config.DUET_CAKE_DYTOKEN,
        pancakePid: 86,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'DUET-dUSD',
    address: config.DUET_DUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DUET_DUSD_VAULT,
        dyTokenAddress: config.DUET_DUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'DUET-WBNB',
    address: config.DUET_WBNB,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DUET_WBNB_VAULT,
        dyTokenAddress: config.DUET_WBNB_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'dUSD-BUSD',
    address: config.DUSD_BUSD,
    singleOrLp: SingleOrLp.LP,
    image: '',
    vaults: [
      {
        vaultAddress: config.DUSD_BUSD_VAULT,
        dyTokenAddress: config.DUSD_BUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
        compound: true,
      },
    ],
  },
  {
    symbol: 'CAKE',
    address: config.CAKE,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.CAKE_VAULT,
        dyTokenAddress: config.CAKE_DYTOKEN,
        pancakePid: 0,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: false,
        compound: true,
      },
    ],
  },
  {
    symbol: 'USDT',
    address: config.USDT,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.USDT_VAULT,
        dyTokenAddress: config.USDT_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'BUSD',
    address: config.BUSD,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.BUSD_VAULT,
        dyTokenAddress: config.BUSD_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
      {
        vaultAddress: config.BUSD_ENHANCE_VAULT,
        dyTokenAddress: config.BUSD_ENHANCE_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_INNOVATION,
        targetTokenSingleOrLp: SingleOrLp.LP,
        targetTokenAddress: config.DUSD_BUSD,
        incentive: true,
        onFire: true,
      },
    ],
  },
  {
    symbol: 'USDC',
    address: config.USDC,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.USDC_VAULT,
        dyTokenAddress: config.USDC_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'UST',
    address: config.UST,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    hideZapEntry: true,
    vaults: [
      {
        vaultAddress: config.UST_VAULT,
        dyTokenAddress: config.UST_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT_ARCHIVED,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'DUET',
    address: config.DUET,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.DUET_VAULT,
        dyTokenAddress: config.DUET_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  // {
  //   symbol: 'OPE',
  //   address: config.OPE,
  //   vaultAddress: config.OPE_VAULT,
  //   image: USDC_LOGO,
  //   dyTokenAddress: config.OPE_DYTOKEN,
  //   type: TokenType.Single,
  //   displayPosition: DisplayPosition.DEPOSIT,
  // },
  {
    symbol: 'ETH',
    address: config.ETH,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.ETH_VAULT,
        dyTokenAddress: config.ETH_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'BTCB',
    address: config.BTCB,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.BTCB_VAULT,
        dyTokenAddress: config.BTCB_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'WBNB',
    address: config.WBNB,
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
    vaults: [
      {
        vaultAddress: config.WBNB_VAULT,
        dyTokenAddress: config.WBNB_DYTOKEN,
        displayPosition: DisplayPosition.DEPOSIT,
        incentive: true,
      },
    ],
  },
  {
    symbol: 'BNB',
    address: '',
    singleOrLp: SingleOrLp.SINGLE,
    image: '',
    inZap: true,
  },
  {
    symbol: 'dUSD',
    address: config.DUSD,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.STABLE_COIN,
    image: '',
    vaults: [
      {
        vaultAddress: config.DUSD_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS_ARCHIVED,
      },
    ],
  },
  {
    symbol: 'dWTI',
    address: config.DWTI,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    image: '',
    vaults: [
      {
        vaultAddress: config.DWTI_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS_ARCHIVED,
      },
    ],
  },
  {
    symbol: 'dXAU',
    address: config.DXAU,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    image: '',
    vaults: [
      {
        vaultAddress: config.DXAU_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS_ARCHIVED,
      },
    ],
  },
  {
    symbol: 'dTMC',
    address: config.DTMC,
    singleOrLp: SingleOrLp.SINGLE,
    dAssetsType: DAssetsType.DASSETS,
    image: '',
    vaults: [
      {
        vaultAddress: config.DTMC_VAULT,
        dyTokenAddress: '',
        displayPosition: DisplayPosition.DASSETS_ARCHIVED,
      },
    ],
  },
];

export default allLpTokens;
