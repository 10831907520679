import config from '@/config/config';
import { ZERO_ADDRESS } from '@/constants';
import { IContractContainer, injectContractERC20, injectContractReader } from '@/contracts';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from 'ethers';

export const getDusdMarketCap = async (contractContainer: IContractContainer) => {
  const dusdContract = injectContractERC20(contractContainer, config.DUSD);
  const dusdTotalSupply: BigNumber = await dusdContract.totalSupply();
  const marketCap = dusdTotalSupply.sub(BigNumber.from('23330883777').mul(parseUnits('1', 18)));
  return marketCap.div(1e10);
};
