import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import Margin from '@/components/Margin';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/ModalHeader';
import { dodoLpPairs } from '@/config/config';
import { DodoLpPair } from '@/config/types';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import CombineLp from './CombineLp';
import SelectTokenArea from './SelectTokenArea';
import SettingModal from './SettingModal';

interface Props {
  visible: boolean;
  closeModal: () => void;
  lpTokenAddress: string;
  refreshData: () => void;
}

interface Token {
  symbol: string;
  address: string;
}

const AddLiquidityModal: FC<Props> = props => {
  const { visible, closeModal, lpTokenAddress, refreshData } = props;

  const [detailVisible, setDetailVisible] = useState(true);
  const [selectedDodoLpPair, setSelectedDodoLpPair] = useState<DodoLpPair | null>(null);

  // const token0List = dodoLpPairs.map(each => each.token0);
  // const token1List = dodoLpPairs.map(each => each.token1);

  // const [token0, setToken0] = useState<Token>(token0List[0]);
  // const [token1, setToken1] = useState<Token>(token1List[0]);

  useEffect(() => {
    const lpPair = dodoLpPairs.find(each => each.lpToken.address === lpTokenAddress);
    if (lpPair) {
      setSelectedDodoLpPair(lpPair);
    }
  }, [lpTokenAddress]);

  if (!lpTokenAddress) return null;
  if (!selectedDodoLpPair) return null;

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <ModifyLiquidityModalWrapper>
        {!detailVisible && (
          <ModalHeader
            title="Add Liquidity"
            operations={[<i className="iconfont icon-guanbi1" onClick={closeModal} />]}
          />
        )}

        {detailVisible && (
          <ModalHeader
            title={
              <DetailModalTitle>
                {/* <span className="arrow-span">
                  <i className="iconfont icon-jiantou1" />
                </span> */}
                Add Liquidity
              </DetailModalTitle>
            }
            operations={[
              // <i className="iconfont icon-shezhi" onClick={changeSetting} />,
              <i className="iconfont icon-guanbi1" onClick={closeModal} />,
            ]}
          />
        )}

        {/* {!detailVisible && (
          <ChooseLpDiv>
            <Margin top={20} />
            <div>CHOOSE A VALID PAIR</div>
            <Margin top={20} />
            <SelectTokenRow>
              <SelectTokenArea tokenList={token0List} onChange={token => setToken0(token)} />
              <AddIcon className="iconfont icon-jia" />
              <SelectTokenArea tokenList={token1List} onChange={token => setToken1(token)} />
            </SelectTokenRow>
            <Margin top={20} />
            <DButton onClick={() => setDetailVisible(true)}>Add Liquidity</DButton>
          </ChooseLpDiv>
        )} */}

        {detailVisible && <CombineLp selectedDodoLpPair={selectedDodoLpPair} refreshData={refreshData} />}
      </ModifyLiquidityModalWrapper>
    </Modal>
  );
};

export default AddLiquidityModal;

export const ModifyLiquidityModalWrapper = styled.div`
  padding: 40px;
  margin: 50px auto;
  width: 840px;
  height: auto;
  background: var(--bg-main);
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  color: var(--text-main);

  @media (max-width: 768px) {
    padding: 20px;
    width: calc(100% - 40px);
    max-height: 70vh;
    overflow: scroll;
  }
`;

export const SelectTokenRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ChooseLpDiv = styled.div``;

const AddIcon = styled.i`
  font-size: 40px;
`;

const DetailModalTitle = styled.div`
  cursor: pointer;
`;
