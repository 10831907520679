import { BigNumber, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

interface CommifyBigNumberOptions {
  unit?: number;
  decimal?: number;
}

const commifyBigNumber = (originValue?: BigNumber | null, options?: CommifyBigNumberOptions | number): string => {
  let unit = 18;
  let decimal = 4;
  if (!options) {
    // pass
  } else if (typeof options === 'number') {
    unit = options;
  } else {
    unit = options.unit ?? 18;
    decimal = options.decimal ?? 4;
  }

  if (!originValue) return '-';
  if (unit < decimal) return '-';

  const divider = parseUnits('1', unit - decimal);

  const remainder = originValue.mod(divider);
  const theBalance = ethers.utils.formatUnits(originValue.sub(remainder), unit);
  const valueString = ethers.utils.commify(theBalance);

  if (decimal === 0) {
    return valueString.split('.')[0];
  }

  const decimalStringLength = valueString.split('.')[1].length;
  const zeroString = new Array(decimal - decimalStringLength).fill('0').join('');
  return valueString + zeroString;
};

export const commifyBigNumberToValue = (originValue: BigNumber): string => {
  return commifyBigNumber(originValue, {
    decimal: 4,
  });
};

export default commifyBigNumber;
