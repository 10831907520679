import type { Signer } from '@ethersproject/abstract-signer';
import type { Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import config from '@/config/config';
import cakeAbi from '../config/abi/cake.json';
import { getMulticallAddress } from './addressHelpers';
import { simpleRpcProvider } from './providers';

// Addresses

import MultiCallAbi from '../config/abi/Multicall.json';

// Types
type Multicall = any;
type Cake = any;

export const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getCakeContract = (signer?: Signer | Provider) => {
  return getContract(cakeAbi, config.CAKE, signer) as Cake;
};

export const getMulticallContract = () => {
  return getContract(MultiCallAbi, getMulticallAddress(), simpleRpcProvider) as Multicall;
};
