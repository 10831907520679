import { ContractInterface } from 'ethers';

export const MintVaultABi: ContractInterface = [
  'event Borrow(address indexed,uint256)',
  'event ControllerChanged(address)',
  'event FeeConfChanged(address)',
  'event Liquidated(address indexed,address indexed,uint256)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event Repay(address indexed,uint256,uint256)',
  'function PercentBase() view returns (uint256)',
  'function borrow(uint256)',
  'function borrows(address) view returns (uint256)',
  'function controller() view returns (address)',
  'function feeConf() view returns (address)',
  'function initialize(address,address,address)',
  'function isDuetVault() view returns (bool)',
  'function liquidate(address,address,bytes)',
  'function owner() view returns (address)',
  'function pendingValue(address,uint256) view returns (uint256)',
  'function renounceOwnership()',
  'function repay(uint256)',
  'function repayTo(address,uint256)',
  'function setAppController(address)',
  'function setFeeConf(address)',
  'function tokensReceived(address,uint256,bytes) returns (bool)',
  'function transferOwnership(address)',
  'function underlying() view returns (address)',
  'function underlyingAmountValue(uint256,bool) view returns (uint256)',
  'function userValue(address,bool) view returns (uint256)',
  'function valueToAmount(uint256,bool) view returns (uint256)',
];
