import BigNumber from 'bignumber.js';
import cakeVaultAbi from '../../config/abi/cakeVaultV2.json';
import { getCakeContract } from '../../utils/contractHelpers';
import { BIG_ZERO } from '../../utils/bigNumber';
import { getCakeVaultAddress } from '../../utils/addressHelpers';
import { multicallv2 } from '../../utils/multicall';

const cakeVaultV2 = getCakeVaultAddress();
const cakeContract = getCakeContract();
export const fetchPublicVaultData = async (cakeVaultAddress = cakeVaultV2) => {
  try {
    const calls = ['getPricePerFullShare', 'totalShares', 'totalLockedAmount'].map(method => ({
      address: cakeVaultAddress,
      name: method,
    }));

    const [[[sharePrice], [shares], totalLockedAmount], totalCakeInVault] = await Promise.all([
      multicallv2(cakeVaultAbi, calls, {
        requireSuccess: false,
      }),
      cakeContract.balanceOf(cakeVaultV2),
    ]);

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO;
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO;
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO;
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
    };
  } catch (error) {
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalCakeInVault: null,
    };
  }
};

export const fetchVaultFees = async (cakeVaultAddress = cakeVaultV2) => {
  try {
    const calls = ['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map(method => ({
      address: cakeVaultAddress,
      name: method,
    }));

    const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2(cakeVaultAbi, calls);

    return {
      performanceFee: performanceFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    };
  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    };
  }
};

export default fetchPublicVaultData;
