import { FORKED_CHAIN_IDS } from '@/config/forked';
import configTestBsc from './bsctest/config';
import configBsc from './bsc/config';
import { dodoLpPairs as dodoLpPairsBsc } from './bsc/dodoLpPairs';
import { dodoLpPairs as dodoLpPairsBscTest } from './bsctest/dodoLpPairs';
import { DodoLpPair } from './types';

type Combine<T, U> = Extract<keyof T, keyof U>;

type CommonKey = Combine<typeof configBsc, typeof configTestBsc>;

type Config = Pick<typeof configBsc, CommonKey>;

const configMap: Record<string, Config> = {
  56: configBsc,
  97: configTestBsc,
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (configMap[chainId] = configMap[56]));
const config = configMap[import.meta.env.VITE_CHAIN_ID];

const dodoLpPairsMap: Record<string, DodoLpPair[]> = {
  56: dodoLpPairsBsc,
  97: dodoLpPairsBscTest,
};

export const dodoLpPairs = dodoLpPairsMap[import.meta.env.VITE_CHAIN_ID];

export default config;
