import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { injectContractMintVault } from '../contracts';
import { useWeb3 } from '../web3';

const useBorrowPrice = (vaultAddress?: string) => {
  const { contractContainer } = useWeb3();

  const [value, setValue] = useState<BigNumber>(ethers.constants.Zero);

  useEffect(() => {
    if (!vaultAddress || !contractContainer) return;
    (async () => {
      const contract = injectContractMintVault(contractContainer, vaultAddress);
      const res = await contract.underlyingAmountValue(parseEther('1'), false);
      setValue(res);
    })();
  }, [vaultAddress, contractContainer]);

  return value;
};

export default useBorrowPrice;
