import { BigNumber, ethers } from 'ethers';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { formatEther, parseEther } from '@ethersproject/units';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import commifyBigNumber from '@/utils/commify';
import DButton from '@/components/DButton';
import useTokenBalance from '@/components/TokenInput/useTokenBalance';
import TokenDisplay from '@/components/TokenDisplay';
import executeAndShowTx from '@/utils/executeAndShowTx';
import toFixed from '@/utils/toFixed';
import FlexRow from '@/components/FlexRow';
import InfoCircle from '@/components/InfoCircle';
import { allDAssetsStrategyList, dAssetsInnovationStrategyList, dAssetsStrategyList } from '@/config/allLpTokens';
import { useStrategy } from '@/pages/DAssetsDetail/context';
import { useWeb3 } from '@/web3';
import styles from './style.module.scss';
import { injectContractMintVault } from '../../../../../contracts';

export interface VaultInfo {
  assets: BigNumber;
  syntheticAssets: BigNumber;
  debtToAssetRatio: string;
  vaultBalance: BigNumber;
}

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
}

const Repay: FC<Props> = props => {
  const { refreshDataCount, refreshData } = props;
  const { account, contractContainer } = useWeb3();
  const { address } = useParams();

  const strategy = useStrategy();
  const vaultAddress = strategy?.vaultAddress;
  const currentToken = allDAssetsStrategyList.find(each => each.address === address);

  const [inputAmount, setInputAmount] = useState('');
  const [borrowsAmount, setBorrowsAmount] = useState<BigNumber>();

  useEffect(() => {
    (async () => {
      if (!account || !vaultAddress || !contractContainer) return;
      const contract = injectContractMintVault(contractContainer, vaultAddress);
      const res = await contract.borrows(account);
      setBorrowsAmount(res);
    })();
  }, [vaultAddress, refreshDataCount, contractContainer]);

  const { balance: accountBalance, refreshBalance } = useTokenBalance({
    tokenAddress: address,
    account,
  });

  useEffect(() => {
    refreshBalance();
  }, [refreshDataCount]);

  const repay = async () => {
    if (!vaultAddress || !contractContainer || !account) return;
    const contract = injectContractMintVault(contractContainer, vaultAddress).connect(
      contractContainer.getSigner(account),
    );
    const promise = contract.repay(parseEther(inputAmount));

    await executeAndShowTx(promise);

    refreshData();
    setInputAmount('');
  };

  const handleAmountChange = (amount: string) => {
    setInputAmount(amount);
  };

  const maxString = useMemo(() => {
    if (!borrowsAmount || !accountBalance) return '';
    const borrowsAmountInput = borrowsAmount.div(99).mul(100);
    if (borrowsAmountInput.gt(accountBalance)) {
      return formatEther(accountBalance);
    }
    return formatEther(borrowsAmountInput);
  }, [borrowsAmount, accountBalance]);

  const buttonDisabled = useMemo(() => {
    if (!accountBalance || !borrowsAmount) return true;
    if (!inputAmount || +inputAmount === 0) return true;
    if (parseEther(inputAmount || '0').gt(accountBalance)) return true;
    return false;
  }, [inputAmount, accountBalance, borrowsAmount]);

  return (
    <section className={styles.MintBurnWrapper}>
      <TokenDisplay token={currentToken} />
      <Margin top={20} />
      <FlexRow>
        <DInput value={inputAmount} onChange={handleAmountChange} maxAmount={maxString} />
        <Margin left={10} />
        <InfoCircle text="A 1% repayment fee is charged, repay 101% of your outstanding debt to clear your obligations." />
      </FlexRow>
      <Margin top={10} />
      <div className={styles.tips}>Wallet Balance: {commifyBigNumber(accountBalance)}</div>
      <Margin top={30} />
      <DButton width={590} onClick={repay} disabled={buttonDisabled} approveFrom={address} approveTo={vaultAddress}>
        Repay
      </DButton>
    </section>
  );
};

export default Repay;
