import { Button, Image, Tooltip } from 'antd';
import cs from 'classnames';
import commifyBigNumber from '@/utils/commify';
import InfoCircle from '@/components/InfoCircle';
import addTokenToWallet from '@/utils/addTokenToWallet';
import config from '@/config/config';
import getCdnResource from '@/utils/getCdnResource';
import styles from './style.module.scss';
import { BondObject } from '../..';
import iconBond from '../icon_bond.png';
import { getMonthDisplay } from '../DuetBondsTable';

interface DuetBondsTableIProps {
  tableSource: BondObject[];
}

const DuetBondsTableMobile = ({ tableSource }: DuetBondsTableIProps) => {
  return (
    <section className={styles.DuetBondsTableMobile}>
      <span className={styles.title}>On-the-Run Duet Bond</span>
      {tableSource.map((item: BondObject, index: number) => {
        return (
          <section key={index} className={styles.tableItem}>
            {/* 名称 */}
            <div className={styles.names}>
              <div className={styles.left}>
                <div className={styles.iconSec}>
                  <Image className={styles.icon} src={iconBond} preview={false} />
                </div>
                <span className={styles.text}>
                  <div>{item?.name}</div>
                  <div>{getMonthDisplay(item.month)}</div>
                </span>
              </div>
              <Tooltip placement="top" className="tooltip" title="Import token to wallet">
                <span
                  onClick={() => {
                    addTokenToWallet(
                      { address: item?.epochAddr, symbol: item?.name || '' },
                      getCdnResource(`/token/bondIcon.png`),
                    );
                  }}
                  className={styles.addToken}
                />
              </Tooltip>
            </div>
            {/* 价格 */}
            <section className={styles.row}>
              {/* Label */}
              <div className={styles.rowTitle}>
                <span className={styles.rowTitleSpan}>PRICE</span>
                <span className={styles.rowTitleSpan}>
                  ($bDuet/$DUET)
                  <InfoCircle text="the mount of $Duet token that should be invested today in order to receive 1 $Duet at maturity date">
                    <i className={cs('iconfont icon-tishi_xianxing', styles.icon)} />
                  </InfoCircle>
                </span>
              </div>
              {/* Value */}
              <div className={styles.rowValue}>{commifyBigNumber(item?.price)}</div>
            </section>
            {/* YTM */}
            <section className={styles.row}>
              {/* Label */}
              <div className={styles.rowTitle}>
                <span className={styles.rowTitleSpan}>
                  YTM
                  <InfoCircle text="The annualized return anticipated on a bond if the bond is held until it matures.">
                    <i className={cs('iconfont icon-tishi_xianxing', styles.icon)} />
                  </InfoCircle>
                </span>
              </div>
              {/* Value */}
              <div className={styles.rowValue}>{Number(item.YTM).toFixed(2)}%</div>
            </section>
            {/* MATURITY */}
            <section className={styles.row}>
              {/* Label */}
              <div className={styles.rowTitle}>
                <span className={styles.rowTitleSpan}>MATURITY</span>
              </div>
              {/* Value */}
              <div className={styles.rowValue}>{item?.time}</div>
            </section>
            {/* YOUR BALANCE */}
            <section className={styles.row}>
              {/* Label */}
              <div className={styles.rowTitle}>
                <span className={styles.rowTitleSpan}>YOUR BALANCE</span>
              </div>
              {/* Value */}
              <div className={styles.rowValue}>{commifyBigNumber(item?.balance)}</div>
            </section>
            {/* 按钮组 */}
            <section className={styles.buttons}>
              <div className={styles.buttonsTop}>
                <Button
                  className={cs(styles.button, styles.buttonBuy)}
                  onClick={() =>
                    window.open(
                      `https://pancakeswap.finance/swap?inputCurrency=${config?.DUET}&outputCurrency=${item?.epochAddr}`,
                    )
                  }>
                  Buy
                </Button>
                <Button
                  className={cs(styles.button, styles.buttonSell)}
                  onClick={() =>
                    window.open(
                      `https://pancakeswap.finance/swap?inputCurrency=${item?.epochAddr}&outputCurrency=${config?.DUET}`,
                    )
                  }>
                  Sell
                </Button>
              </div>
              <div className={styles.buttonsBottom}>
                <Button
                  className={cs(styles.button, styles.buttonLiquidity)}
                  onClick={() => window.open(`https://pancakeswap.finance/add/${item?.epochAddr}/${config?.DUET}`)}>
                  Liquidity
                </Button>
              </div>
            </section>
          </section>
        );
      })}
    </section>
  );
};

export default DuetBondsTableMobile;
