import { BigNumber, constants, ethers } from 'ethers';
import { calcDetRatio } from '@/utils/calcDetRatio';
import { allDepositStrategyList, depositInnovationStrategyList, depositStrategyList } from '@/config/allLpTokens';
import config from '@/config/config';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import { ebCake, ebCakes } from '@/pages/EbCake/config';
import { getTokenPrice } from '@/servers/getTokenPrice';
import { DisplayPosition } from '@/config/types';
import {
  IContractContainer,
  injectContractAppController,
  injectContractEbCake,
  injectContractLinkUsdOracle,
  injectContractReader,
} from '../../contracts';
import { fetchArbitrumTVL } from './arbitrumTVL';

const { Zero } = ethers.constants;
export interface VaultInfo {
  totalAssets: BigNumber | null;
  collateralAssets: BigNumber | null;
  synthetic: BigNumber | null;
  reducedAssets: BigNumber | null;
  reducedSyntheticAssets: BigNumber | null;
  credit: BigNumber | null;
  debt: string | null;
  TVL: BigNumber | null;
  ebCakeTVL: BigNumber | null;
  dAssets: BigNumber | null;
}

// 统计 CAKE 的锁仓量
const getCakeTVL = async (contractContainer: IContractContainer) => {
  if (!contractContainer || !ebCakes) return Zero;

  const promises = ebCakes.map(ebCake => {
    const ebCakeInteractionContract = injectContractEbCake(
      contractContainer,
      ebCake.addresses.interactionContractAddress,
    );
    return ebCakeInteractionContract.totalUnderlyingAmount();
  });
  const supplies = await Promise.all(promises);
  const totalSupply = supplies.reduce((a, c) => a.add(c), constants.Zero);

  const price = await getTokenPrice(contractContainer, config.CAKE_BUSD);
  return price.mul(totalSupply).div(parseUnits('1', 18));
};

export const getTVL = async (container: IContractContainer) => {
  const allTokenVaultAddress = allDepositStrategyList
    .filter(strategy => strategy.displayPosition !== DisplayPosition.DEPOSIT_ARCHIVED)
    .map(each => each.vaultAddress);

  const contract = injectContractReader(container);
  const [res, ebCakeTVL, arbitrumTVL] = await Promise.all([
    contract.depositVaultValues(allTokenVaultAddress, false) as BigNumber[][],
    getCakeTVL(container),
    fetchArbitrumTVL(),
  ]);

  console.log('arbitrumTVL', arbitrumTVL);

  const TVL = res[1].reduce((a, c) => {
    return a.add(c);
  }, ethers.constants.Zero);
  return {
    totalTVL: TVL.add(ebCakeTVL).add(parseUnits(Math.floor(arbitrumTVL).toString(), 8)),
    ebCakeTVL,
  };
};

export const getVaultInfo = async (account: string, container: IContractContainer) => {
  const contract = injectContractAppController(container);
  const oracleContract = injectContractLinkUsdOracle(container);

  const [
    [totalAssets],
    [collateralAssets, totalSynthetic],
    [reducedTotalAssets, reducedTotalSynthetic],
    dWTIMaxBorrow,
    dWTIPrice,
  ] = await Promise.all([
    // 总的价值
    contract.userTotalValues(account, false) as Promise<[BigNumber, BigNumber]>,
    // 作为抵押品的价值
    contract.userValues(account, false) as Promise<[BigNumber, BigNumber]>,
    contract.userValues(account, true) as Promise<[BigNumber, BigNumber]>,
    contract.maxBorrow(account, config.DWTI_VAULT) as BigNumber,
    oracleContract.getPrice(config.DWTI) as BigNumber,
  ]);

  const debt = calcDetRatio(reducedTotalAssets, reducedTotalSynthetic);
  // 把 DUSD 最大可借数量转化为 美元价值
  const credit = dWTIMaxBorrow ? dWTIMaxBorrow.mul(dWTIPrice).div(parseUnits('1', 18)) : null;

  const valueInfo: VaultInfo = {
    reducedAssets: reducedTotalAssets,
    reducedSyntheticAssets: reducedTotalSynthetic,
    collateralAssets,
    totalAssets,
    synthetic: totalSynthetic,
    credit,
    debt,
    TVL: null,
    ebCakeTVL: null,
    dAssets: null,
  };
  return valueInfo;
};
