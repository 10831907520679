import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import Margin from '@/components/Margin';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/ModalHeader';
import config, { dodoLpPairs } from '@/config/config';
import { DodoLpPair } from '@/config/types';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModifyLiquidityModalWrapper } from './AddLiquidityModal';
import CombineLp from './CombineLp';
import SelectTokenArea from './SelectTokenArea';
import SplitLp from './SplitLp';

interface Props {
  visible: boolean;
  closeModal: () => void;
  lpTokenAddress: string;
  refreshData: () => void;
}

const RemoveLiquidityModal: FC<Props> = props => {
  const { visible, closeModal, lpTokenAddress, refreshData } = props;
  const [detailVisible, setDetailVisible] = useState(true);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [selectedDodoLpPair, setSelectedDodoLpPair] = useState<DodoLpPair>();

  useEffect(() => {
    const lpPair = dodoLpPairs.find(each => each.lpToken.address === lpTokenAddress);
    if (lpPair) {
      setSelectedDodoLpPair(lpPair);
    }
  }, [lpTokenAddress]);

  if (!lpTokenAddress) return null;
  if (!selectedDodoLpPair) return null;

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <ModifyLiquidityModalWrapper>
        {!detailVisible && (
          <ModalHeader
            title="Remove Liquidity"
            operations={[<i className="iconfont icon-guanbi1" onClick={closeModal} />]}
          />
        )}

        {detailVisible && (
          <ModalHeader
            title="Remove Liquidity"
            operations={[<i className="iconfont icon-guanbi1" onClick={closeModal} />]}
          />
        )}

        {!detailVisible && (
          <div>
            <Margin top={20} />
            <div>CHOOSE A Dodo LP Token</div>
            <Margin top={20} />
            {selectedDodoLpPair.lpToken.symbol}
            <Margin top={20} />
            <DButton onClick={() => setDetailVisible(true)}>Remove Liquidity</DButton>
          </div>
        )}

        {detailVisible && <SplitLp selectedDodoLpPair={selectedDodoLpPair} refreshData={refreshData} />}
      </ModifyLiquidityModalWrapper>
    </Modal>
  );
};

export default RemoveLiquidityModal;

// const ModalHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

const ModalHeaderLeft = styled.div`
  font-size: 24px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #ffffff;

  .arrow-span {
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 10px;
  }
`;

const ModalHeaderRight = styled.div`
  display: flex;

  .iconfont {
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
`;

const AddIcon = styled.i`
  font-size: 40px;
`;
