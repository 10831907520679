import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { injectContractERC20 } from '../../contracts';
import { useWeb3 } from '../../web3';

interface UseTokenBalanceProps {
  tokenAddress?: string;
  account?: string | null;
}

const useTokenBalance = (props: UseTokenBalanceProps) => {
  const { tokenAddress, account } = props;
  const [refreshCount, setRefreshCount] = useState(0);
  const { contractContainer } = useWeb3();

  const refreshBalance = () => {
    setRefreshCount(refreshCount + 1);
  };

  const [balance, setBalance] = useState<BigNumber | null>(null);

  // 通过 token 和 account 读取 token 的余额
  useEffect(() => {
    if (!account) {
      setBalance(null);
      return;
    }
    if (!tokenAddress || !contractContainer) return;
    (async () => {
      try {
        const contract = injectContractERC20(contractContainer, tokenAddress);
        const tokenBalance = await contract.balanceOf(account);
        setBalance(tokenBalance);
      } catch (err) {
        console.error('err: ', err);
      }
    })();
  }, [tokenAddress, account, refreshCount, contractContainer]);

  return {
    balance,
    refreshBalance,
  };
};

export default useTokenBalance;
