import { allLpTokensMap } from '@/config/allLpTokens';
import zapRoutes from '@/config/zapRoutes';
import configTemp from '../config/config';

const config = configTemp as any;

const getTokenSymbolByAddress = (address: string) => {
  const tokenSymbol = allLpTokensMap[address].symbol;
  return tokenSymbol;
};

export const getTokenZapRoutes = (originAddress: string, targetAddress: string) => {
  const origin = getTokenSymbolByAddress(originAddress);
  const target = getTokenSymbolByAddress(targetAddress);

  if (origin === target) return [];
  // 如果没有配置
  if (!zapRoutes[origin] || !zapRoutes[origin][target]) {
    return [config[origin.toUpperCase()], config[target.toUpperCase()]];
  }

  const routeConfig = zapRoutes[origin][target];

  const routeAddress = routeConfig.map(route => config[route.toUpperCase()]);
  const finalRoutes = [config[origin.toUpperCase()], ...routeAddress, config[target.toUpperCase()]];
  return finalRoutes;
};

export const getLpZapRoutes = (originAddress: string, targetAddress: string) => {
  const target = getTokenSymbolByAddress(targetAddress);
  const [target0, target1] = target.split('-');
  const target0Temp = target0 === 'BNB' ? 'WBNB' : target0;
  const target1Temp = target1 === 'BNB' ? 'WBNB' : target1;
  const targetAddress0 = config[target0Temp.toUpperCase()];
  const targetAddress1 = config[target1Temp.toUpperCase()];
  return [getTokenZapRoutes(originAddress, targetAddress0), getTokenZapRoutes(originAddress, targetAddress1)];
};
