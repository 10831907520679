import styled from '@emotion/styled';

export const RewardPanel = styled.div`
  width: 500px;
  height: 540px;
  background: var(--primary-color);
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
    span {
      margin-left: 10px;
      font-size: 20px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: var(--text-white3);
      line-height: 30px;
      max-width: 230px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  > div:nth-of-type(2) {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    > span:nth-of-type(1) {
      font-size: 14px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: var(--text-white3);
      opacity: 0.3;
      margin-right: 5px;
      @media (max-width: 768px) {
        margin-top: 40px;
      }
    }
    > span:nth-of-type(2) {
      font-size: 24px;
      font-family: Poppins-SemiBold, Poppins;
      font-weight: 600;
      color: var(--text-white3);
      line-height: 35px;
      margin-left: 5px;

      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }
`;
