import DButton from '@/components/DButton';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import { injectContractEbCake } from '@/contracts';
import commifyBigNumber from '@/utils/commify';
import toFixed from '@/utils/toFixed';
import { useWeb3 } from '@/web3';
import styled from 'styled-components';
import { parseEther } from '@ethersproject/units';
import { message } from 'antd';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { useCatchError, useEbCakeInfo } from '../context';

const Redeem: FC = () => {
  const { ebCakes, refresh } = useEbCakeInfo();
  const [inputAmount, setInputAmount] = useState<string>('');
  const { contractContainer, account } = useWeb3();
  const executeAndShowTx = useExecuteAndShowTx();
  const { onError } = useCatchError();

  const ebCakeInfo = useMemo(() => {
    const nowMoment = moment();
    for (const ebCake of ebCakes) {
      if (
        ebCake.timeWindow?.redeemWindow?.[0]?.isBefore(nowMoment) &&
        ebCake.timeWindow?.redeemWindow?.[1]?.isAfter(nowMoment)
      ) {
        return ebCake;
      }
    }
    return null;
  }, [ebCakes]);

  const redeem = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCake(contractContainer, ebCakeInfo.addresses.interactionContractAddress).connect(
      contractContainer.getSigner(account),
    );

    await contract.estimateGas.redeem(parseEther(inputAmount)).then(async gasEstimated => {
      await executeAndShowTx(
        contract.redeem(parseEther(inputAmount), {
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      setInputAmount('');
      refresh();
    }, onError);
  };

  const redeemWindow = ebCakeInfo?.timeWindow?.redeemWindow;
  const canRedeem = moment().isBetween(redeemWindow?.[0], redeemWindow?.[1]);

  if (!ebCakeInfo || !canRedeem) {
    return (
      <RedeemWrapper>
        The redemption window is currently not available. Please check the maturity date and come back later!
      </RedeemWrapper>
    );
  }

  const ebCakeBalanceMaxStr = toFixed(ebCakeInfo.balance);

  return (
    <RedeemOperationWrapper>
      <div>
        <DInput value={inputAmount} onChange={setInputAmount} width={600} maxAmount={ebCakeBalanceMaxStr} />
        <span>ebCAKE Balance: {commifyBigNumber(ebCakeInfo.balance)}</span>
      </div>

      <Margin left={40} />
      <DButton
        approveFrom={ebCakeInfo.addresses.tokenAddress}
        approveTo={ebCakeInfo.addresses.interactionContractAddress}
        disabled={inputAmount === ''}
        onClick={redeem}
        width={260}>
        Redeem
      </DButton>
    </RedeemOperationWrapper>
  );
};

export default Redeem;

const RedeemWrapper = styled.div`
  font-size: 20px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(--text-white3);
  line-height: 30px;
  padding-top: 40px;
`;

const RedeemOperationWrapper = styled.div`
  display: flex;
  padding: 40px 0 0;

  @media (max-width: 768px) {
    flex-direction: column;

    > section {
      margin-top: 20px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > span {
      margin-top: 10px;
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: var(--text-gray2);
      line-height: 24px;
    }
  }
`;
