import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { injectContractReader } from '../contracts';
import { useWeb3 } from '../web3';

interface Props {
  vaultAddress?: string;
}

const useVaultBalance = (props: Props) => {
  const { vaultAddress } = props;
  const { account, contractContainer } = useWeb3();
  const [vaultBalance, setVaultBalance] = useState<BigNumber>();
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshVaultBalance = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    if (!vaultAddress || !account || !contractContainer) return;
    (async () => {
      const contract = injectContractReader(contractContainer);
      const res = await contract.userVaultDepositAmounts(account, [vaultAddress]);
      setVaultBalance(res[1][0]);
    })();
  }, [vaultAddress, refreshCount, contractContainer]);

  return {
    vaultBalance,
    setVaultBalance,
    refreshVaultBalance,
  };
};

export default useVaultBalance;
