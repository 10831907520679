import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import EbCakeBg from '@/assets/ebcake-bg.png';
import EbCake from '@/assets/ebcake.png';
import PercentDisplay from '@/components/PercentDisplay';
import commifyBigNumber from '@/utils/commify';
import { useWeb3 } from '@/web3';
import { getTokenPrice } from '@/servers/getTokenPrice';
import { BigNumber, constants } from 'ethers';
import { parseUnits } from '@ethersproject/units';
import UsdDisplay from '@/components/UsdDisplay';
import config from '@/config/config';
import { AnnualYield, restfulClient } from '@/restful';
import { useEbCakeInfo } from '../context';

const Information: FC = () => {
  const { contractContainer, account } = useWeb3();
  const { ebCakes } = useEbCakeInfo();
  const ebCakeInfo = ebCakes[0];
  const [cakeValue, setCakeValue] = useState<BigNumber | null>(null);
  const [cakeYieldValue, setCakeYieldValue] = useState<AnnualYield | null>(null);

  useEffect(() => {
    // 测试环境
    if (import.meta.env.VITE_NETWORK === 'bsctest') {
      setCakeYieldValue({
        totalApy: 5.02,
      } as any);
      return;
    }

    restfulClient
      .annualYieldLatest({
        token: config.CAKE,
        vault: config.CAKE_VAULT,
      })
      .then(({ data }) => {
        setCakeYieldValue(data);
      });
  }, []);

  const totalSupply = ebCakes.reduce((a, c) => a.add(c.totalSupply || constants.Zero), constants.Zero);

  useEffect(() => {
    if (!contractContainer || !ebCakeInfo || !ebCakeInfo?.totalSupply) return;
    getTokenPrice(contractContainer, config.CAKE_BUSD).then(price => {
      const value = price.mul(totalSupply).div(parseUnits('1', 18));
      setCakeValue(value);
    });
  }, [contractContainer, totalSupply]);

  const displayAPY = useMemo(() => {
    if (ebCakeInfo?.yield?.bDuetAPY == null || ebCakeInfo?.yield?.bDuetLpAPY == null) return null;
    if (ebCakeInfo.ebCakeAPR == null) return null;
    const singleTotalAPR = ebCakeInfo.yield.bDuetAPY + ebCakeInfo.ebCakeAPR;
    const lpTotalAPR = ebCakeInfo.yield.bDuetLpAPY + ebCakeInfo.ebCakeAPR;

    return Math.max(singleTotalAPR, lpTotalAPR);
  }, [ebCakeInfo]);

  return (
    <Wrapper>
      <Left>
        <TitleRow>
          <img src={EbCake} alt="" />
          <span>CAKE Liquid Staking</span>
        </TitleRow>
        <Description>
          Enjoy highest $CAKE staking rewards. Cash in rewards as you go, trade staked $CAKEs and utilise $ebCAKE in
          DeFi protocols.
        </Description>
        <InfoAreaWrapper>
          <InfoArea>
            <span>Stake with Duet</span>
            <span>
              {commifyBigNumber(totalSupply)}
              <img src={EbCake} alt="" />
            </span>
            <span>
              <UsdDisplay>{cakeValue}</UsdDisplay>
            </span>
          </InfoArea>
          <InfoArea>
            <span>APY</span>
            <span>
              <PercentDisplay>{displayAPY}</PercentDisplay>
            </span>
            <span style={{ textDecoration: 'line-through' }}>
              <PercentDisplay>{cakeYieldValue?.totalApy}</PercentDisplay>
            </span>
          </InfoArea>
        </InfoAreaWrapper>
      </Left>
      <PancakeBg style={{}} src={EbCakeBg} alt="" />
    </Wrapper>
  );
};

export default Information;

const Wrapper = styled.div`
  width: 1140px;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Left = styled.div``;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 80px;
    height: 80px;

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }

  span {
    font-size: 40px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: var(--common-white);
    line-height: 60px;
    margin-left: 20px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const Description = styled.div`
  font-size: 20px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: var(--common-white);
  line-height: 28px;
  margin-top: 20px;
`;

const InfoAreaWrapper = styled.div`
  display: flex;
  margin-top: 60px;

  & > div:not(:first-of-type) {
    margin-left: 150px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > div:not(:first-of-type) {
      margin-left: 0px;
      margin-top: 30px;
    }
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;

  & > span:first-of-type {
    font-size: 20px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 29px;
  }

  & > span:nth-of-type(2) {
    font-size: 36px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 46px;
    margin-top: 10px;

    img {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }

  & > span:nth-of-type(3) {
    font-size: 16px;
    font-family: 'Roboto';
    color: var(--text-normal);
    line-height: 21px;
    margin-top: 10px;
  }
`;

const PancakeBg = styled.img`
  width: 440px;
  height: 320px;
  margin-left: 50px;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;
