import getCdnResource from '@/utils/getCdnResource';
import styled from '@emotion/styled';
import { FC } from 'react';

interface Props {
  tokenSymbol: string;
  display?: string;
}

const TokenDisplay: FC<Props> = props => {
  const { tokenSymbol, display } = props;
  return (
    <TokenDisplayWrapper>
      <TokenImg src={getCdnResource(`/token/${tokenSymbol}.png`)} />
      <span>{display || tokenSymbol}</span>
    </TokenDisplayWrapper>
  );
};

export default TokenDisplay;

const TokenDisplayWrapper = styled.div`
  display: inline-block;
`;

const TokenImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;
