import React from 'react';
import commifyBigNumber from '@/utils/commify';
import InfoCircle from '../InfoCircle';
import { useVaultInfo } from './context';
import FlexRow from '../FlexRow';
import Margin from '../Margin';
import styles from './style.module.scss';
import MyVault from './MyVault';
import UsdDisplay from '../UsdDisplay';

const VaultDashboard: React.FC = () => {
  const { vaultInfo } = useVaultInfo();

  return (
    <div>
      <FlexRow>
        <section className={styles.Card}>
          <div className={styles.cardTitle}>
            <span className={styles.cardTitleText}>TVL</span>
            <InfoCircle text="TVL equals all the funds deposited to EARN and stablecoin minter(dUSD) modules." />
          </div>
          <div className={styles.cardContent}>
            <UsdDisplay>{vaultInfo.TVL}</UsdDisplay>
          </div>
        </section>
        <Margin left={20} />
        <section className={styles.Card}>
          <div className={styles.cardTitle}>
            <span className={styles.cardTitleText}>TBD</span>
            <InfoCircle text="Total USD value of dAssets borrowed by all users." />
          </div>
          <div className={styles.cardContent}>
            <UsdDisplay>{vaultInfo.dAssets}</UsdDisplay>
          </div>
        </section>
      </FlexRow>
      <MyVault />
    </div>
  );
};
export default VaultDashboard;
