import { FC } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import classNames from 'classnames';
import FlexRow from '@/components/FlexRow';
import globalStore from '@/stores/global';
import { Theme } from '@/assets/theme';
import DuetLogoMobile from '@/assets/duet-logo-mobile.png';
import DuetLogoMobileLight from '@/assets/duet-logo-mobile-light.png';
import ClosePic from '@/assets/close.png';
import menus from '@/menus';
import Margin from '@/components/Margin';
import NewBadge from '@/components/NewBadge';
import styles from './style.module.scss';

interface Props {
  showMenu: boolean;
  closeMenu: () => void;
}

const MobileMenu: FC<Props> = props => {
  const { dataTheme } = globalStore();
  const { showMenu, closeMenu } = props;
  const location = useLocation();

  const currentMenu = menus.find(menu => {
    if (menu.outerLink) return false;
    if (pathToRegexp(menu.link).test(location.pathname)) {
      return true;
    }
    if (menu.children) {
      return menu.children.some(child => pathToRegexp(child.route).test(location.pathname));
    }
    return false;
  });

  const navigate = useNavigate();

  const handleMenuItemClick = (link: string, outerLink: boolean) => {
    if (outerLink) {
      window.open(link, '_blank');
    } else {
      navigate(link);
      closeMenu();
    }
  };

  if (!showMenu) return null;

  return (
    <section className={styles.MobileMenuWrapper}>
      <FlexRow justifyContent="space-between">
        <img className={styles.duetLogo} src={dataTheme === Theme.dark ? DuetLogoMobile : DuetLogoMobileLight} alt="" />
        <i className={classNames(styles.closeButton, 'iconfont icon-guanbi2')} onClick={closeMenu} />
      </FlexRow>
      <Margin top={70} />
      <div className={styles.menuArea}>
        {menus
          .filter(menu => !menu.hideInMenu)
          .map(menu => (
            <div
              key={menu.text}
              className={classNames(styles.menuItem, {
                [styles.active]: currentMenu?.link === menu.link,
              })}
              onClick={() => handleMenuItemClick(menu.link, menu.outerLink)}>
              <i className={classNames('iconfont', menu.icon, styles.iconfont)} />
              <Margin left={10} />
              <span className={styles.menuText}>
                {menu.text}
                {menu.isNew && (
                  <NewWrapper>
                    <NewBadge />
                  </NewWrapper>
                )}
              </span>
            </div>
          ))}
      </div>
    </section>
  );
};

export default MobileMenu;

const NewWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -40px;
`;
