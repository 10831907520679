import { FC } from 'react';
import cs from 'classnames';
import FlexRow from '@/components/FlexRow';
import styles from './style.module.scss';

interface PerTab {
  label: string;
  value: string;
}

interface Props {
  tabs: PerTab[];
  value: string;
  onChange: (value: string) => void;
}

const SwitchTabs: FC<Props> = props => {
  const { tabs, value, onChange } = props;

  // const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <section className={styles.TabsWrapper}>
      <FlexRow>
        {tabs.map(tab => (
          <section
            className={cs(styles.LabelWrapper, { [styles.LabelWrapperFalse]: value === tab.value })}
            key={tab.label}
            onClick={() => onChange(tab.value)}>
            {tab.label}
          </section>
        ))}
      </FlexRow>
    </section>
  );
};

export default SwitchTabs;
