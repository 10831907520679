import classNames from 'classnames';
import React, { createRef, useState } from 'react';
import { message, Tooltip } from 'antd';
import addTokenToWallet from '@/utils/addTokenToWallet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.module.scss';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

// TODO: 写好类型
type SimpleToken = any;

interface SelectTokenProps {
  selectList: SimpleToken[];
  tokenSymbol?: string;
  onChange: (token: SimpleToken) => void;
  enableSelect?: boolean;
}

const SelectToken: React.FC<SelectTokenProps> = props => {
  const { selectList, tokenSymbol, onChange, enableSelect = true } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const elTrigger = createRef<HTMLElement>();
  useOnClickOutside(elTrigger, () => setMenuVisible(false));

  const toggleDropDown = () => {
    if (!enableSelect || selectList.length <= 1) {
      return;
    }
    setMenuVisible(!menuVisible);
  };

  const handleCopy = (e: any) => {
    message.success('Copied!');
  };

  const currentToken = selectList.find(token => token.symbol === tokenSymbol);
  const isBNB = currentToken?.symbol === 'BNB';

  return (
    <section ref={elTrigger} className={styles.SelectAreaWrapper}>
      <div
        className={classNames([styles.selectArea], {
          [styles.cursorPointer]: selectList.length >= 2,
        })}>
        <div onClick={toggleDropDown} className={styles.TokenArea}>
          {currentToken?.iconComponent && <div>{currentToken?.iconComponent}</div>}
          {!currentToken?.iconComponent && currentToken?.image && (
            <img className={styles.tokenLogo} src={currentToken?.image} alt={currentToken.symbol} />
          )}
          <div className={styles.tokenSymbol}>{currentToken && currentToken.symbol}</div>
          {enableSelect && selectList.length >= 2 && (
            <div>
              <i className={classNames('iconfont icon-xialajiantou', styles.xialajiantou)} />
            </div>
          )}
        </div>

        {!isBNB && (
          <CopyToClipboard text={currentToken?.address} onCopy={handleCopy}>
            <Tooltip placement="top" className="tooltip" title="Copy token address">
              <i className={classNames(styles.OperationIcon, 'iconfont icon-COPY')} />
            </Tooltip>
          </CopyToClipboard>
        )}
        {!isBNB && (
          <Tooltip placement="top" className="tooltip" title="Import token to wallet">
            <i
              onClick={e => {
                e.stopPropagation();
                addTokenToWallet(currentToken);
              }}
              className={classNames(styles.OperationIcon, 'iconfont icon-Add')}
              style={{ marginLeft: 10 }}
            />
          </Tooltip>
        )}
      </div>

      <div className={styles.menu} hidden={!menuVisible}>
        {selectList.map(token => (
          <div
            key={token.symbol}
            className={styles.menuItem}
            onClick={() => {
              onChange(token);
              setMenuVisible(false);
            }}>
            {token.iconComponent && <div className={styles.logo}>{token.iconComponent}</div>}
            {!token.iconComponent && token.image && (
              <img className={styles.tokenLogoSmall} src={token.image} alt={token.symbol} />
            )}
            <span className={styles.tokenSymbol}>{token.symbol}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SelectToken;
