/* tslint:disable */
/* eslint-disable */
/**
 * Duet RESTful APIs
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import { RestConfiguration } from "./configuration";
import { AxiosResponse, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { useRequest } from 'ahooks'

interface ModelObject {}
declare type Unknown = unknown
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 * 
 * @export
 * @interface AnnualYield
 */
export interface AnnualYield {
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    depositTokenSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    depositTokenAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    vaultAddress: string;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    swapFeeApr: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    swapFarmApr: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    duetFarmApr: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    boostedApy: number;
    /**
     * 
     * @type {number}
     * @memberof AnnualYield
     */
    totalApy: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    inHour: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    inDay: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualYield
     */
    inMonth: string;
}

/**
 * 
 * @export
 * @interface AnnualYieldPaginatedResult
 */
export interface AnnualYieldPaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof AnnualYieldPaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<AnnualYield>}
     * @memberof AnnualYieldPaginatedResult
     */
    result: Array<AnnualYield>;
}

/**
 * 
 * @export
 * @interface BaseMutatedOperationResponse
 */
export interface BaseMutatedOperationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BaseMutatedOperationResponse
     */
    success: boolean;
}

/**
 * 
 * @export
 * @interface EmailSubscribeInput
 */
export interface EmailSubscribeInput {
    /**
     * email address
     * @type {string}
     * @memberof EmailSubscribeInput
     */
    email: string;
    /**
     * email verify code
     * @type {string}
     * @memberof EmailSubscribeInput
     */
    emailVerifyCode: string;
    /**
     * wallet address
     * @type {string}
     * @memberof EmailSubscribeInput
     */
    address: string;
    /**
     * signature from address
     * @type {string}
     * @memberof EmailSubscribeInput
     */
    signature: string;
    /**
     * channels to subscribe
     * @type {Array<MessageSubscriptionChannel>}
     * @memberof EmailSubscribeInput
     */
    channels: Array<MessageSubscriptionChannel>;
}

/**
 * 
 * @export
 * @interface EmailSubscriptionInfo
 */
export interface EmailSubscriptionInfo {
    /**
     * 
     * @type {string}
     * @memberof EmailSubscriptionInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSubscriptionInfo
     */
    address: string;
    /**
     * channels to subscribe
     * @type {Array<MessageSubscriptionChannel>}
     * @memberof EmailSubscriptionInfo
     */
    channels: Array<MessageSubscriptionChannel>;
}

/**
 * 
 * @export
 * @interface EmailVerifyCodeInput
 */
export interface EmailVerifyCodeInput {
    /**
     * Your email address
     * @type {string}
     * @memberof EmailVerifyCodeInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EmailVerifyCodeInput
     */
    recaptchaToken: string;
}

/**
 * 
 * @export
 * @interface LockedValue
 */
export interface LockedValue {
    /**
     * 
     * @type {number}
     * @memberof LockedValue
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LockedValue
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof LockedValue
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    vaultAddress: string;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    usdValue: string;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    inHour: string;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    inDay: string;
    /**
     * 
     * @type {string}
     * @memberof LockedValue
     */
    inMonth: string;
}

/**
 * 
 * @export
 * @interface LockedValuePaginatedResult
 */
export interface LockedValuePaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof LockedValuePaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<LockedValue>}
     * @memberof LockedValuePaginatedResult
     */
    result: Array<LockedValue>;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageSubscriptionChannel {
    NEWS = 'NEWS',
    LIQUIDATION = 'LIQUIDATION'
}

export class RESTClient {
      constructor(protected configuration: RestConfiguration = {}) {

      }

        /**
         * AxiosRequestOptions generator for systemHealth
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected systemHealthAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/system/health`;

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/system/health
     * @param input
     * @param {*} [options] Override http request option.
     */
    public systemHealth<T = unknown>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.systemHealthAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/system/health
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
    public useSystemHealth<T = unknown>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.systemHealthAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }


        /**
         * AxiosRequestOptions generator for messageSubscriptionInfo
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected messageSubscriptionInfoAxiosOptionsCreator(input: { address: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/message-subscriptions/{address}`
                .replace(`{${"address"}}`, encodeURIComponent(String(input.address)));

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/message-subscriptions/{address}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public messageSubscriptionInfo<T = EmailSubscriptionInfo>(input: {
        address: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.messageSubscriptionInfoAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/message-subscriptions/{address}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag MessageSubscriptionsApi
     */
    public useMessageSubscriptionInfo<T = EmailSubscriptionInfo>(input: {
        /**
         * 
         */
        address: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.messageSubscriptionInfoAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for messageSubscriptionSendEmailCode
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected messageSubscriptionSendEmailCodeAxiosOptionsCreator(input: { body: EmailVerifyCodeInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/message-subscriptions/send-email-code`;

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("EmailVerifyCodeInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/message-subscriptions/send-email-code
     * @param input
     * @param {*} [options] Override http request option.
     */
    public messageSubscriptionSendEmailCode<T = BaseMutatedOperationResponse>(input: {
        body: EmailVerifyCodeInput,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.messageSubscriptionSendEmailCodeAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/message-subscriptions/send-email-code
     * @param input
     * @param {*} [options] Override http request option.
     * @tag MessageSubscriptionsApi
     */
    public useMessageSubscriptionSendEmailCode<T = BaseMutatedOperationResponse>(input: {
        /**
         * 
         */
        body: EmailVerifyCodeInput,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.messageSubscriptionSendEmailCodeAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for messageSubscriptionSubscribe
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected messageSubscriptionSubscribeAxiosOptionsCreator(input: { body: EmailSubscribeInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/message-subscriptions`;

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("EmailSubscribeInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/message-subscriptions
     * @param input
     * @param {*} [options] Override http request option.
     */
    public messageSubscriptionSubscribe<T = EmailSubscriptionInfo>(input: {
        body: EmailSubscribeInput,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.messageSubscriptionSubscribeAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/message-subscriptions
     * @param input
     * @param {*} [options] Override http request option.
     * @tag MessageSubscriptionsApi
     */
    public useMessageSubscriptionSubscribe<T = EmailSubscriptionInfo>(input: {
        /**
         * 
         */
        body: EmailSubscribeInput,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.messageSubscriptionSubscribeAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }


        /**
         * AxiosRequestOptions generator for annualYieldHistory
         * get history of annual yields by specific token and vaultAddress and interval
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected annualYieldHistoryAxiosOptionsCreator(input: { token: string, vault: string, interval: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/annual-yields/{token}/{vault}/history`
                .replace(`{${"token"}}`, encodeURIComponent(String(input.token)))
                .replace(`{${"vault"}}`, encodeURIComponent(String(input.vault)));

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.interval !== undefined) {
                localVarQueryParameter['interval'] = input.interval;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get history of annual yields by specific token and vaultAddress and interval
     * @summary 
     * @url /api/v1/statistics/annual-yields/{token}/{vault}/history
     * @param input
     * @param {*} [options] Override http request option.
     */
    public annualYieldHistory<T = AnnualYieldPaginatedResult>(input: {
        token: string,
              vault: string,
              interval: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.annualYieldHistoryAxiosOptionsCreator(input, options));
    }
    /**
     * get history of annual yields by specific token and vaultAddress and interval
     * @summary 
     * @url /api/v1/statistics/annual-yields/{token}/{vault}/history
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useAnnualYieldHistory<T = AnnualYieldPaginatedResult>(input: {
        /**
         * 
         */
        token: string,
              /**
         * 
         */
        vault: string,
              /**
         * 
         */
        interval: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.annualYieldHistoryAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for annualYieldLatest
         * get latest annual yields by specific token and vaultAddress 
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected annualYieldLatestAxiosOptionsCreator(input: { token: string, vault: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/annual-yields/{token}/{vault}/latest`
                .replace(`{${"token"}}`, encodeURIComponent(String(input.token)))
                .replace(`{${"vault"}}`, encodeURIComponent(String(input.vault)));

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get latest annual yields by specific token and vaultAddress 
     * @summary 
     * @url /api/v1/statistics/annual-yields/{token}/{vault}/latest
     * @param input
     * @param {*} [options] Override http request option.
     */
    public annualYieldLatest<T = AnnualYield>(input: {
        token: string,
              vault: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.annualYieldLatestAxiosOptionsCreator(input, options));
    }
    /**
     * get latest annual yields by specific token and vaultAddress 
     * @summary 
     * @url /api/v1/statistics/annual-yields/{token}/{vault}/latest
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useAnnualYieldLatest<T = AnnualYield>(input: {
        /**
         * 
         */
        token: string,
              /**
         * 
         */
        vault: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.annualYieldLatestAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for annualYieldLatestAll
         * get latest annual yields of all vaults
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected annualYieldLatestAllAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/annual-yields/latest`;

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get latest annual yields of all vaults
     * @summary 
     * @url /api/v1/statistics/annual-yields/latest
     * @param input
     * @param {*} [options] Override http request option.
     */
    public annualYieldLatestAll<T = AnnualYieldPaginatedResult>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.annualYieldLatestAllAxiosOptionsCreator(input, options));
    }
    /**
     * get latest annual yields of all vaults
     * @summary 
     * @url /api/v1/statistics/annual-yields/latest
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useAnnualYieldLatestAll<T = AnnualYieldPaginatedResult>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.annualYieldLatestAllAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for lockedValueHistory
         * get history of lock value by specific vaultAddress and interval
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected lockedValueHistoryAxiosOptionsCreator(input: { vault: string, interval: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/locked-values/{vault}/history`
                .replace(`{${"vault"}}`, encodeURIComponent(String(input.vault)));

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.interval !== undefined) {
                localVarQueryParameter['interval'] = input.interval;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get history of lock value by specific vaultAddress and interval
     * @summary 
     * @url /api/v1/statistics/locked-values/{vault}/history
     * @param input
     * @param {*} [options] Override http request option.
     */
    public lockedValueHistory<T = LockedValuePaginatedResult>(input: {
        vault: string,
              interval: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.lockedValueHistoryAxiosOptionsCreator(input, options));
    }
    /**
     * get history of lock value by specific vaultAddress and interval
     * @summary 
     * @url /api/v1/statistics/locked-values/{vault}/history
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useLockedValueHistory<T = LockedValuePaginatedResult>(input: {
        /**
         * 
         */
        vault: string,
              /**
         * 
         */
        interval: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.lockedValueHistoryAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for lockedValueLatest
         * get latest locked value info by specific vault address
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected lockedValueLatestAxiosOptionsCreator(input: { vault: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/locked-values/{vault}/latest`
                .replace(`{${"vault"}}`, encodeURIComponent(String(input.vault)));

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get latest locked value info by specific vault address
     * @summary 
     * @url /api/v1/statistics/locked-values/{vault}/latest
     * @param input
     * @param {*} [options] Override http request option.
     */
    public lockedValueLatest<T = LockedValue>(input: {
        vault: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.lockedValueLatestAxiosOptionsCreator(input, options));
    }
    /**
     * get latest locked value info by specific vault address
     * @summary 
     * @url /api/v1/statistics/locked-values/{vault}/latest
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useLockedValueLatest<T = LockedValue>(input: {
        /**
         * 
         */
        vault: string,
       },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.lockedValueLatestAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }
        /**
         * AxiosRequestOptions generator for lockedValueLatestAll
         * get history of lock values of all vaults
         * @summary 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected lockedValueLatestAllAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/statistics/locked-values/latest`;

            const urlObject = new URL('http://host' + localVarPath)
            const localVarUrlObj = new URLSearchParams(urlObject.search)
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            for (const [k, v] of Object.entries(localVarQueryParameter || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            for (const [k, v] of Object.entries(options.params || {})) localVarUrlObj.set(k, `${v ?? ''}`) 
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete (localVarUrlObj as any).search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath

            localVarRequestOptions.url = [urlObject.pathname, localVarUrlObj.toString()].filter(Boolean).join("?")
            return localVarRequestOptions;
        }

    /**
     * get history of lock values of all vaults
     * @summary 
     * @url /api/v1/statistics/locked-values/latest
     * @param input
     * @param {*} [options] Override http request option.
     */
    public lockedValueLatestAll<T = LockedValuePaginatedResult>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }): Promise<AxiosResponse<T>> {
        return axios(this.lockedValueLatestAllAxiosOptionsCreator(input, options));
    }
    /**
     * get history of lock values of all vaults
     * @summary 
     * @url /api/v1/statistics/locked-values/latest
     * @param input
     * @param {*} [options] Override http request option.
     * @tag StatisticsApi
     */
    public useLockedValueLatestAll<T = LockedValuePaginatedResult>(input: {
 },options?: AxiosRequestConfig & { $ignoreErrorNotification?: boolean }) {
        const srv = axios(this.lockedValueLatestAllAxiosOptionsCreator(input, options))
        const { refresh, data, ...other } = useRequest<{ data: T }, any[]>(srv as any, {
            refreshDeps: [JSON.stringify(input)]
        })
        return [{ ...other, data: data?.data }, refresh] as const
    }


};
