import AmountDisplay from '@/components/AmountDisplay';
import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import Margin from '@/components/Margin';
import PercentDisplay from '@/components/PercentDisplay';
import { DodoLpPair } from '@/config/types';
import { createFungibleContract__DuetDppController } from '@/fixtures/web3/contract/generated-map';
import useApproved from '@/hooks/useApproved';
import executeAndShowTx from '@/utils/executeAndShowTx';
import getCdnResource from '@/utils/getCdnResource';
import { useWeb3 } from '@/web3';
import { parseEther } from '@ethersproject/units';
import { BigNumber } from 'ethers';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AdditionalInfo, InformationArea } from './CombineLp';
import SettingModal from './SettingModal';
import TokenInput from './TokenInput';

interface Props {
  selectedDodoLpPair: DodoLpPair;
  refreshData: () => void;
}

const SplitLp: FC<Props> = props => {
  const { selectedDodoLpPair, refreshData } = props;
  const [slippage, setSlippage] = useState<number>(0.001);
  const [settingModalVisible, setSettingModalVisible] = useState(false);

  const [inputAmount, setInputAmount] = useState('');
  const [token1Amount, setToken1Amount] = useState<BigNumber | null>(null);
  const [token2Amount, setToken2Amount] = useState<BigNumber | null>(null);
  const [refreshCount, setRefreshCount] = useState(0);

  const { account, contractContainer } = useWeb3();
  const inputTokenSymbol = selectedDodoLpPair.token0.symbol;
  const outputTokenSymbol = selectedDodoLpPair.token1.symbol;
  const lpTokenSymbol = selectedDodoLpPair.lpToken.symbol;
  const lpTokenAddress = selectedDodoLpPair.lpToken.address;
  const { dppController } = selectedDodoLpPair;

  const handleInputAmountChanged = async (value: string) => {
    setInputAmount(value);
    if (!contractContainer) return;
    (async () => {
      const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
      const res = await contract.recommendQuoteInAmount(parseEther(value));
    })();
  };

  useEffect(() => {
    if (!contractContainer) return;
    if (inputAmount === '') {
      setToken1Amount(null);
      setToken2Amount(null);
      return;
    }
    (async () => {
      const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
      const res = await contract.recommendBaseAndQuote(parseEther(inputAmount));
      setToken1Amount(res.baseAmount);
      setToken2Amount(res.quoteAmount);
    })();
  }, [inputAmount]);

  const removeLiquidity = async () => {
    if (!account || !contractContainer) return;
    if (!token1Amount || !token2Amount) return;
    const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
    const promise = contract.connect(contractContainer.getSigner(account)).removeDuetDppLiquidity(
      parseEther(inputAmount),

      token1Amount.mul(BigNumber.from('10000').sub(slippage * 10000)).div(10000),
      token2Amount.mul(BigNumber.from('10000').sub(slippage * 10000)).div(10000),
      0,
      9999999999,
    );

    await executeAndShowTx(promise);
    setInputAmount('');
    setRefreshCount(refreshCount + 1);
    refreshData();
  };

  const {
    approve: input1Approve,
    approveLoading: input1ApproveLoading,
    approved: input1Approved,
    approvedStatusLoading: input1ApprovedStatusLoading,
  } = useApproved({
    tokenAddress: lpTokenAddress,
    approveTo: dppController,
  });

  const changeSetting = () => {
    setSettingModalVisible(true);
  };

  if (settingModalVisible) {
    return (
      <SettingModal
        setSlippage={setSlippage}
        slippage={slippage}
        visible={settingModalVisible}
        onClose={() => setSettingModalVisible(false)}
      />
    );
  }

  return (
    <CombineLpWrapper>
      <FlexRow direction="column">
        <TokenInput
          tokenSymbol={lpTokenSymbol}
          tokenImage={getCdnResource(`/token/${lpTokenSymbol}.png`)}
          value={inputAmount}
          onChange={handleInputAmountChanged}
          tokenAddress={lpTokenAddress}
          key={refreshCount}
        />
      </FlexRow>

      <PriceAndShareInfo>
        <div className="title">You will get</div>
        <InformationArea>
          <div className="info-item">
            <div className="info-label">
              <AmountDisplay>{token1Amount}</AmountDisplay>
            </div>
            <div className="info-value">{inputTokenSymbol}</div>
          </div>
          <div className="info-item">
            <div className="info-label">
              <AmountDisplay>{token2Amount}</AmountDisplay>
            </div>
            <div className="info-value">{outputTokenSymbol}</div>
          </div>
        </InformationArea>
      </PriceAndShareInfo>
      <AdditionalInfo>
        <div className="additional-left">Slippage Tolerance</div>
        <div className="additional-right cursor-pointer" onClick={changeSetting}>
          <PercentDisplay>{slippage * 100}</PercentDisplay>
          <Margin left={10} />
          <i className="iconfont icon-shezhi" />
        </div>
      </AdditionalInfo>
      <Margin top={50} />
      {!input1Approved && (
        <DButton loading={input1ApproveLoading || input1ApprovedStatusLoading} onClick={input1Approve}>
          Approve {lpTokenSymbol}
        </DButton>
      )}
      <Margin top={20} />
      {input1Approved && <DButton onClick={removeLiquidity}>Remove</DButton>}
    </CombineLpWrapper>
  );
};

export default SplitLp;

const CombineLpWrapper = styled.div`
  margin-top: 20px;
`;

const PriceAndShareInfo = styled.div`
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const Hint = styled.div`
  font-size: 12px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 14px;
`;
