import { createRef, CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export const PopupEntry: FC<{
  label: ReactNode;
  style?: CSSProperties;
  children?: (ctrl: { deactivate: () => void; activate: () => void }) => ReactNode;
}> = ({ children, label, style }) => {
  const elOuterBox = createRef<HTMLDivElement>();

  const ctrl = {
    activate: () => elOuterBox.current?.classList.add('active'),
    deactivate: () => elOuterBox.current?.classList.remove('active'),
    toggle: () => elOuterBox.current?.classList.toggle('active'),
  };

  useOnClickOutside(elOuterBox, ctrl.deactivate);

  return (
    <HoveringEntryOuterBox ref={elOuterBox} onClick={ctrl.toggle} style={style}>
      {label}
      <HoveringEntryInnerBox>{children?.(ctrl)}</HoveringEntryInnerBox>
    </HoveringEntryOuterBox>
  );
};

const HoveringEntryInnerBox = styled.div`
  position: relative;
  & > * {
    position: absolute;
    right: 0;
  }
`;

const HoveringEntryOuterBox = styled.div`
  &:not(.active) {
    ${HoveringEntryInnerBox} {
      display: none;
    }
  }
`;
