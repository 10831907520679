import styled from '@emotion/styled';
import { FC, useState } from 'react';
import Margin from '@/components/Margin';
import { useWeb3 } from '@/web3';
import globalStore from '@/stores/global';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';

import config from '@/config/config';
import { createFungibleContract__ERC20 } from '@/fixtures/web3/contract/generated-map__ebcake';
import classNames from 'classnames';
import moment from 'moment';
import DInput from '@/components/DInput';
import { CDN_BASE_URL } from '@/config/baseUrl';
import { parseEther } from '@ethersproject/units';
import { constants } from 'ethers';
import { message } from 'antd';
import discountPng from '@/assets/pro-cloud/discount.png';
import { TRANSFER_ADDRESS } from './config';
import EmailConfirm from './EmailConfirm';
import SuccessModal from './SuccessModal';

interface Props {
  visible: boolean;
  closeModal: () => void;
}

const PurchaseModal: FC<Props> = props => {
  const { visible, closeModal } = props;
  const { modalVisible } = globalStore();
  const [inputAmount, setInputAmount] = useState('1');
  const { contractContainer, account } = useWeb3();

  const [step, setStep] = useState(1);

  const [tabIndex, setTabIndex] = useState(0);
  const [txHash, setTxHash] = useState('');

  const [loading, setLoading] = useState(false);

  const [currentToken, setCurrentToken] = useState<'USDC' | 'USDT' | 'DAI'>('USDC');

  const buyNow = async () => {
    if (!contractContainer || !account) return;

    let tokenAddress = '';
    let amount = constants.Zero;
    if (tabIndex === 0) {
      tokenAddress = config.DUET;
      amount = parseEther('500000').mul(inputAmount);
    } else {
      tokenAddress = config[currentToken];
      amount = parseEther('5000').mul(inputAmount);
    }

    const contract = createFungibleContract__ERC20(contractContainer, tokenAddress).connect(
      contractContainer.getSigner(account),
    );

    try {
      setLoading(true);
      const tx = await contract.transfer(TRANSFER_ADDRESS, amount);
      await tx.wait();
      setTxHash(tx.hash);
      setStep(3);
    } catch (e: any) {
      console.log('e', e);
      if (e.message.indexOf('transfer amount exceeds balance') !== -1) {
        message.error(`Execution reverted: Transfer amount exceeds balance`);
      } else {
        message.error('Failed');
      }
      //
    } finally {
      setLoading(false);
    }
  };

  const connectWallet = () => {
    globalStore.setState({
      modalVisible: true,
    });
  };

  const connected = !!account;

  const renderAccount = (accountStr: string) => `${accountStr.slice(0, 6)}...${accountStr.slice(-6)}`;

  const currentDate = moment().format('DD/MM/YYYY');

  const requestEmail = `mailto: contact@duet.finance?subject=Intent to Subscribe to Duet Pro Cloud Service&body=Dear Duet Protocol Team,

          %0A%0AI hope this message finds you well. My name is [Client's Name], and I am writing to express my intention to subscribe to the Duet Pro Cloud service. I plan to use the BNB Smart Chain address provided below to initiate my subscription on the date specified.

          %0A%0ABNB Smart Chain Address: ${account}
          %0A%0ASubscription Date: ${currentDate}

          %0A%0AShould there be any prerequisites or additional steps needed for this process, kindly notify me at your earliest convenience. Thank you for your attention to this matter.

          %0A%0ABest Regards,

          %0A%0A[Client's Name]`;

  const payConfirmEmail = `mailto: contact@duet.finance?subject=Payment Confirmation for Duet Pro Cloud Subscription&body=Dear Duet Protocol Team,

  %0A%0AI hope this message finds you well. My name is [Client's Name], and I am writing to confirm that I have completed the payment for my subscription to the Duet Pro Cloud service. 
  
  %0A%0AFor your reference, I have attached the transaction ID below:
  
  %0A%0ATransaction ID: ${txHash}
  
  %0A%0AI kindly request a confirmation once the Duet Protocol team has received my payment. Also, I would appreciate any guidance on the next steps for integration. 
  
  %0A%0AThank you for your prompt attention to this matter.
  
  %0A%0ABest Regards,
  
  %0A%0A[Client's Name]
  `;

  const renderStep1 = () => {
    return (
      <>
        <StepText>Step 1 . Connect Your Wallet in Payment Address And Send Email</StepText>
        <Margin top={20} />
        <Button
          className={classNames({
            connected,
          })}
          onClick={() => {
            if (connected) return;
            connectWallet();
          }}>
          {connected ? renderAccount(account) : 'Connect Wallet'}
        </Button>
        <Margin top={20} />
        <a href={requestEmail} target="_blank" rel="noreferrer">
          <Button
            className={classNames({
              disabled: !connected,
            })}
            onClick={() => {
              // setStep(2);
              setEmailConfirmModalVisible(true);
            }}>
            Send Email
          </Button>
        </a>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <StepText>Step 2. Purchase </StepText>
        <div className="tab-row">
          <div
            className={classNames('tab-item', {
              active: tabIndex === 0,
            })}
            onClick={() => {
              setTabIndex(0);
            }}>
            $DUET
          </div>
          <div
            className={classNames('tab-item', {
              active: tabIndex === 1,
            })}
            onClick={() => {
              setTabIndex(1);
            }}>
            Stable Coin
          </div>
        </div>
        <SubTitle>Price Starting From</SubTitle>
        <div className="duet-price">
          <span>
            {tabIndex === 0 ? (
              <>
                500,000 $DUET
                <Discount>
                  <img className="discount" src={discountPng} alt="" />
                  <div className="discount-text">Discount 30%</div>
                </Discount>
              </>
            ) : (
              `$5,000 USD`
            )}
          </span>
        </div>
        <SubTitle>Quantity</SubTitle>
        <Margin top={10} />
        <div className="input-row">
          <input
            className="my-input"
            type="number"
            value={inputAmount}
            onInput={(e: any) => {
              console.log('e.target.value', e.target.value);
              if (/[1-9]\D*/.test(e.target.value) || e.target.value === '') {
                setInputAmount(e.target.value);
              }
            }}
          />
        </div>
        <SubTitle>Estimated Total Amount</SubTitle>
        <Margin top={20} />
        <div className="total-amount">
          {tabIndex === 0 && (
            <>
              {(500000 * +inputAmount).toLocaleString()} $DUET
              <Margin left={10} />
              <img src={`${CDN_BASE_URL}/token/DUET.png`} alt="" className="token" />
            </>
          )}
          {tabIndex === 1 && (
            <>
              ${(5000 * +inputAmount).toLocaleString()}
              <Margin left={20} />
              <div className="token-tab">
                <div
                  onClick={() => {
                    setCurrentToken('USDC');
                  }}
                  className={classNames('token-item', {
                    active: currentToken === 'USDC',
                  })}>
                  <img src={`${CDN_BASE_URL}/token/USDC.png`} alt="" />
                </div>

                <div
                  onClick={() => {
                    setCurrentToken('USDT');
                  }}
                  className={classNames('token-item', {
                    active: currentToken === 'USDT',
                  })}>
                  <img src={`${CDN_BASE_URL}/token/USDT.png`} alt="" />
                </div>

                <div
                  onClick={() => {
                    setCurrentToken('DAI');
                  }}
                  className={classNames('token-item', {
                    active: currentToken === 'DAI',
                  })}>
                  <img src={`${CDN_BASE_URL}/token/DAI.png`} alt="" />
                </div>
              </div>
            </>
          )}
        </div>
        <Margin top={20} />

        <Hint>*Insufficient balance could lead to a failed transaction</Hint>

        <Margin top={50} />
        <Button onClick={buyNow}>{loading ? `Transferring...` : `Buy Now`}</Button>
      </>
    );
  };

  const renderStep3 = () => {
    return (
      <>
        <StepText>Step 3 . Send Your Payment TX ID</StepText>
        <Margin top={20} />
        <div className="tx-hash">{txHash}</div>
        <Margin top={20} />

        <a href={payConfirmEmail} target="_blank" rel="noreferrer">
          <Button
            className={classNames({
              disabled: !connected,
            })}
            onClick={() => {
              // setStep(2);
              setEmailConfirmModalVisible(true);
            }}>
            Send Email
          </Button>
        </a>
      </>
    );
  };

  const [emailConfirmModalVisible, setEmailConfirmModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  if (step === 4) {
    return (
      <SuccessModal
        visible={successModalVisible}
        closeModal={() => {
          setStep(1);
          setSuccessModalVisible(false);
        }}
      />
    );
  }

  return (
    <>
      {!visible && null}
      {visible && (
        <ModalBackground>
          <Modal>
            <TitleRow>
              {step !== 1 && (
                <span
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    setStep(step - 1);
                  }}>
                  <i className="iconfont icon-jiantou1" />
                </span>
              )}
              <span>{step === 1 ? `Subscribe to Duet Pro Cloud Service` : `Buy Duet Pro Cloud`}</span>
              <i className="iconfont icon-guanbi2" onClick={closeModal} />
            </TitleRow>
            <Margin top={20} />
            <Step>
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
              {step === 3 && renderStep3()}
            </Step>
          </Modal>
        </ModalBackground>
      )}
      <EmailConfirm
        visible={emailConfirmModalVisible}
        onConfirm={() => {
          setEmailConfirmModalVisible(false);
          if (step === 3) {
            setSuccessModalVisible(true);
          }
          setStep(step + 1);
        }}
        onCancel={() => {
          setEmailConfirmModalVisible(false);
        }}
      />
    </>
  );
};

export default PurchaseModal;

export const Discount = styled.div`
  display: inline-block;
  margin-left: 20px;

  .discount {
    width: 25px;
    height: 25px;
  }

  .discount-text {
    top: -2px;
    position: relative;
    display: inline-block;
    background: rgba(244, 67, 54, 1);
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin-left: 10px;
  }
`;

const Step = styled.div`
  .input-row {
    text-align: left;
  }

  .total-amount {
    color: rgba(184, 80, 233, 1);
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;

    .token {
      width: 35px;
      height: 35px;
    }
  }

  .tab-row {
    display: flex;
  }

  .tab-item {
    background: rgba(157, 33, 214, 0.4);
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    min-width: 109px;
    height: 39px;
    padding: 0 20px;
    white-space: nowrap;
    margin-top: 20px;

    &.active {
      background: rgba(157, 33, 214, 1);
    }
  }

  .token-tab {
    display: flex;
    background: rgba(157, 33, 214, 0.52);
    .token-item {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 30px;
        height: 30px;
      }

      &.active {
        background: rgba(157, 33, 214, 1);
      }
    }
  }

  .tx-hash {
    color: white;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-all;
  }
`;

const SubTitle = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-top: 20px;
`;

const Hint = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const Button = styled.div`
  background: rgba(157, 33, 214, 1);
  width: 320px;
  font-size: 20px;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  color: white;
  margin: 0 auto;

  &.disabled {
    background: rgba(133, 133, 133, 1);
  }

  &.connected {
    background: rgba(81, 20, 109, 1);
  }
`;

const StepText = styled.div`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: rgba(90, 92, 242, 1);
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const Modal = styled.div`
  margin: 112px auto 0;
  max-width: 700px;
  height: auto;
  background: #1e2450;
  border-radius: 4px;
  text-align: center;
  padding: 20px 20px 40px;
  z-index: 101;
  // overflow: scroll;

  @media (max-width: 768px) {
    margin: 20px auto 0;
  }

  .duet-price {
    text-align: left;
    margin-top: 10px;
    span {
      color: rgba(163, 100, 192, 1);
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0em;
    }
  }

  .my-input {
    width: 50%;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;
    background: var(--bg-purple5);
    outline: none;
    border: 0;
    color: var(--common-white);
    padding-left: 10px;

    @include respond-to(sm) {
      width: 100% !important;
    }
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;

  span {
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
  }

  i {
    font-size: 20px;
    cursor: pointer;
    color: var(--text-white3);
  }
`;
