import { useCatchError } from '@/pages/EbCake/context';
import executeAndShowTx from '@/utils/executeAndShowTx';

export const useExecuteAndShowTx = () => {
  const { onError } = useCatchError();

  const newExecuteAndShowTx = (...[p0, p1, ...params]: Parameters<typeof executeAndShowTx>) =>
    executeAndShowTx(
      p0,
      {
        ...p1,
        onError,
      },
      ...params,
    );

  return newExecuteAndShowTx;
};
