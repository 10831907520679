import type { JsonRpcSigner, Network, Web3Provider } from '@ethersproject/providers';
import type { Contract } from 'ethers';

export interface IContractContainer {
  context: ContractContext;
  getSigner(address: string): JsonRpcSigner;
}

export function createContractInjector<T extends Contract = Contract>(
  create: (chainId: number, library: Web3Provider) => T,
): ContractInjector<T> {
  const map = new WeakMap<IContractContainer, T>();
  return container => {
    if (!map.has(container)) map.set(container, create(container.context.network.chainId, container.context.library));
    return map.get(container)!;
  };
}

type ContractContext = {
  library: Web3Provider;
  network: Network;
};

export type ContractInjector<T extends Contract = Contract> = (container: IContractContainer) => T;

export function createFungibleContractInjectorFactory<T extends Contract = Contract>(
  create: (address: string, library: Web3Provider) => T,
) {
  const map = new Map<string, ContractInjector<T>>();
  return (container: IContractContainer, address: string) => {
    if (!map.has(address))
      map.set(
        address,
        createContractInjector<T>((_chainId, library) => {
          return create(address, library);
        }),
      );
    return map.get(address)!(container);
  };
}
