import AmountDisplay from '@/components/AmountDisplay';
import Margin from '@/components/Margin';
import useTokenBalance from '@/components/TokenInput/useTokenBalance';
import UsdDisplay from '@/components/UsdDisplay';
import usePriceByAddress from '@/hooks/usePriceByAddress';
import { useWeb3 } from '@/web3';
import { formatEther, parseEther, parseUnits } from '@ethersproject/units';
import { ChangeEventHandler, FC, useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  tokenSymbol: string;
  tokenImage: string;
  value: string;
  tokenAddress: string;
  onChange: (value: string) => void;
}

const TokenInput: FC<Props> = props => {
  const { tokenSymbol, tokenImage, value, tokenAddress, onChange } = props;
  const { account } = useWeb3();

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.currentTarget.value;
    if (newValue === '') {
      onChange(newValue);
    }

    // if (maxAmount && +newValue > +maxAmount) {
    //   onChange(maxAmount);
    //   return;
    // }

    if (!/^\d+(?:\.\d{0,18})?$/.test(newValue)) {
      return;
    }

    onChange(newValue);
  };

  const balanceRes = useTokenBalance({
    tokenAddress,
    account,
  });

  const price = usePriceByAddress(tokenAddress);

  const usdValue = useMemo(() => {
    if (!price || !value) return null;
    return price.mul(parseEther(value)).div(parseUnits('1', 18));
  }, [price, value]);

  const setToMax = () => {
    if (!balanceRes.balance) return;
    const maxString = formatEther(balanceRes.balance);
    onChange(maxString);
  };

  return (
    <TokenInputWrapper>
      <InputArea>
        <div className="token-area">
          <img src={tokenImage} alt="" />
          <span className="token-symbol">{tokenSymbol}</span>
        </div>
        <div className="right-area">
          <input
            type="number"
            value={value}
            onChange={handleChange}
            onWheel={e => e.currentTarget.blur()}
            placeholder="0"
          />
          <div className="right-area-hint">
            ~<UsdDisplay>{usdValue}</UsdDisplay>
          </div>
        </div>
      </InputArea>
      <BalanceRow>
        Balance:
        <Margin left={5} />
        <AmountDisplay>{balanceRes.balance}</AmountDisplay>
        <Margin left={10} />
        <MaxButton onClick={setToMax}>MAX</MaxButton>
      </BalanceRow>
    </TokenInputWrapper>
  );
};

export default TokenInput;

const TokenInputWrapper = styled.div`
  width: 100%;
`;

const InputArea = styled.div`
  width: 100%;
  height: 100px;
  background: var(--bg-input);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .token-area {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;

      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
    .token-symbol {
      font-size: 26px;
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      color: var(--text-main);
      margin-left: 10px;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .right-area {
    display: flex;
    flex-direction: column;
    width: 70%;

    @media (max-width: 768px) {
      width: 60%;
    }

    input {
      background: rgba(0, 0, 0, 0);
      font-size: 23px;
      outline: none;
      border: 0;
      color: var(--text-main);
      text-align: right;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .right-area-hint {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: var(--text-main);
    }

    .value {
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.3);
      line-height: 16px;
    }
  }
`;

const BalanceRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(--text-main);
  line-height: 16px;
  text-align: right;
  margin-top: 10px;
`;

const MaxButton = styled.div`
  font-size: 16px;
  font-family: Poppins Bold, Inter;
  font-weight: normal;
  color: #7872ff;
  cursor: pointer;
`;
