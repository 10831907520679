import { BigNumber, constants } from 'ethers/lib/ethers';
import { createContext, useContext } from 'react';
import { VaultInfo } from './server';

const { Zero } = constants;

interface VaultContextProps {
  vaultInfo: VaultInfo;
  refreshVaultInfo: () => void;
}

export const initialVaultInfo: VaultInfo = {
  totalAssets: null,
  collateralAssets: null,
  synthetic: null,
  reducedAssets: null,
  reducedSyntheticAssets: null,
  credit: null,
  TVL: null,
  dAssets: null,
  debt: null,
};

const initialContext = {
  vaultInfo: initialVaultInfo,
  refreshVaultInfo: () => {
    //
  },
};

const VaultInfoContext = createContext<VaultContextProps>(initialContext);

export const useVaultInfo = () => {
  return useContext(VaultInfoContext);
};

export default VaultInfoContext;
