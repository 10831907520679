import React from 'react';
import styles from './style.module.scss';

interface FeeContentProps {
  rate: string;
  fee: string;
}

const FeeContent: React.FC<FeeContentProps> = props => {
  const { rate, fee } = props;
  return (
    <section className={styles.FeeContentWrapper}>
      <div>
        <span className={styles.label}>Exchange Rate</span>
        <span className={styles.value}>{rate}</span>
      </div>
      <div>
        <span className={styles.label}>Mint Fee</span>
        <span className={styles.value}>{fee}</span>
      </div>
    </section>
  );
};

export default FeeContent;
