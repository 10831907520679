import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const NormalButton: FC<PropsWithChildren<Props>> = props => {
  const { children, onClick } = props;

  return <ButtonWrapper onClick={onClick}>{children}</ButtonWrapper>;
};

export default NormalButton;

export const ButtonWrapper = styled.button`
  border-radius: 4px;
  border: 1px solid #613bf4;
  background: var(--primary-color);
  padding: 0 15px;
  font-size: 14px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(--text-white);
  line-height: 21px;
  cursor: pointer;

  &:hover {
    background: #613bf4;
    color: #d7d6d6;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
`;
