import { FC, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import _, { cloneDeep } from 'lodash';
import ReactHighcharts from '@/components/ReactHighcharts';
import Margin from '@/components/Margin';
import FlexRow from '@/components/FlexRow';
import classnames from 'classnames';
import globalStore from '@/stores/global';
import { Theme } from '@/assets/theme';
import useIsInnovationDeposit from '@/hooks/useIsInnovationDeposit';
import { LockedValue } from '@/restful';
import commifyBigNumber from '@/utils/commify';
import { BigNumber } from 'ethers';
import SwitchTabs from './components/SwitchTabs';
import { useStrategy, useTokenData } from '../../context';
import styles from './styles.module.scss';
import { ApyInfo } from '../../server';
import { getTvlInfoPeriodMap, TvlInfoPeriodMap } from './server';

enum DataType {
  APY = 'apy',
  MARKET_CAP = 'marketCap',
}

enum TimeType {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  // ALL = 'ALL',
}

// 日期 x 轴
const DATE_X_AXIS: Highcharts.XAxisOptions = {
  type: 'datetime',
  lineColor: '#473E6A',
  tickColor: '#473E6A',
  title: {
    text: undefined,
  },
  tickPixelInterval: 50,
  labels: {
    style: {
      color: '#9195A5',
    },
    formatter(this) {
      return `${moment(+this.value).format('MM-DD')}`;
    },
  },
};

// 月份 x 轴
const MONTH_X_AXIS: Highcharts.XAxisOptions = {
  type: 'datetime',
  lineColor: '#473E6A',
  tickColor: '#473E6A',
  title: {
    text: undefined,
  },
  tickPixelInterval: 50,
  labels: {
    style: {
      color: '#9195A5',
    },
    formatter(this) {
      return `${moment(+this.value).format('YYYY-MM')}`;
    },
  },
};

const apyOptions: Highcharts.Options = {
  title: {
    text: undefined,
  },
  colors: ['#7872FF'],
  chart: {
    height: 330,
    backgroundColor: '#FFFFFF00',
  },
  series: [
    {
      type: 'line',
      marker: {
        states: {
          hover: {
            lineColor: '#7872FF',
          },
        },
      },
    },
  ],
  xAxis: DATE_X_AXIS,
  yAxis: {
    title: {
      text: undefined,
    },
    gridLineColor: '#473E6A',
    labels: {
      style: {
        color: '#9195A5',
      },
      formatter() {
        return `${(+this.value).toFixed(2)}%`;
      },
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    useHTML: true,
    formatter() {
      return `${moment(+this.x).format('YYYY-MM-DD')}<br>${(+this.y).toFixed(2)}%`;
    },
  },
};

const marketCapOptions: Highcharts.Options = {
  ...apyOptions,
  xAxis: DATE_X_AXIS,
  yAxis: {
    title: {
      text: undefined,
    },
    labels: {
      formatter() {
        const [integer, decimal] = `${(+this.value).toFixed(2)}`.split('.');
        const res = `$${Number(integer).toLocaleString()}.${decimal}`;
        return res;
      },
    },
  },
  tooltip: {
    useHTML: true,
    formatter() {
      const [integer, decimal] = `${(+this.y).toFixed(2)}`.split('.');
      const value = `$${Number(integer).toLocaleString()}.${decimal}`;
      return `${moment(+this.x).format('YYYY-MM-DD')}<br>${value}`;
    },
  },
};

const Chart: FC = () => {
  const { dataTheme } = globalStore();
  const [tvlInfoPeriodMap, setTvlInfoPeriodMap] = useState<TvlInfoPeriodMap | null>(null);

  const strategy = useStrategy();
  const vaultAddress = strategy?.vaultAddress;
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(apyOptions);

  // const [tvlInfoDatas, setTvlInfoDatasMap] = useState<Record<string, TvlInfo[]>>();
  const [dataType, setDataType] = useState<DataType>(DataType.APY);
  const [timeType, setTimeType] = useState<TimeType>(TimeType.WEEK);
  const [isNoData, setIsNoData] = useState(false);

  const isInnovationDeposit = useIsInnovationDeposit();

  useEffect(() => {
    if (!vaultAddress) {
      setTvlInfoPeriodMap(null);
      return;
    }

    getTvlInfoPeriodMap(vaultAddress).then(res => {
      setTvlInfoPeriodMap(res);
    });
  }, [vaultAddress]);

  const { tokenData } = useTokenData();
  const { apyInfoPeriodMap } = tokenData;
  const { apyInfo } = tokenData;

  useEffect(() => {
    if (dataType === DataType.APY) {
      let apys: ApyInfo[] = [];
      if (timeType === TimeType.MONTH) {
        apys = apyInfoPeriodMap?.month || [];
      } else if (timeType === TimeType.WEEK) {
        apys = apyInfoPeriodMap?.week || [];
      } else {
        apys = apyInfoPeriodMap?.year || [];
      }
      if (_.isEmpty(apys)) {
        setIsNoData(true);
        return;
      }
      setIsNoData(false);

      const newOptions: Highcharts.Options = {
        ...apyOptions,
        series: [
          {
            type: 'line',
            data: _.reverse(apys).map(each => {
              return [+each.createdAt, each.totalApy ? +each.totalApy.toFixed(2) : undefined];
            }),
          },
        ],
        yAxis: {
          ...apyOptions.yAxis,
          gridLineColor: dataTheme === Theme.dark ? '#473E6A' : '#D7D6D6',
        },
        xAxis: timeType === TimeType.YEAR ? MONTH_X_AXIS : DATE_X_AXIS,
      };

      setChartOptions(newOptions);
    }

    if (dataType === DataType.MARKET_CAP) {
      let lpTokensData: LockedValue[] = [];
      if (timeType === TimeType.MONTH) {
        lpTokensData = tvlInfoPeriodMap?.month || [];
      } else if (timeType === TimeType.WEEK) {
        lpTokensData = tvlInfoPeriodMap?.week || [];
      } else {
        lpTokensData = tvlInfoPeriodMap?.year || [];
      }
      if (_.isEmpty(lpTokensData)) {
        setIsNoData(true);
        return;
      }
      setIsNoData(false);

      const newOptions: Highcharts.Options = {
        ...marketCapOptions,
        series: [
          {
            type: 'line',
            data: lpTokensData.map(token => {
              return [+token.createdAt, token.usdValue ? +(+token.usdValue / 1e8).toFixed(2) : undefined];
            }),
          },
        ],
        yAxis: {
          ...marketCapOptions.yAxis,
          gridLineColor: dataTheme === Theme.dark ? '#473E6A' : '#D7D6D6',
        },
        xAxis: timeType === TimeType.YEAR ? MONTH_X_AXIS : DATE_X_AXIS,
      };

      setChartOptions(newOptions);
    }
  }, [tvlInfoPeriodMap, dataType, timeType, apyInfoPeriodMap]);

  const apy = apyInfo?.totalApy ? apyInfo.totalApy.toFixed(2) + '%' : '-';
  const tvl = tvlInfoPeriodMap?.today?.usdValue
    ? '$' +
      commifyBigNumber(BigNumber.from(tvlInfoPeriodMap.today.usdValue), {
        unit: 8,
        decimal: 4,
      })
    : '-';

  const APYLabel = isInnovationDeposit ? `APY: ${apy}` : 'APY';
  const TVLLabel = isInnovationDeposit ? `TVL: ${tvl}` : 'TVL';

  return (
    <div>
      <FlexRow justifyContent="space-between">
        <SwitchTabs
          tabs={[
            {
              label: APYLabel,
              value: DataType.APY,
            },
            {
              label: TVLLabel,
              value: DataType.MARKET_CAP,
            },
          ]}
          value={dataType}
          onChange={value => setDataType(value as DataType)}
        />

        <SwitchTabs
          tabs={[
            {
              label: '1 Week',
              value: TimeType.WEEK,
            },
            {
              label: '1 Month',
              value: TimeType.MONTH,
            },
            {
              label: '1 Year',
              value: TimeType.YEAR,
            },
          ]}
          value={timeType}
          onChange={value => setTimeType(value as TimeType)}
        />
      </FlexRow>
      <Margin top={20} />
      {isNoData && (
        <div className={styles.NoData}>
          <div className={classnames('iconfont icon-a-NODATE', styles.NoDataIcon)} />
          <div className={styles.NoDataText}>No Data</div>
        </div>
      )}
      {!isNoData && <ReactHighcharts options={cloneDeep(chartOptions)} />}
    </div>
  );
};

export default Chart;
