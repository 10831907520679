import { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { pathToRegexp } from 'path-to-regexp';
import classNames from 'classnames';
import menus from '@/menus';
import Margin from '../Margin';
import styles from './style.module.scss';

const BreadCrumb: FC = () => {
  const location = useLocation();

  const currentMenu = menus.find(menu => {
    if (menu.outerLink) return false;
    if (pathToRegexp(menu.link).test(location.pathname)) {
      return true;
    }
    if (menu.children) {
      return menu.children.some(child => pathToRegexp(child.route).test(location.pathname));
    }
    return false;
  });

  return (
    <section className={styles.BreadCrumbWrapper}>
      <i className={classNames('iconfont', currentMenu?.icon)} />
      <Margin left={10} />
      <span className={styles.menuText}>{currentMenu?.text}</span>
    </section>
  );
};
export default BreadCrumb;

export const BreadCrumbWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }

  color: white;
  display: flex;
  align-items: center;
  margin: 50px 0 30px 0;

  .iconfont {
    font-size: 20px;
  }

  .menu-text {
    font-size: 24px;
  }
`;
