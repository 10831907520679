import { ContractInterface } from 'ethers';

export const PoolAbi: ContractInterface = [
  'constructor()',
  'event Deposit(address indexed,uint256)',
  'event Withdraw(address indexed,uint256)',
  'function deposit(address,uint256)',
  'function deposits(address) view returns (uint256)',
  'function epochInfos(address) view returns (uint256, uint256)',
  'function epoches(uint256) view returns (address)',
  'function farming() view returns (address)',
  'function getEpoches() view returns (address[])',
  'function init()',
  'function lastRewardSecond() view returns (uint256)',
  'function liquidate(address)',
  'function pending(address) view returns (address[], uint256[])',
  'function periodEnd() view returns (uint256)',
  'function remove(address)',
  'function rewardAvailable(address,address) view returns (uint256)',
  'function rewardDebt(address,address) view returns (uint256)',
  'function totalAmount() view returns (uint256)',
  'function updateReward(address[],uint256[],uint256)',
  'function validEpoches(address) view returns (bool)',
  'function withdraw(address,uint256)',
  'function withdrawAward(address) returns (address[], uint256[])',
];
