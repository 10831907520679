import Margin from '@/components/Margin';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export interface Info {
  key: string;
  value: ReactNode;
}

interface Props {
  infos: Info[];
  title?: string;
  operation?: ReactNode;
}

const InfoArea: FC<Props> = props => {
  const { title, infos, operation } = props;

  return (
    <InfoAreaWrapper>
      {title && (
        <>
          <Title>{title}</Title>
          <Margin top={10} />
        </>
      )}

      {infos.map(each => (
        <InfoRow key={each.key}>
          <div>{each.key}</div>
          <div>{each.value}</div>
        </InfoRow>
      ))}
      {operation && (
        <>
          <Margin top={20} />
          {operation}
        </>
      )}
    </InfoAreaWrapper>
  );
};

export default InfoArea;

const InfoAreaWrapper = styled.div`
  padding: 20px;
  width: 460px;
  border-radius: 10px;
  border: 1px solid #613bf4;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: var(--text-white3);
  line-height: 21px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-top: 5px;
  }

  & > div:nth-of-type(1) {
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 21px;
    opacity: 0.3;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 21px;
  }
`;
