import { createGlobalStyle, css } from 'styled-components';

const fadeOutStyles = css`
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
`;

const NotificationStyle = createGlobalStyle`
  .rc-notification {
    position: fixed;
    z-index: 1000;
    right: 20px;
    top: 120px !important;
    left: unset !important;

    .rc-notification-notice {
      width: 320px;
      background: #4025A7;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
      border-radius: 6px;

      &:not(:first-child) {
        margin-top: 20px
      }

      .rc-notification-notice-content {
        min-height: 80px;
      }
    }

    .rc-notification-fade-appear,
    .rc-notification-fade-enter {
      opacity: 0;
      ${fadeOutStyles}
      animation-play-state: paused;
    }

    .rc-notification-fade-leave {
      ${fadeOutStyles}
      animation-play-state: paused;
    }

    .rc-notification-fade-appear.rc-notification-fade-appear-active,
    .rc-notification-fade-enter.rc-notification-fade-enter-active {
      animation-name: rcNotificationFadeIn;
      animation-play-state: running;
    }

    .rc-notification-fade-leave.rc-notification-fade-leave-active {
      animation-name: rcDialogFadeOut;
      animation-play-state: running;
    }

    @keyframes rcNotificationFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes rcDialogFadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

export default NotificationStyle;
