import styled from '@emotion/styled';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';

interface Props {
  title: string;
  contentTitle: string;
  description: ReactNode;
}

const Collapse: FC<PropsWithChildren<Props>> = props => {
  const { title, contentTitle, description, children } = props;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <StepWrapper>
      <Title>{title}</Title>
      {collapsed && <i className="iconfont icon-row-down" onClick={() => setCollapsed(!collapsed)} />}
      {!collapsed && <i className="iconfont icon-row-up" onClick={() => setCollapsed(!collapsed)} />}
      <CollapseArea>
        <div>
          <ContentTitle>{contentTitle}</ContentTitle>
          <Description>{description}</Description>
        </div>
        <div hidden={collapsed} onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </CollapseArea>
    </StepWrapper>
  );
};

export default Collapse;

const StepWrapper = styled.div`
  position: relative;

  > .iconfont {
    font-size: 16px;
    position: absolute;
    right: 40px;
    top: 120px;
    color: var(--text-white2);
    cursor: pointer;

    @media (max-width: 768px) {
      right: 20px;
      top: 90px;
    }
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: #613bf4;
  line-height: 35px;
`;

const CollapseArea = styled.div`
  width: 1140px;
  border-radius: 6px;
  border: 1px solid var(--bg-purple3);
  margin-top: 20px;
  padding: 30px 40px 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 20px;
  }

  & > div:first-of-type {
    /* cursor: pointer; */
  }
`;

const ContentTitle = styled.div`
  font-size: 32px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: var(--text-white3);
  line-height: 48px;
`;

const Description = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: var(--text-normal);
  line-height: 24px;
  max-width: 900px;
  margin-top: 10px;
`;
