import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Highcharts from 'highcharts';
import ReactHighcharts from '@/components/ReactHighcharts';
import BaseRadioGroup from '@/components/BaseRadioGroup';
import styles from './style.module.scss';
import { BondObject } from '../..';

interface LineChartProps {
  list: BondObject[];
}

// 单选框组参数
const radioList = [
  {
    label: 'Yield Curve',
    value: 1,
  },
  {
    label: 'Price',
    value: 2,
  },
];

const LineChart = ({ list }: LineChartProps) => {
  // const newList = list;
  const newList = JSON.parse(JSON.stringify(list)).sort((a: BondObject, b: BondObject) => a.month - b.month);
  const [radioValue, setRadioValue] = useState<string | number>(1);
  // 折线图参数
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: undefined,
    },
    colors: ['#7872FF'],
    chart: {
      height: 480,
      backgroundColor: '#FFFFFF00',
    },
    yAxis: {
      title: {
        text: undefined,
      },
      gridLineColor: '#473E6A',
      plotLines: [
        {
          value: 0,
          width: 1,
          color: '#808080',
        },
      ],
    },
    legend: {
      enabled: false,
    },
  });
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

  useEffect(() => {
    setOptions({
      ...options,
      series: [
        {
          type: 'line',
          data:
            radioValue === 1
              ? newList.map((item: BondObject) => item?.YTM)
              : newList.map((item: BondObject) => Number(ethers.utils.formatUnits(item?.price))),
        },
      ],
      tooltip: {
        useHTML: true,
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return radioValue === 1 ? `YTM: ${Number(this.y).toFixed(2)}%` : `${Number(this.y).toFixed(4)}`;
        },
      },
      xAxis: {
        categories: newList.map((item: BondObject) => item?.noDay),
      },
      yAxis: {
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return radioValue === 1 ? `${this.value}%` : `${this.value}$DUET`;
          },
        },
      },
    });
  }, [radioValue, list]);

  useEffect(() => {
    setChartOptions(options);
  }, [options]);
  return (
    <section className={styles.LineChart}>
      <section className={styles.radio}>
        <BaseRadioGroup
          optionList={radioList}
          activeRadio={radioValue}
          onValueChange={(val: string | number) => setRadioValue(val)}
        />
      </section>
      <section className={styles.chart}>
        <ReactHighcharts options={chartOptions} />
      </section>
    </section>
  );
};

export default LineChart;
