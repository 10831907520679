import { FC, useMemo, useState } from 'react';
import Card from '@/components/Card';
import Tabs from '@/components/Tab';
import Margin from '@/components/Margin';
import { useParams } from 'react-router-dom';
import { predicate } from '@/utils/lang';
import DusdAnnouncement from '@/components/DusdAnnouncement';
import config from '@/config/config';
import Supply from './components/Supply';
import Withdraw from './components/Withdraw';
import { useStrategy, useTokenData } from '../../context';
import ZapSupply from './components/ZapSupply';
import styles from './style.module.scss';
import DodoSupply from './components/DodoSupply';

const SupplyWithdraw: FC = () => {
  const [refreshDataCount, setRefreshDataCount] = useState(0);
  const { refreshData: refreshTokenData } = useTokenData();
  const strategy = useStrategy();
  const isArchived = window.location.pathname.includes('archived-deposit-detail');

  const dusdRelated = strategy?.symbol.includes('dUSD');

  const refreshData = () => {
    refreshTokenData();
    setRefreshDataCount(refreshDataCount + 1);
  };

  if (dusdRelated) {
    return (
      <Card width={690} height={386}>
        <DusdAnnouncement />
      </Card>
    );
  }

  return (
    <Card width={690} height={416}>
      <section className={styles.LeftArea}>
        <Tabs
          tabs={[
            strategy?.platform === 'Dodo' && {
              key: 'dodoSupply',
              label: 'Supply',
              content: <DodoSupply refreshData={refreshData} refreshDataCount={refreshDataCount} />,
            },
            strategy?.platform !== 'Dodo' &&
              !isArchived && {
                key: 'Supply',
                label: 'Supply',
                content: <Supply refreshData={refreshData} refreshDataCount={refreshDataCount} />,
              },
            {
              key: 'Withdraw',
              label: 'Withdraw',
              content: <Withdraw refreshData={refreshData} refreshDataCount={refreshDataCount} />,
            },
            !strategy?.hideZapEntry &&
              !isArchived && {
                key: 'Zap & Supply',
                label: 'Zap & Supply',
                content: <ZapSupply refreshData={refreshData} refreshDataCount={refreshDataCount} />,
              },
          ].filter(predicate)}
        />
        <Margin top={20} />
      </section>
    </Card>
  );
};

export default SupplyWithdraw;
