import DButton from '@/components/DButton';
import InfoCircle from '@/components/InfoCircle';
import Margin from '@/components/Margin';
import PercentDisplay from '@/components/PercentDisplay';
import { injectContractEbCakeFarmingPool } from '@/contracts';
import { useWeb3 } from '@/web3';
import { FC, useMemo, useState } from 'react';
import rabbitImg from '@/assets/ebcake-rabbit.png';
import commifyBigNumber from '@/utils/commify';
import { useCatchError, useEbCakeInfo } from '@/pages/EbCake/context';
import { constants } from 'ethers';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { EbCake } from '@/pages/EbCake/server';
import InfoArea from './InfoArea';
import { RewardPanel, Title } from './styles';
import SingleUnstakeModal from './SingleUnstakeModal';

interface Props {
  ebCake: EbCake;
}

const EbCakeSingle: FC<Props> = props => {
  const { ebCake: ebCakeInfo } = props;

  const { contractContainer, account } = useWeb3();
  const { refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const [modalVisible, setModalVisible] = useState(false);
  const executeAndShowTx = useExecuteAndShowTx();

  const claimSingle = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCakeFarmingPool(
      contractContainer,
      ebCakeInfo.addresses.ebCakeFarmingPoolAddress,
    ).connect(contractContainer.getSigner(account));

    await contract.estimateGas.claimBonuses().then(async gasEstimated => {
      await executeAndShowTx(
        contract.claimBonuses({
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      refresh();
    }, onError);
  };

  if (!ebCakeInfo) return null;

  const name = ebCakeInfo.name + ' Stake';
  const haveStakedToken = !ebCakeInfo.singleToken?.staked?.eq(constants.Zero);
  const haveUnClaimedToken = !ebCakeInfo.singleToken?.bDuetPending?.eq(constants.Zero);

  return (
    <RewardPanel>
      <Title>
        <div>
          <img src={ebCakeInfo.icon} alt="ebCAKE icon" />
          <span>{name}</span>
        </div>
        <div>
          <span>
            APY
            <InfoCircle
              text={
                <div>
                  <div>
                    ebCAKE APY: <PercentDisplay>{ebCakeInfo?.ebCakeAPR}</PercentDisplay>
                  </div>
                  <div>
                    bDuet APY: <PercentDisplay>{ebCakeInfo?.yield?.bDuetAPY}</PercentDisplay>
                  </div>
                </div>
              }
            />
          </span>

          <span>
            <PercentDisplay>
              {ebCakeInfo?.yield?.bDuetAPY == null || ebCakeInfo?.ebCakeAPR == null
                ? null
                : ebCakeInfo.yield.bDuetAPY + ebCakeInfo.ebCakeAPR}
            </PercentDisplay>
          </span>
        </div>
      </Title>
      <InfoArea
        title="$ebCAKE rewards are auto-compounded. You don't need to claim."
        infos={[
          {
            key: 'Staked（compounding）',
            value: commifyBigNumber(ebCakeInfo.singleToken?.staked),
          },
          {
            key: 'Earned to Date',
            value: commifyBigNumber(ebCakeInfo.singleToken?.ebCakeEarnedToDate) + ' ebCAKE',
          },
        ]}
      />
      <InfoArea
        title="$bDuet rewards from the pool are distributed every day."
        infos={[
          {
            key: 'Pending Rewards',
            value: commifyBigNumber(ebCakeInfo.singleToken?.bDuetPending) + ' bDuet',
          },
          {
            key: 'Earned to Date',
            // value: '- bDuet',
            value: commifyBigNumber(ebCakeInfo.singleToken?.bDuetEarnedToDate) + ' bDuet',
          },
        ]}
        operation={
          <DButton height={40} onClick={claimSingle} disabled={!haveUnClaimedToken}>
            Claim
          </DButton>
        }
      />
      <Margin top={30} />
      <DButton height={40} onClick={() => setModalVisible(true)} disabled={!haveStakedToken}>
        Unstake
      </DButton>
      <SingleUnstakeModal ebCake={ebCakeInfo} visible={modalVisible} closeModal={() => setModalVisible(false)} />
    </RewardPanel>
  );
};

export default EbCakeSingle;
