export enum SingleOrLp {
  SINGLE = 'SINGLE',
  LP = 'LP',
}

export enum DAssetsType {
  STABLE_COIN = 'STABLE_COIN',
  DASSETS = 'DASSETS',
  NOT_DASSETS = 'NOT_DASSETS',
}

/**
 * @constant DEPOSIT: 存款列表
 * @constant DEPOSIT_INNOVATION: 加强策略存款列表
 * @constant DASSETS: 合成资产列表
 * @constant DASSETS_INNOVATION: 创新合成资产列表
 */
export enum DisplayPosition {
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_INNOVATION = 'DEPOSIT_INNOVATION',
  DEPOSIT_ARCHIVED = 'DEPOSIT_ARCHIVE',
  DASSETS = 'DASSETS',
  DASSETS_INNOVATION = 'DASSETS_INNOVATION',
  DASSETS_ARCHIVED = 'DASSETS_ARCHIVED',
}

interface Vault {
  vaultAddress: string;
  dyTokenAddress: string;
  displayPosition: DisplayPosition;
  pancakePid?: number;
  incentive?: boolean;
  onFire?: boolean;
  compound?: boolean;
  // 缺省的时候默认跟 vault 所属的 token 类型一致
  targetTokenSingleOrLp?: SingleOrLp;
  targetTokenAddress?: string;
}

export interface Token {
  symbol: string;
  address: string;
  singleOrLp: SingleOrLp;
  image: string;
  dAssetsType?: DAssetsType;
  // 是否可以作为 zap 选项
  inZap?: boolean;
  // 是否隐藏 zap 入口, 默认为 false 不隐藏
  hideZapEntry?: boolean;
  vaults?: Vault[];
  isNew?: boolean;
  platform?: 'PancakeSwap' | 'Dodo';
}

export type Strategy = Token & Vault;

export interface ZapRoutes {
  [key: string]: Record<string, string[]>;
}

export interface DodoLpPair {
  token0: {
    symbol: string;
    address: string;
  };
  token1: {
    symbol: string;
    address: string;
  };
  lpToken: {
    symbol: string;
    address: string;
  };
  dppController: string;
}
