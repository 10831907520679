import { Button, Image, Table, Tooltip } from 'antd';
import cs from 'classnames';
import { BigNumber } from 'ethers';
import addTokenToWallet from '@/utils/addTokenToWallet';
import commifyBigNumber from '@/utils/commify';
import InfoCircle from '@/components/InfoCircle';
import config from '@/config/config';
import getCdnResource from '@/utils/getCdnResource';
import { BondObject } from '../..';
import styles from './style.module.scss';
import iconBond from '../icon_bond.png';

interface DuetBondsTableIProps {
  tableSource: BondObject[];
}

export const getMonthDisplay = (month?: number) => {
  if (!month) return '';
  if (month > 0) {
    return `< ${month + 1} Months`;
  }
  return '< 1 Month';
};

const tableColumns = [
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>NAME</span>
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: BondObject) => (
      <div className={styles.nameSec}>
        <div className={styles.iconSec}>
          <Image className={styles.icon} src={iconBond} preview={false} />
        </div>
        <div className={styles.names}>
          <div className={styles.rowItemText}>{text}</div>
          <div className={styles.rowItemText}>{getMonthDisplay(record.month)}</div>
        </div>
        <Tooltip placement="top" className="tooltip" title="Import token to wallet">
          <span
            onClick={() => {
              addTokenToWallet(
                { address: record?.epochAddr, symbol: record?.name || '' },
                getCdnResource('/token/bondIcon.png'),
              );
            }}
            className={styles.addToken}
          />
        </Tooltip>
      </div>
    ),
  },
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>PRICE($bDuet/$DUET)</span>
        <InfoCircle text="The amount of $Duet token that should be invested today in order to receive 1 $Duet at maturity date.">
          <i className="iconfont icon-tishi_xianxing" />
        </InfoCircle>
      </div>
    ),
    dataIndex: 'price',
    key: 'price',
    render: (text: BigNumber) => <span className={styles.rowItemText}>{commifyBigNumber(text)}</span>,
  },
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>YTM</span>
        <InfoCircle text="The annualized return anticipated on a bond if the bond is held until it matures.">
          <i className="iconfont icon-tishi_xianxing" />
        </InfoCircle>
      </div>
    ),
    dataIndex: 'YTM',
    key: 'YTM',
    render: (text: number) => (
      <span className={styles.rowItemText}>{Number(text).toFixed(2)}%</span>
      // <span className={styles.rowItemText}>{commifyBigNumber(ethers.utils.parseUnits(`${text}`))}</span>
    ),
  },
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>MATURITY</span>
      </div>
    ),
    dataIndex: 'time',
    key: 'time',
    render: (text: string) => <span className={styles.rowItemText}>{text}</span>,
  },
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>YOUR BALANCE</span>
      </div>
    ),
    dataIndex: 'balance',
    key: 'balance',
    render: (text: BigNumber) => <span className={styles.rowItemText}>{commifyBigNumber(text)}</span>,
  },
  {
    title: () => (
      <div className={styles.tableTitleItem}>
        <span className={styles.tableTitleItemText}>ACTION</span>
      </div>
    ),
    dataIndex: 'action',
    key: 'action',
    render: (_text: string, record: BondObject) => (
      <div className={styles.actionButtons}>
        <Button
          className={cs(styles.button, styles.buttonBuy)}
          onClick={() =>
            window.open(
              `https://pancakeswap.finance/swap?inputCurrency=${config?.DUET}&outputCurrency=${record?.epochAddr}`,
            )
          }>
          Buy
        </Button>
        <Button
          className={cs(styles.button, styles.buttonSell)}
          onClick={() =>
            window.open(
              `https://pancakeswap.finance/swap?inputCurrency=${record?.epochAddr}&outputCurrency=${config?.DUET}`,
            )
          }>
          Sell
        </Button>
        <Button
          className={cs(styles.button, styles.buttonLiquidity)}
          onClick={() => window.open(`https://pancakeswap.finance/add/${record?.epochAddr}/${config?.DUET}`)}>
          Liquidity
        </Button>
      </div>
    ),
  },
];

const DuetBondsTable = ({ tableSource }: DuetBondsTableIProps) => {
  return (
    <section className={styles.DuetBondsTable}>
      <section className={styles.title}>On-the-Run Duet Bonds</section>
      <Table columns={tableColumns} dataSource={tableSource} pagination={false} />
    </section>
  );
};

export default DuetBondsTable;
