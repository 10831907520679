import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3 } from '@/web3';
import {
  createFungibleContract__LinkUSDOracle,
  injectContract__AppController,
} from '@/fixtures/web3/contract/generated-map';
import { injectContractLinkUsdOracle } from '../contracts';

const usePriceByAddress = (address: string) => {
  const { contractContainer } = useWeb3();

  const [value, setValue] = useState<BigNumber>(ethers.constants.Zero);

  useEffect(() => {
    if (!address || !contractContainer) return;
    (async () => {
      const appControllerContract = injectContract__AppController(contractContainer);
      const { oracle } = await appControllerContract.getValueConf(address);

      const oracleContract = await createFungibleContract__LinkUSDOracle(contractContainer, oracle);

      const res = await oracleContract.getPrice(address);
      setValue(res);
    })();
  }, [address, contractContainer]);

  return value;
};

export default usePriceByAddress;
