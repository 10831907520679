import React, { ReactNode } from 'react';
import { Popover } from 'antd';
import { BigNumber, ethers } from 'ethers';
import cs from 'classnames';
import styles from './style.module.scss';

interface TokenInputProps {
  value: string;
  balance?: BigNumber;
  showClear?: boolean;
  showMax?: boolean;
  showInfo?: boolean;
  view?: boolean; // 不可修改
  infoContent?: ReactNode;
  onChange?: (value: string) => void;
}

const TokenInput: React.FC<TokenInputProps> = props => {
  const { value, balance, showClear, showMax, showInfo, view, infoContent, onChange, children } = props;

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (view) return;
    const newValue = event.currentTarget.value;
    onChange?.(newValue);
  };

  const clearInput = () => {
    onChange?.('');
  };

  const setMax = () => {
    if (!balance) return;
    const remainder = balance.mod(1e14);
    const maxValue = ethers.utils.formatEther(balance.sub(remainder));
    onChange?.(maxValue);
  };

  const balanceFormatter = (originValue?: BigNumber): string => {
    if (!originValue) return '-';
    const remainder = originValue.mod(1e14);
    const theBalance = ethers.utils.formatEther(originValue.sub(remainder));
    return ethers.utils.commify(theBalance);
  };

  const showedValue = value;

  return (
    <section className={styles.InputWrapper}>
      <div className={styles.inputRow}>
        {children}
        <input className={styles.input} type="number" value={showedValue} onChange={onInputChange} />
        {showClear && <i className={cs('iconfont icon-guanbi', styles.iconfont)} onClick={clearInput} />}
        {showInfo && (
          <Popover content={infoContent} title="" placement="bottom" overlayClassName="popover">
            <i className={cs('iconfont icon-tishi', styles.iconfont)} />
          </Popover>
        )}
      </div>
      <div className={styles.tipsRow}>
        <p className={styles.balance}>
          Balance: <span className={styles.span}>{balanceFormatter(balance)}</span>
        </p>
        {showMax && (
          <div className={styles.maxButton} onClick={setMax}>
            MAX
          </div>
        )}
      </div>
    </section>
  );
};

export default TokenInput;
