import { Spin } from 'antd';
import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import useApproved from '@/hooks/useApproved';
import Margin from '../Margin';
import FlexRow from '../FlexRow';
import styles from './style.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface DButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  width?: number;
  height?: number;
  loading?: boolean;
  approveFrom?: string;
  approveTo?: string;
  fontSize?: number;
}

const DButton: React.FC<PropsWithChildren<DButtonProps>> = props => {
  const {
    disabled = false,
    onClick,
    children,
    width,
    height = 52,
    loading,
    approveFrom,
    approveTo,
    fontSize = 18,
  } = props;

  const [innerLoading, setInnerLoading] = useState(false);

  const { approve, approveLoading, approved, approvedStatusLoading } = useApproved({
    tokenAddress: approveFrom,
    approveTo,
  });

  // 是否检查 approve 状态
  const checkIsApprove = approveFrom && approveTo;
  // 是否显示 approve 按钮
  const showApprove = checkIsApprove && !approvedStatusLoading && !approved;
  // 在按钮请求 approve 状态的时候置灰
  const buttonDisabled = checkIsApprove && approvedStatusLoading;

  const handleClick = async () => {
    if (loading ?? innerLoading) return;
    if (disabled) return;

    setInnerLoading(true);
    try {
      await onClick?.();
    } finally {
      setInnerLoading(false);
    }
  };

  const buttonLoading = loading ?? (innerLoading || approveLoading);

  return (
    <section
      className={classNames(
        styles.ButtonWrapper,
        {
          [styles.disabled]: !showApprove && (buttonDisabled || disabled),
        },
        { [styles.loading]: buttonLoading },
      )}
      style={{ width: width ? `${width}px` : '100%', height: `${height}px`, fontSize: `${fontSize}px` }}
      onClick={showApprove ? approve : handleClick}>
      <FlexRow justifyContent="center">
        {/* 是否显示 loading 效果 */}
        {buttonLoading && (
          <>
            <Spin indicator={antIcon} />
            <Margin left={10} />
          </>
        )}
        {!showApprove && children}
        {showApprove && 'Approve'}
      </FlexRow>
    </section>
  );
};

export default DButton;
