import React from 'react';
import InfoCircle from '../InfoCircle';
import { useVaultInfo } from './context';
import styles from './style.module.scss';
import UsdDisplay from '../UsdDisplay';

const MyVault: React.FC = () => {
  const { vaultInfo } = useVaultInfo();

  return (
    <section className={styles.VaultWrapper}>
      <section className={styles.VaultTitle}>My Vault</section>
      <section className={styles.VaultContent}>
        <section className={styles.VaultLine}>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Assets
              <InfoCircle text="Total market value of single tokens and receipt tokens supplied. " />
            </section>
            <section className={styles.VaultValue}>
              <UsdDisplay>{vaultInfo.totalAssets}</UsdDisplay>
            </section>
          </section>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Collateralized Assets
              <InfoCircle text="Assets that is used as collateral to borrow synthetic assets. Note that all collateralized assets are subject to liquidation if Asset-to-Debt ratio falls below 105%." />
            </section>
            <section className={styles.VaultValue}>
              <UsdDisplay>{vaultInfo.collateralAssets}</UsdDisplay>
            </section>
          </section>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Uncollateralized Assets
              <InfoCircle text="Assets that are not subject to liquidation risks." />
            </section>
            {vaultInfo && (
              <section className={styles.VaultValue}>
                <UsdDisplay>
                  {vaultInfo.totalAssets && vaultInfo.collateralAssets
                    ? vaultInfo.totalAssets.sub(vaultInfo.collateralAssets)
                    : null}
                </UsdDisplay>
              </section>
            )}
          </section>
        </section>
        <section className={styles.VaultLine}>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Borrowed dAssets
              <InfoCircle text="Total USD value of dAssets borrowed by you." />
            </section>
            <section className={styles.VaultValue}>
              <UsdDisplay>{vaultInfo.synthetic}</UsdDisplay>
            </section>
          </section>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Credit
              <InfoCircle text="Market value of Assets that is not currently used as collateral and thus can be used as collateral to borrow more synthetic assets. " />
            </section>
            <section className={styles.VaultValue}>
              <UsdDisplay>{vaultInfo.credit}</UsdDisplay>
            </section>
          </section>
          <section className={styles.VaultItem}>
            <section className={styles.VaultKey}>
              Asset-to-Debt Ratio
              <InfoCircle text="The market value of assets divided by the market value of all borrowed synthetic assets adjusted by liquidation factors. Your vault is subject to liquidation if the ratio falls to 105%." />
            </section>
            <section className={styles.VaultValue}>{vaultInfo.debt == null ? '-' : `${vaultInfo.debt}%`}</section>
          </section>
        </section>
      </section>
    </section>
  );
};
export default MyVault;
