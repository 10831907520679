import { Strategy } from '@/config/types';
import { BigNumber, constants } from 'ethers/lib/ethers';
import { createContext, useContext } from 'react';
import { ApyInfo, ApyInfoPeriodMap } from './server';

const { Zero } = constants;

export interface TokenData {
  walletBalance: BigNumber | null;
  vaultBalance?: BigNumber;
  price: BigNumber | null;
  apyInfoPeriodMap: ApyInfoPeriodMap | null;
  apyInfo: ApyInfo | null;
}

interface DataContextProps {
  tokenData: TokenData;
  refreshData: () => void;
  strategy?: Strategy;
}

export const initialTokenData: TokenData = {
  walletBalance: Zero,
  vaultBalance: Zero,
  price: Zero,
  apyInfoPeriodMap: null,
  apyInfo: null,
};

const initialContext = {
  tokenData: initialTokenData,
  refreshData: () => {
    //
  },
  strategy: undefined,
};

const DataContext = createContext<DataContextProps>(initialContext);

export const useTokenData = () => {
  const { tokenData, refreshData } = useContext(DataContext);
  return { tokenData, refreshData };
};

export const useStrategy = () => {
  const { strategy } = useContext(DataContext);
  return strategy;
};

export default DataContext;
