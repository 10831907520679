import type { Contract, ContractInterface } from 'ethers';
import EbCakePage from '@/pages/EbCake';
import { EbCakeAbi } from '@/abis/EbCakeAbi';
import { EbCakeFarmingPoolAbi } from '@/abis/EbCakeFarmingPoolAbi';
import { EbCakeLpFarmingPoolAbi } from '@/abis/EbCakeLpFarmingPoolAbi';
import { LpTokenAbi } from '@/abis/LpTokenAbi';
import { ContractInjector, createContractInjector, IContractContainer } from './define';
import { ERC20Abi } from '../abis/ERC20Abi';
import { MintVaultABi } from '../abis/MintVaultAbi';
import { LpFarmingVault } from '../abis/LpFarmingVault';
import { PoolAbi } from '../abis/PoolAbi';
import { DYTokenERC20Abi } from '../abis/DYTokenERC20Abi';
import { DuetUSDMinerPairAbi } from '../abis/DuetUSDMinerPairAbi';
import { StableCoinAbi } from '../abis/StableCoinAbi';
import { DusdAbi } from '../abis/DusdAbi';

export const injectContractERC20 = createFungibleContractInjectorFactory(ERC20Abi);

export const injectContractStableCoin = createFungibleContractInjectorFactory(StableCoinAbi);

export const injectContractPool = createFungibleContractInjectorFactory(PoolAbi);

export const injectContractLpFarmingVault = createFungibleContractInjectorFactory(LpFarmingVault);

export const injectContractMintVault = createFungibleContractInjectorFactory(MintVaultABi);

export const injectContractDyTokenERC20 = createFungibleContractInjectorFactory(DYTokenERC20Abi);

export const injectContractDuetUsdMinerPair = createFungibleContractInjectorFactory(DuetUSDMinerPairAbi);

export const injectContractDUsd = createFungibleContractInjectorFactory(DusdAbi);

// EbCake 交互合约
export const injectContractEbCake = createFungibleContractInjectorFactory(EbCakeAbi);

export const injectContractEbCakeFarmingPool = createFungibleContractInjectorFactory(EbCakeFarmingPoolAbi);

export const injectContractEbCakeLpFarmingPool = createFungibleContractInjectorFactory(EbCakeLpFarmingPoolAbi);

// PancakeSwap Lp token
export const injectContractLpToken = createFungibleContractInjectorFactory(LpTokenAbi);

/// ------

function createFungibleContractInjectorFactory<T extends Contract = Contract>(abi: ContractInterface) {
  const map = new Map<string, ContractInjector<T>>();
  return (container: IContractContainer, address: string) => {
    if (!map.has(address)) map.set(address, createContractInjector<T>(address, abi));
    return map.get(address)!(container);
  };
}
