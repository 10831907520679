import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import commifyBigNumber from '@/utils/commify';
import { BigNumber, constants, Contract, ethers } from 'ethers';
import { useWeb3 } from '@/web3';
import { simpleRpcProvider } from '@/third_party/pancake/utils/providers';
import { SingleBondNewAbi } from '@/abis/SingleBondNewAbi';
import { parseEther } from '@ethersproject/units';
import styles from './style.module.scss';
import MaturedBonds from './components/MaturedBonds';
import TotalLocked from './components/TotalLocked';
import LineChart from './components/LineChart';
import DuetBondsTable from './components/DuetBondsTable';
import DuetBondsTableMobile from './components/DuetBondsTableMobile';
import { injectContractBondReader, injectContractSingleBond } from '../../contracts';
import { getPrice } from './getPrice';

export interface BondObject {
  balance: BigNumber;
  maturity: BigNumber;
  price: BigNumber;
  YTM: number;
  epochAddr: string;
  month: number;
  key: number;
  tokenName: string;
  days: number;
  time?: string;
  noDay?: string;
  name?: string;
}

dayjs.extend(relativeTime);
const Bond: React.FC = () => {
  const { account, contractContainer } = useWeb3();
  const [doneList, setDoneList] = useState<BondObject[]>([]);
  const [progressList, setProgressList] = useState<BondObject[]>([]);
  const [maturedBondsAmount, setMaturedBondsAmount] = useState('--');
  const [totalLocked, setTotalLocked] = useState('--');
  const [hoursVolume] = useState('--');

  useEffect(() => {
    (async () => {
      const bondList1 = await getBondsList();
      const bondList2 = await getBondList2();
      const list = [...bondList1, ...bondList2];
      console.log('list', list);

      // 已完成的债券
      const doneListArr: BondObject[] = [];
      // 进行中的债券
      const progressListArr: BondObject[] = [];

      const nowTime = Number(String(new Date().getTime()).substring(0, 10));
      // 数据组装
      list?.map((item: any, index: number) => {
        if (item.maturity.lt(nowTime)) {
          doneListArr.push({
            balance: item.balance,
            maturity: item.maturity,
            price: item.price,
            key: index + 1,
            month: 0,
            YTM: 0,
            days: 0,
            epochAddr: item.epochAddr,
            name: `${index + 1} Month`,
            tokenName: '',
          });
        } else {
          const time = timeConversion({ timestamp: item.maturity });
          const now = dayjs();
          const to = dayjs(item.maturity.toNumber() * 1000);
          const days = to.diff(now) / 86400000; // 计算到目标日期还有多少天
          const months = to.diff(now, 'month'); // 计算当前日期距离到期日期还有几个月
          progressListArr.push({
            balance: item.balance,
            maturity: item.maturity,
            price: item.price,
            YTM: ((1 / Number(ethers.utils.formatUnits(item.price))) ** (1 / (days / 365)) - 1) * 100,
            days,
            time,
            month: months,
            key: index + 1,
            epochAddr: item.epochAddr,
            noDay: timeConversion({ timestamp: item.maturity, noDay: true }),
            name: item.name,
            tokenName:
              `bDuet ${timeConversion({ timestamp: item.maturity, noDay: true })}`.substring(0, 9) +
              `bDuet ${timeConversion({ timestamp: item.maturity, noDay: true })}`.substring(11, 13),
          });
        }
      });
      setDoneList(doneListArr);
      setProgressList(progressListArr);
      setTotalLocked(
        commifyBigNumber(
          list.reduce((a: BigNumber, c: any) => {
            return a.add(c.lockedValue);
          }, constants.Zero),
          {
            unit: 18,
            decimal: 0,
          },
        ),
      );

      // setHoursVolume(
      //   commifyBigNumber(
      //     res[3]
      //       .reduce((last: BigNumber, now: BigNumber) => {
      //         return last.add(now);
      //       })
      //       .mul(15)
      //       .div(100),
      //     {
      //       unit: 18,
      //       decimal: 0,
      //     },
      //   ),
      // );
      // 计算已到期的债券总价值
      if (doneListArr.length === 0) return;
      const totalResult = doneListArr
        .map((item: BondObject) => item?.balance)
        .reduce((last: BigNumber, now: BigNumber) => {
          return last.add(now);
        });
      setMaturedBondsAmount(commifyBigNumber(totalResult));
    })();
  }, [account, contractContainer]);

  const getBondList2 = async (): Promise<any[]> => {
    if (!contractContainer) return [];
    const promises = [
      { name: 'bDuet#8', address: '0x1B9A2a68f44620745c09699ADF022cB33AC072F7' },
      { name: 'bDuet#9', address: '0x9c5657692447400Ca70A4EFA75347f6878612300' },
      { name: 'bDuet#10', address: '0x857EAE5b1d7EE68bf7dF93F68FaD515c721526e6' },
      { name: 'bDuet#11', address: '0x4b8BA4Cc10181aEd4755c386E5e26f4BdCf3E0f7' },
    ].map(async bond => {
      const contract = new Contract(bond.address, SingleBondNewAbi, simpleRpcProvider);
      const maturity = await contract.maturity();
      const balance = await contract.balanceOf(account);
      const price = await getPrice(contractContainer, bond.address);
      const epochAddr = bond.address;
      const symbol = await contract.symbol();
      return {
        balance,
        maturity,
        price: price,
        month: 0,
        YTM: 0,
        days: 0,
        epochAddr: epochAddr,
        name: symbol.replace('DUET', 'Duet'),
        tokenName: '',
        lockedValue: constants.Zero,
      };
    });
    const res = await Promise.all(promises);
    console.log('res', res);
    return res;
  };

  // 获取并处理bond数据
  const getBondsList = async (): Promise<any[]> => {
    if (!account || !contractContainer) return [];
    const bondReaderContract = injectContractBondReader(contractContainer);

    const res = await bondReaderContract.myBonds(account);

    const singleBondContract = injectContractSingleBond(contractContainer).connect(
      contractContainer.getSigner(account),
    );
    console.log('singleBondContract', singleBondContract);
    const res2 = await singleBondContract.getEpoches();

    return res[1]
      ?.map((item: BigNumber, index: number) => {
        return {
          balance: res[0][index],
          maturity: item,
          price: res[2][index],
          key: index + 1,
          month: 0,
          YTM: 0,
          days: 0,
          epochAddr: res2[index],
          name: `${res[4][index]}`,
          tokenName:
            `bDuet ${timeConversion({ timestamp: item, noDay: true })}`.substring(0, 9) +
            `bDuet ${timeConversion({ timestamp: item, noDay: true })}`.substring(11, 13),
          lockedValue: res[3][index],
        };
      })
      .filter((bond: any) => bond.name !== 'bDuet#8');
  };

  // 时间戳转日期
  const timeConversion = ({ timestamp, noDay = false }: { timestamp: BigNumber; noDay?: boolean }) => {
    const date = new Date(timestamp.toNumber() * 1000);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.toDateString().split(' ')[1];
    const year = date.getFullYear();
    return !noDay ? `${day} ${month} ${year}` : `${month}${year}`;
  };

  return (
    <section className={styles.BondPage}>
      {/* 上方内容部分 */}
      <section className={styles.top}>
        {/* 左侧 */}
        <section className={styles.topLeft}>
          <MaturedBonds
            amount={maturedBondsAmount}
            doneList={doneList}
            refreshData={() => {
              getBondsList();
            }}
          />
          <TotalLocked totalLocked={totalLocked} hoursVolume={hoursVolume} />
        </section>
        {/* 折线图 */}
        <LineChart list={progressList} />
      </section>
      {/* 表格部分 */}
      <DuetBondsTable tableSource={progressList} />
      <DuetBondsTableMobile tableSource={progressList} />
    </section>
  );
};

export default Bond;
