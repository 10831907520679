import styled from '@emotion/styled';
import { FC, useState } from 'react';
import closePng from '@/assets/close.png';
import { useCatchError, useEbCakeInfo } from '@/pages/EbCake/context';
import commifyBigNumber from '@/utils/commify';
import toFixed from '@/utils/toFixed';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import { useWeb3 } from '@/web3';
import { injectContractEbCakeFarmingPool } from '@/contracts';
import { parseEther } from 'ethers/lib/utils';
import globalStore from '@/stores/global';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { EbCake } from '@/pages/EbCake/server';

interface Props {
  visible: boolean;
  closeModal: () => void;
  ebCake: EbCake;
}

const SingleUnstakeModal: FC<Props> = props => {
  const { visible, closeModal, ebCake: ebCakeInfo } = props;
  const { refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const [inputAmount, setInputAmount] = useState('');
  const { contractContainer, account } = useWeb3();
  const { dataTheme } = globalStore();
  const executeAndShowTx = useExecuteAndShowTx();

  const unstakeSingle = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCakeFarmingPool(
      contractContainer,
      ebCakeInfo.addresses.ebCakeFarmingPoolAddress,
    ).connect(contractContainer.getSigner(account));

    // 如果输入框的数值和 staked 数值相等，则调用 stakeAll
    if (+toFixed(ebCakeInfo.singleToken?.staked) === +inputAmount) {
      await contract.estimateGas.unstakeAll().then(async gasEstimated => {
        await executeAndShowTx(
          contract.unstakeAll({
            gasLimit: gasEstimated.mul(12).div(10),
          }),
        );
        setInputAmount('');
        refresh();
      }, onError);
      return;
    }

    await contract.estimateGas.unstakeByAmount(parseEther(inputAmount)).then(async gasEstimated => {
      await executeAndShowTx(
        contract.unstakeByAmount(parseEther(inputAmount), {
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      setInputAmount('');
      refresh();
    }, onError);
  };

  if (!ebCakeInfo) return null;

  return (
    <>
      {!visible && null}
      {visible && (
        <ModalBackground>
          <Modal>
            <TitleRow>
              <span>Unstake</span>
              <i className="iconfont icon-guanbi2" onClick={closeModal} />
            </TitleRow>
            <SingleUnstakeWrapper>
              <StakedRow>
                <span>Staked</span>
                <span>{commifyBigNumber(ebCakeInfo.singleToken?.staked)} ebCAKE</span>
              </StakedRow>
              <Margin top={20} />
              <DInput
                value={inputAmount}
                onChange={setInputAmount}
                maxAmount={toFixed(ebCakeInfo.singleToken?.staked)}
                style={{ background: dataTheme === 'light' ? '#E0DAFA' : '#484657' }}
              />
              <Margin top={20} />
              <DButton disabled={inputAmount === ''} onClick={unstakeSingle}>
                Unstake
              </DButton>
              <Margin top={40} />
              <YouWillGet>
                <span>You Will Get</span>
                <YouWillGetItem>
                  <span>{inputAmount ? commifyBigNumber(parseEther(inputAmount)) : '-'}</span>
                  <span>ebCAKE</span>
                </YouWillGetItem>
                <YouWillGetItem>
                  <span>{commifyBigNumber(ebCakeInfo.singleToken?.bDuetPending)}</span>
                  <span>bDuet</span>
                </YouWillGetItem>
              </YouWillGet>
            </SingleUnstakeWrapper>
          </Modal>
        </ModalBackground>
      )}
    </>
  );
};

export default SingleUnstakeModal;

const YouWillGet = styled.div`
  text-align: left;

  > span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--text-normal);
    line-height: 20px;
  }
`;

const YouWillGetItem = styled.div`
  margin-top: 10px;

  > span:nth-of-type(1) {
    font-size: 20px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 30px;
  }

  > span:nth-of-type(2) {
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 21px;
    margin-left: 5px;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const Modal = styled.div`
  margin: 112px auto 0;
  max-width: 530px;
  height: auto;
  background: var(--bg-black3);
  border-radius: 4px;
  text-align: center;
  padding: 30px 40px;
  z-index: 101;
  // overflow: scroll;

  @media (max-width: 768px) {
    margin: 20px auto 0;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-white3);
  font-weight: bold;

  span {
    font-size: 24px;
  }

  i {
    font-size: 20px;
    cursor: pointer;
    color: var(--text-white3);
  }
`;

const SingleUnstakeWrapper = styled.div``;

const StakedRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: var(--text-white3);
  line-height: 25px;
  margin-top: 40px;
`;
