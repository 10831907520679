import { FC, useEffect, useMemo, useState } from 'react';
import { BigNumber, constants, ethers } from 'ethers';
import { useNavigate, useParams } from 'react-router';
import cs from 'classnames';
import moment from 'moment';
import Card from '@/components/Card';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import commifyBigNumber from '@/utils/commify';
import executeAndShowTx from '@/utils/executeAndShowTx';
import InfoCircle from '@/components/InfoCircle';
import useIsMobile from '@/hooks/useIsMobile';
import { Spin } from 'antd';
import { ZERO_ADDRESS } from '@/constants';
import { DisplayPosition } from '@/config/types';
import { useWeb3 } from '@/web3';
import TransitionText from './components/TransitionText';
import { useStrategy, useTokenData } from '../../context';
import styles from './style.module.scss';
import {
  IContractContainer,
  injectContractBondReader,
  injectContractLpFarmingVault,
  injectContractPool,
  injectContractVaultFarm,
} from '../../../../contracts';

interface Reward {
  epoch: string;
  amount: BigNumber;
  end: BigNumber;
  symbol: string;
}

interface Props {
  cardWidth: number;
}

const Farm: FC<Props> = props => {
  const { cardWidth } = props;
  const { account, contractContainer } = useWeb3();

  const [poolAddress, setPoolAddress] = useState('');
  const [bondsPerBlock, setBondsPerBlock] = useState<BigNumber>();
  const [lockedAmount, setLockedAmount] = useState<BigNumber>();
  const [lockedEpochs, setLockedEpochs] = useState<Reward[]>();
  const [maturedAmount, setMaturedAmount] = useState<BigNumber>();
  const [maturedEpochs, setMaturedEpochs] = useState<Reward[]>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [showStakeButton, setShowStakeButton] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const strategy = useStrategy();
  const dyTokenAddress = strategy?.dyTokenAddress;
  const vaultAddress = strategy?.vaultAddress;
  const isInnovation = strategy?.displayPosition === DisplayPosition.DEPOSIT_INNOVATION;

  useEffect(() => {
    if (!poolAddress || !vaultAddress || !contractContainer || !account) return;
    (async () => {
      const contract = injectContractLpFarmingVault(contractContainer, vaultAddress);
      const farmBalancePromise: Promise<BigNumber> = contract.deposits(account);

      const poolContract = injectContractPool(contractContainer, poolAddress);
      const vaultBalancePromise: Promise<BigNumber> = poolContract.deposits(account);

      const [farmBalance, vaultBalance] = await Promise.all([farmBalancePromise, vaultBalancePromise]);

      if (farmBalance && vaultBalance && !farmBalance?.eq(constants.Zero) && !vaultBalance.eq(farmBalance)) {
        setShowStakeButton(true);
      }
    })();
  }, [account, poolAddress, refreshCount, contractContainer]);

  const { tokenData } = useTokenData();
  const { apyInfo } = tokenData;

  const navigate = useNavigate();

  // 通过 dyToken 获取 poolAddress
  useEffect(() => {
    (async () => {
      if (!dyTokenAddress || !contractContainer) return;

      // 当读取 poolAddress 时，显示 loading
      setLoading(true);
      const contract = injectContractVaultFarm(contractContainer);
      const poolAddr = await contract.assetPool(dyTokenAddress);
      setLoading(false);
      if (!poolAddr || poolAddr === ZERO_ADDRESS) return;
      setPoolAddress(poolAddr);
    })();
  }, [dyTokenAddress, contractContainer]);

  const getLockedAmount = async (contractContainer: IContractContainer) => {
    const bondContract = injectContractBondReader(contractContainer);
    const res = await bondContract.poolPendingAward(poolAddress, account);
    const rewards: Reward[] = res[0].map((each: string, index: number) => ({
      epoch: res[0][index],
      amount: res[1][index],
      end: res[2][index],
      symbol: res[3][index],
    }));

    const momentNow = moment();
    const locked = rewards.filter(each => {
      const endsMoment = moment(each.end.mul(1000).toNumber());
      if (endsMoment.isAfter(momentNow)) {
        return true;
      }
      return false;
    });
    const lockedAmountTemp = locked.reduce((a, c) => {
      return a.add(c.amount);
    }, constants.Zero);
    if (!lockedAmount?.eq(lockedAmountTemp)) {
      setLockedAmount(lockedAmountTemp);
      setLockedEpochs(locked);
    }

    const matured = rewards.filter(each => {
      const endsMoment = moment(each.end.mul(1000).toNumber());
      if (endsMoment.isAfter(momentNow)) {
        return false;
      }
      return true;
    });

    const maturedAmountTemp = matured.reduce((a, c) => {
      return a.add(c.amount);
    }, constants.Zero);
    if (!maturedAmount?.eq(maturedAmountTemp)) {
      setMaturedAmount(maturedAmountTemp);
      setMaturedEpochs(matured);
    }
  };

  // 获取 epochs 奖励数据
  useEffect(() => {
    let interval: any;
    (async () => {
      if (!poolAddress || !account || !contractContainer) return;
      interval = setInterval(() => {
        getLockedAmount(contractContainer);
      }, 3000);
    })();
    return () => {
      clearInterval(interval);
    };
  }, [poolAddress, refreshCount, maturedAmount, lockedAmount, contractContainer]);

  // 计算出块数量
  useEffect(() => {
    (async () => {
      if (!poolAddress || !contractContainer) return;
      const contract = injectContractBondReader(contractContainer);
      const bondsPerBlockRes = await contract.bondsPerBlock(poolAddress, 3);
      if (!bondsPerBlockRes) return;
      const bondsPerBlockTemp = (bondsPerBlockRes[1] as BigNumber[]).reduce<BigNumber>((a, c) => {
        return a.add(c);
      }, constants.Zero);
      setBondsPerBlock(bondsPerBlockTemp);
    })();
  }, [poolAddress, contractContainer]);

  const claimAndRedeem = async () => {
    if (!poolAddress || !account || !contractContainer) return;
    const contract = injectContractVaultFarm(contractContainer).connect(contractContainer.getSigner(account));
    const promise = contract.withdrawAward([poolAddress], account, true);
    await executeAndShowTx(promise);
    setRefreshCount(refreshCount + 1);
  };

  const stake = async () => {
    if (!vaultAddress || !contractContainer || !account) return;
    const contract = injectContractVaultFarm(contractContainer).connect(contractContainer.getSigner(account));
    const promise = await contract.syncVault(vaultAddress);
    await executeAndShowTx(promise);
    setRefreshCount(refreshCount + 1);
    setShowStakeButton(false);
  };

  const findIncentive = () => {
    navigate('/deposit-list');
  };

  const isMobile = useIsMobile();

  if (loading) {
    return (
      <Card width={cardWidth} height={519}>
        <div style={{ height: 519, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Spin spinning={loading} />
        </div>
      </Card>
    );
  }

  const claimButtonDisabled = !lockedAmount || lockedAmount?.eq(constants.Zero);

  const paddingStyle = isInnovation ? { padding: '20px 20px 0' } : { padding: '20px 50px 0' };

  return (
    <Card width={cardWidth} height={isMobile ? 670 : 520}>
      <section className={styles.FarmWrapper}>
        <div className={styles.farmTitle}>Farm</div>
        <Margin top={20} />
        <div className={styles.farmSubTitle}>Farm Info</div>
        <Margin top={10} />
        <div className={styles.farmInfo} style={paddingStyle}>
          <div className={cs(styles.farmInfoItem, styles.farmInfoLeft)}>
            <span className={styles.infoName}>APR</span>
            <span className={styles.infoValue}>{apyInfo ? `${apyInfo.duetFarmApr?.toFixed(2)}%` : '-'}</span>
            <span className={styles.infoSuffix}>
              <span className={styles.infoSuffixSpecial}>Rewards</span>
              <Margin left={10} />
              <span>Bonded Duet</span>
            </span>
          </div>
          <div className={cs(styles.farmInfoItem, styles.farmInfoRight)}>
            <span className={styles.infoName}>Bonded Duets per Block</span>
            <span className={styles.infoValue}>{commifyBigNumber(bondsPerBlock)}</span>
            <span className={styles.infoSuffix}>Bonded Duet</span>
          </div>
        </div>
        <Margin top={20} />
        <div className={styles.farmSubTitle}>User Info</div>
        <Margin top={10} />
        <div className={styles.userInfo} style={paddingStyle}>
          <div className={styles.userInfoTop}>
            <div className={cs(styles.userInfoItem, styles.userInfoLeft)}>
              <span className={styles.infoName}>Claimable Rewards</span>
              <span className={styles.infoValue}>
                <TransitionText text={commifyBigNumber(lockedAmount)} />
                <Margin left={5} />
                {lockedEpochs && lockedEpochs.length !== 0 && (
                  <InfoCircle
                    text={lockedEpochs?.map(each => {
                      return (
                        <div key={each.epoch}>
                          {each.symbol}: {commifyBigNumber(each.amount)}
                        </div>
                      );
                    })}
                  />
                )}
              </span>
              <span className={styles.infoSuffix}>
                <span>Bonded Duet</span>
              </span>
            </div>
            <div className={cs(styles.userInfoItem, styles.userInfoRight)}>
              <span className={styles.infoName}>Matured Rewards</span>
              <span className={styles.infoValue}>
                {commifyBigNumber(maturedAmount)}
                <Margin left={5} />
                {maturedEpochs && maturedEpochs.length !== 0 && (
                  <InfoCircle
                    text={maturedEpochs?.map(each => {
                      return (
                        <div key={each.epoch}>
                          {each.symbol}: {commifyBigNumber(each.amount)}
                        </div>
                      );
                    })}
                  />
                )}
              </span>
              <span className={styles.infoSuffix}>$DUET</span>
            </div>
          </div>
          <section className={styles.ButtonRowWrapper}>
            <DButton height={52} fontSize={16} onClick={claimAndRedeem} disabled={claimButtonDisabled}>
              Claim
            </DButton>

            {showStakeButton && (
              <>
                <Margin top={15} />
                <DButton height={40} fontSize={16} onClick={stake}>
                  Stake
                </DButton>
              </>
            )}
          </section>
        </div>
      </section>
    </Card>
  );
};

export default Farm;
