import { FC, useState } from 'react';
import Card from '@/components/Card';
import Tabs from '@/components/Tab';
import classNames from 'classnames';
import styled from 'styled-components';
import { platform } from 'process';
import Mint from './components/Borrow';
import Repay from './components/Repay';
import { useStrategy, useTokenData } from '../../context';
import styles from './style.module.scss';
import { predicate } from '../../../../utils/lang';
import { DAssetsType } from '../../../../config/types';
import { DuetLogo } from '../../../../components/DuetLogo';
import { TradePlatform, tradePlatforms } from '../../../../config/trade-platforms';
import { PopupEntry } from '../../../../components/PopupEntry';
import { ItemList } from '../../../../components/ItemList';
import TradeDusd from '../TradeDusd';

const MortgageMint: FC = () => {
  const [refreshDataCount, setRefreshDataCount] = useState(0);
  const { refreshData: refreshTokenData } = useTokenData();
  const strategy = useStrategy();
  const isArchived = window.location.pathname.includes('archived-dassets-detail');
  const [activeKey, setActivatedKey] = useState(() => {
    if (isArchived) {
      return 'Repay';
    }
    return 'Borrow';
  });

  const refreshData = () => {
    refreshTokenData();
    setRefreshDataCount(refreshDataCount + 1);
  };

  const showMinter = strategy && strategy.dAssetsType === DAssetsType.STABLE_COIN && strategy.symbol === 'dUSD';

  const filterPlatform = (platForm: TradePlatform) => {
    if (strategy?.symbol === 'dTMC' && platForm.name === 'iZumi') {
      return false;
    }
    return true;
  };

  return (
    <Card minHeight={400}>
      <section className={styles.LeftArea}>
        <Tabs
          onChange={key => setActivatedKey(key)}
          activeKey={activeKey}
          tabs={[
            !isArchived && {
              key: 'Borrow',
              label: 'Borrow',
              content: <Mint refreshDataCount={refreshDataCount} refreshData={refreshData} />,
            },
            {
              key: 'Repay',
              label: 'Repay',
              content: <Repay refreshDataCount={refreshDataCount} refreshData={refreshData} />,
            },
            !isArchived &&
              showMinter && {
                key: 'Minter',
                label: 'Minter',
                content: <TradeDusd />,
              },
            // DUET-207
            !isArchived && {
              key: 'Trade',
              label: (
                <PopupEntry
                  label={
                    <>
                      Trade <DropdownIcon />
                    </>
                  }>
                  {hoveringCtrl => (
                    <ItemList
                      // 因为是一个不常见的交互形式，很难直接设计一个合理的组件。因此这里通过一些视觉 trick 来完善效果，margin 偏移是为了配合 tab 容器中自带的 padding
                      style={{ marginRight: -15 }}
                      items={tradePlatforms.filter(filterPlatform).map(({ name, logo, href }) => ({
                        icon: (
                          <svg aria-hidden>
                            <use xlinkHref={logo} />
                          </svg>
                        ),
                        label: `Trade on ${name}`,
                        key: name,
                        onClick: () => {
                          if (strategy) window.open(href(strategy.address));
                          return false;
                        },
                      }))}
                    />
                  )}
                </PopupEntry>
              ),
              content: null,
              onClick: async () => false,
            },
          ].filter(predicate)}
        />
      </section>
    </Card>
  );
};

export default MortgageMint;

const DropdownIcon: FC = () => (
  <i className={classNames('iconfont icon-xialajiantou')} style={{ marginLeft: 2, color: 'var(--text-white)' }} />
);

const UnsupportedTip = styled.article`
  color: var(--text-gary);
  margin: 140px 0;
  text-align: center;
`;
