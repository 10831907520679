import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
}

const AnnouncementModal: FC<ModalProps> = props => {
  const { visible, closeModal } = props;

  if (!visible) return null;

  return (
    <ModalDiv>
      <ContentWrapper>
        <Title>Latest updates</Title>
        <CloseButton className="iconfont icon-guanbi2" onClick={closeModal} />
        <ContentInner>
          <div>
            <div>As per the snapshot of Block 19575512-</div>
            <br />
            <div>
              1. Users who supplied dUSD-BUSD LP and the LP tokens are in the Duet Vault at the time of attack, they
              will get equivalent USD value in BUSD in their Duet Vault (No action from user needed). If users had their
              dUSD-BUSD LP tokens in their decentralized wallet, they can claim the BUSD compensation from the
              compensation page.
            </div>
            <br />
            <div>
              2. Users who supplied dUSD-DUET LP and the LP tokens are in the Duet Vault at the time of attack, they
              will get equivalent USD value of DUET-CAKE LP tokens in their Duet Vault (No action from user needed). If
              users had their dUSD-DUET LP tokens in their decentralized wallet, they can claim DUET-CAKE LP tokens
              compensation from the compensation page.
            </div>
            <br />
            <div>
              3. Users who have dUSD in their decentralized wallet, they can claim the corresponding amount of BUSD from
              the compensation page.
            </div>
            <br />
            <div>
              4. Users who supplied dXAU-dUSD, dTMC-dUSD, dWTI-dUSD liquidity, whether they have the LP tokens in their
              wallet or supplied them in the Duet Vault, can claim the corresponding dAsset-BUSD LP tokens through the
              compensation page.
            </div>
            <br />
            <div>
              5. Users‘ dUSD loan will be automatically repaid and the corresponding amount of the deposit will be
              liquidated with no liquidation fee.
            </div>
            <div>
              6. Users who deposited BUSD in BUSD Smart Strategy can claim the amount of BUSD they deposited through the
              compensation page.
            </div>
            <br />
            <div>
              7. If you are holding dWTI, dTMC & dXAU tokens, no action needs to be done from your side as these assets
              were not affected by the attack.
            </div>
            <br />
          </div>
        </ContentInner>
      </ContentWrapper>
    </ModalDiv>
  );
};

export default AnnouncementModal;

const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(31, 27, 27, 0.8);
  /* background: #161616; */
  z-index: 99;
`;

const ContentWrapper = styled.div`
  @media (max-width: 768px) {
    width: 90%;
  }

  position: relative;
  width: 1000px;
  height: 700px;
  background: #222531;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  margin: 50px auto;
  padding: 30px;
  /* overflow: scroll; */
`;

const Title = styled.div`
  font-size: 24px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: #7872ff;
  line-height: 28px;
  text-align: center;
`;

const ContentInner = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
  }

  position: relative;
  background: #0c0b10;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-top: 20px;
  padding: 30px;
  height: 600px;

  font-size: 16px;
  font-family: Poppins-Light, Poppins;
  font-weight: 300;
  color: #ffffff;
  line-height: 24px;

  overflow: scroll;
`;

const EnhanceText = styled.div`
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #7872ff;
  line-height: 28px;
`;

const CloseButton = styled.i`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
