import { ReadonlyDeep } from '@/utils/_';
import React, { useContext } from 'react';
import { EbCake } from './server';

export const EbCakeContext = React.createContext<{
  ebCakes: EbCake[];
  ebCakeInfo: ReadonlyDeep<EbCake> | null;
  refresh: () => void;
}>({
  ebCakes: [],
  ebCakeInfo: null,
  refresh: () => {
    //
  },
});

export const useEbCakeInfo = () => {
  return useContext(EbCakeContext);
};

export type UnknownError =
  | null
  | undefined
  | string
  | Error
  | { message: string }
  | { reason: string | Error | { message: string } };

export const CatchErrorContext = React.createContext<{
  onError: (error: unknown) => void;
}>({
  onError: (error: unknown) => {
    //
  },
});

export const useCatchError = () => {
  return useContext(CatchErrorContext);
};

export const normalizeError = (error: unknown): string => {
  if (typeof error === 'string') {
    // 防止 error 信息过长
    if (error.length > 100) return error.substring(0, 100) + '...';
    return error;
  }
  if (typeof error === 'object') {
    if (error == null) {
      return 'Operation failed';
    }

    if ('reason' in error) {
      return normalizeError((error as typeof error & { reason: unknown }).reason);
    }
    if (error instanceof Error) {
      return normalizeError(error.message);
    }
    if ('message' in error) {
      return normalizeError((error as typeof error & { message: unknown }).message);
    }
  }

  return 'Internal error';
};
