import { message } from 'antd';
import { hexValue } from 'ethers/lib/utils';
import { networkConfigMap } from '@/config/network';

export enum WalletEnum {
  metamask = 'MetaMask',
  walletconnect = 'WalletConnect',
  bitkeep = 'BitKeep',
  gnosis = 'GnosisSafe',
}

export interface Wallet {
  logo: string;
  name: WalletEnum;
}

// switch network to ethereum
export const switchNetwork = async (chainId: number) => {
  try {
    // @TODO: provide by typed module
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await window.ethereum?.request?.({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexValue(chainId) }],
    });
  } catch (error: any) {
    console.error(error);
    if (error.code === 4902 || error.code === -32603) {
      try {
        // @TODO: provide by typed module
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await window.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [networkConfigMap[chainId]],
        });
      } catch (addError) {
        message.error('add network fail');
      }
    }
    return false;
  }
  return true;
};
