/* eslint-disable no-console */
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { Contract, utils } from 'ethers';
import toFixed from '@/utils/toFixed';

const { parseEther } = utils;

interface UseAmountProps {
  pairContract?: Contract;
  account?: string | null;
}

const useAmount = (props: UseAmountProps) => {
  const { pairContract, account } = props;

  const [amountIn, setAmountIn] = useState<string>('');
  const [amountOut, setAmountOut] = useState<string>('');
  const [amountOutFee, setAmountOutFee] = useState<string>('');

  const getAmountOut = useCallback(
    debounce(async (amount: string) => {
      try {
        if (!pairContract) return;

        const { amountOut: amountOutRes, fee } = await pairContract.calcOutputFee(parseEther(amount));
        setAmountOut(toFixed(amountOutRes, 4));
        setAmountOutFee(toFixed(fee, 4));
      } catch (err) {
        console.error(`err`, err);
      }
    }, 500),
    [setAmountOut, setAmountOutFee, pairContract, account],
  );

  const getAmountIn = useCallback(
    debounce(async (amount: string) => {
      try {
        if (!pairContract) return;

        const { amountIn: amountInRes, fee } = await pairContract.calcInputFee(parseEther(amount));
        setAmountIn(
          toFixed(amountInRes, 4, {
            ROUNDING_MODE: 'CEIL',
          }),
        );
        setAmountOutFee(toFixed(fee, 4));
      } catch (err) {
        console.error(`err`, err);
      }
    }, 500),
    [setAmountIn, setAmountOutFee, pairContract, account],
  );

  const onAmountInChange = (value: string) => {
    setAmountIn(value);
    if (value === '') {
      setAmountOut('');
      setAmountOutFee('');
      getAmountOut.cancel();
      return;
    }
    getAmountOut(value);
  };

  const onAmountOutChange = (value: string) => {
    setAmountOut(value);
    if (value === '') {
      setAmountIn('');
      setAmountOutFee('');
      getAmountIn.cancel();
      return;
    }
    getAmountIn(value);
  };

  return {
    amountIn,
    amountOut,
    amountOutFee,
    setAmountIn,
    setAmountOut,
    setAmountOutFee,
    onAmountInChange,
    onAmountOutChange,
  };
};

export default useAmount;
