import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useWeb3 } from '@/web3';
import DButton from '@/components/DButton';
import { injectContractRetrieveTokens } from '@/contracts';
import executeAndShowTx from '@/utils/executeAndShowTx';
import config, { Config } from '@/config/config';
import { formatEther, parseEther } from '@ethersproject/units';
import { BigNumber, constants } from 'ethers';
import getCdnResource from '@/utils/getCdnResource';
import { message, Spin } from 'antd';
import summary from './summary.json';
import liquidationUsers from './liquidation-user.json';
import AnnouncementModal from './components/AnnouncementModal';
import swapUserDeposit from './swap-user-deposit.json';
import VaultLink from './components/VaultLink';
import TokenDisplay from './components/TokenDisplay';
import { compensationUsers } from './compensationUsers';

const userInfos = summary;

const addressToVaultMap: Record<string, string> = Object.entries(config).reduce(
  (a, [key, value]) => ({
    ...a,
    [value]: key,
  }),
  {},
);

type UserInfo = typeof userInfos[0];

const DY_DUET_CAKE_TO_DUET_CAKE = 0.6922525543667735;

interface Token {
  symbol: string;
  image: string;
  wallet: string;
  vault: string;
  walletCompensation: string;
  vaultCompensation: string;
  compensationToken: string;
}

const claimableTokensInfo = [
  {
    symbol: 'BUSD',
    configName: 'BUSD',
  },
  {
    symbol: 'dWTI-BUSD',
    configName: 'DWTI_BUSD',
  },
  {
    symbol: 'dXAU-BUSD',
    configName: 'DXAU_BUSD',
  },
  {
    symbol: 'dTMC-BUSD',
    configName: 'DTMC_BUSD',
  },
  {
    symbol: 'DUET-CAKE',
    configName: 'DUET_CAKE',
  },
] as const;

interface ClaimableToken {
  symbol: string;
  amount: string;
}

const addTwoEtherString = (a: string, b: string) => {
  const value = parseEther(a || '0').add(parseEther(b || '0'));
  if (value.eq(constants.Zero)) return '';
  return formatEther(value);
};

const swapDyDuetCakeToDuetCake = (amount?: string) => {
  if (!amount) return '-';
  return +amount / DY_DUET_CAKE_TO_DUET_CAKE;
};

const Compensation: FC = () => {
  const { account } = useWeb3();
  const userInfo: UserInfo | undefined = userInfos.find(each => {
    const compensationUsersSet = new Set(compensationUsers.map(each => each.toLocaleLowerCase()));
    const inCompensation = compensationUsersSet.has(each.account.toLocaleLowerCase());
    return inCompensation && each.account.toLocaleLowerCase() === account?.toLocaleLowerCase();
  });
  const swapInfo = swapUserDeposit.find(each => each.user.toLocaleLowerCase() === account?.toLocaleLowerCase());
  const [tokens, setTokens] = useState<Token[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [claimableTokens, setClaimableTokens] = useState<ClaimableToken[]>([]);
  const [retrievedTokens, setRetrievedTokens] = useState<ClaimableToken[]>([]);

  const { contractContainer } = useWeb3();

  const liquidationEvents =
    liquidationUsers.find(each => each.borrower.toLocaleLowerCase() === account?.toLocaleLowerCase())?.events || [];
  const mintVaultReleased = liquidationEvents
    .filter(each => each.event === 'MintVaultReleased')
    .map(each => ({
      ...each,
      ...each.argsObj,
      ...(each.argsObj?.vault && { vaultName: addressToVaultMap[each.argsObj.vault] }),
    }));
  const depositVaultReleased = liquidationEvents
    .filter(each => each.event === 'DepositVaultReleased')
    .map(each => ({
      ...each,
      ...each.argsObj,
      ...(each.argsObj?.vault && { vaultName: addressToVaultMap[each.argsObj.vault] }),
    }));

  const [refreshClaimableCount, setRefreshClaimableCount] = useState(0);
  const [claimableLoading, setClaimableLoading] = useState(false);
  useEffect(() => {
    if (!contractContainer || !account) return;
    setClaimableLoading(true);
    (async () => {
      try {
        const contract = injectContractRetrieveTokens(contractContainer);
        const promises = claimableTokensInfo
          .map(each => each.configName)
          .map(each => contract.userRetrievableTokenMap(account, config[each]));
        const values = await Promise.all(promises);
        const res = values.map((each, index) => ({
          symbol: claimableTokensInfo[index].symbol,
          amount: values[index].eq(constants.Zero) ? '' : formatEther(values[index]),
        }));
        setClaimableTokens(res);
      } catch (e) {
        //
      } finally {
        setClaimableLoading(false);
      }
    })();
  }, [contractContainer, account, refreshClaimableCount]);

  useEffect(() => {
    if (!contractContainer || !account) return;
    setClaimableLoading(true);
    (async () => {
      try {
        const contract = injectContractRetrieveTokens(contractContainer);
        const promises = claimableTokensInfo
          .map(each => each.configName)
          .map(each => contract.userRetrievedTokenMap(account, config[each]));
        const values = await Promise.all(promises);
        const res = values.map((each, index) => ({
          symbol: claimableTokensInfo[index].symbol,
          amount: values[index].eq(constants.Zero) ? '' : formatEther(values[index]),
        }));
        setRetrievedTokens(res);
      } catch (e) {
        //
      } finally {
        setClaimableLoading(false);
      }
    })();
  }, [contractContainer, account, refreshClaimableCount]);

  const retrieved = retrievedTokens.some(each => each.amount !== '');

  const claim = async () => {
    if (!contractContainer || !account) return;

    const tokens = [config.BUSD, config.DWTI_BUSD, config.DXAU_BUSD, config.DTMC_BUSD, config.DUET_CAKE];
    const contract = injectContractRetrieveTokens(contractContainer).connect(contractContainer.getSigner(account));
    await contract.estimateGas
      .retrieveTokens(tokens)
      .then(async gasEstimated => {
        await executeAndShowTx(
          contract.retrieveTokens(tokens, {
            gasLimit: gasEstimated.mul(12).div(10),
          }),
        )
          .then(() => {
            setRefreshClaimableCount(refreshClaimableCount + 1);
          })
          .catch(e => {
            if (e.reason) {
              message.error(e.reason);
            }
          });
      })
      .catch(e => {
        if (e.reason) {
          message.error(e.reason);
        }
      });
  };

  const convertDuetDusd2DuetCake = (value: string) => {
    if (!value) return '';
    const duetDusdPrice = '0.306272958533394446';
    const duetCakePrice = '0.543196629430342792';
    return formatEther(parseEther(value).mul(parseEther(duetDusdPrice)).div(parseEther(duetCakePrice)));
  };

  useEffect(() => {
    if (!userInfo) return;
    const compensationTokens: Token[] = [
      {
        symbol: 'smart BUSD',
        image: getCdnResource('/token/BUSD.png'),
        wallet: '',
        vault: userInfo.SBUSD_VAULT_UNDERLYING,
        walletCompensation: '',
        vaultCompensation: userInfo.SBUSD_VAULT_BUSD,
        compensationToken: 'BUSD',
      },
      {
        symbol: 'dUSD',
        image: getCdnResource('/token/dUSD.png'),
        wallet: userInfo.DUSD,
        vault: '',
        walletCompensation: userInfo.DUSD,
        vaultCompensation: '',
        compensationToken: 'BUSD',
      },
      {
        symbol: 'dWTI-dUSD',
        image: getCdnResource('/token/dWTI-dUSD.png'),
        wallet: userInfo.DWTI_DUSD,
        vault: userInfo.DWTI_DUSD_VAULT,
        walletCompensation: userInfo.DWTI_DUSD,
        vaultCompensation: userInfo.DWTI_DUSD_VAULT,
        compensationToken: 'dWTI-BUSD',
      },
      {
        symbol: 'dXAU-dUSD',
        image: getCdnResource('/token/dXAU-dUSD.png'),
        wallet: userInfo.DXAU_DUSD,
        vault: userInfo.DXAU_DUSD_VAULT,
        walletCompensation: userInfo.DXAU_DUSD,
        vaultCompensation: userInfo.DXAU_DUSD_VAULT,
        compensationToken: 'dXAU-BUSD',
      },
      {
        symbol: 'dTMC-dUSD',
        image: getCdnResource('/token/dTMC-dUSD.png'),
        wallet: userInfo.DTMC_DUSD,
        vault: userInfo.DTMC_DUSD_VAULT,
        walletCompensation: userInfo.DTMC_DUSD,
        vaultCompensation: userInfo.DTMC_DUSD_VAULT,
        compensationToken: 'dTMC-BUSD',
      },
      {
        symbol: 'DUET-dUSD',
        image: getCdnResource('/token/DUET-dUSD.png'),
        wallet: userInfo.DUET_DUSD,
        vault: '',
        walletCompensation: convertDuetDusd2DuetCake(userInfo.DUET_DUSD),
        vaultCompensation: '',
        compensationToken: 'DUET-CAKE',
      },
      {
        symbol: 'dUSD-BUSD',
        image: getCdnResource('/token/dUSD-BUSD.png'),
        wallet: userInfo.DUSD_BUSD,
        vault: '',
        walletCompensation: addTwoEtherString(userInfo.DUSD_BUSD_DUSD, userInfo.DUSD_BUSD_BUSD),
        vaultCompensation: '',
        compensationToken: 'BUSD',
      },
    ].filter(token => token.wallet !== '' || token.vault !== '');
    setTokens(compensationTokens);
  }, [userInfo]);

  const haveTokenToClaim = claimableTokens.some(token => token.amount !== '');

  const renderTotalClaimable = () => {
    if (claimableLoading) {
      return <Spin spinning />;
    }
    if (!haveTokenToClaim && !retrieved) {
      return '-';
    }

    return (retrieved ? retrievedTokens : claimableTokens)
      .filter(token => token.amount)
      .map(token => (
        <TotalRow key={token.symbol}>
          <span>{token.amount + ' '}</span>
          <TokenDisplay tokenSymbol={token.symbol} />
          <br />
        </TotalRow>
      ));
  };

  const renderPolicy = (token: Token, key: 'wallet' | 'vault') => {
    if (key === 'wallet') {
      return (
        <>
          {token.walletCompensation} <TokenDisplay tokenSymbol={token.compensationToken} /> claimable
        </>
      );
    }
    return (
      <>
        {token.vaultCompensation} <TokenDisplay tokenSymbol={token.compensationToken} /> claimable
      </>
    );
  };

  const renderDepositSwaps = () => {
    if (!swapInfo) return null;
    const arr: ReactNode[] = [];
    if (swapInfo['DUET-dUSD-amount']) {
      arr.push(
        <tr>
          <td>
            <TokenDisplay tokenSymbol="DUET-dUSD" /> in <VaultLink token="DUET-dUSD" />
          </td>
          <td>{swapInfo['DUET-dUSD-amount']}</td>
          <td>
            swapped to {swapDyDuetCakeToDuetCake(swapInfo['swapped-DUET-CAKE-amount'])} DUET-CAKE in{' '}
            <VaultLink token="DUET-CAKE" />
          </td>
        </tr>,
      );
    }
    if (swapInfo['dUSD-BUSD-amount']) {
      arr.push(
        <tr>
          <td>
            <TokenDisplay tokenSymbol="dUSD-BUSD" /> in <VaultLink token="dUSD-BUSD" />
          </td>
          <td>{swapInfo['dUSD-BUSD-amount']}</td>
          <td>
            swapped to {swapInfo['swapped-BUSD-amount']} BUSD in <VaultLink token="BUSD" />
          </td>
        </tr>,
      );
    }
    return arr;
  };

  const renderVaultReleased = () => {
    if (mintVaultReleased.length === 0 && depositVaultReleased.length === 0) return null;
    return (
      <tr>
        <td>
          <TokenDisplay tokenSymbol="dUSD" /> borrowed
        </td>
        <td>{mintVaultReleased[0]?.argsObj?.amount || '-'}</td>
        <td>
          closed with:
          <br />
          {depositVaultReleased.map(each => {
            const amount =
              each.vaultName === 'DUET_CAKE_VAULT'
                ? swapDyDuetCakeToDuetCake(each.argsObj?.amount)
                : each.argsObj?.usdValue;
            return (
              <div key={each.vaultName}>
                {`${amount} ${each.vaultName} ($${each.argsObj?.usdValue})`}
                <br />
              </div>
            );
          })}
        </td>
      </tr>
    );
  };

  const showCompensation = userInfo || swapInfo || liquidationEvents.length;

  return (
    <CompensationWrapper>
      <Announcement>
        We thank the community for the support and standby in the past weeks. We all know that the attack happened was
        unpleasant. The team has finalized the compensation plan for all affected users. Connect your wallet with the
        compensation page and you can claim the compensation.
        <br />
        <br />
        Kindly click here for the detailed information regarding the compensation.
        <ClickForDetailWrapper onClick={() => setModalVisible(true)}>
          <ClickForDetail>Click for detail</ClickForDetail>
          <i className="iconfont icon-jiantou1" />
        </ClickForDetailWrapper>
      </Announcement>
      {showCompensation && (
        <>
          <YourLoss>Your dUSD related assets as of block 19575512</YourLoss>
          <TableWrapper>
            <TableArea>
              <thead>
                <tr>
                  <td>Assets</td>
                  <td>Amount</td>
                  <td>Policy</td>
                </tr>
              </thead>
              <tbody>
                {tokens.reduce<ReactNode[]>((a, token) => {
                  const nodeArr = (['wallet', 'vault'] as const)
                    .filter(key => token[key] !== '' && parseEther(token[key]).gte(parseEther('0.1')))
                    .map(key => {
                      const link =
                        token.symbol === 'smart BUSD'
                          ? '/innovation-deposit-detail/0xe9e7cea3dedca5984780bafc599bd69add087d56'
                          : '';

                      return (
                        <tr key={token.symbol + key}>
                          <td>
                            {/* <TokenImage src={token.image} /> */}
                            <TokenDisplay
                              display={token.symbol}
                              tokenSymbol={token.symbol === 'smart BUSD' ? 'BUSD' : token.symbol}
                            />{' '}
                            in{' '}
                            {key === 'wallet' ? 'wallet' : <VaultLink token={token.symbol} {...(link && { link })} />}
                          </td>
                          <td>{token[key]}</td>
                          <td>{renderPolicy(token, key)}</td>
                        </tr>
                      );
                    });

                  return [a, ...nodeArr];
                }, [])}

                {renderDepositSwaps()}

                {renderVaultReleased()}

                <tr className="total">
                  <td>Total {retrieved ? 'claimed' : 'claimable'} assets</td>
                  <td>{renderTotalClaimable()}</td>
                  <td />
                </tr>
              </tbody>
            </TableArea>
          </TableWrapper>
          <ButtonRow>
            {retrieved && (
              <DButton width={420} disabled>
                Claimed
              </DButton>
            )}
            {!retrieved && (
              <DButton width={420} onClick={claim} disabled={!haveTokenToClaim || claimableLoading}>
                Claim
              </DButton>
            )}
          </ButtonRow>
        </>
      )}
      {!showCompensation && <InformationTips>You have no assets affected by this attack</InformationTips>}
      <AnnouncementModal visible={modalVisible} closeModal={() => setModalVisible(false)} />
    </CompensationWrapper>
  );
};

export default Compensation;

const InformationTips = styled.div`
  font-size: 28px;
  color: var(--common-white);
  text-align: center;
`;

const CompensationWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  color: white;
  width: 1280px;
  margin: 0 auto;
  @include respond-to(sm) {
    width: 100%;
  }
`;

const Announcement = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
    height: auto;
  }

  padding: 40px 60px;
  font-size: 18px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
  height: 262px;
  background: rgba(97, 59, 244, 0.5);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
`;

const TableWrapper = styled.div`
  @media (max-width: 768px) {
    overflow: scroll;
  }

  padding: 0 20px;
  background: #151031; ;
`;

const TableArea = styled.table`
  @media (max-width: 768px) {
    width: 1200px;
  }

  width: 100%;
  margin-top: 20px;
  background: #151031;

  thead {
    tr td {
      height: 60px;
      padding: 0 20px;
      font-size: 14px;
      color: #9195a5;
    }
  }

  tbody {
    tr td {
      height: 100px;
      background: #1d173d;
      padding: 20px;
      font-size: 16px;
      color: #d7d6d6;
    }

    tr.total {
      background: #613bf4;
      opacity: 1;

      td {
        background: #613bf4;
      }
    }

    tr {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #151031;
        height: 5px;
      }
    }
  }
`;

const YourLoss = styled.div`
  font-size: 28px;
  font-family: Poppins-SemiBold, Poppins;
  font-weight: 600;
  color: var(--common-white);
  margin-top: 40px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const TokenImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const TotalRow = styled.div`
  height: 30px;
  &:not(first-child) {
    margin-top: 10px;
  }
`;

const TokenSymbol = styled.span`
  margin-right: 20px;
`;

const ClickForDetailWrapper = styled.div`
  margin: 20px auto;
  justify-content: center;
  display: flex;
  cursor: pointer;

  .iconfont {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
  }
`;

const ClickForDetail = styled.div`
  font-size: 20px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #ffffff;
  text-decoration: underline;
`;
