import { FORKED_CHAIN_IDS } from '@/config/forked';
import allLpTokensTest from './bsctest/allLpTokens';
import allLpTokensBsc from './bsc/allLpTokens';

import { DisplayPosition, Token, Strategy } from './types';
import { CDN_BASE_URL } from './baseUrl';

export type { Token } from './types';
export { SingleOrLp as TokenType } from './types';

const allLpTokensDict: Record<string, Token[]> = {
  56: allLpTokensBsc,
  97: allLpTokensTest,
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (allLpTokensDict[chainId] = allLpTokensDict[56]));
// 约定: 通过 cdn + token symbol 获取 token 的 icon
const allLpTokens: Token[] = allLpTokensDict[import.meta.env.VITE_CHAIN_ID].map(each => {
  return {
    ...each,
    image: `${CDN_BASE_URL}/token/${each.symbol}.png`,
  };
});

export const allStrategies = allLpTokens.reduce<Strategy[]>(
  (a, c) => [
    ...a,
    ...(c.vaults || []).map(each => ({
      ...c,
      ...each,
    })),
  ],
  [],
);

export const depositStrategyList = allStrategies.filter(each => each.displayPosition === DisplayPosition.DEPOSIT);
export const depositArchivedStrategyList = allStrategies.filter(
  each => each.displayPosition === DisplayPosition.DEPOSIT_ARCHIVED,
);
export const depositInnovationStrategyList = allStrategies.filter(
  each => each.displayPosition === DisplayPosition.DEPOSIT_INNOVATION,
);
export const allDepositStrategyList = allStrategies.filter(each =>
  [DisplayPosition.DEPOSIT, DisplayPosition.DEPOSIT_ARCHIVED, DisplayPosition.DEPOSIT_INNOVATION].includes(
    each.displayPosition,
  ),
);
export const dAssetsStrategyList = allStrategies.filter(each => each.displayPosition === DisplayPosition.DASSETS);
export const dAssetsInnovationStrategyList = allStrategies.filter(
  each => each.displayPosition === DisplayPosition.DASSETS_INNOVATION,
);
export const dAssetsArchivedStrategyList = allStrategies.filter(
  each => each.displayPosition === DisplayPosition.DASSETS_ARCHIVED,
);
export const allDAssetsStrategyList = allStrategies.filter(each =>
  [DisplayPosition.DASSETS, DisplayPosition.DASSETS_INNOVATION, DisplayPosition.DASSETS_ARCHIVED].includes(
    each.displayPosition,
  ),
);

export const allLpTokensMap: Record<string, Token> = allLpTokens.reduce(
  (a, c) => ({
    ...a,
    [c.address]: c,
  }),
  {},
);

export const strategyListToMap = (strategies: Strategy[]) =>
  strategies.reduce<Record<string, Strategy>>(
    (a, c) => ({
      ...a,
      [c.address]: c,
    }),
    {},
  );

export default allLpTokens;
