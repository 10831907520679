export const compensationUsers = [
  '0xc11b02bba83ba5554855ee58f80952b38dafa383',
  '0xd047b39824107d23116b544e1127fc0d3b131509',
  '0xd7f4a04c736cc1c5857231417e6cb8da9cadbec7',
  '0x51f2d3fdfc459363bf7a1ac2c378b882de918c1b',
  '0xe7afc1509ea558a1ea3a25e6f26ee018d23ec878',
  '0x95ff5b7681aa91d8d9a565b2e983b054d4f922cb',
  '0x5dfff0ba7b0f0c8431aba53ac1278c3accc8d6c6',
  '0xe078b6dfac68b9282c16777a78f2680f1dc5cd86',
  '0xaf30e7504eec4e46af6bf4018f8c9f7ce9e9ba8c',
  '0xce7d5ee041a40e9f724f7d3b74e99d8fc1f0f696',
  '0x42810ba75fd56a4052209fc42d5a93174abd66b1',
  '0x11b2e2b4a8f5f7fd59229a1b3d86eb7aaab15f82',
  '0x5bd8d53af7cd96af62589b06798a8e4216d4d7e8',
  '0x94be0b9124ae7c111b9c02871100e62c37c14a69',
  '0x24f850a2addf409d34f171e56b7e7d74fdac2a0f',
  '0x70edf363c1682b806cd58eaa4c45d7adb6f721f9',
  '0xebcca48f3eb0a058b4540ef802d598e1406008ef',
  '0x3cccc8ffbac4aaa5b97899f823ffdae9ad0cd4b6',
  '0xf5f70962a33e046c08fc4c8a852b6cd79f4cc182',
  '0x1eec3865fd3015b0ce4b7ab55ed948f80df07ee9',
  '0x5275817b74021e97c980e95ede6bbac0d0d6f3a2',
  '0xe35e49532b4067d514d76ca769b984e844b1e031',
  '0xd9d3dd56936f90ea4c7677f554dfefd45ef6df0f',
  '0xc13cad139d0a54bb496cb05be126fcbff785aeae',
  '0x3b410f92ccdfad18477c9b4f325cec96d9dd397f',
  '0x4ba13102569af816bc3cfbad0c95d478fa3df58c',
  '0xaa8bdb60ee28e7616bfe4867f58bfe4edf858271',
  '0x4475d1be974301106036061898b26fd5d62dbf5c',
  '0xa53c541d155c06a6fc0b0d42ea26ad7414cf547b',
  '0x77f75363faeaae05aa54aae33e19a0c901147c11',
  '0xf0419ee1156cc75fc0dbaa1906f85782430a5f6e',
  '0x2418c7ff88f10b461566a65d5563c22aba85ab2b',
  '0x7e497d71fc537a0d859211cbef7cca2fa648f8c1',
  '0xec04eaefdd9a514964f136bd3e02398ec62ecc24',
  '0xb22b1187beacfda3fe2a414d0bcb65c2c2d3012e',
  '0x613d6139eac603587eb97638993692a78aa9c447',
  '0xb58c189fad07daf826542ac9f3a3c3a2c5ac4a75',
  '0xf82ca9d627b15336de5f88dea0a338cd283188a0',
  '0x5df89e500d14fbdc01ca39af9e76e76bc46c7a09',
  '0xa2e2c0ef708ecdeebf7da840f2b38389d19803db',
  '0x14f4f859281e3f941d5194c1938bbab1290e7dcf',
  '0xbf9b490af060529a7f89d155b3d045b896bb646e',
  '0x4b80ef7c6d4c4cdbe28029852ebaf1b88ad2b35d',
  '0x93f5043925c4d2a28628330820925fe0a82d2b24',
  '0x3a887cf29012fb8ab4ee196a3eaccdaf57946fa8',
  '0x7f317b488fd3346a377ef178fde494aa59b044f7',
  '0x2d0c7a0807a21615fb945dd64afde7861dfb9c43',
  '0x73234a37e49902136eb5318804f195b78cfdea34',
  '0xca3ab843b1c0665816b45cee77b3a0c1c3338c98',
  '0xc04a3df04fe48c261a233e46110b0fdadab1733e',
  '0xd6e65d0a291115fb7319f73c49c1b703d252a7c0',
  '0x691d6724e2e956457632b5072b2f0ee826ae0b61',
  '0x11ededebf63bef0ea2d2d071bdf88f71543ec6fb',
  '0xa98d587471b48518163d3d2268ef4ed42cd69614',
  '0xa4036d9c5d1c5edb323cedc88699e8241a3773bd',
  '0xf43f5be06615a0303c8cff19e38cc1fe0f0ebbc3',
  '0xbed58e0dcbc4b4ac0a9fce1d2f2804fbb6fe49aa',
  '0x65bc72f5e5daefe930f1b468fc73ec96b0228956',
  '0x9c2fa2cddea1c0a612049728d4af9ba20fe58630',
  '0xd161099739c555b6d3d5e565cd55c470ee430f0e',
  '0xd89c07d7abbf83e4bac5907567c8cc1b32491450',
  '0xbdb4cb303a4e288110d57e9b25ad96242b5f1b9d',
  '0xbf3cd4e28ced7f61c588693c018f4f38b4b0444e',
  '0xc0ec851c663f97399cc749ebe798c9153b0dbb4b',
  '0xacdceb490c614fa827c4f20710ee38e6b27d0eb2',
  '0x55fd2a393793cdb546fe0ab1fdc37df8f61520f4',
  '0xb5ee4f9dfcab6198dee8b013a74c951a44b8d78d',
  '0x52ff0010d4058bb726725ad76f542c53033d67a1',
  '0x627e46f5922b5601ada533cb229c519103537c28',
  '0xad382901be4c94467fefbf32e0764cd977b67344',
  '0x593bb3859dd0d244e94e233745a2542c732e1fe3',
  '0xf4ce1da492110616024d401f8c659ff395851726',
  '0x9d52de02cdc6eac4245b5a838b6e776fe1f1d312',
  '0x45aecad3551f5c628e07a2a2b190bce2229fde5a',
  '0x96451702bfe9ec3c5718402c99e69ed5776c4b37',
  '0xb1f5dd6746151c73047838694c04b31494511c2c',
  '0x8f1421db7c4dfd995e3b2209b754a22d8cc5a35e',
  '0x643777228acf1198d5e1a595dc12911955182376',
  '0x67735e8032e1d191a5cfd55c0e117fd045a7508e',
  '0x8f918a9e919cc28c12169a9f4a36ac2fbeb89c42',
  '0x6a4376a31623db641aa21f1e5cd63da526657b89',
  '0x0818b8938fcd0a253ccdede9ec417277d3ca11e3',
  '0x79db77b84a811ced34651dce941dfb707a8b9e8b',
  '0x5915ee060ec503cee03b4e81490cc424868d799f',
  '0x29d60a12b00b1f7ebe1f67c82a832ec6d2727439',
  '0xb5a48798d829b3389df6bd39189ba46128967bf2',
  '0xf19c6fe6eb173f2c1c19c47a4e87ba2bba570a08',
  '0x8ec1c09522758be634a6d9f0e3661c8b1f91a5e4',
  '0x6728a80cf45eebe656fbd5b3d3820a9a05f78c98',
  '0xe53f8cc868dc2e0fb1da5bd1b5e6404600a6de22',
  '0xe03311b4590f5516739bf4c5197bcdbbbfc9d7e0',
  '0xa7eef766b1f3f3d31a0c7c6ebd0b3f31e9a87634',
  '0x77c2da8140693996be6bc72776cb1f04a77a4e68',
  '0x7977b909d55a53f9c73140f7f611eaf0638238ed',
  '0x9ce6e6b60c894d1df9bc3d9d6cc969b79fb176b7',
  '0x4dc810b632409bcbd2b7f8b5ace94279bc68cdd5',
  '0xbd938b6b6f83e5535c649e921bc9d6151e86c100',
  '0x872c9926d1ba0794a637c23ecb45bb590273d6d3',
  '0x6d8b62e180c841e721a61f6003b148a76f246cec',
  '0xb6ebe9453a42315a54e3184cd59b70a4924c1064',
  '0x9c299fa124bdfec5e5fdbb2cf480ac7d914691c6',
  '0x13b91b3ff58886ace7637e547c53bbc2936d803f',
];
