import { FC, PropsWithChildren } from 'react';
import styled, { CSSProperties } from 'styled-components';

interface Props {
  alignItems?: 'center' | 'flex-start';
  direction?: 'column' | 'row';
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
  style?: CSSProperties;
}

const FlexRow: FC<PropsWithChildren<Props>> = props => {
  const { direction = 'row', alignItems = 'center', children, justifyContent = 'flex-start', style } = props;

  return (
    <FlexRowWrapper alignItems={alignItems} direction={direction} justifyContent={justifyContent} style={style}>
      {children}
    </FlexRowWrapper>
  );
};

export default FlexRow;

const FlexRowWrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`;
