/* eslint-disable no-console */
import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { MaxUint256 } from '@ethersproject/constants';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { injectContractERC20 } from '../contracts';
import { useWeb3 } from '../web3';

interface Props {
  tokenAddress?: string;
  approveTo?: string;
}

const useApproved = (props: Props) => {
  const { account, contractContainer } = useWeb3();

  const { tokenAddress, approveTo } = props;

  const [approved, setApproved] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approvedStatusLoading, setApprovedStatusLoading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);

  const approve = async () => {
    if (!tokenAddress || !contractContainer || !account) return;
    try {
      const contract = injectContractERC20(contractContainer, tokenAddress).connect(
        contractContainer.getSigner(account),
      );
      const promise = contract.approve(approveTo, MaxUint256);
      setApproveLoading(true);
      await executeAndShowTx(promise);
      refreshApproveStatus();
    } catch (e) {
      console.error(e);
    } finally {
      setApproveLoading(false);
    }
  };

  // 获取 approved 状态
  useEffect(() => {
    if (!tokenAddress || !account || !approveTo || !contractContainer) return;
    (async () => {
      try {
        const contract = injectContractERC20(contractContainer, tokenAddress);
        setApprovedStatusLoading(true);
        const approvedValue: BigNumber = await contract.allowance(account, approveTo);
        setApprovedStatusLoading(false);
        if (!approvedValue.isZero()) {
          setApproved(true);
        } else {
          setApproved(false);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [tokenAddress, account, approveTo, refreshCount, contractContainer]);

  const refreshApproveStatus = () => {
    setRefreshCount(refreshCount + 1);
  };

  return {
    approve,
    approveLoading,
    approved: !approvedStatusLoading && approved,
    approvedStatusLoading,
    refreshApproveStatus,
  };
};

export default useApproved;
