import { BigNumber, constants, ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

interface ToFixedOption {
  ROUNDING_MODE: 'FLOOR' | 'CEIL';
}

const toFixed = (
  value?: BigNumber | null,
  decimals = 4,
  option: ToFixedOption = {
    ROUNDING_MODE: 'FLOOR',
  },
): string => {
  if (!value) return '';
  const divider = parseUnits('1', 18 - decimals);
  if (option.ROUNDING_MODE === 'FLOOR') {
    const remainder = value.mod(divider);
    const left = value.sub(remainder);
    return ethers.utils.formatEther(left);
  }

  const remainder = value.mod(divider);
  const complement = remainder.eq(constants.Zero) ? BigNumber.from(divider) : BigNumber.from(divider).sub(remainder);
  const left = value.add(complement);
  return ethers.utils.formatEther(left);
};

export default toFixed;
