import config from './config';

export interface TradePlatform {
  name: 'PancakeSwap' | 'iZumi' | 'Dodo';
  logo: string;
  href: (to: string, from?: string) => string;
}

/**
 * DUET-220: 如果当前是 dUSD 则跳转到 BUSD-dUSD 的池子，否则跳 dUSD-dAssets 的池子
 */
const transformOutputAndInputCurrency = (outputCurrency: string, inputCurrency?: string) => {
  if (!inputCurrency) {
    inputCurrency = config.BUSD;
  }
  return [outputCurrency, inputCurrency];
};

export const tradePlatforms: TradePlatform[] = [
  // {
  //   logo: '#icon-pancake',
  //   name: 'PancakeSwap',
  //   href: (outputCurrency, inputCurrency) => {
  //     const query = new URLSearchParams();

  //     const [newOutputCurrency, newInputCurrency] = transformOutputAndInputCurrency(outputCurrency, inputCurrency);

  //     if (newInputCurrency) query.set('inputCurrency', newInputCurrency);
  //     if (newOutputCurrency) query.set('outputCurrency', newOutputCurrency);
  //     return `https://pancakeswap.finance/swap?${query}`;
  //   },
  // },
  // {
  //   logo: '#icon-izumi',
  //   name: 'iZumi',
  //   href: (outputCurrency, inputCurrency) => {
  //     const query = new URLSearchParams();

  //     const [newOutputCurrency, newInputCurrency] = transformOutputAndInputCurrency(outputCurrency, inputCurrency);

  //     if (newInputCurrency) query.set('tokenFrom', newInputCurrency);
  //     if (newOutputCurrency) query.set('tokenTo', newOutputCurrency);
  //     return `https://izumi.finance/trade/swap?${query}`;
  //   },
  // },
  {
    logo: '#icon-dodo',
    name: 'Dodo',
    href: (outputCurrency, inputCurrency) => {
      const query = new URLSearchParams();

      const [newOutputCurrency, newInputCurrency] = transformOutputAndInputCurrency(outputCurrency, inputCurrency);

      if (newInputCurrency) query.set('from', newInputCurrency);
      if (newOutputCurrency) query.set('to', newOutputCurrency);
      query.set('network', 'bsc-mainnet');
      return `https://app.dodoex.io/?${query}`;
    },
  },
];
