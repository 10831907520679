import URLS_MAP from '@/config/urls';

interface MenuItem {
  icon: string;
  text: string;
  link?: string;
  children?: {
    icon: string;
    link: string;
  }[];
}

const otherMenus: MenuItem[] = [
  // {
  //   icon: 'icon-a-Auditreports1',
  //   text: 'Audit Reports',
  //   link: URLS_MAP.auditReport,
  // },
  // {
  //   icon: 'icon-a-meidiakit',
  //   text: 'Blog',
  //   link: URLS_MAP.blog,
  // },
  {
    icon: 'icon-a-socialmedia',
    text: 'Social Media',
    children: [
      {
        icon: 'icon-twitter',
        link: URLS_MAP.twitter,
      },
      {
        icon: 'icon-discord',
        link: URLS_MAP.discord,
      },
      // {
      //   icon: 'icon-telegram',
      //   link: URLS_MAP.telegram,
      // },
      {
        icon: 'icon-medium',
        link: URLS_MAP.medium,
      },
      {
        icon: 'icon-YouTube',
        link: URLS_MAP.youtube,
      },
      {
        icon: 'icon-facebook',
        link: URLS_MAP.facebook,
      },
      {
        icon: 'icon-reddit',
        link: URLS_MAP.reddit,
      },
    ],
  },
  // {
  //   icon: 'icon-a-DuetDocs',
  //   text: 'Duet Docs',
  //   link: URLS_MAP.docs,
  // },
  // {
  //   icon: 'icon-Contact',
  //   text: 'Contact us',
  //   link: URLS_MAP.mail,
  // },
  {
    icon: 'icon-more',
    text: 'More',
    children: [
      {
        icon: 'icon-a-auditreport',
        link: URLS_MAP.auditReport,
      },
      {
        icon: 'icon-blog',
        link: URLS_MAP.blog,
      },
      {
        icon: 'icon-a-DuetDocs2',
        link: URLS_MAP.docs,
      },
      {
        icon: 'icon-connect',
        link: URLS_MAP.mail,
      },
    ],
  },
];

export default otherMenus;
