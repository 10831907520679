import GiftBox from '@/pages/Bond/GiftBox.png';
import MoneyBox from '@/pages/Bond/MoneyBox.png';

const commonVars = {
  '--white': '#fff', // 纯白色
};

export const darkTheme = {
  '--text-main': '#ffffff', //
  '--bg-main': '#1D173D', //
  '--bg-input': '#221650', //
  '--primary-color': '#1D173D', // 全局主色
  '--common-white': '#D7D6D6', // 常用白色
  '--common-bg': 'linear-gradient(180deg, #0f073b 0%, #0c0b10 50%, #0c0b10 100%)', // 公共背景色
  '--text-normal': 'rgba(255, 255, 255, 0.6)', // 普通正文文本
  '--text-white': '#d7d6d6', // 文案-白色
  '--text-white2': '#fff', // 文案-白色2
  '--text-white3': '#fff', // 文案-白色3
  '--text-purple': '#613BF4', // 文案-紫色
  '--text-purple2': '#613BF4', // 文案-紫色2
  '--text-gary': '#9195A5', // 文案-灰色
  '--text-gary2': '#d7d6d6', // 文案-灰色2
  '--text-gary3': '#9195a5', // 文案-灰色3
  '--text-gary4': '#9195a5', // 文案-灰色4
  '--text-gary5': '#707482', // 文案-灰色5
  '--text-gary6': '#707482', // 文案-灰色6
  '--text-green': '#5DC647', // 文案-绿色
  '--text-red': '#E1536F', // 文案-红色
  '--text-flesh': '#b6a9a9', // 文案-肉色
  '--text-radioBg': '#0F092F', // 文案-单选框
  '--text-radio': '#707482', // 文案-单选框
  '--text-tab': '#fff', // 文案-tab框
  '--text-balance': '#D7D6D6', // 文案-余额
  '--text-highlight': '#ffce00', // 文案-高亮
  '--shadow-color': '#493F87', // 阴影颜色
  '--bg-wallet': 'linear-gradient(45deg, #11093d 0%, #11093d 79%, #4a2b57 100%)', // 背景-连接钱包弹窗
  '--bg-black': '#0F092F', // 背景-黑色
  '--bg-yellow': '#D5B732', // 背景-黄色
  '--bg-black2': '#151031', // 背景-黑色2
  '--bg-black3': '#232228', // 背景-黑色3
  '--bg-black4': '#0c0b10', // 背景-黑色4
  '--bg-black5': 'rgba(21, 16, 49, 1)', // 背景-黑色5
  '--bg-black6': 'rgba(29, 23, 61, 1)', // 背景-黑色6
  '--bg-black7': '#151031', // 背景-黑色7
  '--bg-black8': '#06050a', // 背景-黑色8
  '--bg-gray': '#484657', // 背景灰色
  '--bg-purple': '#613BF4', // 背景-紫色
  '--bg-purple2': '#4025A7', // 背景-紫色2
  '--bg-purple3': '#493f87', // 背景-紫色3
  '--bg-purple4': '#261e4b', // 背景-紫色4
  '--bg-purple5': '#221650', // 背景-紫色5
  '--bg-purple6': '#0f092f', // 背景-紫色6
  '--bg-purple7': '#493F87', // 背景-紫色7
  '--bg-purple8': '#1D173D', // 背景-紫色8
  '--bg-purple9': '#222531', // 背景-紫色9
  '--bg-select': '#261E4B', // 背景-下拉框
  '--bg-popover': '#0c0b10', // 背景-popover
  '--bg-popover-border': '#493f87', // 背景-popover-border
  '--bg-slider': '#fff', // 背景-slider
  '--bg-sliderBg': 'rgba(255, 255, 255, 0.1)', // 背景-sliderBg
  '--bg-blue': '#4025a7', // 背景-蓝色
  '--bg-green': '#50e3c2', // 背景-绿色
  '--bg-table-th': '#1D173D', // 背景-表格单行
  '--bg-button-purple': '#0F092F', // 背景-紫色
  '--bg-button': 'linear-gradient(315deg, #543BF0 0%, #E9B5FF 100%);', // 背景-紫色
  '--bg-button-disable': '#22202d', // 背景-紫色
  '--bg-gradual': 'linear-gradient(321deg, #151031 0%, #1a0c45 67%, #31296a 100%);', // 背景-渐变
  '--bg-gradual2': 'linear-gradient(45deg, #11093d 0%, #11093d 79%, #734f81 100%);', // 背景-渐变2
  '--border-purple': '#613bf4', // 边框-紫色
  '--border-gray': '#494751', // 边框-灰色
  '--table-td-hover': '261e49', // 表格hover
  '--card-box-shadow': '1px -1px 0px 0px rgba(97, 59, 244, 0.6)', // 卡片shadow
  '--bond--gift--img': `url(${GiftBox})`, // bond页面背景图片
  '--menu-bg': 'linear-gradient(1deg, #120e2b 0%, #0f0930 71%, #311b69 100%)', // 菜单背景
  '--menu-active-bg': '#241963', // menu active
  '--menu-text': '#d7d6d6', // menu text
  '--menu-active-text': '#d7d6d6', // menu active text
  ...commonVars,
};

export const lightTheme = {
  '--text-main': '#484657', // 文案-灰色
  '--bg-main': '#EEE9FF', // 文案-灰色
  '--bg-input': '#E0DAFA', //
  '--primary-color': '#EEE9FF', // 全局主色
  '--common-white': '#0C0B10', // 常用白色
  '--common-bg': '#F4F2FB', // 公共背景色
  '--text-normal': '#0C0B10',
  '--text-white': '#0C0B10', // 文案-白色
  '--text-white2': '#613BF4', // 文案-白色2
  '--text-white3': '#0C0B10', // 文案-白色2
  '--text-purple': '#613BF4', // 文案-紫色
  '--text-purple2': '#E0DAFA', // 文案-紫色2
  '--text-gary': '#484657', // 文案-灰色
  '--text-gary2': '#0C0B10', // 文案-灰色2
  '--text-gary3': '#9195a5', // 文案-灰色3
  '--text-gary5': '#9195A5', // 文案-灰色5
  '--text-gary6': '#707482', // 文案-灰色6
  '--text-gary4': '#000', // 文案-灰色4
  '--text-green': '#5DC647', // 文案-绿色
  '--text-red': '#E1536F', // 文案-红色
  '--text-flesh': '#0C0B10', // 文案-肉色
  '--text-radioBg': '#E0DAFA', // 文案-单选框
  '--text-radio': '#707482', // 文案-单选框
  '--text-tab': '#707482', // 文案-tab框
  '--text-balance': '#0C0B10', // 文案-余额
  '--text-highlight': '#613BF4', // 文案-高亮
  '--shadow-color': '#D7D6D6', // 阴影颜色
  '--bg-wallet': '#fff', // 背景-连接钱包弹窗
  '--bg-black': '#0C0B10', // 背景-黑色
  '--bg-yellow': '#D5B732', // 背景-黄色
  '--bg-black2': '#EEE9FF', // 背景-黑色2
  '--bg-black3': '#fff', // 背景-黑色3
  '--bg-black4': '#F4F2FB', // 背景-黑色4
  '--bg-black7': '#E8E4FA', // 背景-黑色7
  '--bg-black8': '#fff', // 背景-黑色8
  '--bg-gray': '#E0DAFA', // 背景灰色
  '--bg-purple': '#613BF4', // 背景-紫色
  '--bg-purple2': '#4025A7', // 背景-紫色2
  '--bg-purple3': '#493f87', // 背景-紫色3
  '--bg-purple4': '#EEE9FF', // 背景-紫色4
  '--bg-purple5': '#E0DAFA', // 背景-紫色5
  '--bg-purple6': '#E0DAFA', // 背景-紫色6
  '--bg-purple7': '#646CFF', // 背景-紫色7
  '--bg-purple8': '#F4F2FB', // 背景-紫色8
  '--bg-purple9': '#E0DAFA', // 背景-紫色9
  '--bg-select': '#E0DAFA', // 背景-下拉框
  '--bg-popover': '#fff', // 背景-popover
  '--bg-popover-border': '#F2F2F2', // 背景-popover-border
  '--bg-slider': '#613BF4', // 背景-slider
  '--bg-sliderBg': '#E0DAFA', // 背景-sliderBg
  '--bg-blue': '#E0DAFA', // 背景-蓝色
  '--bg-green': '#50e3c2', // 背景-绿色
  '--bg-table-th': '#E8E4FA', // 背景-表格单行
  '--bg-button-purple': '#EEE9FF', // 背景-紫色
  '--bg-button': 'rgba(97, 59, 244, 1)', // 背景-紫色
  '--bg-button-disable': 'rgba(97, 59, 244, 0.3)', // 背景-紫色
  '--bg-gradual': '#EEE9FF', // 背景-渐变
  '--bg-gradual2': '#EEE9FF', // 背景-渐变2
  '--border-purple': '#EEE9FF', // 边框-紫色
  '--border-gray': '#D7D6D6', // 边框-灰色
  '--table-td-hover': 'rgba(97,59,244,0.1)', // 表格hover
  '--card-box-shadow': 'none', // 卡片shadow
  '--bond--gift--img': `url(${MoneyBox})`, // bond页面背景图片
  '--menu-bg': '#E0D9F9', // 菜单背景
  '--menu-active-bg': '#613BF4', // menu active
  '--menu-text': '#493F87', // menu text
  '--menu-active-text': '#fff', // menu active text
  ...commonVars,
};

export enum Theme {
  light = 'light',
  dark = 'dark',
}
