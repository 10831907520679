const config = {
  mainChainId: 56,
  CHAIN_ID: 56,
  NETWORK_NAME: 'BNB Chain',
  WALLET_NETWORK_NAME_IN_CHAIN_REGISTRY: 'Binance Smart Chain Mainnet',
  APYInfo: '0x94087da71fccC200FB11065B3F4Bc2aE63EF74A8',

  THE_GRAPH_API_URL: 'https://api.thegraph.com/subgraphs/name/iuscript/duet-protocol-bsc',
  API_BASE_URL: 'https://api.duet.finance/api',
  CDN_BASE_URL: 'https://duet.s3.amazonaws.com',

  APP_CONTROLLER: '0x01d77e7CC19cc562adB17AD6Cb1F08f7a66fe301',
  FEE_CONF: '0x5e32093b7642059941d5C2317257F8720F581df8',
  READER: '0xE61e38108e248eF632b83d74FB99850944B18cA6',
  DUET_ZAP: '0x014127E127C6788BaDaE75Ba213a58Ac22944c82',
  LinkUSDOracle: '0x22cd82e00D12315B440B88E518d035Be62Fb0e9d',

  DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  USDT_BUSD: '0x7efaef62fddcca950418312c6c91aef321375a00',
  USDC_USDT: '0xec6557348085aa57c72514d67070dc863c0a5a8c',
  BUSD_USDC: '0x2354ef4df11afacb85a5c7f98b624072eccddbb1',
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  WBNB_DYTOKEN: '0xeA852C739929B23F32914093979d1d8154b6cF6d',
  WBNB_VAULT: '0x1d307a8fe3B0F34a55d4eD72759b33366D271Df4',
  ETH: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  ETH_DYTOKEN: '0xe84D6BD14BaB1072f1a45967F8Fd7c08Fc87d503',
  ETH_VAULT: '0x2197827b693eE46C3907893a6e9685BF36d66308',
  BTCB: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  BTCB_DYTOKEN: '0x3b2448b100194C0c623a81D75eB76af7B515c586',
  BTCB_VAULT: '0x3f47A90ae7fbEc8922447138Ba47b5A448fDf98B',
  CAKE: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  CAKE_DYTOKEN: '0x6Bde5D17924014048bF984D68f328a0F4AA07db1',
  CAKE_VAULT: '0x7d2Ae0355f374625EDA6E9CA2F3694bb880e39E1',
  DUET_WBNB: '0x27027ef46202b0ff4d091e4bed5685295afbd98b',
  DUET_WBNB_DYTOKEN: '0x4aE218e17AbEFF4BC1E1704b9Eae7DB688969BB0',
  DUET_WBNB_VAULT: '0x3ff0b76A3db4356662Fdf808ede7C921de820A36',

  BUSD_WBNB_DYTOKEN: '0x2F84f7b38A5379D64F0899193210D30A89efc2DC',
  BUSD_WBNB_VAULT: '0xc311C276B5bE3817677B6C5f5e59538e63dFa726',
  WBNB_USDT_DYTOKEN: '0xC8C44F3B4999bD5245d0634778355189c7F50302',
  WBNB_USDT_VAULT: '0x4f7834b87dFE2f9D391f1509530457956E96D641',
  USDT_BUSD_DYTOKEN: '0xbcedb96c87a3FeB4B9314b1F73c7E5f426051a16',
  USDT_BUSD_VAULT: '0x29d7165545275ee94c78c1E91d30b527C735e531',
  BUSD_USDC_DYTOKEN: '0x102f9694B12EFE45eF0357b1790a5aA605F2Ea25',
  BUSD_USDC_VAULT: '0x534c727EF1CD043132DF558EdB734Da4BE5b7E66',
  USDC_ETH_DYTOKEN: '0x6bD83b7C6300b62d5e53B6801EE95eeD896C3d19',
  USDC_ETH_VAULT: '0x8bDC2fc5900e12aD1c0B70Cc97Cdf3Be76c4D6e1',
  ETH_WBNB_DYTOKEN: '0x9B6a5F6022c348F25245F0B00107f6A1E66686d5',
  ETH_WBNB_VAULT: '0xE81cA5ED657d7092f4c3C565F2082bef0650F786',
  DAI_BUSD_DYTOKEN: '0x8BA31d8068F0de342155321Df6df3B4DD3cb7e09',
  DAI_BUSD_VAULT: '0x83B0c59932CeABfF14d5B85c6A4d89cC53a4Ab5b',
  BTCB_BUSD_DYTOKEN: '0x783Cc188923d5B9e69D8ABC57AEe908A71bFcb39',
  BTCB_BUSD_VAULT: '0x88f069D6CABdE1D6C37F92EC5eb989d7133922A3',
  USDC_USDT_DYTOKEN: '0x4CA76149b36A1230379f61e4F91f6d61A88487aF',
  USDC_USDT_VAULT: '0xc76944B36E928FDAf9F481048C770877BCe4cd25',
  BTCB_WBNB_DYTOKEN: '0xaF1Ff76F0fb78bE6962d3670ccD04b7381944e05',
  BTCB_WBNB_VAULT: '0xA4A92b80d4BbD1db3Bad6B65eD5bebE2E5c9a7EC',
  BTCB_ETH_DYTOKEN: '0x0f2A8C64aE412e3e66045FEb9295757faeB78d70',
  BTCB_ETH_VAULT: '0xbFF94Aa7948B6801b0ae68dD312Ec2EfC3561F8E',
  BNB_DYTOKEN: '0xaa95E129e1650aDbAed44AeAA15636503A718889',
  BNB_VAULT: '0x3Ad2F27eBF5aF7fe8132CAaE3794B75f715f42c0',
  USDT_DYTOKEN: '0xDcB28cacCAb6b1fcFDaB5340330f14eA593cbCA2',
  USDT_VAULT: '0xCAb51Fe16891960E1D0d8a3DCdb6c51C460536A7',
  DAI_DYTOKEN: '0x3638138d1E3647165eA764498862280605C40230',
  DAI_VAULT: '0x5A4F321f0017dd48F1fdf31EfeA25D160f9bC104',
  USDC_DYTOKEN: '0xd10cCe0EC5c5aBe62cCAD11b52092DE8D7860A59',
  USDC_VAULT: '0x7265f5C160142883855613Fb85C55900d7B5bD2e',
  BUSD_DYTOKEN: '0x538A09AE5B4Dc0a576D41767d912D33451043eE8',
  BUSD_VAULT: '0x90703ef182FE722Ea3A38a2f367aba72090aea0B',
  UST_BUSD: '0x05faf555522Fa3F93959F86B41A3808666093210',
  UST_BUSD_DYTOKEN: '0x5e9981EAB76FCB9BDc6D1A99ECf4De197cf9c7f1',
  UST_BUSD_VAULT: '0x1aaC45C904a83aa738a622DC078bA442e4168084',
  UST: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  UST_DYTOKEN: '0xCBF794adDFaEc1BFb9779A6b6cFB289164eA396d',
  UST_VAULT: '0xe9EE0Ff40FBcC94285646623Bb462380Bb94128D',

  BNB_CAKE: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
  BNB_CAKE_DYTOKEN: '0x19c3E5f664990115f7Ed57fa0081E30D595DA54A',
  BNB_CAKE_VAULT: '0x51e6303fAB90554d4FCCF71d8BcF2246999c9313',
  DUET_CAKE: '0xbdf0aa1d1985caa357a6ac6661d838da8691c569',
  DUET_CAKE_DYTOKEN: '0xf68B023a80F936F4A7b3a0e2772479c5097612f8',
  DUET_CAKE_VAULT: '0xecd30328108Fe62603705A56B5dF6757A2c9902E',
  // PancakeSwap 的 DUSD_BUSD 交易对，与配置中的 BUSD_DUSD 不同
  DUSD_BUSD: '0x4124A6dF3989834c6aCbEe502b7603d4030E18eC',
  DUSD_BUSD_DYTOKEN: '0x217b5345a48211b24Adc45fD1F13250020573d67',
  DUSD_BUSD_VAULT: '0xC703Fdad6cA5DF56bd729fef24157e196A4810f8',
  DUET: '0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
  DUET_DYTOKEN: '0x29FD5BdCa277eb5b8C31314e259C7fEAbd1bADd4',
  DUET_VAULT: '0x2b19468C5668c40DF22b9F2Bd335cbE6432970dE',
  DUET_DUSD: '0x33c8fb945d71746f448579559ea04479a23dff17',
  DUET_DUSD_DYTOKEN: '0x99b06e5C03DE28a5bfE124a13E6596708561C274',
  DUET_DUSD_VAULT: '0x4527Ba20F16F86525b6D174b6314502ca6D5256E',
  DWTI_DUSD: '0xf8f890e40a5f8d2455a5d7a007dd2170c1a72dd1',
  DWTI_DUSD_DYTOKEN: '0x0f68b977eb222dF9F736bcF70257c2f9c2BC64FB',
  DWTI_DUSD_VAULT: '0x519B4Cff883696e7bD2Fd21821221e9ea9680A83',
  DXAU_DUSD: '0xc21534dfc450977500648732f49b9b186bcb1386',
  DXAU_DUSD_DYTOKEN: '0x6C452bdf211353c817e9C4C035085468E7468d7A',
  DXAU_DUSD_VAULT: '0x91f02291a4aD9F99b04c37C626651703600273bd',
  DTMC_DUSD: '0xbdb2fbe2b635ee689e916e490673b0a8b9eb687b',
  DTMC_DUSD_DYTOKEN: '0x1b97552cca0F0e795a0e0EDA4EF332fC6C5e424f',
  DTMC_DUSD_VAULT: '0x6ec38848e56570f0192Ec1AEb0D2A30c908Ae6D9',

  DUSD: '0xe04fe47516C4Ebd56Bc6291b15D46A47535e736B',
  DUSD_VAULT: '0xcc8bBe47c0394AbbCA37fF0fb824eFDC79852377',
  // 我们系统中的 BUSD_DUSD 交易对
  BUSD_DUSD: '0xE8521b61f64Fc45A0bC3db36D2A524fe61a69b52',
  USDC_DUSD: '0x7d3762B09D010957A551Ad512fC0E53E6d3Fb914',

  OPE: '0x3EF826a9ABF18AE80fa86F636516B8123D072E92',
  OPE_VAULT: '0xf0df3198200DBE8051301063644Ab363B3eEE2b9',
  OPE_DYTOKEN: '0x1c97E6EA17656CeFAE24E4cd5D2b801451ff149D',
  // Bond
  DUET_BOND: '0x4bBfc602F5c8aCba36dd61DffbA86C55F52781C6',
  BOND_READER: '0xe9D755f78d0Af8d87E350FeBdb2ae5CED9A333C7',
  VAULT_FARM: '0x4fEDe27d81C10Ac3d9Be791702574bFd5a8ddf8F',

  // 合成资产
  DWTI: '0x587Fb3e1C6819fd54e3740C6C4C7832484eF451b',
  DWTI_VAULT: '0x9D90752b06efD43e1AB5e95c5728f08a53A60dFf',
  DXAU: '0x7E3b9B1313AD547feb71ac5BC48b03D7E683601E',
  DXAU_VAULT: '0xCcC091823CCFe88f60D0505fc9Bb5FaBDCAE726e',
  DTMC: '0x529AaFadba71386AB77519DA5eC75ab943dF0b6D',
  DTMC_VAULT: '0xab3069E7A658104D9203E640cB446072bC85C002',

  // 增强策略
  BUSD_ENHANCE_VAULT: '0x1E3174C5757cf5457f8A3A8c3E4a35Ed2d138322',
  BUSD_ENHANCE_DYTOKEN: '0x5F21818aeE05EB7eFC1c59792a224B2A5220efFc',

  // EbCake
  MULTI_REWARDS_MASTER_CHEF: '0xfd63c73330EfAfa1AcF937103798d826e77D54F3',
  DUET_BUSD: '',
  CAKE_BUSD: '0x804678fa97d91B974ec2af3c843270886528a9E6',

  // 715 赔偿合约
  RETRIEVE_TOKENS: '0x0b30F2e19a63f2dDF8eF87093f14284cB6Ae188f',

  DWTI_BUSD: '0x3ebd1b7a27dcc113639fce3a063d6083ef0ef56d',
  DXAU_BUSD: '0x95ca57ff396864c25520bc97deaae978daaf73f3',
  DTMC_BUSD: '0xf048897b35963aaed1a241512d26540c7ec42a60',
};

export default config;
