import Axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '@/config/baseUrl';

const instance = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

interface ResponseData {
  data: any;
  code: any;
  // errorCode: number;
  // msg: string;
}

// instance.interceptors.response.use((response: AxiosResponse<ResponseData>) => {
//   return response;
// });

const axios = instance;

export default axios;
