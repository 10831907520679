import BigNumber from 'bignumber.js';
import sdk from '@defillama/sdk';
import proPoolABI from './abis/pro-pool.json';
const PRO_POOL_CONTRACT = '0xdE57c591de8B3675C43fB955725b62e742b1c0B4';

export async function fetchArbitrumTVL() {
  try {
    const proPoolTvl = await sdk.api.abi.call({
      abi: proPoolABI.liquidity,
      chain: 'arbitrum',
      target: PRO_POOL_CONTRACT,
      params: [],
    });
    const tvl = new BigNumber(proPoolTvl.output).div(10 ** 18);
    return tvl.toNumber();
  } catch (e) {
    return 0;
  }
}
