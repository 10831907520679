import { FC, useEffect } from 'react';
import config from '@/config/config';
import useVaultPrice from '@/hooks/useVaultPrice';
import commifyBigNumber from '@/utils/commify';
import getCdnResource from '@/utils/getCdnResource';
import bunnyIcon from '@/assets/bunny.png';

import styles from './style.module.scss';
import UsdDisplay from '../UsdDisplay';

const PCS_URL = 'https://pancakeswap.finance/swap?inputCurrency=0x95ee03e1e2c5c4877f9a298f1c0d6c98698fab7b';

const DuetPriceMobile: FC = () => {
  const { price, refreshPrice } = useVaultPrice(config.DUET);
  const { price: dUSDPrice, refreshPrice: refreshDUSDPrice } = useVaultPrice(config.DUSD, 'Oracle');

  useEffect(() => {
    const interval = setInterval(() => {
      refreshPrice();
      refreshDUSDPrice();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshPrice, refreshDUSDPrice]);

  return (
    <div className={styles.BottomPlaceHolder}>
      <div className={styles.BottomArea}>
        <div className={styles.PriceRow}>
          <div className={styles.PriceAreaWrapper}>
            <img className={styles.DuetLogo} src={getCdnResource('/token/DUET.png')} alt="" />
            <span className={styles.DuetName}>DUET</span>
            <span className={styles.DuetPrice}>
              <UsdDisplay decimal={3}>{price}</UsdDisplay>
            </span>
          </div>
          <a className={styles.buyDuet} href={PCS_URL} target="_blank" rel="noreferrer">
            <img alt="pancake swap logo" src={bunnyIcon} className={styles.bunny} />
            <span>Buy $DUET</span>
            <i className="iconfont icon-jiantou" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DuetPriceMobile;
