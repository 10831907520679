import React from 'react';
import Mint from './components/Mint';
import Redeem from './components/Redeem';
import Tabs from '../../../../components/Tab';

const TradeDusd: React.FC = () => {
  return (
    <Tabs
      type="underline"
      tabs={[
        {
          key: 'Mint',
          label: 'Mint',
          content: <Mint />,
        },
        {
          key: 'Redeem',
          label: 'Redeem',
          content: <Redeem />,
        },
      ]}
      styleOverrides={{
        braceWholeLine: true,
        withBottomLine: true,
      }}
      style={{
        marginTop: 12,
      }}
    />
  );
};

export default TradeDusd;
