import { ContractInterface } from '@ethersproject/contracts';

export const FeeConfAbi: ContractInterface = [
  'constructor(address)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event SetConfig(bytes32,address,uint16)',
  'function getConfig(bytes32) view returns (address, uint256)',
  'function owner() view returns (address)',
  'function renounceOwnership()',
  'function setConfig(bytes32,address,uint16)',
  'function transferOwnership(address)',
];
