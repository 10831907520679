import { depositStrategyList } from '@/config/allLpTokens';
import { Strategy } from '@/config/types';
import { FC } from 'react';
import DepositTable from '../DepositTable';

const CommonDepositTable: FC<{ list: Strategy[] }> = ({ list }) => (
  <DepositTable depositType="common" strategyList={list} />
);

export default CommonDepositTable;
