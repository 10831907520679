import globalStore from '@/stores/global';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import config from '@/config/config';
import light from '@/assets/light.png';
import dark from '@/assets/dark.png';
import { changeThemeVal } from '@/App';
import { switchNetwork, Wallet, WalletEnum } from '@/utils/wallet';
import useIsMobile from '@/hooks/useIsMobile';
import { connectEagerlyManager, useWeb3 } from '@/web3';
import { NoSafeContext } from '@web3-react/gnosis-safe';
import WalletDetail from '../../WalletDetail.ts';
import WalletModal, { Network } from '../../WalletModal';
import styles from './style.module.scss';
import { networkConfigMap } from '../../../config/network';

const ConnectWallet: React.FC = () => {
  const { modalVisible } = globalStore();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const { isActive, account, chainId, connectors, connector } = useWeb3();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    if (!isActive || !account) {
      Sentry.setUser(null);
      console.log('window.hashmail', window.hashmail);
      window?.hashmail?.disconnect?.();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.hashmail?.identify?.(account);
      Sentry.setUser({ id: account ?? undefined, chainId });
    }
  }, [account, chainId, isActive]);

  const connectWallet = () => {
    globalStore.setState({
      modalVisible: true,
    });
  };

  const disconnectWallet = () => {
    setDetailModalVisible(true);
  };

  const handleSelectNetworkAndWallet = async (network: Network, wallet: Wallet) => {
    if (!connectors) return;
    if (network.chainId !== chainId) {
      await switchNetwork(network.chainId);
      globalStore.setState({
        modalVisible: false,
      });
    }

    /**
     * @NOTE
     * 就当前的设计，只允许一个活动的钱包存在。因此一旦某一个钱包激活需要确保断开旧的钱包
     */
    const activate = async () => {
      if (wallet.name === WalletEnum.gnosis) {
        if (!connectors.GnosisSafe.connector.sdk?.safe) throw new NoSafeContext();
        if (connector !== connectors.GnosisSafe.connector) await connector?.deactivate();
        await connectors.GnosisSafe.connector.activate();
        return;
      }
      if (wallet?.name === WalletEnum.bitkeep) {
        // @TODO: provide by typed module
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!window?.ethereum?.isBitKeep) {
          window.open('https://bitkeep.com/');
          return;
        }
        if (connector !== connectors.GnosisSafe.connector) await connector?.deactivate();
        await connectors.MetaMask.connector.activate(networkConfigMap[network.chainId]);
        return;
      }
      if (wallet.name === WalletEnum.metamask) {
        if (connector !== connectors.MetaMask.connector) await connector?.deactivate();
        await connectors.MetaMask.connector.activate(networkConfigMap[network.chainId]);
        return;
      }
      if (wallet.name === WalletEnum.walletconnect) {
        if (connector !== connectors.WalletConnect.connector) await connector?.deactivate();
        await connectors.WalletConnect.connector.activate(network.chainId);
        return;
      }
      await connector?.activate();
    };

    try {
      await activate();
      connectEagerlyManager.unsuppress();
    } catch (error) {
      console.error(error);
      /**
       * 默认用户是看到 Gnosis 是会用的，因此如果主动点击了 Gnosis 后发现不再对应沙箱环境中才跳转引导进入沙箱
       */
      if (wallet.name === WalletEnum.gnosis && error instanceof NoSafeContext) {
        window.open('https://gnosis-safe.io/app');
        return;
      }
    } finally {
      globalStore.setState({
        modalVisible: false,
      });
    }
  };

  const formatAccount = (accountStr: string) => `${accountStr.slice(0, 6)}...${accountStr.slice(-4)}`;

  const isMobile = useIsMobile();

  return (
    <>
      <section className={styles.WalletArea}>
        {isActive && account && chainId ? (
          <div className={styles.connectedDiv} onClick={disconnectWallet}>
            <div className={styles.account}>{formatAccount(account)}</div>
            <div className={styles.network}>{config.NETWORK_NAME}</div>
          </div>
        ) : (
          <div className={styles.connectDiv}>
            <button type="button" className={styles.button} onClick={connectWallet}>
              {isMobile ? 'Connect' : 'Connect Wallet'}
            </button>
          </div>
        )}
        <img
          className={styles.themeChange}
          alt=""
          src={theme === 'light' ? dark : light}
          onClick={() => {
            changeThemeVal();
            setTheme(localStorage.getItem('theme'));
          }}
        />
      </section>
      {modalVisible && (
        <Modal
          wrapClassName="customize-modal"
          maskClosable
          onCancel={() => {
            // setModalVisible(false);
            globalStore.setState({
              modalVisible: false,
            });
          }}
          visible
          title={null}
          footer={null}
          style={{ top: 200 }}>
          <WalletModal
            closeModal={() => {
              // setModalVisible(false);
              globalStore.setState({
                modalVisible: false,
              });
            }}
            onSelectNetworkAndWallet={handleSelectNetworkAndWallet}
          />
        </Modal>
      )}

      <Modal
        wrapClassName="customize-modal"
        maskClosable
        onCancel={() => setDetailModalVisible(false)}
        visible={detailModalVisible}
        title={null}
        footer={null}
        width={600}
        style={{ top: 200 }}>
        <WalletDetail closeModal={() => setDetailModalVisible(false)} />
      </Modal>
    </>
  );
};

export default ConnectWallet;

const BUTTON_WIDTH = 158;
const CONNECTED_WIDTH = 270;
const WalletArea = styled.div`
  @media (max-width: 768px) {
    margin-top: 0;
  }

  display: flex;
  margin-top: 35px;

  .connect-div {
    margin: 0 60px;
    padding: 2px;
    width: ${`${BUTTON_WIDTH}px`};
    height: 50px;
    border-radius: 25px;
    background-image: linear-gradient(315deg, rgba(84, 59, 240, 1), rgba(233, 181, 255, 1));

    @media (max-width: 768px) {
      margin: 0;
      height: 30px;
    }

    button {
      height: 46px;
      width: ${`${BUTTON_WIDTH - 4}px`};
      border-radius: 23px;
      border: none;
      background-color: #0c0b10;
      color: #d7d6d6;
      font-size: 16px;
      cursor: pointer;

      @media (max-width: 768px) {
        height: 26px;
      }
    }
  }

  .connected-div {
    position: relative;
    margin: 0 60px 0 20px;
    padding: 2px;
    /* width: ${`${CONNECTED_WIDTH}px`}; */
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;

    @media (max-width: 768px) {
      width: 150px;
      margin: 0;
    }

    .account {
      position: relative;
      height: 36px;
      /* width: ${`${CONNECTED_WIDTH - 4}px`}; */
      margin-right: 20px;
      border-radius: 23px;
      border: none;
      background-color: #0c0b10;
      color: #d7d6d6;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 20px;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 150px;
        margin-top: 5px;
        margin-right: 0px;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 13px;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #50e3c2;
      }
    }

    .network {
      @media (max-width: 768px) {
        display: none;
      }

      position: relative;
      top: -2px;
      padding: 0 10px;
      height: 40px;
      background: linear-gradient(315deg, #543bf0 0%, #e9b5ff 100%);
      border-radius: 25px;
      color: #232228;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
