import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import FlexRow from '../FlexRow';
import styles from './style.module.scss';

export interface PerTab<T> {
  key: T;
  label: ReactNode;
  content?: ReactNode;
  badge?: ReactNode;
  onClick?: () => Promise<boolean>;
  style?: CSSProperties;
}

type LabelStyle = 'default' | 'underline';
interface Props<T = string> {
  tabs: PerTab<T>[];
  type?: LabelStyle;
  onChange?: (key: T) => void;
  activeKey?: T;
  tabPosition?: 'start' | 'center';
  style?: CSSProperties;
  styleOverrides?: {
    braceWholeLine?: boolean;
    withBottomLine?: boolean;
  };
}

const labelStyleMap: Record<LabelStyle, string> = {
  default: styles.LabelWrapper,
  underline: styles.UnderlineLabelWrapper,
};

const Tabs = <T,>(props: Props<T>) => {
  const { tabs, type = 'default', onChange, activeKey, tabPosition = 'start', styleOverrides, style } = props;

  const [currentActiveKey, setCurrentActiveKey] = useState<T>();

  useEffect(() => {
    setCurrentActiveKey(tabs[0].key);
  }, []);

  const labelStyleName = labelStyleMap[type];

  const handleChange = (tab: PerTab<T>) => {
    const { onClick } = tab;
    if (onClick) {
      onClick().then(res => {
        if (!res) return;
        setCurrentActiveKey(tab.key);
        onChange?.(tab.key);
      });
    } else {
      setCurrentActiveKey(tab.key);
      onChange?.(tab.key);
    }
  };

  const theActiveKey = activeKey || currentActiveKey;

  return (
    <section className={styles.TabsWrapper} style={style}>
      <div
        className={classNames(styles.TabPositionWrapper, {
          [styles.FlexCenter]: tabPosition === 'center',
        })}>
        <FlexRow style={styleOverrides?.braceWholeLine ? { width: '100%' } : {}}>
          {tabs.map(tab => (
            <section
              key={'' + tab.key}
              onClick={() => handleChange(tab)}
              className={classNames(labelStyleName, {
                [styles.LabelWrapperTrue]: tab.key === theActiveKey,
                [styles.WithBottomLine]: styleOverrides?.withBottomLine,
              })}
              style={{
                ...(styleOverrides?.braceWholeLine && { flex: 1 }),
                ...tab.style,
              }}>
              {tab.label}
              {tab.badge && <div className={styles.Badge}>{tab.badge}</div>}
            </section>
          ))}
        </FlexRow>
      </div>
      <div>
        {tabs.map((tab, index) =>
          tab.content == null ? null : (
            <div key={index} hidden={theActiveKey !== tab.key}>
              <section className={styles.ContentWrapper}>{tab.content}</section>
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export default Tabs;
