import InfoCircle from '@/components/InfoCircle';
import Margin from '@/components/Margin';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/ModalHeader';
import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

interface Props {
  slippage: number;
  visible: boolean;
  onClose: () => void;
  setSlippage: (value: number) => void;
}

const slippageOptions = [
  {
    label: '0.1%',
    value: 0.001,
  },
  {
    label: '0.05%',
    value: 0.0005,
  },
  {
    label: '1.0%',
    value: 0.01,
  },
];

const SettingModal: FC<Props> = props => {
  const { visible, onClose, slippage, setSlippage } = props;

  const [input, setInput] = useState('');
  const [selectIndex, setSelectIndex] = useState(() => {
    const index = slippageOptions.findIndex(each => each.value === slippage);
    if (index !== -1) {
      return index;
    }
    setInput('' + slippage * 100);
    return -1;
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.currentTarget.value;
    if (newValue === '') {
      setInput('');
    }

    if (!/^([1-9]\d|\d)(?:\.\d{0,2})?$/.test(newValue)) {
      return;
    }

    setInput(newValue);
  };

  const handleClose = () => {
    if (selectIndex === -1) {
      setSlippage(+input / 100);
    }
    onClose();
  };

  if (!visible) return null;

  return (
    <Modal visible={visible} closeModal={() => handleClose()}>
      <SettingModalWrapper>
        <ModalHeader title="Setting" operations={[<i className="iconfont icon-guanbi1" onClick={handleClose} />]} />
        <Margin top={40} />
        <Slippage>
          <div className="title">
            Slippage Tolerance
            <InfoCircle text="slippage tolerance" />
          </div>
          <div className="option-row">
            {slippageOptions.map((option, index) => (
              <SlippageOption
                className={selectIndex === index ? 'active' : ''}
                onClick={() => {
                  setSelectIndex(index);
                  setSlippage(option.value);
                }}>
                {option.label}
              </SlippageOption>
            ))}
            <SlippageInput onClick={() => setSelectIndex(-1)}>
              <input value={input} onChange={handleChange} />
            </SlippageInput>
          </div>
        </Slippage>
      </SettingModalWrapper>
    </Modal>
  );
};

export default SettingModal;

const SettingModalWrapper = styled.div`
  width: 540px;
  background: var(--bg-main);
  border-radius: 24px 24px 24px 24px;
  opacity: 1;

  padding: 40px;
  margin: 50px auto;
  height: auto;
  opacity: 1;
  color: white;

  @media (max-width: 768px) {
    padding: 20px;
    width: calc(100% - 20px);
  }
`;

const Slippage = styled.div`
  font-size: 18px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: #9195a5;

  .option-row {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const SlippageOption = styled.div`
  height: 40px;
  background: rgba(97, 59, 244, 0.1);
  border-radius: 20px 20px 20px 20px;
  color: #613bf4;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  &.active {
    background: #613bf4;
    color: #ffffff;
  }
`;

const SlippageInput = styled.div`
  width: 90px;
  height: 40px;
  background: var(--bg-input);
  border-radius: 24px 24px 24px 24px;
  border: 2px solid #613bf4;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  input {
    width: 50px;
    outline: none;
    background: rgba(0, 0, 0, 0);
    border: 0;
    color: var(--text-main);
    font-size: 16px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
  }

  &::after {
    content: '%';
    color: #613bf4;
  }
`;
