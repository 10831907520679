import config from './config';

export const dodoLpPairs = [
  {
    token0: {
      symbol: 'dWTI',
      address: config.DWTI,
    },
    token1: {
      symbol: 'BUSD',
      address: config.BUSD,
    },
    lpToken: {
      symbol: 'dWTI-BUSD',
      address: '0xC104ab269303BF5606F118914aFf8A25F7498313',
    },
    dppController: '0xC104ab269303BF5606F118914aFf8A25F7498313',
  },
];
