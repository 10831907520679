import type { TransactionResponse } from '@ethersproject/providers';
import { captureException } from '@sentry/browser';
import { txFail, txPending, txSuccess } from './txNotifications';

const executeAndShowTx = async (
  promise: Promise<any>,
  {
    throwError = false,
    onError,
  }: {
    onError?: (error: unknown) => void;
    throwError?: boolean;
  } = {},
) => {
  let tx;
  try {
    tx = (await promise) as TransactionResponse;
    txPending(tx.chainId, tx.hash);

    await tx.wait();
    txSuccess(tx.chainId, tx.hash);
  } catch (err) {
    captureException(err);
    onError?.(err);

    if (tx) {
      txFail(tx.chainId, tx.hash);
    }
    if (throwError) {
      throw err;
    } else {
      console.error('transaction fail: ', err);
    }
  }
};

export default executeAndShowTx;
