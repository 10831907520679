import styled from '@emotion/styled';
import { FC } from 'react';

interface Props {
  // 例如 60.35%，传入 60.35 而不是传 0.6035
  children: number | null | undefined;
}

const PercentDisplay: FC<Props> = props => {
  const { children } = props;

  if (children == null) return <>-</>;

  if (children < 0) return <>0.00%</>;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <PercentDisplayWrapper>{children.toFixed(2) + '%'}</PercentDisplayWrapper>;
};

export default PercentDisplay;

const PercentDisplayWrapper = styled.span`
  font-family: Roboto;
  font-weight: 500;
`;
