/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useState } from 'react';
import { Contract, ethers, utils } from 'ethers';
import getTokens from '@/constants/tokenList';
import SelectToken from '@/components/SelectToken';
import TokenInput from '@/components/TokenInput';
import useTokenBalance from '@/components/TokenInput/useTokenBalance';
import DButton from '@/components/DButton';
import { Token } from '@/types/token';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { useWeb3 } from '@/web3';
import { getPairAddressBySymbols } from './tokenPairAddress';
import useAmount from './useAmount';
import FeeContent from './FeeContent';
import { injectContractDuetUsdMinerPair } from '../../../../../contracts';
import { TradeLayout } from './Layout';
import { useTokenData } from '../../../context';

const { parseEther } = utils;

const LEFT_TOKEN_SYMBOLS = ['BUSD', 'USDC'];
const RIGHT_TOKEN_SYMBOLS = ['dUSD'];

const Mint: React.FC = () => {
  const { account, chainId, contractContainer } = useWeb3();

  const tokenList = useMemo(() => getTokens(chainId) || [], [chainId]);

  const { refreshData } = useTokenData();
  const [leftToken, setLeftToken] = useState<Token>();
  const [rightToken, setRightToken] = useState<Token>();
  const [pairContract, setPairContract] = useState<Contract>();
  const [pairContractAddress, setPairContractAddress] = useState<string>();

  const {
    amountIn,
    amountOut,
    amountOutFee,
    setAmountIn,
    setAmountOut,
    setAmountOutFee,
    onAmountInChange,
    onAmountOutChange,
  } = useAmount({
    pairContract,
    account,
  });

  useEffect(() => {
    const BUSDToken = tokenList.find(token => token.symbol === 'BUSD');
    setLeftToken(BUSDToken);

    const DUSDToken = tokenList.find(token => token.symbol === 'dUSD');
    setRightToken(DUSDToken);
  }, [tokenList]);

  // 每当 leftToken 或 rightToken 变化，则代币对合约随之变化
  useEffect(() => {
    if (!leftToken || !rightToken || !chainId || !contractContainer) return;
    const pairAddress = getPairAddressBySymbols(chainId, leftToken.symbol, rightToken?.symbol);
    const contract = injectContractDuetUsdMinerPair(contractContainer, pairAddress);
    setPairContract(contract);
    setPairContractAddress(pairAddress);
  }, [leftToken, rightToken, chainId]);

  const { balance: leftTokenBalance, refreshBalance: refreshLeftTokenBalance } = useTokenBalance({
    tokenAddress: leftToken?.address,
    account,
  });

  const { balance: rightTokenBalance, refreshBalance: refreshRightTokenBalance } = useTokenBalance({
    tokenAddress: rightToken?.address,
    account,
  });

  const canMint = useMemo(
    () => leftTokenBalance && amountIn && ethers.utils.parseEther(amountIn).lte(leftTokenBalance),
    [leftTokenBalance, amountIn],
  );

  const mint = async () => {
    if (!pairContract || !account || !contractContainer) return;

    const contract = pairContract.connect(contractContainer.getSigner(account));
    const promise = await contract.mineDusd(parseEther(amountIn), parseEther(amountOut), account);

    await executeAndShowTx(promise);

    refreshLeftTokenBalance();
    refreshRightTokenBalance();
    refreshData();
    clearInput();
  };

  const clearInput = () => {
    setAmountIn('');
    setAmountOut('');
    setAmountOutFee('');
  };

  const onLeftTokenChange = (token: Token) => {
    setLeftToken(token);
    clearInput();
  };

  const leftTokenList = tokenList.filter(token => LEFT_TOKEN_SYMBOLS.includes(token.symbol));
  const rightTokenList = tokenList.filter(token => RIGHT_TOKEN_SYMBOLS.includes(token.symbol));

  const rate = useMemo(() => {
    if (!amountOut || !amountOutFee) {
      return '';
    }
    return `1${leftToken?.symbol || ''} = ${(+amountOut / +amountIn).toFixed(4)}${rightToken?.symbol || ''}`;
  }, [leftToken, rightToken, amountIn, amountOut, amountOutFee]);

  const fee = useMemo(() => {
    if (!amountOutFee || !leftToken) {
      return '';
    }
    return `${amountOutFee}${leftToken?.symbol}`;
  }, [amountOutFee, leftToken]);

  return (
    <TradeLayout
      leftHand={
        <TokenInput showClear showMax value={amountIn} onChange={onAmountInChange} balance={leftTokenBalance}>
          <SelectToken selectList={leftTokenList} onChange={onLeftTokenChange} tokenSymbol={leftToken?.symbol} />
        </TokenInput>
      }
      rightHand={
        <TokenInput
          showInfo
          infoContent={<FeeContent rate={rate} fee={fee} />}
          value={amountOut}
          balance={rightTokenBalance}
          onChange={onAmountOutChange}>
          <SelectToken selectList={rightTokenList} onChange={setRightToken} tokenSymbol={rightToken?.symbol} />
        </TokenInput>
      }
      approve={
        <DButton onClick={mint} disabled={!canMint} approveFrom={leftToken?.address} approveTo={pairContractAddress}>
          Mint
        </DButton>
      }
    />
  );
};

export default Mint;
