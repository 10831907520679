import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import styleSelectToken from '../../../../../components/SelectToken/style.module.scss';
import styleTokenInput from '../../../../../components/TokenInput/style.module.scss';

export const TradeLayout: FC<{ [x in 'leftHand' | 'rightHand' | 'approve']: ReactNode }> = ({
  leftHand,
  rightHand,
  approve,
}) => (
  <ContentWrapper style={{ marginTop: 16 }}>
    <LeftArea>{leftHand}</LeftArea>
    <MiddleArrow />
    <RightArea>{rightHand}</RightArea>

    <ButtonRow style={{ marginTop: 14 }}>{approve}</ButtonRow>
  </ContentWrapper>
);

const deviceAdaption = css`
  @media (max-width: 768px) {
    .tooltip,
    .icon-guanbi,
    .icon-tishi {
      display: none !important;
    }
    & .${styleTokenInput.inputRow} {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 550px) {
    & .${styleSelectToken.TokenArea} {
      & .${styleSelectToken.tokenSymbol} {
        margin-left: 8px;
      }
      & .icon-xialajiantou {
        margin-left: 6px;
      }
    }
    & .${styleTokenInput.inputRow} {
      input.${styleTokenInput.input} {
        width: 100%;
        font-size: 16px;
        padding: 0;
        margin-left: 6px;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
  margin-top: 12px;
  ${deviceAdaption}
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
  margin-bottom: 12px;
  ${deviceAdaption}
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiddleArrow: FC = () => {
  return (
    <MiddleContainer>
      <svg aria-hidden>
        <use xlinkHref="#icon-jiantou1" />
      </svg>
    </MiddleContainer>
  );
};

const MiddleContainer = styled.figure`
  transform: rotate(90deg);
  width: 42px;
  height: 42px;
  background-color: var(--bg-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 8px auto;
  opacity: 0.9;

  & > svg {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
  }
`;
