import { FC } from 'react';
import bunnyIcon from '@/assets/bunny.png';
import styles from './style.module.scss';

const PCS_URL = 'https://pancakeswap.finance/swap?inputCurrency=0x95ee03e1e2c5c4877f9a298f1c0d6c98698fab7b';

const BuyCoin: FC = () => (
  <div className={styles.BuyCoinWrapper}>
    <a target="_blank" href={PCS_URL} className={styles.pcs} rel="noreferrer">
      <img src={bunnyIcon} className={styles.ellipsisImg} alt="ellipsis" />
      <span className={styles.ellipsisText}>Buy $DUET on Pancakeswap</span>
      <span />
    </a>
  </div>
);

export default BuyCoin;
