import create from 'zustand';

type State = {
  // 颜色模式
  dataTheme: string;
  // 链接钱包弹窗
  modalVisible: boolean;
  // 接口随机数
  userNonce: string;
  // 邀请页签名
  signature: string;
  // 邀请页用户验证码
  invitationsUserVcodeData: any;
  // 邀请页用户信息
  invitationUserInfo: any;
  // 如果该用户信息没有被存储则重新获取
  needGetNonce: boolean;
};

const globalStore = create<State>(() => ({
  dataTheme: 'dark',
  modalVisible: false,
  userNonce: '',
  signature: '',
  invitationsUserVcodeData: '',
  invitationUserInfo: {},
  needGetNonce: false,
}));

export default globalStore;
