import { ContractInterface } from 'ethers';

export const VaultFarmAbi: ContractInterface = [
  'constructor()',
  'event NewPool(address,address)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event VaultApproved(address,bool)',
  'function allocPoint(address) view returns (uint256)',
  'function appendReward(address,uint256)',
  'function approveVault(address,bool)',
  'function assetPool(address) view returns (address)',
  'function assetPoolAlloc(address) view returns (address, uint256)',
  'function bond() view returns (address)',
  'function emergencyWithdraw(address[],uint256[])',
  'function epochRewards(uint256) view returns (uint256)',
  'function epoches(uint256) view returns (address)',
  'function epochesRewards() view returns (address[], uint256[])',
  'function getPools() view returns (address[])',
  'function initialize(address,address)',
  'function lastUpdateSecond() view returns (uint256)',
  'function massUpdatePools(address[],uint256[])',
  'function newPool(uint256,address)',
  'function newReward(address[],uint256[],uint256)',
  'function owner() view returns (address)',
  'function periodFinish() view returns (uint256)',
  'function poolImp() view returns (address)',
  'function pools(uint256) view returns (address)',
  'function removePoolEpoch(address,address)',
  'function renounceOwnership()',
  'function setPoolImp(address)',
  'function syncDeposit(address,uint256,address)',
  'function syncLiquidate(address,address)',
  'function syncVault(address)',
  'function syncWithdraw(address,uint256,address)',
  'function totalAllocPoint() view returns (uint256)',
  'function transferOwnership(address)',
  'function updatePool(uint256,address)',
  'function vaults(address) view returns (bool)',
  'function withdrawAward(address[],address,bool)',
];
