import { ethers } from 'ethers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { depositArchivedStrategyList, depositInnovationStrategyList, depositStrategyList } from '@/config/allLpTokens';
import useVaultBalance from '@/hooks/useVaultBalance';
import useVaultPrice from '@/hooks/useVaultPrice';
import useERC20Balance from '@/hooks/useERC20Balance';
import { useVaultInfo } from '@/components/MyVault/context';
import { useWeb3 } from '@/web3';
import DataContext, { TokenData } from './context';
import styles from './style.module.scss';
import CommonDepositDetail from './CommonDepositDetail';
import InnovationDepositDetail from './InnovationDepositDetail';
import { getApyInfoPeriodMap, ApyInfoPeriodMap } from './server';

const DepositDetail = () => {
  const { address } = useParams();
  const { chainId } = useWeb3();

  const [apyInfoPeriodMap, setApyInfoPeriodMap] = useState<ApyInfoPeriodMap | null>(null);

  const isInnovation = window.location.pathname.includes('innovation-deposit-detail');
  const isArchived = window.location.pathname.includes('archived-deposit-detail');

  // 通过 address 获取对应的 lpToken
  const currentStrategy = useMemo(() => {
    if (isInnovation) {
      return depositInnovationStrategyList.find(each => each.address === address);
    }
    if (isArchived) {
      return depositArchivedStrategyList.find(each => each.address === address);
    }
    return depositStrategyList.find(each => each.address === address);
  }, [address, chainId]);
  const vaultAddress = currentStrategy?.vaultAddress;

  useEffect(() => {
    if (!address || !vaultAddress) {
      setApyInfoPeriodMap(null);
      return;
    }

    getApyInfoPeriodMap(address, vaultAddress, { ...(isInnovation && { totalApyAmendValue: -1 }) }).then(res => {
      setApyInfoPeriodMap(res);
    });
  }, [address, vaultAddress, isInnovation]);

  const pageTokenVaultAddress = currentStrategy?.vaultAddress;
  const { vaultBalance, refreshVaultBalance } = useVaultBalance({
    vaultAddress: pageTokenVaultAddress,
  });
  const { price } = useVaultPrice(address);
  const { refreshVaultInfo } = useVaultInfo();

  const { accountBalance, refreshAccountBalance } = useERC20Balance(address);

  const apyInfo = useMemo(() => {
    if (apyInfoPeriodMap && apyInfoPeriodMap.today) {
      return apyInfoPeriodMap.today;
    }
    return null;
  }, [apyInfoPeriodMap]);

  const tokenData: TokenData = useMemo(
    () => ({
      vaultBalance,
      price,
      walletBalance: accountBalance,
      apyInfoPeriodMap,
      apyInfo,
    }),
    [vaultBalance, price, accountBalance, apyInfoPeriodMap, apyInfo],
  );
  const refreshData = useCallback(() => {
    refreshVaultBalance();
    refreshAccountBalance();
    refreshVaultInfo();
  }, [refreshVaultBalance, refreshAccountBalance, refreshVaultInfo]);

  const context = useMemo(
    () => ({
      tokenData,
      refreshData,
      strategy: currentStrategy,
    }),
    [tokenData, refreshData],
  );

  if (!address || !ethers.utils.isAddress(address)) return null;

  if (!currentStrategy) {
    console.error('Strategy Not Found');
    return null;
  }

  return (
    <section className={styles.DepositDetailWrapper}>
      <DataContext.Provider value={context}>
        {!isInnovation && <CommonDepositDetail strategy={currentStrategy} />}
        {isInnovation && <InnovationDepositDetail strategy={currentStrategy} />}
      </DataContext.Provider>
    </section>
  );
};

export default DepositDetail;
