import { DodoLpPair } from '../types';
import config from './config';

export const dodoLpPairs: DodoLpPair[] = [
  {
    token0: {
      symbol: 'dTMC',
      address: config.DTMC,
    },
    token1: {
      symbol: 'BUSD',
      address: config.BUSD,
    },
    lpToken: {
      symbol: 'dTMC-BUSD',
      address: '0xa63c7491176b271cb62fa3f7932dd86ffeba0ff4',
    },
    dppController: '0xa63c7491176b271cb62fa3f7932dd86ffeba0ff4',
  },
  {
    token0: {
      symbol: 'dWTI',
      address: config.DWTI,
    },
    token1: {
      symbol: 'BUSD',
      address: config.BUSD,
    },
    lpToken: {
      symbol: 'dWTI-BUSD',
      address: '0x9f3dcc4e96de4bb63c9f0b5e7b8038522e6ec2ef',
    },
    dppController: '0x9f3dcc4e96de4bb63c9f0b5e7b8038522e6ec2ef',
  },
  {
    token0: {
      symbol: 'dXAU',
      address: config.DXAU,
    },
    token1: {
      symbol: 'BUSD',
      address: config.BUSD,
    },
    lpToken: {
      symbol: 'dXAU-BUSD',
      address: '0x8367acf2934feaf9610b45c80b904f4e992f5b70',
    },
    dppController: '0x8367acf2934feaf9610b45c80b904f4e992f5b70',
  },
];
