import { ContractInterface } from 'ethers';

export const AppControllerAbi: ContractInterface = [
  'constructor()',
  'event AllowedLiquidatorChanged(address,bool)',
  'event CollateralRateChanged(uint256)',
  'event DTokenVaultChanged(address indexed,address,address,uint256)',
  'event InitValidVault(address,uint8)',
  'event LiquidateRateChanged(uint256)',
  'event OpenLiquidateChanged(bool)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event SetValidVault(address,address,uint8)',
  'event SetVaultStates(address,tuple(bool,bool,bool,bool,bool,bool))',
  'event UnderlyingDTokenChanged(address indexed,address,address)',
  'event UnderlyingStrategyChanged(address indexed,address,address,uint256)',
  'event ValueConfChanged(address indexed,address,uint256,uint256)',
  'function allowedLiquidator(address) view returns (bool)',
  'function beforeBorrow(address,address,uint256) view',
  'function beforeDeposit(address,address,uint256) view',
  'function beforeRepay(address,address,uint256) view',
  'function beforeWithdraw(address,address,uint256) view',
  'function collateralRate() view returns (uint256)',
  'function dyTokenVaults(address) view returns (address)',
  'function dyTokens(address) view returns (address)',
  'function emergencyWithdrawAll(address)',
  'function exitVault(address,bool)',
  'function getValueConf(address) view returns (address, uint16, uint16)',
  'function getValueConfs(address,address) view returns (address, uint16, uint16, address, uint16, uint16)',
  'function initValidVault(address[],uint8[])',
  'function initialize()',
  'function isNeedLiquidate(address) view returns (bool)',
  'function isOpenLiquidate() view returns (bool)',
  'function joinVault(address,bool)',
  'function liquidate(address,bytes)',
  'function liquidateRate() view returns (uint256)',
  'function maxBorrow(address,address) view returns (uint256)',
  'function owner() view returns (address)',
  'function renounceOwnership()',
  'function setCollateralRate(uint256)',
  'function setDYToken(address,address)',
  'function setLiquidateRate(uint256)',
  'function setOpenLiquidate(bool)',
  'function setOracles(address,address,uint16,uint16)',
  'function setStrategy(address,address,uint256)',
  'function setValidVault(address[],uint8[])',
  'function setVault(address,address,uint256)',
  'function setVaultStates(address,tuple(bool,bool,bool,bool,bool,bool))',
  'function strategies(address) view returns (address)',
  'function transferOwnership(address)',
  'function updateAllowedLiquidator(address,bool)',
  'function userJoinedVaultCount(address,bool) view returns (uint256)',
  'function userJoinedVaultInfoAt(address,bool,uint256) view returns (address, tuple(bool,bool,bool,bool,bool,bool))',
  'function userPendingValues(address,address,int256,bool) view returns (uint256, uint256)',
  'function userTotalPendingValues(address,address,int256,bool) view returns (uint256, uint256)',
  'function userTotalValues(address,bool) view returns (uint256, uint256)',
  'function userValues(address,bool) view returns (uint256, uint256)',
  'function validVaults(address) view returns (uint8)',
  'function validVaultsOfUser(address,address) view returns (uint8)',
  'function vaultStates(address) view returns (bool, bool, bool, bool, bool, bool)',
];
