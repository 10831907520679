import styles from './style.module.scss';

interface IProps {
  totalLocked?: string | number;
  hoursVolume?: string | number;
}

const TotalLocked = ({ totalLocked, hoursVolume }: IProps) => {
  return (
    <section className={styles.totalLocked}>
      {/* Total Value Locked */}
      <section className={styles.content}>
        <span className={styles.title}>Total Value Locked</span>
        <div className={styles.value}>
          <span className={styles.text}>{totalLocked}</span>
          <span className={styles.unit}>$DUET</span>
        </div>
      </section>
      {/* 24hours VOLUME */}
      <section className={styles.content}>
        <span className={styles.title}>24hours VOLUME</span>
        <div className={styles.value}>
          <span className={styles.text}>{hoursVolume !== '--' ? `$${hoursVolume}` : '--'}</span>
        </div>
      </section>
    </section>
  );
};

export default TotalLocked;
