import { Empty, Table, Tabs as AntdTabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import hotFireImg from '@/assets/hot.png';
import { TokenType } from '@/config/allLpTokens';
import commifyBigNumber from '@/utils/commify';
import InfoCircle from '@/components/InfoCircle';
import FlexRow from '@/components/FlexRow';
import ListView from '@/components/ListView';
import yellowStarImg from '@/assets/yellow-star.png';
import Tabs from '@/components/Tab';
import { Strategy } from '@/config/types';
import classNames from 'classnames';
import { useWeb3 } from '@/web3';
import { ApyInfo } from '@/pages/DepositDetail/server';
import getCdnResource from '@/utils/getCdnResource';
import UsdDisplay from '@/components/UsdDisplay';
import { EbCake, getEbCakeInfo } from '@/pages/EbCake/server';
import PercentDisplay from '@/components/PercentDisplay';
import { ebCake } from '@/pages/EbCake/config';
import { useEbcakeAPR } from '@/hooks/useEbcakeAPR';
import { useVaultInfo } from '@/components/MyVault/context';
import NewBadge from '@/components/NewBadge';
import styled from '@emotion/styled';
import config from '@/config/config';
import { IContractContainer, injectContractReader } from '../../../../contracts';
import styles from './style.module.scss';
import { getApyInfoVaultMap } from './server';
import '@/pages/common/styles/style.module.scss';

enum TokenEnum {
  Stablecoin = 'stablecoin', // None Yield
  LP = 'lp', // Yield
  All = 'all',
}

interface TableToken {
  symbol: string;
  apy: string;
  totalLiquidity: string;
  balance: string;
  address: string;
  vaultAddress: string;
  image?: string;
  type: TokenType;
  incentive?: boolean;
  onFire?: boolean;
  compound?: boolean;
  isNew?: boolean;
}

const percentDisplay = (num: number) => {
  if (!num) return '-';
  if (num < 0) return '0.00%';
  return `${num.toFixed(2)}%`;
};

type DepositType = 'common' | 'enhanced' | 'archived';

const RouteMap: Record<DepositType, string> = {
  common: 'deposit-detail',
  enhanced: 'innovation-deposit-detail',
  archived: 'archived-deposit-detail',
};
interface Props {
  depositType: DepositType;
  strategyList: Strategy[];
}

const DepositTable: React.FC<Props> = props => {
  const { strategyList, depositType } = props;

  const allTokenAddress = strategyList.map(each => each.address);
  const allTokenVaultAddress = strategyList.map(each => each.vaultAddress);
  const isInnovation = depositType === 'enhanced';

  const [apyInfoVaultMap, setApyInfoVaultMap] = useState<Record<string, ApyInfo> | null>(null);

  const navigate = useNavigate();

  const { account, contractContainer } = useWeb3();

  const [tableTokens, setTableTokens] = useState<TableToken[]>([]);
  const [filteredTableTokens, setFilteredTableTokens] = useState<TableToken[]>([]);
  const [tableTokenLoading, setTableTokenLoading] = useState(false);

  const [dataType, setDataType] = useState(TokenEnum.All);
  const [tokenTVLMap, setTokenTVLMap] = useState<Record<string, BigNumber>>();
  const [userVaultBalanceMap, setUserVaultBalanceMap] = useState<Record<string, BigNumber>>();
  const [ebCakeInfo, setEbcakeInfo] = useState<EbCake | null>(null);

  // 获取 ebCakeInfo
  useEffect(() => {
    if (depositType !== 'common') return;
    if (!ebCake || !contractContainer) return;
    (async () => {
      const ebcakeTemp = await getEbCakeInfo(contractContainer, ebCake);
      setEbcakeInfo(ebcakeTemp);
    })();
  }, [contractContainer, ebCake]);

  const ebCakeAPR = useEbcakeAPR(ebCake);
  const ebCakeDisplayAPY = useMemo(() => {
    if (ebCakeInfo?.yield?.bDuetAPY == null || ebCakeInfo?.yield?.bDuetLpAPY == null) return null;
    if (ebCakeAPR == null) return null;
    const singleTotalAPR = ebCakeInfo.yield.bDuetAPY + ebCakeAPR;
    const lpTotalAPR = ebCakeInfo.yield.bDuetLpAPY + ebCakeAPR;

    return Math.max(singleTotalAPR, lpTotalAPR);
  }, [ebCakeInfo, ebCakeAPR]);

  useEffect(() => {
    if (dataType === TokenEnum.All) {
      setFilteredTableTokens(tableTokens);
    } else if (dataType === TokenEnum.Stablecoin) {
      setFilteredTableTokens(tableTokens.filter(each => each.type === TokenType.SINGLE));
    } else if (dataType === TokenEnum.LP) {
      setFilteredTableTokens(tableTokens.filter(each => each.type === TokenType.LP));
    } else {
      setFilteredTableTokens([]);
    }
  }, [tableTokens, dataType]);

  const getTokenTVLMap = async (container: IContractContainer) => {
    const contract = injectContractReader(container);
    const res: BigNumber[][] = await contract.depositVaultValues(allTokenVaultAddress, false);

    const tokenTVLMapTemp: Record<string, BigNumber> = allTokenAddress.reduce(
      (a, c, index) => ({
        ...a,
        [allTokenVaultAddress[index] === config.BUSD_ENHANCE_VAULT ? 'sBUSD' : c]: res[1][index],
      }),
      {},
    );
    setTokenTVLMap(tokenTVLMapTemp);
  };

  const getUserBalanceMap = async (container: IContractContainer) => {
    if (!account) return;
    const contract = injectContractReader(container);
    const res: BigNumber[] = await contract.userVaultValues(account, allTokenVaultAddress, false);

    const tokenTVLMapTemp: Record<string, BigNumber> = allTokenAddress.reduce(
      (a, c, index) => ({
        ...a,
        [c]: res[index],
      }),
      {},
    );
    setUserVaultBalanceMap(tokenTVLMapTemp);
  };

  useEffect(() => {
    if (strategyList.length === 0) {
      setTableTokenLoading(true);
      return;
    }
    if (!contractContainer) return;
    setTableTokenLoading(true);
    Promise.all([getTokenTVLMap(contractContainer), getUserBalanceMap(contractContainer)]).finally(() => {
      setTableTokenLoading(false);
    });
  }, [strategyList, contractContainer]);

  useEffect(() => {
    getApyInfoVaultMap({ ...(isInnovation && { totalApyAmendValue: -1 }) }).then(res => {
      setApyInfoVaultMap(res);
    });
  }, [isInnovation]);

  // 获取用户在每个 vault 中的存款数量
  useEffect(() => {
    // if (!account) return;
    if (strategyList.length === 0) return;

    (async () => {
      const tempTokens = strategyList.map(each => ({
        symbol: each.symbol,
        image: each.image,
        balance: '',
        apy: '',
        totalLiquidity: '',
        address: each.address,
        type: each.singleOrLp,
        incentive: each.incentive,
        onFire: each.onFire,
        compound: each.compound,
        vaultAddress: each.vaultAddress,
        isNew: each.isNew,
      }));

      if (depositType === 'common') {
        const ebCakeStrategy = {
          symbol: 'CAKE',
          image: getCdnResource('/token/CAKE.png'),
          balance: '',
          apy: '',
          totalLiquidity: '',
          address: '',
          type: TokenType.SINGLE,
          incentive: false,
          onFire: true,
          compound: false,
          vaultAddress: '',
          isNew: true,
        };
        setTableTokens([ebCakeStrategy, ...tempTokens]);
        return;
      }

      setTableTokens(tempTokens);
    })();
  }, [strategyList, account]);

  const renderApy = () => {
    return '0';
  };

  const renderApyDeprecated = (token: TableToken) => {
    if (token.symbol === 'CAKE' && depositType === 'common') {
      if (!ebCakeDisplayAPY) return '-';
      return (
        <section className={styles.TableText}>
          <span style={{ color: '#FF934D' }}>{percentDisplay(ebCakeDisplayAPY)}</span>
          {token.onFire && <img className={styles.YellowStar} src={hotFireImg} alt="" />}
        </section>
      );
    }

    if (!token.incentive && !token.compound) {
      return (
        <section className={styles.TableText}>
          N/A
          <InfoCircle text="The token generates no yield due to the unavailability of appropriate farm pools." />
        </section>
      );
    }

    let apyInfo = {
      apy: '-',
      transactionFee: '-',
      baseApr: '-',
      farmYield: '-',
      apyIncrease: '-',
    };
    if (apyInfoVaultMap && apyInfoVaultMap[token.vaultAddress.toLocaleLowerCase()]) {
      const info = apyInfoVaultMap[token.vaultAddress.toLocaleLowerCase()];
      apyInfo = {
        apy: percentDisplay(info.totalApy),
        transactionFee: percentDisplay(info.swapFeeApr),
        baseApr: percentDisplay(info.swapFarmApr),
        farmYield: percentDisplay(info.duetFarmApr),
        apyIncrease: percentDisplay(info.boostedApy),
      };
    }

    return (
      <section className={styles.TableText}>
        <span
          className={token.incentive ? styles.APYInfoYellow : styles.APYInfo}
          style={token.onFire ? { color: '#FF934D' } : {}}>
          {apyInfo.apy}
        </span>
        {token.onFire && <img className={styles.YellowStar} src={hotFireImg} alt="" />}
        {!token.onFire && token.incentive && <img className={styles.YellowStar} src={yellowStarImg} alt="" />}
        <InfoCircle
          text={
            <div>
              <div>Swap Fee Yield : {apyInfo.transactionFee}</div>
              <div>Pancake Farm Yield: {apyInfo.baseApr}</div>
              <div>Duet Farm Yield: {apyInfo.farmYield}</div>
              <div>Strategy boost: {apyInfo.apyIncrease}</div>
            </div>
          }
        />
      </section>
    );
  };

  const { vaultInfo } = useVaultInfo();
  const renderTVL = (token: TableToken) => {
    if (token.vaultAddress === config.BUSD_ENHANCE_VAULT) {
      return (
        <section className={styles.TableText}>
          <UsdDisplay decimal={0}>{tokenTVLMap?.sBUSD || null}</UsdDisplay>
        </section>
      );
    }

    if (token.symbol === 'CAKE' && depositType === 'common') {
      return <UsdDisplay decimal={0}>{vaultInfo.ebCakeTVL}</UsdDisplay>;
    }
    return (
      <section className={styles.TableText}>
        <UsdDisplay decimal={0}>{tokenTVLMap?.[token.address] || null}</UsdDisplay>
      </section>
    );
  };

  const renderBalance = (token: TableToken) => (
    <section className={styles.TableText}>
      <UsdDisplay decimal={0}>{userVaultBalanceMap?.[token.address] || null}</UsdDisplay>
    </section>
  );

  const columns: ColumnsType<TableToken> = [
    {
      title: 'Assets',
      key: 'symbol',
      dataIndex: 'symbol',
      render: (symbol, item) => (
        <section className={styles.NameItem}>
          <section className={styles.ImgContent}>
            <img
              className={classNames(styles.ImgSelf, item.type === TokenType.LP ? styles.LpTokenImg : '')}
              src={item.image}
              alt=""
            />
          </section>
          <section className={styles.AssetsNameContent}>
            {item.vaultAddress === config.BUSD_ENHANCE_VAULT ? 'smart BUSD' : symbol}
          </section>
          {item.isNew && (
            <NewWrapper>
              <NewBadge />
            </NewWrapper>
          )}
        </section>
      ),
    },
    {
      title: 'APY',
      key: 'apy',
      dataIndex: 'apy',
      render: (APY, record) => {
        return renderApy(record);
      },
    },
    {
      title: 'Total Value Locked',
      key: 'tvl',
      dataIndex: 'tvl',
      render: (tvl, record) => renderTVL(record),
    },
    {
      title: 'Your Balance',
      key: 'balance',
      dataIndex: 'balance',
      render: (balance, record) => renderBalance(record),
    },
  ];

  const jumpToDetail = (record: TableToken) => {
    if (record.vaultAddress === config.BUSD_ENHANCE_VAULT) {
      navigate(`/innovation-deposit-detail/${record.address}`);
      return;
    }
    if (record.symbol === 'CAKE' && depositType === 'common') {
      navigate(`/ebcake/${import.meta.env.VITE_EBCAKE_GROUP || 'beta'}`);
      return;
    }

    const route = RouteMap[depositType];
    navigate(`/${route}/${record.address}`);
  };

  const getRowClassName = (record: TableToken) => (record.incentive ? 'enhanced' : '');

  return (
    <section className={styles.DepositTableWrapper}>
      {depositType === 'common' && (
        <Tabs
          type="underline"
          tabs={[
            {
              key: TokenEnum.All,
              label: 'All',
              content: '',
            },
            {
              key: TokenEnum.Stablecoin,
              label: 'Single Assets',
              content: '',
            },
            {
              key: TokenEnum.LP,
              label: 'LPs',
              content: '',
            },
          ]}
          activeKey={dataType}
          onChange={setDataType}
        />
      )}

      <section className={styles.TableContent}>
        <Table
          onRow={record => {
            return {
              onClick: () => jumpToDetail(record),
            };
          }}
          loading={tableTokenLoading}
          dataSource={filteredTableTokens}
          columns={columns}
          pagination={false}
          sticky
          bordered={false}
          rowKey={record => record.vaultAddress + record.symbol}
          locale={{
            emptyText: <Empty />,
          }}
          rowClassName={getRowClassName}
        />
      </section>
      <section className={styles.CardView}>
        {filteredTableTokens.map(token => (
          <section
            className={styles.TokenCard}
            key={token.vaultAddress + token.symbol}
            onClick={() => jumpToDetail(token)}>
            <FlexRow>
              <img className={styles.tokenLogo} src={token.image} alt="" />
              <span className={styles.tokenName}>
                {token.symbol}
                {token.isNew && (
                  <NewWrapperMobile>
                    <NewBadge />
                  </NewWrapperMobile>
                )}
              </span>
            </FlexRow>
            <ListView
              rows={[
                {
                  label: 'APY',
                  content: renderApy(token),
                },
                {
                  label: 'Total Liquidity Provided',
                  content: renderTVL(token),
                },
                {
                  label: 'Your Balance',
                  content: renderBalance(token),
                },
              ]}
            />
          </section>
        ))}
      </section>
    </section>
  );
};
export default DepositTable;

const NewWrapper = styled.div`
  position: relative;
  right: -5px;
  top: -10px;
`;

const NewWrapperMobile = styled.div`
  position: absolute;
  right: -45px;
  top: 0;
`;
