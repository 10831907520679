import config from '@/config/config';
import { IContractContainer, injectContractLpToken } from '@/contracts';
import { simpleRpcProvider } from '@/third_party/pancake/utils/providers';
import { parseEther } from '@ethersproject/units';
import { Contract, BigNumber } from 'ethers';
import { PancakeRouterAbi } from './PancakeRouterAbi';

const PANCAKE_ROUTER = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73';

export const getPrice = async (contractContainer: IContractContainer, token0: string, token1: string = config.DUET) => {
  const contract = new Contract(PANCAKE_ROUTER, PancakeRouterAbi, simpleRpcProvider);
  const lpAddress = await contract.getPair(token0, token1);

  console.log('lpAddress', lpAddress);

  const price = await getTokenPrice(contractContainer, lpAddress);
  return price;
};

export const getTokenPrice = async (contractContainer: IContractContainer, LpTokenAddress: string) => {
  const contract = injectContractLpToken(contractContainer, LpTokenAddress);
  let { _reserve0, _reserve1 } = await contract.getReserves();
  console.log('_reserve0', _reserve0);
  console.log('_reserve0', _reserve1);
  if (_reserve0.lt(_reserve1)) {
    const temp = _reserve0;
    _reserve0 = _reserve1;
    _reserve1 = temp;
  }
  const price = (_reserve1 as BigNumber).mul(parseEther('1')).div(_reserve0);
  return price;
  // return price * 1e8;
};
