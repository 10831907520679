import { AnnualYield, restfulClient } from '@/restful';

interface Options {
  totalApyAmendValue?: number;
}

export const getApyInfoVaultMap = async (options: Options = {}) => {
  const { totalApyAmendValue } = options;

  const { data: annualYieldData } = await restfulClient.annualYieldLatestAll({});

  const amendApy = (apyInfo: AnnualYield) => {
    if (!totalApyAmendValue) return apyInfo;
    const newApyInfo: AnnualYield = {
      ...apyInfo,
      totalApy: (apyInfo.totalApy += totalApyAmendValue),
    };
    return newApyInfo;
  };

  const setDuetCakeFarmApyToZero = (apyInfo: AnnualYield) => {
    if (apyInfo.depositTokenSymbol === 'DUET-CAKE') {
      return {
        ...apyInfo,
        swapFarmApr: 0,
        totalApy: apyInfo.totalApy - apyInfo.swapFarmApr,
      };
    }
    return apyInfo;
  };

  const apyInfoVaultMap = annualYieldData?.result
    ?.map(amendApy)
    ?.map(setDuetCakeFarmApyToZero)
    ?.reduce<Record<string, AnnualYield>>(
      (a, c) => ({
        ...a,
        [c.vaultAddress.toLocaleLowerCase()]: c,
      }),
      {},
    );

  return apyInfoVaultMap;
};
