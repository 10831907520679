import DButton from '@/components/DButton';
import DInput from '@/components/DInput';
import InfoCircle from '@/components/InfoCircle';
import Margin from '@/components/Margin';
import config from '@/config/config';
import commifyBigNumber from '@/utils/commify';
import toFixed from '@/utils/toFixed';
import { BigNumber } from 'ethers';
import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

export interface KeyValue {
  key: string;
  value: ReactNode;
}

interface Props {
  infos: KeyValue[];
  title: {
    text: string;
    description: string;
  };
  token: {
    balance: BigNumber | null;
  };
  operationButton: ReactNode;
  inputAmount: string;
  addBalanceLink?: ReactNode;
  setInputAmount: (amount: string) => void;
}

const BaseStakeView: FC<Props> = props => {
  const { infos, title, token, operationButton, inputAmount, setInputAmount, addBalanceLink } = props;

  return (
    <BaseStakeWrapper>
      <TitleRow>
        {title.text}
        <InfoCircle text={title.description} />
      </TitleRow>
      <InformationRow>
        {infos.map(each => (
          <InformationItem key={each.key}>
            <div>{each.key}</div>
            <div>{each.value}</div>
          </InformationItem>
        ))}
        <InformationItem />
      </InformationRow>
      <OperateRow>
        <div>
          <DInput
            value={inputAmount}
            onChange={setInputAmount}
            maxAmount={token.balance == null ? '' : toFixed(token.balance)}
          />
          <div style={{ marginTop: 10 }}>
            <span style={{ marginRight: 20 }}>Balance: {commifyBigNumber(token.balance)}</span>
            <span>{addBalanceLink}</span>
          </div>
        </div>
        <Margin left={20} />
        {operationButton}
      </OperateRow>
    </BaseStakeWrapper>
  );
};

export default BaseStakeView;

const BaseStakeWrapper = styled.div`
  width: 480px;
  height: 280px;
  border-radius: 6px;
  border: 1px solid #613bf4;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
`;

const TitleRow = styled.div`
  font-size: 20px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: var(--text-white3);
`;

const InformationRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  margin-top: 20px;

  > div:first-of-type {
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 21px;
    opacity: 0.3;
  }
  > div:nth-of-type(2) {
    font-size: 14px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: var(--text-white3);
    line-height: 21px;
    white-space: nowrap;
  }
`;

const OperateRow = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div {
  }
  > section {
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;
