import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PercentDisplay from '@/components/PercentDisplay';
import commifyBigNumber from '@/utils/commify';
import config from '@/config/config';
import InfoCircle from '@/components/InfoCircle';
import useIsMobile from '@/hooks/useIsMobile';
import SingleStake from './components/SingleStake';
import LpStake from './components/LpStake';
import { useEbCakeInfo } from '../../context';
import { EbCake, renderTimeWindow } from '../../server';

const Manage: FC = () => {
  const [currentUnfoldIndex, setCurrentUnfoldIndex] = useState<number | null>(0);
  const { ebCakes: ebCakeList } = useEbCakeInfo();

  const unfoldEbCake = useMemo(() => {
    return currentUnfoldIndex === null ? null : ebCakeList[currentUnfoldIndex];
  }, [currentUnfoldIndex, ebCakeList]);
  const isMobile = useIsMobile();

  const getTdCell = (ebCake: EbCake, index: number) => {
    let displayAPY: number | null = null;
    if (ebCake?.yield?.bDuetAPY == null || ebCake.yield.bDuetLpAPY == null || ebCake.ebCakeAPR == null) {
      displayAPY = null;
    } else {
      const singleTotalAPR = ebCake.yield.bDuetAPY + ebCake.ebCakeAPR;
      const lpTotalAPR = ebCake.yield.bDuetLpAPY + ebCake.ebCakeAPR;
      displayAPY = Math.max(singleTotalAPR, lpTotalAPR);
    }

    const tokenBaseInfo = (
      <EbCakeBaseInfo>
        <img src={ebCake.icon} alt={ebCake.name} />
        <span>{ebCake.name}</span>
      </EbCakeBaseInfo>
    );

    const ebCakePrice = <FontColorWrapper>{ebCake.price}</FontColorWrapper>;

    const redeemWindow = ebCake.timeWindow && (
      <FontColorWrapper>
        {renderTimeWindow(ebCake.timeWindow.redeemWindow, false)}
        <InfoCircle
          overlayStyle={{ minWidth: isMobile ? 350 : 480 }}
          text={
            <TimeWindowWrapper>
              <div>
                <span>Convert Time Window</span>
                <span>{renderTimeWindow(ebCake.timeWindow.convertWindow, true)}</span>
              </div>
              <div>
                <span>Redeem Time Window</span>
                <span>{renderTimeWindow(ebCake.timeWindow.redeemWindow, true)}</span>
              </div>
              <div>
                <span>Maturity Time</span>
                <span>{ebCake.timeWindow.maturity.format('YYYY-MM-DD HH:mm')}</span>
              </div>
            </TimeWindowWrapper>
          }
        />
      </FontColorWrapper>
    );

    const ebCakeBalance = <FontColorWrapper>{commifyBigNumber(ebCake.balance)}</FontColorWrapper>;

    const operations = (
      <OperationsRow>
        <OperateButton
          onClick={() => {
            const tradeUrl = `https://pancakeswap.finance/swap?inputCurrency=${config.CAKE}&outputCurrency=${ebCake?.addresses.tokenAddress}`;
            window.open(tradeUrl);
          }}>
          Trade
        </OperateButton>
        <OperateButton
          onClick={() => {
            const tradeUrl = `https://pancakeswap.finance/add/${config.CAKE}/${ebCake?.addresses.tokenAddress}`;
            window.open(tradeUrl);
          }}>
          Liquidity
        </OperateButton>
        <OperateButton
          className={currentUnfoldIndex === index ? 'active' : ''}
          onClick={() => {
            if (currentUnfoldIndex === index) {
              setCurrentUnfoldIndex(null);
            } else {
              setCurrentUnfoldIndex(index);
            }
          }}>
          Stake
        </OperateButton>
      </OperationsRow>
    );

    return {
      tokenBaseInfo,
      ebCakePrice,
      displayAPY: (
        <FontColorWrapper>
          <PercentDisplay>{displayAPY}</PercentDisplay>
        </FontColorWrapper>
      ),
      redeemWindow,
      ebCakeBalance,
      operations,
    };
  };

  const renderEbCakeRow = (ebCake: EbCake, index: number) => {
    const { tokenBaseInfo, ebCakePrice, displayAPY, ebCakeBalance, redeemWindow, operations } = getTdCell(
      ebCake,
      index,
    );

    return (
      <TableContentRow key={ebCake.name}>
        <td>{tokenBaseInfo}</td>
        <td>{ebCakePrice}</td>
        <td>{displayAPY}</td>
        <td>{redeemWindow}</td>
        <td>{ebCakeBalance}</td>
        <OperationTd>{operations}</OperationTd>
      </TableContentRow>
    );
  };

  const renderEbCakeRowMobile = (ebCake: EbCake, index: number) => {
    const { tokenBaseInfo, ebCakePrice, displayAPY, ebCakeBalance, redeemWindow, operations } = getTdCell(
      ebCake,
      index,
    );

    return (
      <EbCakeMobileCard key={ebCake.name}>
        {tokenBaseInfo}

        <EbCakeMobileCardInfoArea>
          <EbCakeMobileCardInfoItem width="50%">
            <span>Price</span>
            <span>{ebCakePrice}</span>
          </EbCakeMobileCardInfoItem>

          <EbCakeMobileCardInfoItem width="50%">
            <span>APY</span>
            <span>{displayAPY}</span>
          </EbCakeMobileCardInfoItem>

          <EbCakeMobileCardInfoItem>
            <span>Redeem Window</span>
            <span>{redeemWindow}</span>
          </EbCakeMobileCardInfoItem>

          <EbCakeMobileCardInfoItem>
            <span>ebCAKE Balance</span>
            <span>{ebCakeBalance}</span>
          </EbCakeMobileCardInfoItem>
        </EbCakeMobileCardInfoArea>

        <CardOperationRow>{operations}</CardOperationRow>
      </EbCakeMobileCard>
    );
  };

  return (
    <ManageWrapper>
      {!isMobile && (
        <table>
          <tbody>
            <TableTitleRow>
              <td width={180}>Name</td>
              <td width={80}>Price</td>
              <td width={100}>APY</td>
              <td width={220}>Redeem Window</td>
              <td>ebCAKE Balance</td>
              <td />
            </TableTitleRow>
            {!unfoldEbCake && ebCakeList.map(renderEbCakeRow)}
            {unfoldEbCake && currentUnfoldIndex !== null && (
              <>
                {ebCakeList.slice(0, currentUnfoldIndex + 1).map(renderEbCakeRow)}
                {unfoldEbCake && (
                  <StakeArea>
                    <td colSpan={6}>
                      <div>
                        <SingleStake ebCake={unfoldEbCake} />
                        <LpStake ebCake={unfoldEbCake} />
                      </div>
                    </td>
                  </StakeArea>
                )}
                {ebCakeList
                  .slice(currentUnfoldIndex + 1)
                  .map((each, index) => renderEbCakeRow(each, index + currentUnfoldIndex + 1))}
              </>
            )}
          </tbody>
        </table>
      )}

      {isMobile && (
        <div>
          {!unfoldEbCake && ebCakeList.map(renderEbCakeRowMobile)}
          {unfoldEbCake && currentUnfoldIndex !== null && (
            <>
              {ebCakeList.slice(0, currentUnfoldIndex + 1).map(renderEbCakeRowMobile)}
              {unfoldEbCake && (
                <div style={{ padding: 20 }}>
                  <SingleStake ebCake={unfoldEbCake} />
                  <LpStake ebCake={unfoldEbCake} />
                </div>
              )}
              {ebCakeList
                .slice(currentUnfoldIndex + 1)
                .map((each, index) => renderEbCakeRowMobile(each, index + currentUnfoldIndex + 1))}
            </>
          )}
        </div>
      )}
    </ManageWrapper>
  );
};

export default Manage;

const WIDTH = '1060px';

const ManageWrapper = styled.div`
  margin-top: 40px;
  width: ${WIDTH};
  background: var(--primary-color);
  padding: 30px 40px 10px;

  @media (max-width: 768px) {
    margin-top: 0px;
    width: 100%;
    padding: 20px 0px 10px;
  }
`;

const TableTitleRow = styled.tr``;

const TableContentRow = styled.tr`
  color: #ffffff;
  td {
    padding: 15px 0;
  }
`;

const EbCakeBaseInfo = styled.div`
  display: inline-flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
  }

  span {
    margin-left: 10px;
    color: var(--text-white3);
  }
`;

const OperationsRow = styled.div`
  display: flex;

  > button:not(:first-of-type) {
    margin-left: 20px;
  }
`;

const OperateButton = styled.button`
  padding: 0 15px;
  height: 30px;
  line-height: 21px;
  border-radius: 4px;
  border: 1px solid #613bf4;
  font-size: 14px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(--text-white3);
  background: rgba(255, 255, 255, 0);
  cursor: pointer;

  &.active {
    background: #613bf4;
    border-radius: 4px;
    color: white;
  }
`;

const OperationTd = styled.td`
  padding: 20px 10px;
`;

const StakeArea = styled.tr`
  td {
    > div {
      width: calc(${WIDTH} - 80px);
      display: flex;
      justify-content: space-between;
    }
  }
`;

const TimeWindowWrapper = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    width: 480px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 350px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    > span:first-of-type {
      width: 150px;
      text-align: right;
      @media (max-width: 768px) {
        width: auto;
      }
    }

    > span:last-of-type {
      width: 310px;
      text-align: left;
    }
  }
`;

const EbCakeMobileCard = styled.div<{ width?: string }>`
  width: '100%';
  padding: 20px 15px;
  background: var(--primary-color);
`;

const EbCakeMobileCardInfoArea = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EbCakeMobileCardInfoItem = styled.div<{ width?: string }>`
  width: ${props => props.width || '100%'};
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  > :nth-child(1) {
    color: #9195a5;
  }

  > :nth-child(2) {
    color: #ffffff;
  }
`;

const CardOperationRow = styled.div`
  margin-top: 20px;
  display: flex;
`;

const FontColorWrapper = styled.span`
  color: var(--text-white3);
`;
