import { ContractInterface } from 'ethers';

export const SingleBondAbi: ContractInterface = [
  'constructor(address)',
  'event NewEpoch(address indexed)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'function debtor() view returns (address)',
  'function duration() view returns (uint256)',
  'function end() view returns (uint256)',
  'function epochImp() view returns (address)',
  'function getEpoch(uint256) view returns (address)',
  'function getEpoches() view returns (address[])',
  'function initBond(uint256,uint256,uint256,uint256,uint256,address)',
  'function multiTransfer(address[],uint256[],address)',
  'function owner() view returns (address)',
  'function phasenum() view returns (uint256)',
  'function redeem(address[],uint256[],address)',
  'function redeemAll(address)',
  'function redeemOrTransfer(address[],uint256[],address)',
  'function renewSingleEpoch(uint256,uint256,address)',
  'function renewal(uint256,uint256,uint256)',
  'function renounceOwnership()',
  'function rewardtoken() view returns (address)',
  'function setEpochImp(address)',
  'function start() view returns (uint256)',
  'function transferOwnership(address)',
];
