import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { injectContractERC20 } from '../contracts';
import { useWeb3 } from '../web3';

const useERC20Balance = (address?: string) => {
  const { account, contractContainer } = useWeb3();
  const [accountBalance, setAccountBalance] = useState<BigNumber | null>(null);
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshAccountBalance = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    if (!address) return;
    if (!account) {
      setAccountBalance(null);
      return;
    }
    if (!contractContainer) return;
    (async () => {
      const contract = injectContractERC20(contractContainer, address);
      const res = await contract.balanceOf(account);
      setAccountBalance(res);
    })();
  }, [address, refreshCount, contractContainer]);

  return {
    accountBalance,
    setAccountBalance,
    refreshAccountBalance,
  };
};

export default useERC20Balance;
