import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import Margin from '@/components/Margin';
import { setLocalStorage } from '@/utils/storage';
import { Checkbox, Modal } from 'antd';
import { FC, useState } from 'react';
import styles from './style.module.scss';

interface Props {
  visible: boolean;
  setModalVisible: (visible: boolean) => void;
}

const CautionModal: FC<Props> = props => {
  const { visible, setModalVisible } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleAgreeClick = () => {
    setLocalStorage('AgreeInnovationAssets', 'true');
    setModalVisible(false);
  };

  if (!visible) return null;

  return (
    <div className={styles.CautionModalWrapper}>
      <div className={styles.Modal}>
        <div className={styles.Title}>Caution</div>
        <Margin top={20} />
        <div className={styles.CautionContent}>
          Synthetic assets listed in the Innovation Market are volatile in nature and might be subject to limited
          liquidity, price manipulation, oracle manipulation, etc. Please do your own research before you invest and
          invest according to your own risk tolerance level.
        </div>
        <Margin top={40} />
        <div className={styles.Agree}>
          <Checkbox checked={checked} onChange={handleChange}>
            I've done sufficient research and am fully aware of the risk going forward.
          </Checkbox>
        </div>
        <Margin top={30} />
        <FlexRow justifyContent="center">
          <DButton disabled={!checked} width={250} onClick={handleAgreeClick}>
            Proceed
          </DButton>
        </FlexRow>
      </div>
    </div>
  );
};

export default CautionModal;
