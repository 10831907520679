import { injectContractEbCake } from '@/contracts';
import { EbCake } from '@/pages/EbCake/server';
import { useVaultApy } from '@/third_party/pancake/hooks/useVaultApy';
import { useWeb3 } from '@/web3';
import { useEffect, useState } from 'react';

export const useEbcakeAPR = (ebCake?: EbCake | null) => {
  const [ebCakeAPR, setEbCakeAPR] = useState<number | null>(null);
  const { contractContainer } = useWeb3();

  const { getLockedApy } = useVaultApy();

  useEffect(() => {
    if (!contractContainer || !ebCake) return;
    if (import.meta.env.VITE_NETWORK === 'bsctest') {
      setEbCakeAPR(8.5);
      return;
    }

    if (!getLockedApy) return;

    const bondContract = injectContractEbCake(contractContainer, ebCake.addresses.interactionContractAddress);
    bondContract.pancakeUserInfo().then((res: any) => {
      const duration = +res.lockEndTime - +res.lockStartTime;
      const apy = getLockedApy(duration);
      if (apy) {
        setEbCakeAPR(+apy);
      }
    });
  }, [contractContainer, ebCake, getLockedApy]);

  return ebCakeAPR;
};
