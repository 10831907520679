import * as React from 'react';
import {
  Web3ReactProvider as OriginalWeb3Provider,
  initializeConnector,
  useWeb3React,
  Web3ReactHooks,
} from '@web3-react/core';
import * as Sentry from '@sentry/react';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';
import { Network } from '@web3-react/network';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import { Connector, Web3ReactStore } from '@web3-react/types';
import { useEffect, useState } from 'react';
import config from './config/config';
import { networkConfigMap } from './config/network';
import { ContractContainer } from './contracts';
import { getLocalStorage } from './utils/storage';

const CONNECT_EAGERLY_SUPPRESS_KEY = `no_conn_eagerly`;
export const connectEagerlyManager = {
  suppress() {
    localStorage.setItem(CONNECT_EAGERLY_SUPPRESS_KEY, '1');
  },
  unsuppress() {
    localStorage.removeItem(CONNECT_EAGERLY_SUPPRESS_KEY);
  },
  get isSuppressed() {
    return !!localStorage.getItem(CONNECT_EAGERLY_SUPPRESS_KEY);
  },
};

const ConnectorsContext = React.createContext<{
  GnosisSafe: SpecifiableConnector<GnosisSafe>;
  MetaMask: SpecifiableConnector<MetaMask>;
  WalletConnect: SpecifiableConnector<WalletConnect>;
} | null>(null);

export const Web3ReactProvider: React.FC = ({ children }) => {
  const defaultChainId = config.mainChainId;
  const connectEagerly = !connectEagerlyManager.isSuppressed;
  const allowedChainIds = [defaultChainId];
  const endpoints = { [defaultChainId]: networkConfigMap[defaultChainId].rpcUrls };

  const [connectorGnosisSafe, hooksGnosisSafe, storeGnosisSafe] = initializeConnector<GnosisSafe>(
    actions => new GnosisSafe(actions, true),
  );
  const [connectorMetaMask, hooksMetaMask, storeMetaMask] = initializeConnector<MetaMask>(
    actions => new MetaMask(actions, connectEagerly),
    allowedChainIds,
  );
  const [connectorWalletConnect, hooksWalletConnect, storeWalletConnect] = initializeConnector<WalletConnect>(
    actions =>
      new WalletConnect(
        actions,
        {
          rpc: endpoints,
          bridge: 'https://bridge.walletconnect.org',
          qrcode: true,
          ...{
            infuraId: 'ad92ef65d7cf424e807d09f01cdb7702',
            pollingInterval: 15000,
          },
        },
        connectEagerly,
      ),
    allowedChainIds,
  );

  const connectors = React.useMemo(
    () => ({
      GnosisSafe: { connector: connectorGnosisSafe, hooks: hooksGnosisSafe, store: storeGnosisSafe },
      MetaMask: { connector: connectorMetaMask, hooks: hooksMetaMask, store: storeMetaMask },
      WalletConnect: { connector: connectorWalletConnect, hooks: hooksWalletConnect, store: storeWalletConnect },
    }),
    [],
  );

  return (
    <OriginalWeb3Provider
      connectors={[
        [connectorGnosisSafe, hooksGnosisSafe, storeGnosisSafe],
        [connectorMetaMask, hooksMetaMask, storeMetaMask],
        [connectorWalletConnect, hooksWalletConnect, storeWalletConnect],
        initializeConnector<Network>(actions => new Network(actions, endpoints, true), allowedChainIds),
      ]}
      network={defaultChainId}
      lookupENS={false}>
      <ConnectorsContext.Provider value={connectors}>{children}</ConnectorsContext.Provider>
    </OriginalWeb3Provider>
  );
};

export const useWeb3 = () => {
  const { hooks, connector, provider, isActive, isActivating, chainId, account, ...etc } = useWeb3React();
  const connectors = React.useContext(ConnectorsContext);
  const isStable = !!(!isActivating && isActive);

  const [mockAccount, setMockAccount] = React.useState<string | null>(null);
  React.useEffect(() => {
    const mockAccount = getLocalStorage('mockAccount');
    setMockAccount(mockAccount);
  }, []);

  const providerName = isStable ? getProviderName(connector) : 'Unknown';

  const [contractContainer, setContractContainer] = useState<ContractContainer | null>(null);
  useEffect(() => {
    if (!provider) return;
    ContractContainer.get(provider).then(container => {
      setContractContainer(container);
    });
  }, [provider]);

  React.useEffect(() => {
    if (provider?.connection?.url) {
      Sentry.setTag('rpc_url', provider.connection.url);
    }
  }, [provider]);

  return {
    ...etc,
    hooks,
    connector: isStable ? connector : null,
    connectors,
    isActive,
    isActivating,
    account: mockAccount || account,
    chainId,
    provider,
    providerName,
    contractContainer,
  } as const;
};

/// ------

function getProviderName(connector: Connector) {
  if (connector instanceof GnosisSafe) return 'GnosisSafe';
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnect) return 'WalletConnect';
  if (connector instanceof Network) return 'Network';
}

type SpecifiableConnector<T> = {
  connector: T;
  hooks: Web3ReactHooks;
  store: Web3ReactStore;
};
