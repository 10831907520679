import Margin from '@/components/Margin';
import { useStrategy } from '@/pages/DepositDetail/context';
import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DInput from '@/components/DInput';
import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import { useWeb3 } from '@/web3';
import { injectContractDyTokenERC20 } from '@/contracts/fungible';
import { formatEther, parseEther } from '@ethersproject/units';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { message } from 'antd';
import useERC20Balance from '@/hooks/useERC20Balance';
import AmountDisplay from '@/components/AmountDisplay';
import toFixed from '@/utils/toFixed';
import RemoveLiquidityModal from './RemoveLiquidityModal';
import AddLiquidityModal from './AddLiquidityModal';

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
}

const DodoSupply: FC<Props> = props => {
  const { refreshDataCount, refreshData } = props;

  const strategy = useStrategy();
  const lpTokenAddress = strategy?.address || '';

  const [inputAmount, setInputAmount] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const dyTokenAddress = strategy?.dyTokenAddress;

  const { account, contractContainer } = useWeb3();

  // 点击 supply 按钮
  const handleSupplyClick = async () => {
    if (!strategy || !contractContainer || !account) return;
    const { dyTokenAddress: currentDyTokenAddress, vaultAddress } = strategy;
    // directly supply
    const contract = injectContractDyTokenERC20(contractContainer, currentDyTokenAddress).connect(
      contractContainer.getSigner(account),
    );
    const promise = contract.deposit(parseEther(inputAmount), vaultAddress);
    try {
      await contract.estimateGas.deposit(parseEther(inputAmount), vaultAddress);

      await executeAndShowTx(promise, {
        throwError: true,
      });
      refreshData();
      setInputAmount('');
    } catch (e: any) {
      if (e?.data?.message === 'execution reverted: mismatch price') {
        const errorMessage = 'Strategy subscription suspended due to peg deviation.';
        message.error(errorMessage);
        console.error(`${errorMessage}. Error: `, e);
      }
    }
  };

  const { accountBalance, refreshAccountBalance } = useERC20Balance(lpTokenAddress);

  useEffect(() => {
    refreshAccountBalance();
  }, [refreshDataCount]);

  const maxString = accountBalance ? formatEther(accountBalance) : '';

  const buttonDisabled = !inputAmount || +inputAmount === 0;
  if (!strategy) return null;

  return (
    <DodoSupplyWrapper>
      <TokenDisplay>{strategy?.symbol}</TokenDisplay>
      <Margin top={30} />
      <DInput value={inputAmount} onChange={setInputAmount} maxAmount={maxString} />
      <WalletBalance>
        <WalletBalanceLabel>Wallet Balance</WalletBalanceLabel>
        <WalletBalanceValue>
          <AmountDisplay>{accountBalance}</AmountDisplay>
          <Margin left={5} />
          {strategy?.symbol}{' '}
        </WalletBalanceValue>
      </WalletBalance>
      <Margin top={20} />
      <FlexRow>
        <DButton onClick={() => setModalVisible(true)}>Add Liquidity</DButton>
        <Margin left={20} />
        <DButton onClick={() => setRemoveModalVisible(true)}>Remove Liquidity</DButton>
      </FlexRow>
      <Margin top={10} />
      <FlexRow>
        <DButton
          onClick={handleSupplyClick}
          disabled={buttonDisabled}
          approveFrom={strategy?.address}
          approveTo={dyTokenAddress}>
          Supply
        </DButton>
      </FlexRow>
      <div>
        <AddLiquidityModal
          visible={modalVisible}
          closeModal={() => setModalVisible(false)}
          lpTokenAddress={lpTokenAddress}
          refreshData={refreshData}
        />
        <RemoveLiquidityModal
          visible={removeModalVisible}
          closeModal={() => setRemoveModalVisible(false)}
          lpTokenAddress={lpTokenAddress}
          refreshData={refreshData}
        />
      </div>
    </DodoSupplyWrapper>
  );
};

export default DodoSupply;

const DodoSupplyWrapper = styled.div`
  padding-top: 20px;
`;

const TokenDisplay = styled.div`
  padding-left: 10px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: var(--bg-purple4);
  color: var(--text-gary2);
  border-radius: 10px;
  border: 1px solid var(--text-purple);
  font-size: 20px;
  @include respond-to(sm) {
    width: 100%;
  }
`;

const WalletBalance = styled.div`
  display: flex;
`;

const WalletBalanceLabel = styled.div`
  font-size: 14px;
  color: #9195a5;
`;

const WalletBalanceValue = styled.div`
  display: flex;
  font-size: 14px;
  color: #d7d6d6;
  margin-left: 20px;
`;
