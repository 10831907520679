import { allStrategies } from '@/config/allLpTokens';
import config from '@/config/config';
import { DisplayPosition } from '@/config/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  token: string;
  link?: string;
}

const VaultLink: FC<Props> = props => {
  const { token, link } = props;
  if (link) {
    return (
      <Link target="_blank" to={link}>
        vault
      </Link>
    );
  }

  const strategy = allStrategies.find(strategy => strategy.symbol === token);

  if (!strategy) return <span>token</span>;

  if (strategy.displayPosition === DisplayPosition.DEPOSIT_ARCHIVED) {
    return (
      <Link target="_blank" to={`/archived-deposit-detail/${strategy.address}`}>
        vault
      </Link>
    );
  }
  return (
    <Link target="_blank" to={`/deposit-detail/${strategy.address}`}>
      vault
    </Link>
  );
};

export default VaultLink;
