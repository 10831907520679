import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import { ConfigProvider } from 'antd';
import cssVars from 'css-vars-ponyfill';
import { darkTheme, lightTheme, Theme } from '@/assets/theme';
import { getLocalStorage, setLocalStorage } from '@/utils/storage';
import '@/assets/styles/index.scss';
import * as rrWeb from 'rrweb';
import _ from 'lodash';
import { Severity } from '@sentry/types';
import moment from 'moment';
import AppInner from './AppInner';
import Announcement from './components/Announcement';
import globalStore from './stores/global';
import { Web3ReactProvider } from './web3';

ConfigProvider.config({
  prefixCls: 'ant',
  theme: {},
});

Sentry.init({
  dsn: 'https://2f2bffdcaed44f399beaf9869816185c@o1198122.ingest.sentry.io/6330499',
  enabled: !['localhost', '127.0.0.1', '::1'].includes(window.location.hostname),
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications'],
  integrations: [
    new BrowserTracing(),
    new SentryRRWeb({
      collectFonts: true,
      maskAllInputs: false, // 将所有输入内容记录为 *,sentry默认为true
      // maskInputOptions: {}, // 选择将特定类型的输入框内容记录为 *，sentry默认输入框为*号
      sampling: {
        scroll: 100, // 每 100ms 最多触发一次
        input: 'last', // 连续输入时，只录制最终值
      },
      packFn: rrWeb.pack,
    }),
  ],
  beforeSend(event, hint) {
    event.extra = event.extra || {};
    event.extra.clientTime = moment.utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC';
    if (hint?.originalException) {
      event.extra.originalException = hint.originalException;
    }

    const hintMessage = _.get(hint, 'originalException.message');
    if (typeof hintMessage === 'string' && hintMessage.includes('header not found')) {
      event.level = Severity.Warning;
      return event;
    }

    const extraMessage = _.get(event, 'extra.__serialized__.message');
    if (
      typeof extraMessage === 'string' &&
      ['header not found', 'missing trie node'].some(each => extraMessage.includes(each))
    ) {
      event.level = Severity.Warning;
      return event;
    }

    if (
      typeof extraMessage === 'string' &&
      ['User rejected the transaction', 'User denied transaction signature'].some(each => extraMessage.includes(each))
    ) {
      return null;
    }

    // 合约调用信息上报
    if (hint?.originalException instanceof Error && _.get(hint.originalException, 'transaction')) {
      const txData = _.get(hint.originalException, 'error.data.data');
      const transaction = _.get(hint.originalException, 'transaction');
      if (!txData || !transaction) return event;
      event.tags = event.tags || {};
      event.tags.to_contract = transaction.to;
      event.tags.to_contract_function = transaction.data;
      event.extra.transacion = {
        from: transaction.from,
        to: transaction.to,
        function: transaction.data,
        txData,
      };
    }

    return event;
  },

  // bsc 环境采样率设置为 0.6，其他环境设置为 1
  tracesSampleRate: import.meta.env.VITE_NETWORK === 'bsc' ? 0.6 : 1.0,
  environment: import.meta.env.VITE_NETWORK,
});

// 主题色切换函数
export const changeThemeVal = () => {
  const nextTheme = getLocalStorage('theme') === Theme.dark ? Theme.light : Theme.dark;
  initTheme(nextTheme);
  setLocalStorage('theme', nextTheme);

  globalStore.setState({
    dataTheme: nextTheme,
  });
};

export function initTheme(theme: string) {
  document.documentElement.setAttribute('data-theme', theme);
  cssVars({
    watch: true, // 当添加，删除或修改其<link>或<style>元素的禁用或href属性时，ponyfill将自行调用
    variables: theme === Theme.light ? lightTheme : darkTheme, // variables 自定义属性名/值对的集合
    onlyLegacy: false, // false  默认将css变量编译为浏览器识别的css样式  true 当浏览器不支持css变量的时候将css变量编译为识别的css
    silent: true,
  });
}

const App: React.FC = () => {
  useEffect(() => {
    if (!getLocalStorage('theme')) {
      setLocalStorage('theme', 'dark');
    }

    // 初始化主题
    initTheme(getLocalStorage('theme') || Theme.dark);

    globalStore.setState({
      dataTheme: getLocalStorage('theme') || Theme.dark,
    });
  }, []);

  return (
    <Web3ReactProvider>
      <ConfigProvider prefixCls="ant">
        <Announcement />
        <AppInner />
      </ConfigProvider>
    </Web3ReactProvider>
  );
};

export default App;
