import { contractAddressMap } from '@/config/network';

export const getPairAddressBySymbols = (chainId: number, symbolA: string, symbolB: string): string => {
  if (contractAddressMap[chainId][`${symbolA}-${symbolB}`]) {
    return contractAddressMap[chainId][`${symbolA}-${symbolB}`];
  }
  if (contractAddressMap[chainId][`${symbolB}-${symbolA}`]) {
    return contractAddressMap[chainId][`${symbolB}-${symbolA}`];
  }
  return '';
};
