import DButton from '@/components/DButton';
import InfoCircle from '@/components/InfoCircle';
import Margin from '@/components/Margin';
import PercentDisplay from '@/components/PercentDisplay';
import { FC, useEffect, useState } from 'react';
import rabbitImg from '@/assets/ebcake-rabbit.png';
import { useWeb3 } from '@/web3';
import { injectContractEbCakeLpFarmingPool, injectContractLpToken } from '@/contracts';
import commifyBigNumber from '@/utils/commify';
import { useCatchError, useEbCakeInfo } from '@/pages/EbCake/context';
import config from '@/config/config';
import CakeIcon from '@/assets/ebcake.png';
import styled from '@emotion/styled';
import { constants } from 'ethers';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { EbCake } from '@/pages/EbCake/server';
import InfoArea from './InfoArea';
import { RewardPanel, Title } from './styles';
import LpUnstakeModal from './LpUnstakeModal';

interface Props {
  ebCake: EbCake;
}

const EbCakeLp: FC<Props> = props => {
  const { ebCake: ebCakeInfo } = props;
  const { contractContainer, account } = useWeb3();
  const { refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const [stakedAmountStr, setStakedAmountStr] = useState('-');
  const [modalVisible, setModalVisible] = useState(false);
  const executeAndShowTx = useExecuteAndShowTx();

  const claimLp = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    const contract = injectContractEbCakeLpFarmingPool(
      contractContainer,
      ebCakeInfo.addresses.ebCakeLpFarmingPoolAddress,
    ).connect(contractContainer.getSigner(account));

    await contract.estimateGas.claimBonuses().then(async gasEstimated => {
      await executeAndShowTx(
        contract.claimBonuses({
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      refresh();
    }, onError);
  };

  useEffect(() => {
    (async () => {
      if (!contractContainer) return;
      if (!ebCakeInfo?.price) return;
      const staked = ebCakeInfo.lpToken?.staked;
      if (!staked) return;

      const lpContract = injectContractLpToken(contractContainer, ebCakeInfo?.addresses.ebCakeCakeAddress);
      const totalSupply = await lpContract.totalSupply();
      if (totalSupply.eq(constants.Zero)) {
        setStakedAmountStr('-');
        return;
      }

      const token0Address = await lpContract.token0();
      let { _reserve0, _reserve1 } = await lpContract.getReserves();
      if (token0Address === config.CAKE) {
        [_reserve0, _reserve1] = [_reserve1, _reserve0];
      }

      const ebCakeAmount = staked.mul(_reserve0).div(totalSupply);
      const cakeAmount = staked.mul(_reserve1).div(totalSupply);
      const stakedAmountStrTemp = `${commifyBigNumber(staked)} LP(${commifyBigNumber(
        cakeAmount,
      )} CAKE + ${commifyBigNumber(ebCakeAmount)} ebCAKE)`;

      setStakedAmountStr(stakedAmountStrTemp);
    })();
  }, [contractContainer, ebCakeInfo]);

  if (!ebCakeInfo) return null;
  const name = ebCakeInfo.name + '/CAKE Lp Stake';

  const haveStakedToken = !ebCakeInfo.lpToken?.staked?.eq(constants.Zero);
  const haveUnClaimedToken =
    !ebCakeInfo.lpToken?.bDuetPending?.eq(constants.Zero) || !ebCakeInfo.lpToken?.ebCakePending?.eq(constants.Zero);

  return (
    <RewardPanel>
      <Title>
        <div>
          <EbCakeImgArea>
            <img src={CakeIcon} alt="cake" />
            <img src={ebCakeInfo.icon} alt="ebcake" />
          </EbCakeImgArea>
          <span>{name}</span>
        </div>
        <div>
          <span>
            APY
            <InfoCircle
              text={
                <div>
                  <div>
                    ebCAKE APY: <PercentDisplay>{ebCakeInfo?.ebCakeAPR}</PercentDisplay>
                  </div>
                  <div>
                    bDuet APY: <PercentDisplay>{ebCakeInfo?.yield?.bDuetLpAPY}</PercentDisplay>
                  </div>
                </div>
              }
            />
          </span>

          <span>
            <PercentDisplay>
              {ebCakeInfo?.yield?.bDuetLpAPY == null || ebCakeInfo?.ebCakeAPR == null
                ? null
                : ebCakeInfo.yield.bDuetLpAPY + ebCakeInfo.ebCakeAPR}
            </PercentDisplay>
          </span>
        </div>
      </Title>
      <InfoArea
        infos={[
          {
            key: 'Staked',
            value: stakedAmountStr,
          },
        ]}
      />
      <InfoArea
        title="$bDuet rewards from the pool are distributed every day."
        infos={[
          {
            key: 'Pending Rewards(compounding)',
            value: commifyBigNumber(ebCakeInfo.lpToken?.ebCakePending) + ' ebCAKE',
          },
          {
            key: 'Pending Rewards bDuet',
            value: commifyBigNumber(ebCakeInfo.lpToken?.bDuetPending) + ' bDuet',
          },
          {
            key: 'Earned to Date',
            value:
              commifyBigNumber(ebCakeInfo.lpToken?.ebCakeEarnedToDate) +
              ' ebCAKE + ' +
              commifyBigNumber(ebCakeInfo.lpToken?.bDuetEarnedToDate) +
              ' bDuet',
          },
        ]}
        operation={
          <DButton height={40} onClick={claimLp} disabled={!haveUnClaimedToken}>
            Claim
          </DButton>
        }
      />
      <Margin top={30} />
      <DButton height={40} onClick={() => setModalVisible(true)} disabled={!haveStakedToken}>
        Unstake
      </DButton>
      <LpUnstakeModal ebCake={ebCakeInfo} visible={modalVisible} closeModal={() => setModalVisible(false)} />
    </RewardPanel>
  );
};

export default EbCakeLp;

const EbCakeImgArea = styled.div`
  width: 45px;
  position: relative;
  top: -15px;

  img:nth-of-type(1) {
    position: absolute;
    left: 15px;
  }

  img:nth-of-type(2) {
    position: absolute;
    left: 0px;
  }
`;
