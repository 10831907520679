import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { NOTIFICATION_DURATION } from '@/config/time';

const TxNotificationProcessBar: FC = () => {
  const barRef = useRef<any>(null);

  useEffect(() => {
    const timer = resetTo100(0);
    return () => {
      clearTimeout(timer);
    };
  }, [barRef]);

  const resetTo100 = (timeout: number) => {
    return setTimeout(() => {
      if (barRef && barRef.current) {
        barRef.current.style.width = '100%';
      }
    }, timeout);
  };

  return (
    <ProcessBar>
      <div ref={barRef} />
    </ProcessBar>
  );
};

export default TxNotificationProcessBar;

const ProcessBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 3px;
  background: #ffffff;
  border-radius: 6px;

  div {
    width: 1px;
    height: 3px;
    background: #1bd19a;
    border-radius: 6px;
    transition: width ${`${NOTIFICATION_DURATION}s`} linear;
  }
`;
