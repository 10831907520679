import React from 'react';
import { DusdAbi } from '@/abis/DusdAbi';
import { StableCoinAbi } from '@/abis/StableCoinAbi';
import { contractAddressMap } from '@/config/network';
import { Token } from '@/types/token';

const BUSD: React.FC = () => (
  <div
    style={{
      width: 50,
      height: 50,
      borderRadius: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(230deg, #89601E 0%, #4F3915 37%, #362811 100%)',
    }}>
    <i
      style={{
        fontSize: 30,
        color: '#e7bb41',
      }}
      className="iconfont icon-a-BUSDlogo"
    />
  </div>
);

const USDC: React.FC = () => (
  <div
    style={{
      width: 50,
      height: 50,
      borderRadius: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(226deg, #27A69D 0%, #364B7C 34%, #22205A 100%)',
    }}>
    <i
      style={{
        fontSize: 30,
        color: '#ffffff',
      }}
      className="iconfont icon-a-USDClogo"
    />
  </div>
);

const DUSD: React.FC = () => (
  <div
    style={{
      width: 50,
      height: 50,
      borderRadius: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(226deg, #27A69D 0%, #364B7C 34%, #22205A 100%)',
    }}>
    <i
      style={{
        fontSize: 30,
        color: '#78e8c6',
      }}
      className="iconfont icon-a-DUSDlogo"
    />
  </div>
);

const getTokens = (chainId?: number): Token[] => {
  if (!chainId) {
    return [];
  }

  const tokens: Token[] = [
    {
      symbol: 'BUSD',
      iconComponent: <BUSD />,
      address: contractAddressMap[chainId].BUSD,
      abi: StableCoinAbi,
    },
    {
      symbol: 'USDC',
      iconComponent: <USDC />,
      address: contractAddressMap[chainId].USDC,
      abi: StableCoinAbi,
    },
    {
      symbol: 'dUSD',
      iconComponent: <DUSD />,
      address: contractAddressMap[chainId].dUSD,
      abi: DusdAbi,
    },
  ];

  return tokens;
};

export default getTokens;
