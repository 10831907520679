import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BigNumber, ethers } from 'ethers';
import SelectToken from '@/components/SelectToken';
import ListView, { ListRow } from '@/components/ListView';
import Margin from '@/components/Margin';
import Card from '@/components/Card';
import { depositArchivedStrategyList, depositStrategyList, Token, TokenType } from '@/config/allLpTokens';
import commifyBigNumber from '@/utils/commify';
import FlexRow from '@/components/FlexRow';
import InfoCircle from '@/components/InfoCircle';
import { Strategy } from '@/config/types';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { useTokenData } from '../context';
import { injectContractAppController, injectContractFeeConf, injectContractReader } from '../../../contracts';
import { useWeb3 } from '../../../web3';

interface Props {
  currentStrategy?: Strategy;
}

const vaultStatusTextMap = {
  0: 'Not initialized',
  1: 'Not collateralized',
  2: 'Collateralized',
} as const;

type VaultStatus = keyof typeof vaultStatusTextMap;
type VaultStatusText = typeof vaultStatusTextMap[VaultStatus];

const Information: FC<Props> = props => {
  const { account, contractContainer } = useWeb3();
  const { currentStrategy } = props;
  const [vaultStatus, setVaultStatus] = useState<VaultStatus | null>(null);
  const [vaultStatusText, setVaultStatusText] = useState<VaultStatusText | null>(null);
  const isArchived = window.location.pathname.includes('archived-deposit-detail');

  const isSingleToken = currentStrategy?.singleOrLp === TokenType.SINGLE;

  const [collateralFactor, setCollateralFactor] = useState('');
  const [fee, setFee] = useState('');

  const getFee = async () => {
    if (!contractContainer) return;
    const contract = injectContractFeeConf(contractContainer);
    const param = ethers.utils.formatBytes32String('withdraw_fee');
    const feeRes = await contract.getConfig(param);
    const feeTemp = `${feeRes[1] / 100}%`;
    setFee(feeTemp);
  };

  const loadVaultStatus = async () => {
    if (!currentStrategy?.vaultAddress || !account || !contractContainer) return;
    const contract = injectContractReader(contractContainer);
    const status: VaultStatus = await contract.getValidVault(currentStrategy.vaultAddress, account);
    const statusText = vaultStatusTextMap[status];
    setVaultStatus(status);
    setVaultStatusText(statusText);
  };

  useEffect(() => {
    getFee();
    // loadVaultStatus();
  }, []);

  const changeVaultStatus = async (statusNumberInput: number) => {
    if (!currentStrategy?.vaultAddress || !account || !contractContainer) return;
    const contract = injectContractAppController(contractContainer).connect(contractContainer.getSigner(account));
    // const statusNumberHex = ethers.utils.hexZeroPad('0x2', 16);
    const promise = contract.setValidVault([currentStrategy.vaultAddress], [statusNumberInput]);
    await executeAndShowTx(promise);
    loadVaultStatus();
  };

  // 获取 Discounted Ratio
  useEffect(() => {
    if (currentStrategy && contractContainer)
      (async () => {
        const contract = injectContractReader(contractContainer);
        const res: number = await contract.getAssetDiscount(
          currentStrategy.address,
          currentStrategy.singleOrLp === TokenType.LP,
        );
        const discountRatioStr = `${res / 100}%`;
        setCollateralFactor(discountRatioStr);
      })();
  }, [currentStrategy, contractContainer]);

  const { tokenData } = useTokenData();
  const { apyInfo } = tokenData;

  // const lpToken = useState<Token>();
  const navigate = useNavigate();

  const onLpTokenListChange = (token: Token) => {
    navigate(`/deposit-detail/${token.address}`);
  };

  const firstRowList: ListRow[] = [
    {
      label: 'APY',
      content: apyInfo?.totalApy ? `${apyInfo.totalApy.toFixed(2)}%` : '-',
    },
    // {
    //   label: '是否作为抵押品',
    //   content: vaultStatus || '-',
    // },
    // {
    //   label: '',
    //   content: (
    //     <FlexRow>
    //       <DButton
    //         disabled={vaultStatusNumber === 2}
    //         width={150}
    //         height={30}
    //         fontSize={12}
    //         onClick={async () => changeVaultStatus(2)}>
    //         设置为抵押品
    //       </DButton>
    //       <Margin left={20} />
    //       <DButton
    //         disabled={vaultStatusNumber === 1}
    //         width={150}
    //         height={30}
    //         fontSize={12}
    //         onClick={async () => changeVaultStatus(1)}>
    //         设置为非抵押品
    //       </DButton>
    //     </FlexRow>
    //   ),
    // },
    // {
    //   label: '是否作为抵押品',
    //   content: vaultStatus || '-',
    // },
    {
      label: 'Collateral Factor',
      content: (
        <FlexRow>
          <Margin left={5} />
          {collateralFactor || '-'}
        </FlexRow>
      ),
    },
    {
      label: 'Price',
      content: tokenData.price?.isZero()
        ? '-'
        : `$${commifyBigNumber(tokenData.price, {
            unit: 8,
            decimal: 4,
          })}`,
    },
    {
      label: 'Platform',
      content: (() => {
        if (isSingleToken) return '-';
        if (currentStrategy?.platform === 'Dodo') {
          return (
            <div>
              <a href="https://app.dodoex.io/" target="_blank" rel="noreferrer">
                Dodo
              </a>
            </div>
          );
        }

        return (
          <div>
            <a href="https://pancakeswap.finance/" target="_blank" rel="noreferrer">
              PancakeSwap
            </a>
          </div>
        );
      })(),
    },
    {
      label: 'Strategy',
      content: isSingleToken ? (
        'N/A'
      ) : (
        <span>
          LP maximization
          <InfoCircle text="Reinvesting farm reward to maximize the amount of LP tokens." />
        </span>
      ),
    },
    {
      label: 'Fee',
      content: fee || '-',
    },
  ];

  const walletBalance = useMemo(() => {
    if (!tokenData.walletBalance) return '-';
    if (!tokenData.price) return '-';
    return `$${commifyBigNumber(tokenData.walletBalance.mul(tokenData.price).div(1e8), { decimal: 4 })}`;
  }, [tokenData]);

  const vaultBalance = useMemo(() => {
    if (!tokenData.vaultBalance) return '-';
    if (!tokenData.price) return '-';
    return `$${commifyBigNumber(tokenData.vaultBalance.mul(tokenData.price).div(1e8), { decimal: 4 })}`;
  }, [tokenData]);

  const secondRowList = [
    {
      label: 'Wallet Balance',
      content: walletBalance,
      infoText: 'The value of selected assets left in your crypto wallet. ',
    },
    {
      label: 'Vault Balance',
      content: vaultBalance,
      infoText: 'The value of selected assets that is supplied to the Duet by you. ',
    },
    // {
    //   label: 'Reward Received',
    //   content: `${isSingleToken ? '0.0000' : reward} ${unit}`,
    //   infoText: 'Cumulative reward received. ',
    // },
  ];

  const selectableToken = useMemo(() => {
    if (isArchived) {
      return depositArchivedStrategyList;
    }
    return depositStrategyList;
  }, []);

  return (
    <Card width={430} height={466}>
      <SelectToken
        enableSelect={false}
        tokenSymbol={currentStrategy?.symbol}
        selectList={selectableToken}
        onChange={onLpTokenListChange}
      />
      <ListView rows={firstRowList} />
      <Margin top={20} />
      <ListView title="Information" rows={secondRowList} />
    </Card>
  );
};

export default Information;
