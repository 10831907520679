import React from 'react';
import globalStore from '@/stores/global';
import { Theme } from '@/assets/theme';
import DuetLogoDarkImage from '@/assets/duet-logo.png';
import DuetLogoLightImage from '@/assets/duet-logo-light.png';

type Props = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'alt' | 'src'>;

export const DuetLogo = (props: Props) => {
  const { dataTheme } = globalStore();
  return <img alt="duet-logo" {...props} src={dataTheme === Theme.dark ? DuetLogoDarkImage : DuetLogoLightImage} />;
};
