import { ContractInterface } from 'ethers';

export const DYTokenERC20Abi: ContractInterface = [
  'constructor(address,string,address)',
  'event Approval(address indexed,address indexed,uint256)',
  'event OwnershipTransferred(address indexed,address indexed)',
  'event Transfer(address indexed,address indexed,uint256)',
  'function DOMAIN_SEPARATOR() view returns (bytes32)',
  'function allowance(address,address) view returns (uint256)',
  'function approve(address,uint256) returns (bool)',
  'function balanceOf(address) view returns (uint256)',
  'function balanceOfUnderlying(address) view returns (uint256)',
  'function burn(uint256)',
  'function controller() view returns (address)',
  'function decimals() view returns (uint8)',
  'function decreaseAllowance(address,uint256) returns (bool)',
  'function deposit(uint256,address)',
  'function depositAll(address)',
  'function depositCoin(address,address) payable',
  'function depositTo(address,uint256,address)',
  'function earn()',
  'function inCaseTokensGetStuck(address,uint256)',
  'function increaseAllowance(address,uint256) returns (bool)',
  'function name() view returns (string)',
  'function nonces(address) view returns (uint256)',
  'function owner() view returns (address)',
  'function permit(address,address,uint256,uint256,uint8,bytes32,bytes32)',
  'function pricePerShare() view returns (uint256)',
  'function renounceOwnership()',
  'function send(address,uint256,bytes) returns (bool)',
  'function setController(address)',
  'function symbol() view returns (string)',
  'function totalSupply() view returns (uint256)',
  'function transfer(address,uint256) returns (bool)',
  'function transferFrom(address,address,uint256) returns (bool)',
  'function transferOwnership(address)',
  'function underlying() view returns (address)',
  'function underlyingAmount(uint256) view returns (uint256)',
  'function underlyingTotal() view returns (uint256)',
  'function withdraw(address,uint256,bool)',
  'function withdrawAll()',
];
