import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Margin from '../Margin';

interface Props {
  title: ReactNode;
  operations: ReactNode[];
}

const ModalHeader: FC<Props> = props => {
  const { title, operations } = props;
  return (
    <ModalHeaderWrapper>
      <ModalHeaderLeft>{title}</ModalHeaderLeft>
      <ModalHeaderRight>
        {operations.map((operation, index) => (
          <OperationItem key={index}>{operation}</OperationItem>
        ))}
      </ModalHeaderRight>
    </ModalHeaderWrapper>
  );
};

export default ModalHeader;

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalHeaderLeft = styled.div`
  font-size: 24px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  color: var(--text-main);

  @media (max-width: 768px) {
    font-size: 20px;
  }

  .arrow-span {
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 10px;
  }
`;

const ModalHeaderRight = styled.div`
  display: flex;

  .iconfont {
    color: var(--text-main);
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

const OperationItem = styled.div`
  margin-left: 20px;
`;
