import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import Margin from '@/components/Margin';
import PercentDisplay from '@/components/PercentDisplay';
import { DodoLpPair } from '@/config/types';
import {
  createFungibleContract__DuetDppController,
  createFungibleContract__IDODOV2,
} from '@/fixtures/web3/contract/generated-map';
import useApproved from '@/hooks/useApproved';
import executeAndShowTx from '@/utils/executeAndShowTx';
import getCdnResource from '@/utils/getCdnResource';
import { useWeb3 } from '@/web3';
import { formatEther, parseEther } from '@ethersproject/units';
import { useDebounceFn } from 'ahooks';
import { BigNumber, constants } from 'ethers';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SettingModal from './SettingModal';
import TokenInput from './TokenInput';

interface Props {
  selectedDodoLpPair: DodoLpPair;
  refreshData: () => void;
}

const CombineLp: FC<Props> = props => {
  const { selectedDodoLpPair, refreshData } = props;

  const [slippage, setSlippage] = useState<number>(0.001);

  const [inputAmount, setInputAmount] = useState('');
  const [inputAmount2, setInputAmount2] = useState('');
  const [sharePercent, setSharePercent] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);
  const [settingModalVisible, setSettingModalVisible] = useState(false);

  const { account, contractContainer } = useWeb3();
  const inputTokenSymbol = selectedDodoLpPair.token0.symbol;
  const outputTokenSymbol = selectedDodoLpPair.token1.symbol;
  const inputTokenAddress = selectedDodoLpPair.token0.address;
  const outputTokenAddress = selectedDodoLpPair.token1.address;
  const { dppController } = selectedDodoLpPair;

  const getOutputAmount = async (value: string) => {
    if (!contractContainer || !account) return;
    const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
    const res = await contract.connect(contractContainer.getSigner(account)).recommendQuoteInAmount(parseEther(value));
    if (res.baseAdjustedInAmount.eq(constants.Zero)) return;
    setInputAmount2(formatEther(res.quoteAdjustedInAmount));
  };

  const { run: getOutputAmountDebounce } = useDebounceFn(getOutputAmount, {
    wait: 500,
  });

  const getInputAmount = async (value: string) => {
    if (!contractContainer || !account) return;
    const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
    const res = await contract.connect(contractContainer.getSigner(account)).recommendBaseInAmount(parseEther(value));
    if (res.baseAdjustedInAmount.eq(constants.Zero)) return;
    setInputAmount(formatEther(res.baseAdjustedInAmount));
  };

  const { run: getInputAmountDebounce } = useDebounceFn(getInputAmount, {
    wait: 500,
  });

  const handleInputAmountChanged = useCallback(async (value: string) => {
    if (!account) return;
    if (value === '') {
      setInputAmount2('');
    }
    setInputAmount(value);
    getOutputAmountDebounce(value);
  }, []);

  const handleInputAmount2Changed = useCallback(async (value: string) => {
    if (!account) return;
    if (value === '') {
      setInputAmount2('');
    }
    setInputAmount2(value);
    getInputAmountDebounce(value);
  }, []);

  useEffect(() => {
    if (!contractContainer) return;
    if (inputAmount === '') {
      setSharePercent('');
      return;
    }
    if (!account) return;
    (async () => {
      const dppControllerContract = createFungibleContract__DuetDppController(contractContainer, dppController);
      const dodoDppAddress = await dppControllerContract.connect(contractContainer.getSigner(account))._DPP_ADDRESS_();
      const contract = createFungibleContract__IDODOV2(contractContainer, dodoDppAddress);
      const res = await contract.getVaultReserve();
      const inputAmountBigNumber = parseEther(inputAmount);
      const sharePercentTemp = inputAmountBigNumber
        .mul(1000000)
        .div(res.baseReserve.add(inputAmountBigNumber))
        .toNumber();
      setSharePercent('' + sharePercentTemp / 10000);
    })();
  }, [inputAmount]);

  const supply = async () => {
    if (!account || !contractContainer) return;
    const contract = createFungibleContract__DuetDppController(contractContainer, dppController);
    const promise = contract.connect(contractContainer.getSigner(account)).addDuetDppLiquidity(
      parseEther(inputAmount),
      parseEther(inputAmount2),
      parseEther(inputAmount)
        .mul(BigNumber.from('10000').sub(slippage * 10000))
        .div(10000),
      parseEther(inputAmount2)
        .mul(BigNumber.from('10000').sub(slippage * 10000))
        .div(10000),
      0,
      9999999999,
    );

    await executeAndShowTx(promise);
    setInputAmount('');
    setInputAmount2('');
    setRefreshCount(refreshCount + 1);
    refreshData();
  };

  const input2PerInput1 = useMemo(() => {
    if (!inputAmount || !inputAmount2 || parseEther(inputAmount).eq(constants.Zero)) return '-';
    return String(parseEther(inputAmount2).mul(10000).div(parseEther(inputAmount)).toNumber() / 10000);
  }, [inputAmount, inputAmount2]);

  const input1PerInput2 = useMemo(() => {
    if (!inputAmount || !inputAmount2 || parseEther(inputAmount2).eq(constants.Zero)) return '-';
    return String(parseEther(inputAmount).mul(10000).div(parseEther(inputAmount2)).toNumber() / 10000);
  }, [inputAmount, inputAmount2]);

  const {
    approve: input1Approve,
    approveLoading: input1ApproveLoading,
    approved: input1Approved,
    approvedStatusLoading: input1ApprovedStatusLoading,
  } = useApproved({
    tokenAddress: inputTokenAddress,
    approveTo: dppController,
  });

  const {
    approve: input2Approve,
    approveLoading: input2ApproveLoading,
    approved: input2Approved,
    approvedStatusLoading: input2ApprovedStatusLoading,
  } = useApproved({
    tokenAddress: outputTokenAddress,
    approveTo: dppController,
  });

  const changeSetting = () => {
    setSettingModalVisible(true);
  };

  if (settingModalVisible) {
    return (
      <SettingModal
        setSlippage={setSlippage}
        slippage={slippage}
        visible={settingModalVisible}
        onClose={() => setSettingModalVisible(false)}
      />
    );
  }

  return (
    <CombineLpWrapper>
      <FlexRow direction="column">
        <TokenInput
          tokenSymbol={inputTokenSymbol}
          tokenImage={getCdnResource(`/token/${inputTokenSymbol}.png`)}
          value={inputAmount}
          onChange={handleInputAmountChanged}
          tokenAddress={inputTokenAddress}
          key={2 * refreshCount + 1}
        />

        <i className="iconfont icon-jia" style={{ fontSize: 30, marginBottom: 10 }} />

        <TokenInput
          tokenSymbol={outputTokenSymbol}
          tokenImage={getCdnResource(`/token/${outputTokenSymbol}.png`)}
          value={inputAmount2}
          onChange={handleInputAmount2Changed}
          tokenAddress={outputTokenAddress}
          key={2 * refreshCount + 2}
        />
      </FlexRow>

      <PriceAndShareInfo>
        <div className="title">Prices and pool share</div>
        <InformationArea>
          <div className="info-item">
            <div className="info-label">{input2PerInput1}</div>
            <div className="info-value">
              {outputTokenSymbol} per {inputTokenSymbol}
            </div>
          </div>
          <div className="info-item">
            <div className="info-label">{input1PerInput2}</div>
            <div className="info-value">
              {inputTokenSymbol} per {outputTokenSymbol}
            </div>
          </div>
          <div className="info-item">
            <div className="info-label">{sharePercent || '-'}%</div>
            <div className="info-value">Share of Pool</div>
          </div>
        </InformationArea>
        <AdditionalInfo>
          <div className="additional-left">Slippage Tolerance</div>
          <div className="additional-right cursor-pointer" onClick={changeSetting}>
            <PercentDisplay>{slippage * 100}</PercentDisplay>
            <Margin left={10} />
            <i className="iconfont icon-shezhi" />
          </div>
        </AdditionalInfo>
        <AdditionalInfo>
          <div className="additional-left">LP reward APR</div>
          <div className="additional-right">-</div>
        </AdditionalInfo>
      </PriceAndShareInfo>
      <Margin top={50} />
      {!input1Approved && (
        <DButton loading={input1ApproveLoading || input1ApprovedStatusLoading} onClick={input1Approve}>
          Approve {inputTokenSymbol}
        </DButton>
      )}
      <Margin top={20} />
      {!input2Approved && (
        <DButton loading={input2ApproveLoading || input2ApprovedStatusLoading} onClick={input2Approve}>
          Approve {outputTokenSymbol}
        </DButton>
      )}
      <Margin top={20} />
      {input1Approved && input2Approved && <DButton onClick={supply}>Supply</DButton>}
      <Margin top={20} />
      <Hint>
        * By adding liquidity you'll earn 0.17% of all trades on this pair proportional to your share of the pool. Fees
        are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
      </Hint>
    </CombineLpWrapper>
  );
};

export default CombineLp;

const CombineLpWrapper = styled.div`
  margin-top: 20px;
`;

const PriceAndShareInfo = styled.div`
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

export const InformationArea = styled.div`
  display: flex;
  justify-content: space-around;
  background: var(--bg-input);
  color: var(--text-main);
  border-radius: 16px;
  opacity: 1;
  height: 142px;
  margin-top: 10px;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .info-item {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-top: 10px;
    }

    .info-label {
      font-size: 24px;
      font-weight: 500;
    }

    .info-value {
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      opacity: 0.6;
    }
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .additional-left {
    font-size: 14px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: var(text-main);
  }

  .additional-right {
    display: flex;
    font-size: 14px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #7872ff;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const Hint = styled.div`
  font-size: 12px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: var(text-main);
  line-height: 14px;
`;
