import { FC, useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from '@ethersproject/units';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import FlexRow from '@/components/FlexRow';
import commifyBigNumber from '@/utils/commify';
import DInput from '@/components/DInput';
import toFixed from '@/utils/toFixed';
import useERC20Balance from '@/hooks/useERC20Balance';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { useStrategy } from '@/pages/DepositDetail/context';
import { useWeb3 } from '@/web3';
import { message } from 'antd';
import styles from './style.module.scss';
import { injectContractDyTokenERC20 } from '../../../../../contracts';

export interface VaultInfo {
  assets: BigNumber;
  syntheticAssets: BigNumber;
  debtToAssetRatio: string;
}

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
  hideTokenArea?: boolean;
}

const Supply: FC<Props> = props => {
  const { refreshDataCount, refreshData, hideTokenArea } = props;
  const { account, contractContainer } = useWeb3();
  const [inputAmount, setInputAmount] = useState('');
  const strategy = useStrategy();
  const dyTokenAddress = strategy?.dyTokenAddress;

  const { accountBalance, refreshAccountBalance } = useERC20Balance(strategy?.address);

  useEffect(() => {
    if (!account) return;
    refreshAccountBalance();
  }, [refreshDataCount]);

  // 点击 supply 按钮
  const handleSupplyClick = async () => {
    if (!strategy || !contractContainer || !account) return;
    const { dyTokenAddress: currentDyTokenAddress, vaultAddress } = strategy;
    // directly supply
    const contract = injectContractDyTokenERC20(contractContainer, currentDyTokenAddress).connect(
      contractContainer.getSigner(account),
    );
    const promise = contract.deposit(parseEther(inputAmount), vaultAddress);
    try {
      await contract.estimateGas.deposit(parseEther(inputAmount), vaultAddress);

      await executeAndShowTx(promise, {
        throwError: true,
      });
      refreshData();
      setInputAmount('');
    } catch (e: any) {
      if (e?.data?.message === 'execution reverted: mismatch price') {
        const errorMessage = 'Strategy subscription suspended due to peg deviation.';
        message.error(errorMessage);
        console.error(`${errorMessage}. Error: `, e);
      }
    }
  };

  const handleAmountChange = (amount: string) => {
    setInputAmount(amount);
  };

  const buttonDisabled = !inputAmount || +inputAmount === 0;

  return (
    <section className={styles.SupplyWithDrawWrapper}>
      {!hideTokenArea && (
        <>
          <section className={styles.TokenDisplay}>{strategy?.symbol}</section>
          <Margin top={30} />
        </>
      )}
      <DInput value={inputAmount} onChange={handleAmountChange} maxAmount={toFixed(accountBalance)} />
      <Margin top={10} />
      <div className={styles.balance}>
        Wallet Balance<div className={styles.balanceValue}>{commifyBigNumber(accountBalance)}</div>
      </div>
      <Margin top={30} />
      <FlexRow justifyContent="flex-start">
        <DButton
          onClick={handleSupplyClick}
          disabled={buttonDisabled}
          approveFrom={strategy?.address}
          approveTo={dyTokenAddress}>
          Supply
        </DButton>
      </FlexRow>
    </section>
  );
};

export default Supply;
