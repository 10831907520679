/* eslint-disable no-console */
import React from 'react';
import cs from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message } from 'antd';
import { scanAddressMap } from '@/config/network';
import { connectEagerlyManager, useWeb3 } from '@/web3';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import styles from './style.module.scss';

interface WalletDetailProps {
  closeModal: () => void;
}

const WalletDetail: React.FC<WalletDetailProps> = props => {
  const { closeModal } = props;

  const { account, chainId, providerName, connector } = useWeb3();
  const disconnectable = !(!connector || connector instanceof GnosisSafe);

  const disconnectWallet = async () => {
    connectEagerlyManager.suppress();
    if (!connector) return;
    try {
      await connector.deactivate();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopy = () => {
    message.success('Copied!');
  };

  const viewOnExplorer = () => {
    if (!chainId) return;
    const prefix = scanAddressMap[chainId];
    window.open(`${prefix}/address/${account}`, '_blank');
  };

  return (
    <section className={styles.WalletDetailWrapper}>
      <div className={styles.header}>
        <span className={styles.account}>Account</span>
        <i className={cs('iconfont icon-guanbi2', styles.iconfont)} onClick={closeModal} />
      </div>
      <div className={styles.infoArea}>
        <div className={styles.walletRow}>
          {providerName && <span className={cs(styles.span, styles.wallet)}>Connected with {providerName}</span>}
          {disconnectable && (
            <div className={styles.disconnectButton} onClick={disconnectWallet}>
              Disconnect
            </div>
          )}
        </div>
        <div className={styles.accountRow}>
          {account || ''}
          {disconnectable && (
            <div className={styles.disconnectButton} onClick={disconnectWallet}>
              Disconnect
            </div>
          )}
        </div>
        <div className={styles.operateRow}>
          <CopyToClipboard text={account || ''} onCopy={handleCopy}>
            <div className={styles.copy}>
              <i className={cs('iconfont icon-copy', styles.iconfont)} />
              <span className={styles.span}>Copy Address</span>
            </div>
          </CopyToClipboard>
          <div className={styles.view} onClick={viewOnExplorer}>
            <i className={cs('iconfont icon-lianjie', styles.iconfont)} />
            <span className={styles.span}>View on Explorer</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletDetail;
