import { useToggle } from 'ahooks';
import { useEffect } from 'react';
import DButton from '@/components/DButton';
import Modal from '@/components/Modal';
import executeAndShowTx from '@/utils/executeAndShowTx';
import { useWeb3 } from '@/web3';
import { BondObject } from '../..';
import styles from './style.module.scss';
import rightIcon from '../../rightIcon.png';
import MaturedBondsDetails from '../MaturedBondsDetails';
import { injectContractSingleBond } from '../../../../contracts';

interface IProps {
  amount?: string | number;
  doneList: BondObject[];
  refreshData: () => void;
}

const MaturedBonds = ({ amount, doneList, refreshData }: IProps) => {
  const { account, contractContainer } = useWeb3();
  const [showModal, { set: setShowModal }] = useToggle(false);
  const [redeemDisabled, { set: setRedeemDisabled }] = useToggle(false);

  useEffect(() => {
    setRedeemDisabled(!account || amount === '--' || Number(amount) === 0);
  }, [account, amount]);

  // 兑换按钮点击
  const redeemClick = async () => {
    if (!contractContainer || !account) return;
    try {
      const contract = injectContractSingleBond(contractContainer).connect(contractContainer.getSigner(account));
      const res = await contract.redeemAll(account);
      await executeAndShowTx(res);
      refreshData();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className={styles.maturedBonds}>
      {/* 标题 */}
      <div className={styles.title}>Matured Bonds</div>
      {/* 数量 */}
      {amount !== '--' && Number(amount) !== 0 ? (
        <section className={styles.amountSec}>
          <span className={styles.amount}>{amount}</span>
          <span className={styles.unit}>$DUET</span>
        </section>
      ) : (
        <section className={styles.amountSec}>
          <span className={styles.unit}>No Matured Bonds</span>
        </section>
      )}

      {/* Redeem按钮 */}
      <section className={styles.button}>
        <DButton width={200} disabled={redeemDisabled} onClick={redeemClick}>
          Redeem
        </DButton>
      </section>
      {/* show Details */}
      {!redeemDisabled && (
        <section className={styles.showDetail} onClick={() => setShowModal(true)}>
          <span className={styles.text}>Show Details</span>
          <img className={styles.rightIcon} alt="rightIcon" src={rightIcon} />
        </section>
      )}

      <Modal visible={showModal} closeModal={() => setShowModal(false)}>
        <MaturedBondsDetails doneList={doneList} onClose={() => setShowModal(false)} />
      </Modal>
    </section>
  );
};

export default MaturedBonds;
