import { FC, PropsWithChildren } from 'react';
import styles from './style.module.scss';

interface Props {
  width?: number;
  // 如果不传 height 则自适应
  height?: number;
  minWidth?: number;
  minHeight?: number;
  title?: string;
}

const Card: FC<PropsWithChildren<Props>> = props => {
  const { width, height, minWidth, minHeight, children, title } = props;

  return (
    <section
      className={styles.CardWrapper}
      style={{
        width: (width != null && `${width}px`) || 'auto',
        height: (height != null && `${height}px`) || 'auto',
        minWidth,
        minHeight,
      }}>
      {title && <div className={styles.cardTitle}>{title}</div>}
      {children}
    </section>
  );
};

export default Card;
