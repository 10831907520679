import ebCakeImg1 from '@/assets/ebcake-rabbit.png';
import ebCakeImg2 from '@/assets/ebcake-hare.png';
import { FORKED_CHAIN_IDS } from '@/config/forked';
import { EbCake } from './server';

interface Group {
  groupName: string;
  ebCakes: EbCake[];
}

// TODO: name、addresses、bondFarmingPoolId、bondLpFarmingPoolId 从合约获取
const EbCakeGroupsTest: Group[] = [
  {
    groupName: 'beta',
    ebCakes: [
      {
        icon: ebCakeImg1,
        name: '',
        addresses: {
          tokenAddress: '0xE681048274Fdf8acA62C3438c517C2d0f30f3639',
          interactionContractAddress: '0xc02755E756F0271D4624648A7375B943622CD1F4',
          ebCakeFarmingPoolAddress: '0x58C0E8Bf91006ca729BEDd8C9272F4e5D9A74D26',
          ebCakeLpFarmingPoolAddress: '0x64a26007C84Ba6FAdcD8C1C79b9c4fd3fD59D268',
          ebCakeCakeAddress: '0x390fef3e04764ffee903227be253909e3d04f506',
        },
        bondFarmingPoolId: 0,
        bondLpFarmingPoolId: 1,
        price: null,
        totalSupply: null,
        yield: null,
        timeWindow: null,
        balance: null,
        lpBalance: null,
        singleToken: null,
        lpToken: null,
        singleTokenBaseInfo: {
          totalStaked: null,
        },
        lpTokenBaseInfo: {
          totalStaked: null,
        },
        ebCakeAPR: null,
      },
    ],
  },
  {
    groupName: 'yearly',
    ebCakes: [
      {
        icon: ebCakeImg1,
        name: '',
        addresses: {
          tokenAddress: '0x61041EB88bf3D28894498990a63EA1dc22aE1d09',
          interactionContractAddress: '0x5B8565c4EB979331367B8CDf09E6E0AB19ab4Cd5',
          ebCakeFarmingPoolAddress: '0x7130c34ed45430b1d0399fA306840F917a7be603',
          ebCakeLpFarmingPoolAddress: '0x191590acad9732467bd770fc8B29Da565D66dF03',
          ebCakeCakeAddress: '0x362708509039527184b08d3C3E55afD7301daAC5',
        },
        bondFarmingPoolId: 2,
        bondLpFarmingPoolId: 3,
        price: null,
        totalSupply: null,
        yield: null,
        timeWindow: null,
        balance: null,
        lpBalance: null,
        singleToken: null,
        lpToken: null,
        singleTokenBaseInfo: {
          totalStaked: null,
        },
        lpTokenBaseInfo: {
          totalStaked: null,
        },
        ebCakeAPR: null,
      },
    ],
  },
];

export const EbCakeGroupsBsc: Group[] = [
  {
    groupName: 'beta',
    ebCakes: [
      {
        icon: ebCakeImg1,
        name: '',
        addresses: {
          tokenAddress: '0x8BFDB449aa079E54948F03405AaA203d878B31B8',
          interactionContractAddress: '0xcB08E86e51aD11025619B01Ca28EEc6601FBa8f7',
          ebCakeFarmingPoolAddress: '0x514c4d92e300c6c80257DfB1DdF9B6C0Be925fC4',
          ebCakeLpFarmingPoolAddress: '0xdDDcAfC86268adB7C1f3371b95Fb3cb28F283A72',
          ebCakeCakeAddress: '0xe9bf4e81d0ba1b911f58ad5a71288e7d27a589c7',
        },
        bondFarmingPoolId: 0,
        bondLpFarmingPoolId: 1,
        price: null,
        totalSupply: null,
        yield: null,
        timeWindow: null,
        balance: null,
        lpBalance: null,
        singleToken: null,
        lpToken: null,
        singleTokenBaseInfo: {
          totalStaked: null,
        },
        lpTokenBaseInfo: {
          totalStaked: null,
        },
        ebCakeAPR: null,
      },
    ],
  },
  {
    groupName: 'yearly',
    ebCakes: [
      {
        icon: ebCakeImg2,
        name: '',
        addresses: {
          tokenAddress: '0x970c71c11B4e39bCdE0dFe4252985FB6633db7BD',
          interactionContractAddress: '0xbAe645Ef1c3493a77922ED7e501f3C993A064b4D',
          ebCakeFarmingPoolAddress: '0x5765C2e8E79C4aF6482f36DFFaE3846cae230b62',
          ebCakeLpFarmingPoolAddress: '0x5710566a04Bb81219F532976eCe1b01A7611285f',
          ebCakeCakeAddress: '0x2083fa23aB7c92dd1Af6fdb65e4FA463ff61Bc8e',
        },
        bondFarmingPoolId: 4,
        bondLpFarmingPoolId: 5,
        price: null,
        totalSupply: null,
        yield: null,
        timeWindow: null,
        balance: null,
        lpBalance: null,
        singleToken: null,
        lpToken: null,
        singleTokenBaseInfo: {
          totalStaked: null,
        },
        lpTokenBaseInfo: {
          totalStaked: null,
        },
        ebCakeAPR: null,
      },
      {
        icon: ebCakeImg1,
        name: '',
        addresses: {
          tokenAddress: '0x2936F05df2Ca613526c840B521ee86F90a14abF7',
          interactionContractAddress: '0xEeC5AE9C41968c2CD0F9D58ea1623D8E88a01211',
          ebCakeFarmingPoolAddress: '0xC340f85C67AdFc81fe31cB26583eefAc3D3004B6',
          ebCakeLpFarmingPoolAddress: '0x1Ac0Cb7453042666e1dA218916E831B711E3A541',
          ebCakeCakeAddress: '0x2b3d045f7ed8b8a4f67bb8140883e6411e86cb50',
        },
        bondFarmingPoolId: 2,
        bondLpFarmingPoolId: 3,
        price: null,
        totalSupply: null,
        yield: null,
        timeWindow: null,
        balance: null,
        lpBalance: null,
        singleToken: null,
        lpToken: null,
        singleTokenBaseInfo: {
          totalStaked: null,
        },
        lpTokenBaseInfo: {
          totalStaked: null,
        },
        ebCakeAPR: null,
      },
    ],
  },
];

const ebCakeGroupsMap: Record<string, Group[]> = {
  97: EbCakeGroupsTest,
  56: EbCakeGroupsBsc,
};
// eslint-disable-next-line prefer-destructuring,no-return-assign
FORKED_CHAIN_IDS.forEach(chainId => (ebCakeGroupsMap[chainId] = ebCakeGroupsMap[56]));
export const ebCakeGroups = ebCakeGroupsMap[import.meta.env.VITE_CHAIN_ID];
export const ebCakes =
  ebCakeGroups.find(group => group.groupName === import.meta.env.VITE_EBCAKE_GROUP)?.ebCakes || ([] as EbCake[]);
export const ebCake = ebCakeGroups.find(group => group.groupName === import.meta.env.VITE_EBCAKE_GROUP)?.ebCakes?.[0];
