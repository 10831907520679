import qs from 'query-string';
import axios from '@/utils/axios';

const reportRefAddress = (inviter: string, invitee: string) => {
  if (inviter === invitee) return;
  const referrer = window.localStorage.getItem(invitee);
  if (referrer) {
    return;
  }

  axios
    .post<{ code: number }>(
      '/invitation',
      qs.stringify({
        inviter,
        invitee,
      }),
    )
    .then(res => {
      if (res && res.data.code === 0) {
        window.localStorage.setItem(invitee, inviter);
        return;
      }
      if (res && res.data.code === 1004) {
        // already bind before
        window.localStorage.setItem(invitee, inviter);
      }
    });
};

export default reportRefAddress;
