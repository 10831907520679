import commifyBigNumber from '@/utils/commify';
import styled from '@emotion/styled';
import { BigNumber } from 'ethers';
import { FC, PropsWithChildren } from 'react';

interface Props {
  children: BigNumber | null;
  decimal?: number;
  showAround?: boolean;
}

const UsdDisplay: FC<Props> = props => {
  const { children, decimal = 4, showAround = false } = props;

  if (!children) return <>-</>;

  return (
    <UsdDisplayWrapper>
      {showAround && '~'}${' '}
      {commifyBigNumber(children, {
        unit: 8,
        decimal,
      })}
    </UsdDisplayWrapper>
  );
};

export default UsdDisplay;

const UsdDisplayWrapper = styled.div`
  font-family: Roboto;
  font-weight: 500;
`;
