import DButton from '@/components/DButton';
import PercentDisplay from '@/components/PercentDisplay';
import config from '@/config/config';
import { injectContractEbCakeLpFarmingPool } from '@/contracts/fungible';
import useERC20Balance from '@/hooks/useERC20Balance';
import { useExecuteAndShowTx } from '@/hooks/useExecuteAndShowTx';
import { useCatchError, useEbCakeInfo } from '@/pages/EbCake/context';
import { EbCake } from '@/pages/EbCake/server';
import commifyBigNumber from '@/utils/commify';
import { useWeb3 } from '@/web3';
import { BigNumber } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { FC, useEffect, useState } from 'react';
import BaseStakeView, { KeyValue } from './BaseStakeView';

interface Props {
  ebCake: EbCake;
}

const LpStake: FC<Props> = props => {
  const { ebCake: ebCakeInfo } = props;

  const { contractContainer, account } = useWeb3();
  const [inputAmount, setInputAmount] = useState('');
  const { refresh } = useEbCakeInfo();
  const { onError } = useCatchError();
  const executeAndShowTx = useExecuteAndShowTx();

  const stake = async () => {
    if (!contractContainer || !account || !ebCakeInfo) return;
    // TODO: 地址后面通过合约获取
    const contract = injectContractEbCakeLpFarmingPool(
      contractContainer,
      ebCakeInfo.addresses.ebCakeLpFarmingPoolAddress,
    ).connect(contractContainer.getSigner(account));

    await contract.estimateGas.stake(parseEther(inputAmount)).then(async gasEstimated => {
      await executeAndShowTx(
        contract.stake(parseEther(inputAmount), {
          gasLimit: gasEstimated.mul(12).div(10),
        }),
      );
      setInputAmount('');
      refresh();
    }, onError);
  };

  // TODO: 从合约获取数据
  const infos: KeyValue[] = [
    {
      key: 'Total Staked',
      value: commifyBigNumber(ebCakeInfo?.lpTokenBaseInfo?.totalStaked),
    },
    {
      key: 'ebCake APY',
      value: <PercentDisplay>{ebCakeInfo?.ebCakeAPR}</PercentDisplay>,
    },
    {
      key: 'bDuet APY',
      value: <PercentDisplay>{ebCakeInfo?.yield?.bDuetLpAPY}</PercentDisplay>,
    },
  ];

  if (!ebCakeInfo) return null;

  return (
    <BaseStakeView
      title={{
        text: 'LP Stake',
        description: `Staking with ${ebCakeInfo.name}/CAKE LP`,
      }}
      infos={infos}
      token={{
        balance: ebCakeInfo.lpBalance,
      }}
      operationButton={
        <DButton
          disabled={inputAmount === ''}
          height={40}
          width={120}
          onClick={stake}
          approveFrom={ebCakeInfo.addresses.ebCakeCakeAddress}
          approveTo={ebCakeInfo.addresses.ebCakeLpFarmingPoolAddress}>
          Stake
        </DButton>
      }
      addBalanceLink={
        <a
          style={{ whiteSpace: 'nowrap' }}
          target="_blank"
          href={`https://pancakeswap.finance/add/${config.CAKE}/${ebCakeInfo?.addresses.tokenAddress}`}
          rel="noreferrer">
          Add Liquidity
        </a>
      }
      inputAmount={inputAmount}
      setInputAmount={setInputAmount}
    />
  );
};

export default LpStake;
