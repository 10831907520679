import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { injectContractMintVault } from '../contracts';
import { useWeb3 } from '../web3';

export const useBorrowsAmount = (vaultAddress?: string) => {
  const { account, contractContainer } = useWeb3();
  const [borrowsAmount, setBorrowsAmount] = useState<BigNumber>();
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshBorrowsAmount = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    if (!account) {
      setBorrowsAmount(undefined);
      return;
    }
    if (!contractContainer) return;
    (async () => {
      if (!vaultAddress) return;
      const contract = injectContractMintVault(contractContainer, vaultAddress);
      const res = await contract.borrows(account);
      setBorrowsAmount(res);
    })();
  }, [vaultAddress, refreshCount, contractContainer]);

  return {
    borrowsAmount,
    refreshBorrowsAmount,
  };
};
