import { BigNumber, constants, ethers } from 'ethers';
import { FC, useEffect, useState } from 'react';
import { parseEther } from 'ethers/lib/utils';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import commifyBigNumber from '@/utils/commify';
import DButton from '@/components/DButton';
import TokenDisplay from '@/components/TokenDisplay';
import toFixed from '@/utils/toFixed';
import executeAndShowTx from '@/utils/executeAndShowTx';
import InfoCircle from '@/components/InfoCircle';
import FlexRow from '@/components/FlexRow';
import allLpTokens, { dAssetsStrategyList } from '@/config/allLpTokens';
import { useStrategy } from '@/pages/DAssetsDetail/context';
import { useWeb3 } from '@/web3';
import styles from './style.module.scss';
import { injectContractAppController, injectContractMintVault } from '../../../../../contracts';

export interface VaultInfo {
  assets: BigNumber;
  syntheticAssets: BigNumber;
  debtToAssetRatio: string;
  vaultBalance: BigNumber;
}

interface Props {
  refreshDataCount: number;
  refreshData: () => void;
}

const Borrow: FC<Props> = props => {
  const { refreshDataCount, refreshData } = props;
  const { account, contractContainer } = useWeb3();
  const strategy = useStrategy();
  const vaultAddress = strategy?.vaultAddress;
  const currentToken = allLpTokens.find(each => each.address === strategy?.address);

  const [inputAmount, setInputAmount] = useState('');
  const [maxCanBorrow, setMaxCanBorrow] = useState<BigNumber>(constants.Zero);

  useEffect(() => {
    if (!account) {
      setMaxCanBorrow(constants.Zero);
      return;
    }
    (async () => {
      if (!vaultAddress || !contractContainer) return;
      const contract = injectContractAppController(contractContainer);
      const res = await contract.maxBorrow(account, vaultAddress);
      setMaxCanBorrow(res);
    })();
  }, [account, vaultAddress, refreshDataCount, contractContainer]);

  const maxString = toFixed(maxCanBorrow);

  const borrow = async () => {
    if (!inputAmount || !vaultAddress || !account || !contractContainer) return;
    const contract = injectContractMintVault(contractContainer, vaultAddress).connect(
      contractContainer.getSigner(account),
    );
    const amount: BigNumber = parseEther(inputAmount);
    // if (inputAmount === maxString) {
    //   amount = parseEther(`${+inputAmount / 0.995}`);
    //   amount = maxCanBorrow.mul(1000).div(995).sub(1);
    // } else {
    //   amount = parseEther(`${+inputAmount / 0.995}`);
    // }
    const promise = contract.borrow(amount, { gasLimit: 1500000 });

    await executeAndShowTx(promise);

    refreshData();
    setInputAmount('');
  };

  const handleAmountChange = (amount: string) => {
    setInputAmount(amount);
  };

  const buttonDisabled = !inputAmount || +inputAmount === 0;

  return (
    <section className={styles.MintBurnWrapper}>
      <TokenDisplay token={currentToken} />
      <Margin top={20} />
      <FlexRow>
        <DInput value={inputAmount} onChange={handleAmountChange} maxAmount={maxString} />
        <Margin left={10} />
        <InfoCircle text="Due to a 0.5% fee, the amount of synthetic asset you receive is 99.5% of your entered amount." />
      </FlexRow>
      <Margin top={10} />
      <div className={styles.tips}>You Can Borrow: {commifyBigNumber(maxCanBorrow)}</div>
      <Margin top={30} />
      <DButton width={590} onClick={borrow} disabled={buttonDisabled}>
        Borrow
      </DButton>
    </section>
  );
};

export default Borrow;
