import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import commifyBigNumber from '@/utils/commify';

import styles from '@/pages/common/styles/style.module.scss';
import FlexRow from '@/components/FlexRow';
import ListView from '@/components/ListView';
import { ZERO_ADDRESS } from '@/constants';
import { DAssetsType, Strategy } from '@/config/types';
import Tabs from '@/components/Tab';
import { useWeb3 } from '@/web3';
import NormalButton from '@/components/NormalButton';
import config from '@/config/config';
import Margin from '@/components/Margin';
import { tradePlatforms } from '@/config/trade-platforms';
import UsdDisplay from '@/components/UsdDisplay';
import { formatEther, parseEther, parseUnits } from 'ethers/lib/utils';
import { getDusdMarketCap } from '@/utils/getDusdMarketCap';
import { injectContractReader } from '../../../../contracts';

enum TokenEnum {
  STABLE_COIN = 'STABLE_COIN',
  DASSETS = 'DASSETS',
  All = 'All',
}

export interface TableToken {
  symbol: string;
  image: string;
  apy: string;
  // marketCap: string;
  address: string;
  price: BigNumber;
  // 借款数量
  amount: BigNumber;
  // 借款价值
  value: BigNumber;
  // 市场上的借款总量
  marketCap: BigNumber;
  dAssetsType: DAssetsType;
}

interface Props {
  dAssetsType: 'common' | 'innovation' | 'archived';
  strategyList: Strategy[];
}

const DAssetsTable: React.FC<Props> = props => {
  const { strategyList, dAssetsType } = props;
  const isArchived = dAssetsType === 'archived';

  const navigate = useNavigate();
  const [tableTokens, setTableTokens] = useState<TableToken[]>([]);
  const [filteredTableTokens, setFilteredTableTokens] = useState<TableToken[]>([]);
  const [tableTokenLoading, setTableTokenLoading] = useState(false);
  const { account, contractContainer } = useWeb3();
  const [dataType, setDataType] = useState(TokenEnum.All);

  useEffect(() => {
    if (dataType === TokenEnum.All) {
      setFilteredTableTokens(tableTokens);
    } else if (dataType === TokenEnum.STABLE_COIN) {
      setFilteredTableTokens(tableTokens.filter(each => each.dAssetsType === DAssetsType.STABLE_COIN));
    } else if (dataType === TokenEnum.DASSETS) {
      setFilteredTableTokens(tableTokens.filter(each => each.dAssetsType === DAssetsType.DASSETS));
    } else {
      setFilteredTableTokens([]);
    }
  }, [tableTokens, dataType]);

  useEffect(() => {
    if (!contractContainer) return;
    (async () => {
      const vaults = strategyList.map(each => each.vaultAddress);
      const contract = injectContractReader(contractContainer);
      setTableTokenLoading(true);
      const res = await contract.getDTokenVaultPrice(vaults, account || ZERO_ADDRESS, false);
      setTableTokenLoading(false);

      let tableTokenTemp: TableToken[] = strategyList.map((token, index) => ({
        symbol: token.symbol,
        image: token.image,
        apy: '0',
        address: token.address,
        amount: res[0][index],
        price: res[1][index],
        value: res[2][index],
        marketCap: res[3][index],
        dAssetsType: token.dAssetsType || DAssetsType.NOT_DASSETS,
      }));

      if (tableTokenTemp.some(each => each.symbol === 'dUSD')) {
        const dusdMarketCap = await getDusdMarketCap(contractContainer);
        tableTokenTemp = tableTokenTemp.map(each => {
          if (each.symbol === 'dUSD') {
            return {
              ...each,
              marketCap: dusdMarketCap,
            };
          }
          return each;
        });
      }

      setTableTokens(tableTokenTemp);
    })();
  }, [contractContainer]);

  const renderPrice = (token: TableToken) => {
    return (
      <section className={styles.TableText}>
        {token.price
          ? `$${commifyBigNumber(token.price, {
              unit: 8,
              decimal: 4,
            })}`
          : '-'}
      </section>
    );
  };

  // const renderInterestRate = (token: TableToken) => {
  //   return <TableText>0.00%</TableText>;
  // };

  const renderAmount = (token: TableToken) => {
    return token.amount ? <section className={styles.TableText}>{commifyBigNumber(token.amount)}</section> : '-';
  };

  const renderValue = (token: TableToken) => {
    return token.value ? (
      <section className={styles.TableText}>
        {' '}
        {`$${commifyBigNumber(BigNumber.from(token.value), { unit: 8, decimal: 4 })}`}
      </section>
    ) : (
      '-'
    );
  };

  const renderMarketCap = (token: TableToken) => {
    return (
      <section className={styles.TableText}>
        <UsdDisplay>{token.marketCap}</UsdDisplay>
      </section>
    );
  };

  const renderOperation = (token: TableToken) => {
    if (token.symbol === 'dUSD') return null;

    return (
      <NormalButton
        onClick={e => {
          e.stopPropagation();
          const platForm = tradePlatforms.find(
            // dTMC 跳转到 PancakeSwap 交易, 其他的合成资产在 iZumi 交易
            each => each.name === (token.symbol === 'dTMC' ? 'PancakeSwap' : 'iZumi'),
          );

          if (platForm) {
            window.open(platForm.href(token.address));
          }
        }}>
        Trade
      </NormalButton>
    );
  };

  const columns: ColumnsType<TableToken> = [
    {
      title: 'Synthetic Assets',
      key: 'symbol',
      dataIndex: 'symbol',
      render: (symbol, item) => (
        <section className={styles.NameItem}>
          <section className={styles.ImgContent}>
            <img className={styles.ImgSelf} src={item.image} alt="" />
          </section>
          <div>{symbol}</div>
        </section>
      ),
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (price, token) => renderPrice(token),
    },
    // {
    //   title: 'Interest Rate',
    //   key: 'interestRate',
    //   dataIndex: 'interestRate',
    //   render: (APY, token) => renderInterestRate(token),
    // },
    {
      title: 'Amount',
      key: 'Balance',
      dataIndex: 'value',
      render: (value, token) => renderAmount(token),
    },
    {
      title: 'Value',
      key: 'liability',
      dataIndex: 'liability',
      render: (liability, token) => renderValue(token),
    },
    {
      title: 'Market Cap',
      key: 'marketCap',
      dataIndex: 'marketCap',
      width: 200,
      render: (marketCap, token) => renderMarketCap(token),
    },
    // {
    //   title: '',
    //   key: 'operation',
    //   dataIndex: '',
    //   render: (foo, token) => renderOperation(token),
    // },
  ];
  return (
    <section className={styles.DAssetsTableWrapper}>
      {dAssetsType === 'common' && (
        <Tabs
          type="underline"
          tabs={[
            {
              key: TokenEnum.All,
              label: 'All',
              content: '',
            },
            {
              key: TokenEnum.STABLE_COIN,
              label: 'Stabecoin',
              content: '',
            },
            {
              key: TokenEnum.DASSETS,
              label: 'dAssets',
              content: '',
            },
          ]}
          activeKey={dataType}
          onChange={setDataType}
        />
      )}
      <section className={styles.TableContent} style={{ padding: 0 }}>
        <Table
          onRow={record => {
            return {
              onClick: () => {
                if (isArchived) {
                  navigate(`/archived-dassets-detail/${record.address}`);
                } else {
                  navigate(`/dAssets-detail/${record.address}`);
                }
              },
            };
          }}
          loading={tableTokenLoading}
          dataSource={filteredTableTokens}
          columns={columns}
          pagination={false}
          sticky
          bordered={false}
          rowKey={record => record.symbol}
          locale={{
            emptyText: <Empty />,
          }}
        />
      </section>

      <div>
        {filteredTableTokens.map(token => (
          <section
            className={styles.TokenCard}
            key={token.symbol}
            onClick={() => {
              if (isArchived) {
                navigate(`/archived-dassets-detail/${token.address}`);
              } else {
                navigate(`/dAssets-detail/${token.address}`);
              }
            }}>
            <FlexRow>
              <img className={styles.tokenLogo} src={token.image} alt="" />
              <span className={styles.tokenName}>{token.symbol}</span>
            </FlexRow>
            <ListView
              rows={[
                {
                  label: 'Price',
                  content: renderPrice(token),
                },
                // {
                //   label: 'APY',
                //   content: renderInterestRate(token),
                // },
                {
                  label: 'Amount',
                  content: renderAmount(token),
                },
                {
                  label: 'Value',
                  content: renderValue(token),
                },
                {
                  label: 'TVL',
                  content: renderMarketCap(token),
                },
              ]}
            />
            <Margin top={10} />
            {renderOperation(token)}
          </section>
        ))}
      </div>
    </section>
  );
};
export default DAssetsTable;
