/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';

const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(31, 27, 27, 0.8);
  /* background: #161616; */
  z-index: 99;
`;

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = props => {
  const { children, visible, closeModal } = props;
  const bodyOverflow = useRef(window.getComputedStyle(document.body).overflow);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = bodyOverflow.current;
    }
  }, [visible]);

  const handleClick = (event: { target: any; currentTarget: any }) => {
    // The modal box is closed when the mask itself is clicked,
    // and it is not closed when the content of the modal box is clicked
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const modal = createPortal(<ModalDiv onClick={handleClick}>{children}</ModalDiv>, document.body);

  return <div>{visible && modal}</div>;
};

export default React.memo(Modal);
