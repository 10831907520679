import { ContractInterface } from 'ethers';

export const BondReaderAbi: ContractInterface = [
  'constructor(address,address,address,address,address)',
  'function bondsPerBlock(address,uint256) view returns (address[], uint256[])',
  'function calcDYTokenBondAward(address,uint256,uint256) view returns (address[], uint256[], uint256[])',
  'function epochPrice(address) view returns (uint256)',
  'function epochUsdVaule(address) view returns (uint256)',
  'function myBonds(address) view returns (uint256[], uint256[], uint256[], uint256[], string[])',
  'function poolPendingAward(address,address) view returns (address[], uint256[], uint256[], string[])',
];
