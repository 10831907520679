import { FC } from 'react';
import useIsMobile from '@/hooks/useIsMobile';
import FlexRow from '@/components/FlexRow';
import MyVault from '@/components/MyVault/MyVault';
import { Strategy } from '@/config/types';
import Card from '@/components/Card';
import Farm from './components/Farm';
import SupplyWithdraw from './components/SupplyWithdraw';
import Chart from './components/Chart';
import Information from './components/Information';
import styles from './style.module.scss';

interface Props {
  strategy: Strategy;
}

const CommonDepositDetail: FC<Props> = props => {
  const { strategy } = props;

  const isMobile = useIsMobile();

  return (
    <section className={styles.DepositDetailWrapper}>
      <MyVault />
      <section className={styles.CardContainer}>
        <FlexRow direction="column">
          <Information currentStrategy={strategy} />
          {!isMobile && (
            <Card width={430} height={440}>
              <Chart />
            </Card>
          )}
        </FlexRow>
        <FlexRow direction="column">
          <SupplyWithdraw />
          <Farm cardWidth={690} />
        </FlexRow>
      </section>
    </section>
  );
};

export default CommonDepositDetail;
