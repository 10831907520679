import Card from '@/components/Card';
import InfoCircle from '@/components/InfoCircle';
import Margin from '@/components/Margin';
import { FC, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import PercentDisplay from '@/components/PercentDisplay';
import { useWeb3 } from '@/web3';
import styles from './style.module.scss';
import { useTokenData } from '../../context';
import { IContractContainer, injectContractFeeConf } from '../../../../contracts';

const StrategyDescription: FC = () => {
  const tipIconStyle = { fontSize: 12 };

  const { contractContainer } = useWeb3();
  const [interestRatio, setInterestRatio] = useState('-');

  useEffect(() => {
    if (!contractContainer) return;
    getInterest(contractContainer);
  }, [contractContainer]);

  const { tokenData } = useTokenData();
  const { apyInfo } = tokenData;

  const getInterest = async (contractContainer: IContractContainer) => {
    const contract = injectContractFeeConf(contractContainer);
    const param = ethers.utils.formatBytes32String('dybusd_wd');
    const interest = await contract.getConfig(param);
    const interestRatioTemp = `-${interest[1] / 100}%`;
    setInterestRatio(interestRatioTemp);
  };

  return (
    <Card width={690}>
      <div className={styles.Title}>Strategy Description</div>
      <Margin top={20} />
      <div className={styles.Content}>
        BUSD Smart is an automated 2x leveraged farming strategy that exposes investors to Stablecoins only.
      </div>
      <Margin top={10} />
      <div className={styles.Content}>
        The strategy pairs borrowed dUSD with the deposited BUSD to form dUSD-BUSD LP on PancakeSwap.
      </div>
      <Margin top={10} />
      <div className={styles.SubTitle}>APY Breakdown</div>
      <Margin top={10} />
      <div className={styles.ApyInfos}>
        <div className={styles.EachInfo}>
          <div className={styles.InfoLabel}>
            Total APY
            <InfoCircle
              iconStyle={tipIconStyle}
              text="Total annualized return of the strategy based on past 7 days performance."
            />
          </div>
          <div className={styles.InfoValue}>
            <PercentDisplay>{apyInfo?.totalApy}</PercentDisplay>
          </div>
        </div>
        <div className={styles.EachInfo}>
          <div className={styles.InfoLabel}>
            Trading Fee APY
            <InfoCircle
              iconStyle={tipIconStyle}
              text="Annualized leveraged return of trading fees based on past 7 days."
            />
          </div>
          <div className={styles.InfoValue}>
            <PercentDisplay>{apyInfo?.swapFeeApr}</PercentDisplay>
          </div>
        </div>
        <div className={styles.EachInfo}>
          <div className={styles.InfoLabel}>
            Farm APY
            <InfoCircle
              iconStyle={tipIconStyle}
              text="Annualized return of $BONDED DUETs assuming bonds are held to maturity and the price of $DUET tokens remain the same at maturity date, based on past 7 days."
            />
          </div>
          <div className={styles.InfoValue}>
            <PercentDisplay>{apyInfo?.duetFarmApr}</PercentDisplay>
          </div>
        </div>
        <div className={styles.EachInfo}>
          <div className={styles.InfoLabel}>
            Interest
            <InfoCircle
              iconStyle={tipIconStyle}
              text="Interest charged for borrowing dUSDs, the interest is charged once only upon redemption regardless of the outstanding period of the loan."
            />
          </div>
          <div className={styles.InfoValue}>{interestRatio}</div>
        </div>
      </div>
      <Margin top={5} />
      <div className={styles.SubTitle}>Risks</div>
      <Margin top={10} />
      <div className={styles.RiskInfos}>
        <div className={styles.RiskItem}>
          <div className={styles.RiskLabel}>
            Impermanent Loss
            <InfoCircle
              iconStyle={tipIconStyle}
              text="dUSD is backed by over-collateralization and is pegged to 1 USD. The value of dUSD and BUSD are therefore not expected to deviate from each other substantially. Thus, Impermanent losses are expected to be minimal."
            />
          </div>
          <div className={styles.RiskValue}>Very Low</div>
        </div>
        <div className={styles.RiskItem}>
          <div className={styles.RiskLabel}>
            Risky Asset Exposure
            <InfoCircle
              iconStyle={tipIconStyle}
              text="The strategy borrows dUSD only, and purchases no risky assets with the underlying borrowings. The principal invested in the strategy is not affected by price movement of the crypto market."
            />
          </div>
          <div className={styles.RiskValue}>None</div>
        </div>
        <div className={styles.RiskItem}>
          <div className={styles.RiskLabel}>
            Liquidation Risk
            <InfoCircle
              iconStyle={tipIconStyle}
              text="The strategy manages risks well, solely investing in this strategy is highly unlikely to be liquidated"
            />
          </div>
          <div className={styles.RiskValue}>Very Low</div>
        </div>
        <div className={styles.RiskItem}>
          <div className={styles.RiskLabel}>
            Contract Risk
            <InfoCircle iconStyle={tipIconStyle} text="The strategy is being audited, use it at your own risk" />
          </div>
          <div className={styles.RiskValue}>Medium</div>
        </div>
      </div>
      <Margin top={20} />
      {/* <div className={styles.SubTitle}>Fees</div> */}
      <div className={styles.SubTitle}>Investment</div>
      <Margin top={10} />
      <div className={styles.Content}>
        By Investing BUSD only, the strategy will automatically borrow the correct amount of dUSD required to form LP at
        current prices.
      </div>
      <Margin top={20} />
      <div className={styles.SubTitle}>Redemption</div>
      <Margin top={10} />
      <div className={styles.Content}>
        Interest is deducted upon redemption. dUSD liabilities will be repaid and investors will receive either BUSD or
        a combination of BUSD and dUSD based on market conditions.
      </div>
      <Margin top={10} />
      <div className={styles.Content}>
        When the terminal amount of dUSD in LP is higher than the amount of dUSD borrowed, excess dUSD will be returned
        on redemption. dUSD is likely to trade at a lower price on redemption date than investment, investors could pay
        back dUSD liabilities in the system or sell once the price of dUSD returns to peg.
      </div>
      <Margin top={10} />
      <div className={styles.Content}>
        When the terminal amount of dUSD in the LP is lower than the amount of dUSD borrowed, some of the excess BUSD
        will be used to purchase dUSD to repay all liabilities. Investors will receive BUSD only in this case. Investing
        in BUSD Smart strategy will both increase your asset and liability, such that the asset-to-debt ratio is not
        affected. You can supply assets to other strategies if you wish to lower your asset-to-debt ratio by supplying
        new assets.
      </div>
    </Card>
  );
};
export default StrategyDescription;
