import { Theme } from '@/assets/theme';
import globalStore from '@/stores/global';
import { FC, useEffect, useState } from 'react';

interface Props {
  text: string;
}

const TransitionText: FC<Props> = props => {
  const { dataTheme } = globalStore();
  const { text } = props;
  const [showText, setShowText] = useState('');
  const [textColor, setTextColor] = useState('#FFFFFF');

  const lightMode = dataTheme === Theme.light;
  const originTextColor = lightMode ? '#613BF4' : '#FFFFFF';

  useEffect(() => {
    setTextColor(originTextColor);
  }, [lightMode]);

  useEffect(() => {
    setShowText(text);
    let timer: any;
    if (showText && showText !== '-') {
      setTextColor('#5DC647');
      timer = setTimeout(() => {
        setTextColor(originTextColor);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [text, originTextColor]);

  return <span style={{ color: textColor }}>{showText}</span>;
};

export default TransitionText;
