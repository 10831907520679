import { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import useTokenAddress from '@/hooks/useVaultAddress';
import { injectContractLinkUsdOracle, injectContractReader } from '../contracts';
import { useWeb3 } from '../web3';

type Origin = 'VaultReader' | 'Oracle';

const useVaultPrice = (tokenAddress?: string, origin: Origin = 'VaultReader') => {
  const { contractContainer } = useWeb3();
  const { vaultAddress } = useTokenAddress(tokenAddress);

  const [value, setValue] = useState<BigNumber | null>(null);
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshPrice = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    if (!tokenAddress || !vaultAddress || !contractContainer) return;
    (async () => {
      try {
        if (origin === 'VaultReader') {
          const contract = injectContractReader(contractContainer);
          const res = await contract.getVaultPrice(vaultAddress, parseEther('1'), false);
          setValue(res);
        } else {
          const contract = injectContractLinkUsdOracle(contractContainer);
          const res = await contract.getPrice(tokenAddress);
          setValue(res);
        }
      } catch (e) {
        console.error('Get price error');
      }
    })();
  }, [tokenAddress, refreshCount, contractContainer]);

  return { price: value, refreshPrice };
};

export default useVaultPrice;
