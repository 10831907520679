import config from '@/config/config';
import {
  IContractContainer,
  injectContractEbCake,
  injectContractEbCakeFarmingPool,
  injectContractEbCakeLpFarmingPool,
  injectContractERC20,
  injectContractLpToken,
  injectContractReader,
} from '@/contracts';
import { getTokenPrice } from '@/servers/getTokenPrice';
import { parseEther } from '@ethersproject/units';
import { BigNumber, constants } from 'ethers';
import moment, { Moment } from 'moment';
import { Contract, Provider } from 'ethers-multicall';
import { MultiRewardsMasterChefAbi } from '@/abis/MultiRewardsMasterChefAbi';
import { getMulticallAbi } from '@/abis/utils/getMulticallAbi';
import { ERC20Abi } from '@/abis/ERC20Abi';
import { LpTokenAbi } from '@/abis/LpTokenAbi';
import { EbCakeFarmingPoolAbi } from '@/abis/EbCakeFarmingPoolAbi';
import { EbCakeLpFarmingPoolAbi } from '@/abis/EbCakeLpFarmingPoolAbi';
import { restfulClient } from '@/restful';

interface TimeWindow {
  redeemWindow: [Moment, Moment];
  convertWindow: [Moment, Moment];
  maturity: Moment;
}

export interface Yield {
  bDuetAPY: number | null;
  bDuetLpAPY: number | null;
}

export interface SingleTokenAccountInfo {
  staked: BigNumber | null;
  ebCakeEarnedToDate: BigNumber | null;
  bDuetPending: BigNumber | null;
  bDuetEarnedToDate: BigNumber | null;
}

export interface LpTokenAccountInfo {
  staked: BigNumber | null;
  ebCakePending: BigNumber | null;
  ebCakeEarnedToDate: BigNumber | null;
  bDuetPending: BigNumber | null;
  bDuetEarnedToDate: BigNumber | null;
}
export interface EbCake {
  icon: string;
  name: string;
  addresses: {
    // EbCake token 的地址
    tokenAddress: string;
    // EbCake 交互合约的地址
    interactionContractAddress: string;

    ebCakeFarmingPoolAddress: string;

    ebCakeLpFarmingPoolAddress: string;

    ebCakeCakeAddress: string;
  };
  bondFarmingPoolId: number;
  bondLpFarmingPoolId: number;

  // Account irrelevant
  price: string | null;
  yield: Yield | null;
  timeWindow: TimeWindow | null;
  singleTokenBaseInfo: {
    totalStaked: BigNumber | null;
  };
  lpTokenBaseInfo: {
    totalStaked: BigNumber | null;
  };
  ebCakeAPR: number | null;

  // Account related
  totalSupply: BigNumber | null;
  balance: BigNumber | null;
  lpBalance: BigNumber | null;
  singleToken: SingleTokenAccountInfo | null;
  lpToken: LpTokenAccountInfo | null;
}

export const getBDuetAPY = async (contractContainer: IContractContainer, ebCake: EbCake) => {
  const masterChefContract = new Contract(config.MULTI_REWARDS_MASTER_CHEF, getMulticallAbi(MultiRewardsMasterChefAbi));
  const ebCakeContract = new Contract(ebCake.addresses.tokenAddress, getMulticallAbi(ERC20Abi));

  const ethCallProvider = new Provider(contractContainer.context.library);
  await ethCallProvider.init();

  const [{ rewardPerBlock }, { allocPoint }, totalAllocPoint, balance] = await ethCallProvider.all([
    masterChefContract.rewardSpecs(0),
    masterChefContract.poolInfo(ebCake.bondFarmingPoolId),
    masterChefContract.totalAllocPoint(),
    ebCakeContract.balanceOf(ebCake.addresses.ebCakeFarmingPoolAddress),
  ]);

  if (balance.eq(constants.Zero)) return null;

  const readerContract = injectContractReader(contractContainer);

  let duetPrice: BigNumber | null = null;
  if (import.meta.env.VITE_NETWORK === 'bsctest') {
    duetPrice = BigNumber.from(1e8);
  } else {
    duetPrice = (await readerContract.getVaultPrice(config.DUET_VAULT, parseEther('1'), false)) as BigNumber;
  }
  const cakePrice = await getTokenPrice(contractContainer, config.CAKE_BUSD);

  const bDuetAPY =
    +rewardPerBlock
      .mul(10000)
      .mul(allocPoint)
      .div(totalAllocPoint)
      .mul((365 * 86400) / 3)
      .mul(duetPrice)
      .div(balance.mul(cakePrice)) / 100;

  return bDuetAPY;
};

export const getBDuetLpAPY = async (contractContainer: IContractContainer, ebCake: EbCake) => {
  const masterChefContract = new Contract(config.MULTI_REWARDS_MASTER_CHEF, getMulticallAbi(MultiRewardsMasterChefAbi));
  const ebCakeCakeContract = new Contract(ebCake.addresses.ebCakeCakeAddress, getMulticallAbi(ERC20Abi));

  const ethCallProvider = new Provider(contractContainer.context.library);
  await ethCallProvider.init();

  const [{ rewardPerBlock }, { allocPoint }, totalAllocPoint, balance] = await ethCallProvider.all([
    masterChefContract.rewardSpecs(0),
    masterChefContract.poolInfo(ebCake.bondLpFarmingPoolId),
    masterChefContract.totalAllocPoint(),
    ebCakeCakeContract.balanceOf(ebCake.addresses.ebCakeLpFarmingPoolAddress),
  ]);

  if (balance.eq(constants.Zero)) return null;

  const readerContract = injectContractReader(contractContainer);
  let duetPrice: BigNumber | null = null;
  if (import.meta.env.VITE_NETWORK === 'bsctest') {
    duetPrice = BigNumber.from(1e8);
  } else {
    duetPrice = (await readerContract.getVaultPrice(config.DUET_VAULT, parseEther('1'), false)) as BigNumber;
  }
  const cakePrice = await getTokenPrice(contractContainer, config.CAKE_BUSD);

  const lpContract = new Contract(ebCake.addresses.ebCakeCakeAddress, getMulticallAbi(LpTokenAbi));
  const [lpTotalSupply, { _reserve0, _reserve1 }, token0Address] = await ethCallProvider.all([
    lpContract.totalSupply(),
    lpContract.getReserves(),
    lpContract.token0(),
  ]);

  const cakeSupply = token0Address === config.CAKE ? _reserve0 : _reserve1;
  const lpPrice = cakeSupply.mul(cakePrice).mul(2).div(lpTotalSupply);

  const bDuetAPY =
    +rewardPerBlock
      .mul(10000)
      .mul(allocPoint)
      .div(totalAllocPoint)
      .mul((365 * 86400) / 3)
      .mul(duetPrice)
      .div(balance.mul(lpPrice)) / 100;

  return bDuetAPY;
};

export const getEbCakeToCakeRation = async (contractContainer: IContractContainer, LpTokenAddress: string) => {
  const contract = injectContractLpToken(contractContainer, LpTokenAddress);
  const token0Address = await contract.token0();
  let { _reserve0, _reserve1 } = await contract.getReserves();
  if (_reserve0.eq(constants.Zero)) return 0;
  if (token0Address === config.CAKE) {
    [_reserve0, _reserve1] = [_reserve1, _reserve0];
  }
  if (_reserve0.eq(constants.Zero)) return 0;
  const price = (_reserve1 as BigNumber).mul(10000).div(_reserve0);
  return +price / 10000;
};

export const getEbCakeInfo = async (contractContainer: IContractContainer, ebCake: EbCake): Promise<EbCake> => {
  const ebCakeContract = injectContractERC20(contractContainer, ebCake.addresses.tokenAddress);
  const ebCakeInteractionContract = injectContractEbCake(
    contractContainer,
    ebCake.addresses.interactionContractAddress,
  );
  const bondContract = injectContractEbCakeFarmingPool(contractContainer, ebCake.addresses.ebCakeFarmingPoolAddress);
  const lpBondContract = injectContractEbCakeLpFarmingPool(
    contractContainer,
    ebCake.addresses.ebCakeLpFarmingPoolAddress,
  );

  const [symbol, bDuetAPY, bDuetLpAPY, checkPoints, totalSupply, singleTokenTotalStaked, lpTokenTotalStaked, price] =
    await Promise.all([
      ebCakeContract.symbol(),
      getBDuetAPY(contractContainer, ebCake),
      getBDuetLpAPY(contractContainer, ebCake),
      ebCakeInteractionContract.checkPoints(),
      ebCakeInteractionContract.totalUnderlyingAmount(),
      bondContract.underlyingAmount(false),
      lpBondContract.totalLpAmount(),
      getEbCakeToCakeRation(contractContainer, ebCake.addresses.ebCakeCakeAddress),
    ]);

  const timeWindow: TimeWindow = {
    redeemWindow: [moment(+checkPoints.redeemableFrom * 1000), moment(+checkPoints.redeemableEnd * 1000)],
    convertWindow: [moment(+checkPoints.convertableFrom * 1000), moment(+checkPoints.convertableEnd * 1000)],
    maturity: moment(+checkPoints.maturity * 1000),
  };

  const newEbCake: EbCake = {
    ...ebCake,
    name: symbol,
    price: price.toFixed(2),
    totalSupply,
    timeWindow,
    yield: {
      bDuetAPY,
      bDuetLpAPY,
    },
    singleTokenBaseInfo: {
      totalStaked: singleTokenTotalStaked,
    },
    lpTokenBaseInfo: {
      totalStaked: lpTokenTotalStaked,
    },
  };

  return newEbCake;
};

const getSingleTokenAccountInfo = async (
  contractContainer: IContractContainer,
  account: string,
  ebCake: EbCake,
): Promise<SingleTokenAccountInfo> => {
  const bondContract = new Contract(ebCake.addresses.ebCakeFarmingPoolAddress, getMulticallAbi(EbCakeFarmingPoolAbi));
  const masterChefContract = new Contract(config.MULTI_REWARDS_MASTER_CHEF, getMulticallAbi(MultiRewardsMasterChefAbi));

  const ethCallProvider = new Provider(contractContainer.context.library);
  await ethCallProvider.init();

  const [{ shares, accNetStaked }] = await ethCallProvider.all([bondContract.usersInfo(account)]);
  const [stakedAmount, bDuetPendingRes, rewardLength] = await ethCallProvider.all([
    bondContract.sharesToBondAmount(shares),
    masterChefContract.pendingRewards(ebCake.bondFarmingPoolId, account),
    masterChefContract.getRewardSpecsLength(),
  ]);

  const ebCakeEarnedToDateTemp = stakedAmount.sub(accNetStaked as BigNumber);
  const ebCakeEarnedToDate = ebCakeEarnedToDateTemp.lt(constants.Zero) ? constants.Zero : ebCakeEarnedToDateTemp;

  const promises = new Array(+rewardLength).fill(null).map((each, index) => {
    return masterChefContract.getUserClaimedRewards(ebCake.bondFarmingPoolId, account, index);
  });
  const amounts: BigNumber[] = await ethCallProvider.all(promises);
  const bDuetClaimed = amounts.reduce((a, c) => a.add(c), constants.Zero);

  return {
    staked: stakedAmount,
    ebCakeEarnedToDate,
    bDuetPending: bDuetPendingRes[0].amount,
    bDuetEarnedToDate: bDuetClaimed.add(bDuetPendingRes[0].amount),
  };
};

const getLpTokenAccountInfo = async (
  contractContainer: IContractContainer,
  account: string,
  ebCake: EbCake,
): Promise<LpTokenAccountInfo> => {
  const lpBondContract = new Contract(
    ebCake.addresses.ebCakeLpFarmingPoolAddress,
    getMulticallAbi(EbCakeLpFarmingPoolAbi),
  );
  const masterChefContract = new Contract(config.MULTI_REWARDS_MASTER_CHEF, getMulticallAbi(MultiRewardsMasterChefAbi));

  const ethCallProvider = new Provider(contractContainer.context.library);
  await ethCallProvider.init();

  const [ebCakePending, { lpAmount: staked, claimedRewards: ebCakeClaimed }, bDuetPendingRes, rewardLength] =
    await ethCallProvider.all([
      lpBondContract.getUserPendingRewards(account),
      lpBondContract.usersInfo(account),
      masterChefContract.pendingRewards(ebCake.bondLpFarmingPoolId, account),
      masterChefContract.getRewardSpecsLength(),
    ]);

  const bDuetPending = bDuetPendingRes[0].amount;

  const promises = new Array(+rewardLength).fill(null).map((each, index) => {
    return masterChefContract.getUserClaimedRewards(ebCake.bondLpFarmingPoolId, account, index);
  });
  const amounts: BigNumber[] = await ethCallProvider.all(promises);
  const bDuetClaimed = amounts.reduce((a, c) => a.add(c), constants.Zero);

  return {
    staked,
    ebCakePending,
    ebCakeEarnedToDate: ebCakePending.add(ebCakeClaimed),
    bDuetPending,
    bDuetEarnedToDate: bDuetPending.add(bDuetClaimed),
  };
};

export const getEbCakeInfoWithAccount = async (
  contractContainer: IContractContainer,
  ebCake: EbCake,
  account: string,
): Promise<EbCake> => {
  const ebCakeContract = injectContractERC20(contractContainer, ebCake.addresses.tokenAddress);
  const lpContract = injectContractERC20(contractContainer, ebCake.addresses.ebCakeCakeAddress);

  const [ebCakeInfo, balance, lpBalance, singleTokenAccountInfo, lpTokenAccountInfo] = await Promise.all([
    getEbCakeInfo(contractContainer, ebCake),
    ebCakeContract.balanceOf(account),
    lpContract.balanceOf(account),
    getSingleTokenAccountInfo(contractContainer, account, ebCake),
    getLpTokenAccountInfo(contractContainer, account, ebCake),
  ]);

  const newEbCakeInfo: EbCake = {
    ...ebCakeInfo,
    balance,
    lpBalance,
    singleToken: singleTokenAccountInfo,
    lpToken: lpTokenAccountInfo,
  };

  return newEbCakeInfo;
};

export const renderTimeWindow = (timeWindow: [Moment, Moment] | null | undefined, showDetail = false) => {
  if (timeWindow == null) return '-';
  const formatStr = showDetail ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  const timeStr = timeWindow[0].format(formatStr) + ' ~ ' + timeWindow[1].format(formatStr);
  return timeStr;
};
