import Collapse from '@/components/Collapse';
import Margin from '@/components/Margin';
import { useEbcakeAPR } from '@/hooks/useEbcakeAPR';
import { useWeb3 } from '@/web3';
import styled from '@emotion/styled';
import { captureException } from '@sentry/browser';
import { message } from 'antd';
import moment, { Moment } from 'moment';
import { FC, ReactNode, useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Claim from './components/Claim';
import Convert from './components/Convert';
import Information from './components/Information';
import Manage from './components/Manage';
import QA from './components/QA';
import Redeem from './components/Redeem';
import { ebCakeGroups } from './config';
import { CatchErrorContext, EbCakeContext, normalizeError, UnknownError } from './context';
import { EbCake, getEbCakeInfo, getEbCakeInfoWithAccount } from './server';

interface Step {
  title: string;
  contentTitle: string;
  description: ReactNode;
  children: ReactNode;
}

const formatTime = (time: Moment | undefined) => {
  if (!time) return '-';
  const formatStr = 'YYYY-MM-DD HH:mm';
  return time.format(formatStr);
};

const EbCakePage: FC = () => {
  const { contractContainer, account } = useWeb3();

  const { group } = useParams();
  const currentGroup = ebCakeGroups.find(eachGroup => eachGroup.groupName === group);

  const [ebCakes, setEbCakes] = useState<EbCake[]>([]);
  const [currentEbCake, setCurrentEbCake] = useState<EbCake | null>(null);

  const [refreshCount, setRefreshCount] = useState(0);

  const ebCakeAPR = useEbcakeAPR(currentEbCake);

  const convertableEbCake = useMemo(() => {
    const nowMoment = moment();
    for (const ebCake of ebCakes) {
      if (
        ebCake.timeWindow?.convertWindow?.[0]?.isBefore(nowMoment) &&
        ebCake.timeWindow?.convertWindow?.[1]?.isAfter(nowMoment)
      ) {
        return ebCake;
      }
    }
    return null;
  }, [ebCakes]);

  useEffect(() => {
    if (!contractContainer || !currentGroup) return;

    if (currentGroup.ebCakes.length === 0) return;

    (async () => {
      let params: any[] = [];
      if (!account) {
        params = [contractContainer, ebCakes];
      }

      if (!account) {
        const ebCakes = await Promise.all(
          currentGroup.ebCakes.map(current => {
            return getEbCakeInfo(contractContainer, current);
          }),
        );

        setEbCakes(ebCakes);
        setCurrentEbCake(ebCakes[1]);
      } else {
        const ebCakes = await Promise.all(
          currentGroup.ebCakes.map(current => {
            return getEbCakeInfoWithAccount(contractContainer, current, account);
          }),
        );

        setEbCakes(ebCakes);
        setCurrentEbCake(ebCakes[1]);
      }
    })();
  }, [contractContainer, account, refreshCount, currentGroup]);

  const steps: Step[] = [
    {
      title: 'Step 1',
      contentTitle: 'Convert',
      description:
        'Convert and Stake your $CAKE to earn the highest APY available in the $CAKE syrup pool. Note: The $CAKE converted can not be redeemed before ' +
        formatTime(convertableEbCake?.timeWindow?.redeemWindow?.[0]) +
        '. However, you can sell your $ebCAKE at the secondary market to exit your position at varying rates. 2% of $CAKE profit will be charged as a management fee.',
      children: currentEbCake && <Convert refreshEbCakeInfo={() => setRefreshCount(refreshCount + 1)} />,
    },
    {
      title: 'Step 2',
      contentTitle: 'Manage',
      description: 'Trade, provide liquidity, or stake your $ebCAKE here',
      children: <Manage />,
    },
    {
      title: 'Step 3',
      contentTitle: 'Claim',
      description: (
        <>
          Claim your rewards anytime!
          <br />
          Please Note: $ebCAKE needs to be staked to earn a compounding return.
        </>
      ),
      children: <Claim />,
    },
    {
      title: 'Step 4',
      contentTitle: 'Redeem',
      description: 'Redeem ebCAKE for CAKE at 1:1 at maturity',
      children: <Redeem />,
    },
  ];

  const context = useMemo(
    () => ({
      ebCakes: ebCakes.map(each => {
        return {
          ...each,
          ebCakeAPR,
        };
      }),
      ebCakeInfo: currentEbCake ? { ...currentEbCake, ebCakeAPR } : null,
      refresh: () => {
        setRefreshCount(refreshCount + 1);
      },
    }),
    [ebCakes, currentEbCake, refreshCount, ebCakeAPR],
  );

  const handleContractError = useCallback((error: unknown) => {
    captureException(error);
    const errorMessage = normalizeError(error);
    message.error(errorMessage);
  }, []);

  const contractErrorContext = useMemo(
    () => ({
      onError: handleContractError,
    }),
    [handleContractError],
  );

  return (
    <Wrapper>
      <CatchErrorContext.Provider value={contractErrorContext}>
        <EbCakeContext.Provider value={context}>
          <Information />
          {steps.map(each => (
            <div key={each.title}>
              <Margin top={40} />
              <Collapse title={each.title} contentTitle={each.contentTitle} description={each.description}>
                {each.children}
              </Collapse>
            </div>
          ))}
          <Margin top={80} />
          <QA />
        </EbCakeContext.Provider>
      </CatchErrorContext.Provider>
    </Wrapper>
  );
};

export default EbCakePage;

const Wrapper = styled.div`
  width: 1140px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
