import React, { useState } from 'react';
import VaultDashboard from '@/components/MyVault/VaultDashboard';
import Tabs from '@/components/Tab';
import { getLocalStorage } from '@/utils/storage';
import styles from './style.module.scss';
import CommonDAssetsTable from './components/CommonDAssetsTable';
import InnovationDAssetsTable from './components/InnovationDAssetsTable';
import CautionModal from './components/CautionModal';
import ArchivedDAssetsTable from './components/ArchivedDAssetsTable';

const DepositList: React.FC = () => {
  const [cautionModalVisible, setCautionModalVisible] = useState(false);

  const handleClickInnovation = async () => {
    const agree = getLocalStorage('AgreeInnovationAssets');
    if (!agree) {
      setCautionModalVisible(true);
    }

    return true;
    // return true;
  };

  return (
    <section className={styles.DAssetsListWrapper}>
      <VaultDashboard />
      <section className={styles.TableArea}>
        <Tabs
          tabs={[
            {
              key: 'Main-Board Markets',
              label: 'Main-Board Markets',
              content: <CommonDAssetsTable />,
            },
            // {
            //   key: 'Innovation Markets',
            //   label: 'Innovation Markets',
            //   content: <InnovationDAssetsTable />,
            //   onClick: handleClickInnovation,
            // },
            {
              key: 'Archived',
              label: 'Archived',
              content: <ArchivedDAssetsTable />,
            },
          ]}
        />
        <CautionModal visible={cautionModalVisible} setModalVisible={setCautionModalVisible} />
      </section>
    </section>
  );
};

export default DepositList;
