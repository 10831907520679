import { FC, useEffect } from 'react';
import config from '@/config/config';
import useVaultPrice from '@/hooks/useVaultPrice';
import commifyBigNumber from '@/utils/commify';
import getCdnResource from '@/utils/getCdnResource';
import styles from './style.module.scss';
import UsdDisplay from '../UsdDisplay';

const DuetPrice: FC = () => {
  const { price, refreshPrice } = useVaultPrice(config.DUET);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshPrice();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshPrice]);

  return (
    <div className={styles.PriceAreaWrapper}>
      <img className={styles.DuetLogo} src={getCdnResource('/token/DUET.png')} alt="" />
      <span className={styles.DuetName}>DUET</span>
      <span className={styles.DuetPrice}>
        <UsdDisplay decimal={4}>{price}</UsdDisplay>
      </span>
    </div>
  );
};

export default DuetPrice;
