/* Auto generated by "scripts/gen/web3.ts" */

import { createContractInjector, createFungibleContractInjectorFactory } from './base';

import { ERC20__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { IBondFarmingPool__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { ICakePool__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { IERC20__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { IERC20Metadata__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { IExtendableBond__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { IMultiRewardsMasterChef__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { MockBEP20__factory } from '@duet-protocol/contracts/lib/specified/ebcake';
import { createContract__mockBDUET } from '@duet-protocol/contracts/lib/specified/ebcake';

export const createFungibleContract__ERC20 = createFungibleContractInjectorFactory((address, library) =>
  ERC20__factory.connect(address, library),
);
export const createFungibleContract__IBondFarmingPool = createFungibleContractInjectorFactory((address, library) =>
  IBondFarmingPool__factory.connect(address, library),
);
export const createFungibleContract__ICakePool = createFungibleContractInjectorFactory((address, library) =>
  ICakePool__factory.connect(address, library),
);
export const createFungibleContract__IERC20 = createFungibleContractInjectorFactory((address, library) =>
  IERC20__factory.connect(address, library),
);
export const createFungibleContract__IERC20Metadata = createFungibleContractInjectorFactory((address, library) =>
  IERC20Metadata__factory.connect(address, library),
);
export const createFungibleContract__IExtendableBond = createFungibleContractInjectorFactory((address, library) =>
  IExtendableBond__factory.connect(address, library),
);
export const createFungibleContract__IMultiRewardsMasterChef = createFungibleContractInjectorFactory(
  (address, library) => IMultiRewardsMasterChef__factory.connect(address, library),
);
export const createFungibleContract__MockBEP20 = createFungibleContractInjectorFactory((address, library) =>
  MockBEP20__factory.connect(address, library),
);
export const injectContract__mockBDUET = createContractInjector((chainId, library) => {
  const contract = createContract__mockBDUET(chainId, library);
  if (contract) return contract;
  throw new Error(`Unresolvable. chain=${chainId}; contract-instance=mockBDUET`);
});
